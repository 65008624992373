import toastr from 'toastr'
import { currencySymbol } from 'filters'
import { funcModal } from 'components/modals'
import { stripeService } from 'services'
import confirm from 'components/modals/confirm'

const components = {
  funcModal,
  confirm,
}

const methods = {
  onSubmitForm (payment_intent_id = null) {
    this.processing = true;
    let data = {};

    if (this.charge_customer) {
      data.charge_customer = this.charge_customer
    }

    data.payment_intent_id = payment_intent_id;

    this.$store.dispatch('reservations.revertCancel', {id: this.$route.params.id, data})
    .then(async (res) => {      
      let errors = this.$store.state.reservations.errors;
      if (errors) {
        if (!_.isEmpty(errors.errors)) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
        this.processing = false
      } else {
        var succeeded = res.data.succeeded;
        
        if(succeeded) {
          this.processing = false
          let reslabFailedMessage = res.data.reslab_failed_message ? res.data.reslab_failed_message : false;
          toastr.success('Reservation has been reverted')
          if (reslabFailedMessage) {
            this.$refs.warningConfirm.confirm(reslabFailedMessage, () => {
              this.$refs.modal.close();
            }, null, false)
          } else {
            this.$refs.modal.close();
          }
        }
        else {
          var clientSecret = res.data.client_secret;
          var requiresAction = res.data.requires_action;  

          if (requiresAction) {
              //3d secure required from stored card
              if(!this.stripe) {
                  const { stripe, elements } = await stripeService.init(this.currency_code);
                  this.stripe = stripe;
              }
              const { error: errorAction, paymentIntent } =  await this.stripe.handleCardAction(clientSecret);                        
              if (errorAction) {
                  this.processing = false;
                  toastr.error(errorAction.message);
              } else {
                this.onSubmitForm (paymentIntent.id)
                return;  
              }
          } else {
            toastr.error('Error while revert cancelled reservation.');
          }
        }
      }
    });
  },

  open () {
    this.$refs.modal.open();
  },

  onClose () {
    this.$emit('close');
  },

}

export default {
  name: "revert-cancel-modal",
  props: ['reservation', 'currency_code'],
  methods,
  components,
  data () {
    return {
      processing: false,
      errors: {},
      currencySymbol,
      charge_customer: true,
    }
  },
}
