var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show_table
    ? _c("datatable", {
        ref: "table",
        attrs: {
          "table-class": "table table-striped table-bordered",
          "ajax-url": _vm.url,
          columns: _vm.columns,
          "run-on-create": false,
          filters: _vm.filters
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-77564510", { render: render, staticRenderFns: staticRenderFns })
  }
}