import toastr from 'toastr'
import { currencySymbol } from 'filters'
import { funcModal } from 'components/modals'

const components = {
  funcModal
}

const methods = {
  onSubmitForm () {
    this.processing = true;
    this.$store.dispatch('reservations.updateCustomerEmail', {id: this.$route.params.id, data: this.form}).then((res) => {
      this.processing = false

      if (this.$store.state.reservations.errors) {
        if (!_.isEmpty(this.$store.state.reservations.errors.errors)) {
          this.errors = _.cloneDeep(this.$store.state.reservations.errors.errors);
        }
        toastr.error(this.$store.state.reservations.errors.message);
      } else {
        toastr.success('Customer email updated secceeded')
        this.$refs.modal.close();
      }
    })

  },

  open () {
    this.$refs.modal.open();
  },

  onClose () {
    this.$emit('close');
  }
}

export default {
  name: "customer-email-modal",
  props: ['data'],
  methods,
  components,
  data () {
    return {
      processing: false,
      errors: {},
      email: this.data.owned_by,
      form: {
        owned_by: this.data.owned_by
      }
    }
  }
}
