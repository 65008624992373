import toastr from 'toastr'
import env from 'constants/env'
import { funcModal } from 'components/modals'
import autocomplete from 'components/autocomplete'
import { dateFormat } from 'filters'

let components = {
    funcModal,
    autocomplete
}

let filters = {
    dateFormat
}

let methods = {
    open () {
        this.$refs.modal.open();
    },

    submit (modal) {
        this.errors = {}
        this.reservation = null;
        this.processing = true;
        this.$store.dispatch('dashboards.validateReservation', {
        id: this.reservation_id,
        location_id: this.location_id,
        }).then(() => {
            let errors = _.cloneDeep(this.$store.state.dashboards.errors);
            if (errors) {
                toastr.error(errors.message);
            } else {
                this.reservation = _.cloneDeep(this.$store.state.dashboards.reservation);
            }
            this.processing = false;
        });
    },

    onSelectLocation ({item}) {
        this.location_id = null;
        if (item) {
            this.location_id = item.id;
        }
    }
}

export default {
    name: 'validate-reservation',
    components,
    methods,
    filters,
    data () {
        return {
            errors: {},
            api_url: env.default.api,
            reservation_id: null,
            location_id: null,
            processing: false,
            reservation: null
        }
    },

    created () {

    }
}