import moment from 'moment'
import toastr from 'toastr'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import {tabs, tab} from 'components/tabs'
import sSelect from 'components/sSelect'
import { hasPermits } from 'directives'

const components = {
  layout,
  tabs,
  tab,
  datepicker,
  sSelect
}

const directives = {
  hasPermits
}

const computed = {
  locations () {
    let empty = [{
      text: '-- Select Location --',
      value: null
    }];

    if (!_.isEmpty(this.$store.state.locations.all)) {
      return empty.concat(this.$store.state.locations.all.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      }))
    }

    return empty;
  },
  // form () {
  //   let coupon = _.cloneDeep(this.$store.state.coupons.active);
  //
  //   return coupon;
  // },
}

const methods = {
  onSubmitForm () {
    this.errors = {}
    this.processing = true;
    let data = _.cloneDeep(this.form);
    data.start_date = moment(data.start_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    data.end_date = moment(data.end_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    this.$store.dispatch('coupons.edit', {
      id: this.$route.params.id, data
    }).then(() => {
      this.processing = false;
      if (this.$store.state.coupons.errors) {
        this.errors = _.cloneDeep(this.$store.state.coupons.errors.errors);
        toastr.error(this.$store.state.coupons.errors.message);
      } else {
        toastr.success('Coupon has been updated');
      }
    })
  },
  getCoupon () {
    this.$store.dispatch('coupons.get', this.$route.params.id).then(() => {
      this.loading = false;
      this.form = _.cloneDeep(this.$store.state.coupons.active);
      this.form.start_date = moment(this.form.start_date, ['YYYY-MM-DD']).format('MM/DD/YYYY');
      this.form.end_date = moment(this.form.end_date, ['YYYY-MM-DD']).format('MM/DD/YYYY');
      
      this.form.locations = this.form.locations.map((x) => {
        this.choosenLocations.push({text: x.name, value: x.id});
        return x.id
      });
    });
  },
  onSelectLocation (l) {
    if (this.form.locations.indexOf(l.value) === -1 && l.value != null) {
      this.form.locations.push(l.value);
      this.choosenLocations.push(l);
    }
    this.selectedLocation = {value: null, text: '-- Select Location --'};
  },
  onRemoveChosenLocation (location) {
    this.choosenLocations = this.choosenLocations.filter(
      (item) => item.value != location.value);
    this.form.locations = this.form.locations.filter(
      (item) => item != location.value);
  }
}

export default {
  name: 'edit-coupon',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: true,
      processing: false,
      errors: {},
      selectedLocation: {value: null, text: '-- Select Location --'},
      choosenLocations: [],
      form: {}
    }
  },
  created () {
    this.$store.dispatch('locations.getAll', {_all: true}).then(() => {
      this.getCoupon();
    });
  }
}
