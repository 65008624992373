import { http } from 'services'

// initial state
const state = () => ({
  errors: null,
  all: [],
})

// getters
const getters = {
}

// actions
const actions = {
  ['adminApiRequests.getAll'] ({ commit, state }) {
    commit('CLEAR_ERRORS')

    return (http.get('admin-api-requests')).then ((res) => {
      commit('GET_ALL_API_REQUESTS', res);
    }).catch ((res) => {
      commit('API_REQUESTS_ERROR', res);
    })
  },
  ['adminApiRequests.send'] ({ commit, state }, {url, type}) {
    commit('CLEAR_ERRORS')

    return (http[type](url)).then ((res) => {

    }).catch ((res) => {
      commit('API_REQUESTS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_API_REQUESTS (state, res) {
    state.all = res.data
  },

  //ERRORS
  API_REQUESTS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
