import layout from 'layouts/default'
import reservationsList from 'layouts/partials/reservationsList'
import confirm from 'components/modals/confirm'
import tabs from '../tabs'
import { dateFormat, currencySymbol } from 'filters'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  tabs,
  confirm,
  reservationsList,
}

const filters = {
  dateFormat,
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  }
}

const methods = {
  
  loadPage (page) {
    this.$route.query.page = page;
    let data = {
      page,
      _q: this.search_key,
      _sort: '-id'
    };

    data = _.assign(data, this.filter);

    this.loading = true;

    (this.$store.dispatch('locations.getReservations', { 
      location_id: this.$route.params.id, params: data
    })).then (() => {
      this.loading = false;
    });
  },
}

export default {
  name: 'reservations-tab',
  computed,
  directives,
  filters,
  methods,
  components,
  data () {
    return {
      loading: false,
      search_key: null,
      filter: {
        cancelled: null,
      },
      view_loadings: {},
      currencySymbol,
    }
  },

  created () {

    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.loadPage(1)
    ])
    .then(() => {
      this.loading = false;
    });
  }
}
