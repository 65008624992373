import { funcModal } from 'components/modals'
import fileUpload from 'components/fileUpload'

const components = {
  funcModal,
  fileUpload
}

const methods = {
  submit () {
    this.processing = true;
    this.errors = {};
    let file = this.file;
    let action = 'amenities.create'

    if (this.update) {
      action = 'amenities.update'
    }

    this.$store.dispatch(action, {
      file,
      display_name: this.form.display_name ? this.form.display_name : "",
      id: this.form.id
    }).then(() => {
      if (this.$store.state.amenities.errors) {
        this.errors = _.cloneDeep(this.$store.state.amenities.errors.errors);
      } else {
        this.$refs.modal.close();
      }

      this.processing = false;
    });

  },

  open () {
    this.$refs.modal.open();
  },

  fileSelectedToUpload (files) {
    console.log("files", files);
    if (files.length) {
      this.file = files[0];
    } else {
      this.file = null;
    }

    this.errors = {};
  },
}

export default {
  name: "create-amenity",
  props: ['onClose', 'update', 'formData'],
  methods,
  components,
  data () {
    return {
      errors: {},
      processing: false,
      file: null,
      form: {
        display_name: null
      }
    }
  },
  created () {
    if (this.formData) {
      this.form.display_name = this.formData.display_name
      this.form.id = this.formData.id
    }
  }
}
