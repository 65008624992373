import moment from 'moment'
import toastr from 'toastr'
import layout from 'layouts/default'
import { dateTime } from 'services'
import { currencySymbol } from 'filters'
import paymentForm from '../paymentForm'
import tripProtections from '../tripProtections'
import calculations from '../calculations'
import searchCriteria from '../searchCriteria'
import coupon from '../coupon'

const components = {
  layout,
  paymentForm,
  tripProtections,
  calculations,
  searchCriteria,
  coupon
}

const computed = {
  plans () {
    if (this.selected_location) {
      if (this.costs) {
        let plans = this.costs.trip_protections;
        return plans;
      } else {
        let plans = this.$store.state.tripProtections.all;
        return plans.map(plan => {
          const filteredPrices = plan.prices.filter(
            price => price.currency.id === this.selected_location.currency.id);
          // Return the plan only if it has matching prices
          if (filteredPrices.length > 0) {
            return {...plan,prices: filteredPrices};
          }
          return null;
        }).filter(plan => plan !== null);
      }
    }
  }
}

const methods = {
  changeSpotsNum (value) {
    this.form.number_of_spots = value
    _.forEach(this.extra_fields, (field) => {
      if (field.per_car) {
        this.form.extra_fields[field.name].length = value
      }
   })
  },
  search (data) {
    this.errors = {};
    this.search_processing = true;
    this.costs = null;
    this.coupon_enabled = false;
    this.locations = [];
    this.selected_location = {};
    this.selected_type = {};

    this.form = _.assign(this.form, data.form);
    this.selected_amenities = data.amenities;

    let required_fields = [
      'airport_id',
      'from_date',
      'from_time',
      'to_date',
      'to_time',
      'number_of_spots',
    ]

    for (let i in required_fields) {
      if (!this.form[required_fields[i]]) {
        this.errors[required_fields[i]] = ['the ' + required_fields[i].replace(/_/g, ' ') + ' field is required'];
        this.search_processing = false;
      }
    }

    if (!this.search_processing) return;

    this.$store.dispatch('ports.getLocations', {
      airport_id: this.form.airport_id,
      params: {
        amenities: this.selected_amenities.length ? this.selected_amenities.join(',') : null
      }
    }).then(() => {

      if (this.$store.state.ports.errors) {
        this.errors = _.cloneDeep(this.$store.state.ports.errors.errors);
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        this.locations = _.cloneDeep(this.$store.state.ports.active.locations);

        this.getMinPrices();
      }

      this.search_processing = false;

    });
  },

  getMinPrices () {
    this.$store.dispatch('reservations.clearMinPrices');

    let data = {
      airport_id: this.form.airport_id,
      number_of_spots: this.form.number_of_spots,
      trip_protection_id: this.form.trip_protection_id
    };

    data = _.assign(data, this.formatDatesToSend());

    for (let i in this.locations) {
      this.locations[i].grand_total = 'Calculating...';
      this.locations[i].soldout = false;
      this.locations[i].error = false;


      this.$store.dispatch('reservations.getMinPrice', {
        location_id: this.locations[i].id,
        data,
      }).then(() => {
        let min_response = this.$store.state.reservations.min_prices[this.locations[i].id];
        let grand_total = min_response.grand_total;

        if (!this.locations[i].reslab_id && min_response.number_of_days < this.locations[i].min_booking_days && min_response.number_of_days !=0) {
          this.locations[i].grand_total = `Minimum ${this.locations[i].min_booking_days} days required`;
          this.locations[i].error = true;
          this.$set(this.locations, i, this.locations[i]);
          return;
        }

        if (grand_total || grand_total === 0) {
          this.locations[i].grand_total = grand_total;
        } else {
          this.locations[i].grand_total = min_response.message;
          this.locations[i].error = true;
        }

        this.locations[i].soldout = min_response.soldout;

        this.$set(this.locations, i, this.locations[i]);
      });
    }
  },

  datesValid (location) {
    if (!this.selectedAirport.timezone.code) return true;
    let now = moment().tz(this.selectedAirport.timezone.code).add(location.hours_before_booking, 'hours');
    let formated_now = now.format('MM/DD/YYYY');
    let diff = dateTime.diffFromTimezone(
      this.form.from_date,
      this.selectedAirport.timezone.code,
      'MM/DD/YYYY',
      'hours',
      location.hours_before_booking
    );
    
    if (diff < 0) {
      location.grand_total = 'Please select date after ' + formated_now
      location.error = true;
      return false;
    }

    return true;
  },

  onSelectAirport (port) {
    this.selectedAirport = port;
  },

  getCosts () {
    this.errors = {};
    this.costs_processing = true;
    this.costs = null;
    this.coupon_enabled = false;

    let data = {
      location_id: this.selected_location.id,
      number_of_spots: this.form.number_of_spots,
      type_id: this.selected_type.id,
      trip_protection_id: this.form.trip_protection_id,
      coupon_code: this.form.coupon_code,
      convenience_fee: this.form.convenience_fee == "true" ? true : false,
      include_trip_protections: true
    }

    data = _.assign(data, this.formatDatesToSend());

    this.getTypeCosts(data).then(() => {
      if (this.$store.state.reservations.type_costs[data.type_id].errors) {
        this.errors = _.cloneDeep(this.$store.state.reservations.type_costs[data.type_id].errors);
        toastr.error(this.$store.state.reservations.type_costs[data.type_id].message);
      } else {
        this.costs = _.cloneDeep(this.$store.state.reservations.type_costs[data.type_id]);
        this.coupon_enabled = true;

      }

      this.costs_processing = false;
    });
  },

  formatDatesToSend () {
    let dates = {
      from_date: null,
      to_date: null
    };

    if (this.form.from_date) {
      dates.from_date = moment(this.form.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    if (this.form.to_date) {
      dates.to_date = moment(this.form.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    if (this.form.from_time) {
      dates.from_date = dates.from_date + ' ' + this.form.from_time + ":00"
    }

    if (this.form.to_time) {
      dates.to_date = dates.to_date + ' ' + this.form.to_time + ":00"
    }

    return dates
  },

  onChangeLocation (location) {
    this.types_loading = true;
    this.selected_location = location;
    this.types = [];
    Promise.all([
      this.$store.dispatch('locations.getLocationTypes', {location_id: location.id, from_reslab : 'true'}),
      this.$store.dispatch('locations.getExtraFields', {id: location.id, from_reslab: 'true'})
    ]).then(() => {
      this.types = _.cloneDeep(this.$store.state.locations.active.types);
      this.extra_fields = _.cloneDeep(this.$store.state.locations.active.extra_fields);

      this.form.extra_fields = {};
      for (let i in this.extra_fields) {
        this.form.extra_fields[this.extra_fields[i].name] = [""];
      }

      this.getAllTypesCosts();
      this.types_loading = false;
    })
  },

  getAllTypesCosts () {

    for (let i in this.types) {

      let data = {
        location_id: this.selected_location.id,
        number_of_spots: this.form.number_of_spots,
        type_id: this.types[i].id,
        convenience_fee: this.form.convenience_fee == "true" ? true : false
      };
      data = _.assign(data, this.formatDatesToSend());

      this.types[i].grand_total = 'Calculating...';
      this.types[i].number_of_days = 'Calculating...';
      this.types[i].soldout = false;
      this.types[i].error = false;

      this.getTypeCosts(data).then(() => {
        if (this.$store.state.reservations.type_costs[data.type_id].errors) {
          this.types[i].grand_total = _.cloneDeep(this.$store.state.reservations.type_costs[data.type_id].message);
          this.types[i].number_of_days = "";
          this.types[i].error = true;
        } else {
          let costs = _.cloneDeep(this.$store.state.reservations.type_costs[data.type_id])
          this.types[i].grand_total = costs.grand_total;
          this.types[i].number_of_days = costs.number_of_days;
          this.types[i].soldout = costs.soldout;
        }

        this.$set(this.types, i, this.types[i]);
      })
    }
  },

  getTypeCosts (data) {
    console.log('data', data);
    return this.$store.dispatch('reservations.getCosts', {data});
  },

  onSelectType (type) {
    console.log('type.soldout', type.soldout);
    console.log('isNaN(type.grand_total)', isNaN(type.grand_total));
    if (type.soldout || !type.grand_total || isNaN(type.grand_total)) return;

    this.selected_type = type;
    this.getCosts();
  },

  onSelectTripProtection (trip_protection_id) {
    this.form.trip_protection_id = trip_protection_id;
    this.getCosts();
  },

  onApplyCoupon (code) {
    this.form.coupon_code = code;
    this.getCosts();
  },

  onRemoveCoupon () {
    this.form.coupon_code = null;
    this.getCosts();
  },

  submit ({form, elements, stripe}) {
    this.errors = {};
    this.stripe = stripe;
    this.form_processing = true;

    this.form = _.assign(this.form, form);
    if (!this.form.click_to_pay) {
      stripe.createPaymentMethod({
        type: 'card',
        card: elements.card_element,
      }).then((result) => {
        this.payment = result;
        if (result.error) {
          this.form_processing = false;
          return;
        }

        this.form.payment_method_id = result.paymentMethod.id
        this.form.card_number = result.paymentMethod.card.last4
        this.form.expiry_month = result.paymentMethod.card.exp_month
        this.form.expiry_year = result.paymentMethod.card.exp_year
        this.form.brand = result.paymentMethod.card.brand
  
        this.sendData();
      });
    } else {
      this.sendData();
    }
  },

  sendData () {
    this.errors = {}
    this.form_processing = true;

    let data = {
      payment_method_id: this.form.payment_method_id,
      card_number: this.form.card_number,
      expiry_month: this.form.expiry_month,
      expiry_year: this.form.expiry_year,
      card_brand: this.form.brand,
      click_to_pay: this.form.click_to_pay,
      external_payment_link: this.form.externalPaymentLink,
      customer_name: this.form.customer_name,
      zipcode: this.form.zipcode,
      number_of_spots: this.form.number_of_spots,
      phone: this.form.phone,
      contact_email: this.form.contact_email,
      location_id: this.selected_location.id,
      type_id: this.selected_type.id,
      extra_fields: this.form.extra_fields,
      trip_protection_id: this.form.trip_protection_id,
      coupon_code: this.form.coupon_code,
      convenience_fee: this.form.convenience_fee == "true" ? true : false
    }

    data = _.assign(data, this.formatDatesToSend());

    this.createReservation(data);
  },

  createReservation (data) {    
    this.$store.dispatch('reservations.create',  _.cloneDeep(data)).then(async () => {
      let errors = this.$store.state.reservations.errors;
      if (errors) {
        this.form_processing = false;
        if (errors.status_code == 400) {
          toastr.error(this.$store.state.reservations.errors.message);
        } else {
          toastr.error(this.$store.state.reservations.errors.message);
          this.errors = _.cloneDeep(errors.errors) || {};
        }
      } else {
        let reservation = this.$store.state.reservations.active;
        var succeeded = reservation.succeeded;
        if(succeeded) {
          this.form_processing = false;
          toastr.success('A new Reservation has been created');
          this.$router.replace({name: 'reservations.list', query: {}});
        } else {
          var clientSecret = reservation.client_secret;
          var requiresAction = reservation.requires_action;  

          if (requiresAction) {
              const { error: errorAction, paymentIntent } =  await this.stripe.handleCardAction(clientSecret);
              if (errorAction) {
                this.form_processing = false;
                toastr.error(errorAction.message);
              }else {
                data.payment_intent_id = paymentIntent.id;
                this.createReservation (data)
                return;
              }
          } else {
              toastr.error('Payment process failed, Try again');
          }

        }      
      }
    })
  },

}

const watch = {
  ['form.convenience_fee'] (n, o) {
    if (n == o) return;
    this.getAllTypesCosts();
    this.getCosts();
  }
}

export default {
  name: 'create-reservation',
  components,
  methods,
  watch,
  computed,
  data () {
    let ports = [{
      text: "-- Select Airport --", 
      value: null,
      timezone: {}
    }];
    return {
      errors: {},
      loading: false,
      types_loading: false,
      search_processing: false,
      costs_processing: false,
      form_processing: false,
      coupon_enabled: false,
      googlePlace: null,
      selected_location: {},
      selected_type: {},
      costs: null,
      locations: [],
      types: [],
      ports,
      selectedAirport: ports[0],
      currencySymbol,
      selected_amenities: [],
      amenities: [],
      trip_protections: [],
      extra_fields: [],
      form: {
        airport_id: null,
        from_date: null,
        to_date: null,
        from_time: null,
        to_time: null,
        number_of_spots: 1,
        extra_fields: {},
        card_number: null,
        expiry_month: null,
        expiry_year: null,
        card_brand: null,
        customer_name: null,
        zipcode: null,
        phone: null,
        trip_protection_id: null,
        coupon_code: null,
        convenience_fee: "true",
      },
      stripe: null,
      payment: null,
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('ports.getAll', {_all: true}),
      this.$store.dispatch('amenities.getAll'),
      this.$store.dispatch('tripProtections.getAll', {_all: true}),
    ])
    .then(() => {
      this.amenities = this.$store.state.amenities.all;
      this.trip_protections = this.$store.state.tripProtections.all;

      this.ports = this.ports.concat(this.$store.state.ports.all.map(item => ({
        value: item.id,
        text: item.name + ` (${item.code})`,
        timezone: item.timezone
      })));
    })
  },
}
