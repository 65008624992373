import CreateCoupon from 'pages/coupons/create'
import CreateAutomatedCoupons from 'pages/coupons/createAutomatedCoupons'
import CreateBulkCoupons from 'pages/coupons/createBulkCoupons'
import ListCoupons from 'pages/coupons/list'
import EditCoupon from 'pages/coupons/edit'
import UsageCoupons from 'pages/coupons/usageCoupons'

export default [
  { path: '/coupons/create', name: 'coupons.create', component: CreateCoupon, meta: {permits: ['coupon_create']}},
  { path: '/coupons/create-automated-coupons/:id', name: 'coupons.createAutomatedCoupons', component: CreateAutomatedCoupons, meta: {permits: ['coupon_create']}},
  { path: '/coupons/create-bulk-coupons', name: 'coupons.createBulkCoupons', component: CreateBulkCoupons, meta: {permits: ['coupon_create']}},
  { path: '/coupons/:id', name: 'coupons.edit', component: EditCoupon, meta: {permits: ['coupon_show']}},
  { path: '/coupons', name: 'coupons.list', component: ListCoupons, meta: {permits: ['coupon_list']}},
  { path: '/usage-coupons', name: 'coupons.usageCoupons', component: UsageCoupons, meta: {permits: ['coupon_list']}},
]
