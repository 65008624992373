var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("h3", { staticClass: "mb-2" }, [_vm._v("Reservations (OSP and TT)")]),
    _vm._v(" "),
    _c("div", { staticClass: "card mb-4" }, [
      _c("div", { staticClass: "card-block" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-3" }, [
            _c("div", { staticClass: "margin-bottom-1x" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search_key,
                    expression: "search_key"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "search-field",
                  type: "text",
                  placeholder: "Res. # or Email"
                },
                domProps: { value: _vm.search_key },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search_key = $event.target.value
                  }
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6 margin-bottom-3x" }, [
            _c("div", { staticClass: "radio" }, [
              _c("span", [_vm._v("Search By:")]),
              _vm._v(" "),
              _c("label", { staticClass: "margin-left-2x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search_by,
                      expression: "search_by"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: null,
                    checked: _vm._q(_vm.search_by, null)
                  },
                  on: {
                    change: function($event) {
                      _vm.search_by = null
                    }
                  }
                }),
                _vm._v(" All\n            ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "margin-left-2x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search_by,
                      expression: "search_by"
                    }
                  ],
                  attrs: { type: "radio", value: "reservation_number" },
                  domProps: {
                    checked: _vm._q(_vm.search_by, "reservation_number")
                  },
                  on: {
                    change: function($event) {
                      _vm.search_by = "reservation_number"
                    }
                  }
                }),
                _vm._v(" Res #\n            ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "margin-left-2x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search_by,
                      expression: "search_by"
                    }
                  ],
                  attrs: { type: "radio", value: "email" },
                  domProps: { checked: _vm._q(_vm.search_by, "email") },
                  on: {
                    change: function($event) {
                      _vm.search_by = "email"
                    }
                  }
                }),
                _vm._v(" Email\n            ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "margin-left-2x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search_by,
                      expression: "search_by"
                    }
                  ],
                  attrs: { type: "radio", value: "customer_name" },
                  domProps: { checked: _vm._q(_vm.search_by, "customer_name") },
                  on: {
                    change: function($event) {
                      _vm.search_by = "customer_name"
                    }
                  }
                }),
                _vm._v(" Customer name\n            ")
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("You can search by date:")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "form-inline" }, [
              _c("div", { staticClass: "form-group margin-right-2x" }, [
                _c("label", { staticClass: "control-label margin-right-1x" }, [
                  _vm._v("Order date ")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-group" },
                  [
                    _c("datepicker", {
                      staticClass: "form-control reservation--date-picker",
                      attrs: { id: "order-date", "min-date": "none" },
                      model: {
                        value: _vm.filter.created_at,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "created_at", $$v)
                        },
                        expression: "filter.created_at"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c("span", { staticClass: "input-group-text" }, [
                        _c("i", { staticClass: "fa fa-calendar" })
                      ])
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group margin-right-2x" }, [
                _c("label", { staticClass: "control-label margin-right-1x" }, [
                  _vm._v("Check In ")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-group" },
                  [
                    _c("datepicker", {
                      staticClass: "form-control reservation--date-picker",
                      attrs: { id: "checkin-date", "min-date": "none" },
                      model: {
                        value: _vm.filter.from_date,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "from_date", $$v)
                        },
                        expression: "filter.from_date"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c("span", { staticClass: "input-group-text" }, [
                        _c("i", { staticClass: "fa fa-calendar" })
                      ])
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group margin-right-2x" }, [
                _c("label", { staticClass: "control-label margin-right-1x" }, [
                  _vm._v("Check Out")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-group" },
                  [
                    _c("datepicker", {
                      staticClass: "form-control reservation--date-picker",
                      attrs: { id: "checkout-date", "min-date": "none" },
                      model: {
                        value: _vm.filter.to_date,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "to_date", $$v)
                        },
                        expression: "filter.to_date"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c("span", { staticClass: "input-group-text" }, [
                        _c("i", { staticClass: "fa fa-calendar" })
                      ])
                    ])
                  ],
                  1
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "radio" }, [
              _c("span", [_vm._v("Cancelled:")]),
              _vm._v(" "),
              _c("label", { staticClass: "margin-left-2x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.cancelled,
                      expression: "filter.cancelled"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: null,
                    checked: _vm._q(_vm.filter.cancelled, null)
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.filter, "cancelled", null)
                    }
                  }
                }),
                _vm._v(" All\n            ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "margin-left-2x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.cancelled,
                      expression: "filter.cancelled"
                    }
                  ],
                  attrs: { type: "radio", value: "true" },
                  domProps: { checked: _vm._q(_vm.filter.cancelled, "true") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.filter, "cancelled", "true")
                    }
                  }
                }),
                _vm._v(" Yes\n            ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "margin-left-2x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.cancelled,
                      expression: "filter.cancelled"
                    }
                  ],
                  attrs: { type: "radio", value: "false" },
                  domProps: { checked: _vm._q(_vm.filter.cancelled, "false") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.filter, "cancelled", "false")
                    }
                  }
                }),
                _vm._v(" No\n            ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "radio" }, [
              _c("span", [_vm._v("Search In:")]),
              _vm._v(" "),
              _c("label", { staticClass: "margin-left-2x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.search_in,
                      expression: "filter.search_in"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: null,
                    checked: _vm._q(_vm.filter.search_in, null)
                  },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.filter, "search_in", null)
                    }
                  }
                }),
                _vm._v(" All\n            ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "margin-left-2x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.search_in,
                      expression: "filter.search_in"
                    }
                  ],
                  attrs: { type: "radio", value: "osp" },
                  domProps: { checked: _vm._q(_vm.filter.search_in, "osp") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.filter, "search_in", "osp")
                    }
                  }
                }),
                _vm._v(" One Stop Parking\n            ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "margin-left-2x" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter.search_in,
                      expression: "filter.search_in"
                    }
                  ],
                  attrs: { type: "radio", value: "tt" },
                  domProps: { checked: _vm._q(_vm.filter.search_in, "tt") },
                  on: {
                    change: function($event) {
                      return _vm.$set(_vm.filter, "search_in", "tt")
                    }
                  }
                }),
                _vm._v(" TripTeo\n            ")
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "margin-top-3x margin-top-3x margin-bottom-3x" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default btn-orange search-btn",
                    on: {
                      click: function($event) {
                        return _vm.loadPage(1)
                      }
                    }
                  },
                  [_vm._v("Search")]
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _vm.loading
          ? _c("div", { staticClass: "text-center" }, [
              _c("i", {
                staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw"
              })
            ])
          : _c(
              "div",
              { staticClass: "table-responsive" },
              [
                _c("div", [
                  _c("table", { staticClass: "table table-striped" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Res. No.")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("System")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Customer Name")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Contact Email")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Creation Date")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("From")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("To")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Grand Total")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Cancelled")]),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["reservation_show"],
                                expression: "['reservation_show']",
                                arg: "allow"
                              }
                            ]
                          },
                          [_vm._v("View")]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["reservation_update"],
                                expression: "['reservation_update']",
                                arg: "allow"
                              }
                            ]
                          },
                          [_vm._v("Edit")]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["reservation_list"],
                                expression: "['reservation_list']",
                                arg: "allow"
                              }
                            ]
                          },
                          [_vm._v("Resend Email")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.reservations, function(reservation) {
                        return _c("tr", [
                          _c("td", [
                            _vm._v(_vm._s(reservation.reservation_number))
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                reservation.system == "osp"
                                  ? "One Stop Parking"
                                  : "TripTeo"
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(reservation.history[0].customer_name))
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(reservation.history[0].contact_email))
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  reservation.created_at,
                                  "MM/DD/YYYY"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  reservation.history[0].from_date,
                                  "MM/DD/YYYY HH:mm"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  reservation.history[0].to_date,
                                  "MM/DD/YYYY HH:mm"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  reservation.history[0].grand_total,
                                  _vm.currencySymbol(reservation.currency_code)
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(reservation.cancelled ? "Yes" : "No"))
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["reservation_show"],
                                  expression: "['reservation_show']",
                                  arg: "allow"
                                }
                              ]
                            },
                            [
                              _vm.view_loadings[reservation.id]
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa fa-spinner fa-spin"
                                    })
                                  ])
                                : _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.showReservationDetails(
                                            reservation.id,
                                            reservation.system
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-eye",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["reservation_update"],
                                  expression: "['reservation_update']",
                                  arg: "allow"
                                }
                              ]
                            },
                            [
                              reservation.system == "osp"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "reservations.edit",
                                          params: { id: reservation.id }
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-pencil-square-o",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                : _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "unifiedReservations.edit",
                                          params: { id: reservation.id }
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-pencil-square-o",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["reservation_list"],
                                  expression: "['reservation_list']",
                                  arg: "allow"
                                }
                              ]
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.openSendToEmailModal(
                                        reservation.system,
                                        reservation.id,
                                        reservation.system == "osp"
                                          ? reservation.owned_by
                                          : reservation.reserved_by
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-refresh",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                ]
                              )
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("view-reservation", { ref: "view" }),
                _vm._v(" "),
                _c("unified-view-reservation", { ref: "unifiedView" }),
                _vm._v(" "),
                _c("send-to-email", { ref: "sendToEmail" }),
                _vm._v(" "),
                _c("unified-send-to-email", { ref: "unifiedSendToEmail" })
              ],
              1
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-237a171b", { render: render, staticRenderFns: staticRenderFns })
  }
}