var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mb-4 rewards" }, [
    _c("div", { staticClass: "card-block" }, [
      _c("div", { staticClass: "row margin-top-4x margin-bottom-4x" }, [
        _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "text-center margin-bottom-4x" }, [
            _c("p", { staticClass: "f22 regular" }, [
              _vm._v(
                "\n                        OSP points to spend\n                    "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "f22 text-primary bold" }, [
              _vm._v(
                "\n                        " +
                  _vm._s(_vm.customer.qualifying_points) +
                  " OSP Points\n                    "
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-4" }, [
          _c("div", { staticClass: "text-center margin-bottom-4x" }, [
            _c("p", { staticClass: "regular f22" }, [
              _vm._v(
                "\n                        Current tier\n                    "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class:
                  "tier-circle tier-circle--" +
                  (_vm.customer.current_tier
                    ? _vm.customer.current_tier.name.toLowerCase()
                    : "")
              },
              [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.customer.current_tier
                        ? _vm.customer.current_tier.name
                        : ""
                    )
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _vm.customer.current_tier &&
            _vm.customer.current_tier.name.toLowerCase() != "ivory"
              ? _c("div", { staticClass: "f11 lighter margin-top-3x" }, [
                  _vm._v(
                    "\n                        Expires on: " +
                      _vm._s(
                        _vm._f("dateFormat")(
                          _vm.customer.current_tier
                            ? _vm.customer.current_tier.expiry_date
                            : "",
                          "MM/DD/YYYY",
                          ["YYYY-MM-DD"]
                        )
                      ) +
                      "\n                    "
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-4" }, [
          _c("p", { staticClass: "f22 regular" }, [
            _vm._v("\n                    Tier progress\n                ")
          ]),
          _vm._v(" "),
          _vm.customer.current_tier
            ? _c("div", { staticClass: "row margin-bottom-4x" }, [
                _c("div", { staticClass: "col-4 text-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "circular-progress",
                      attrs: {
                        "data-name": _vm.customer.next_tier.name,
                        "data-percent": _vm.customer.current_tier.progress + "%"
                      }
                    },
                    [
                      _c("svg", { attrs: { viewBox: "-10 -10 220 220" } }, [
                        _c("circle", {
                          staticClass: "base",
                          attrs: {
                            "stroke-dashoffset": "0",
                            "stroke-width": "9",
                            r: "90",
                            cx: "100",
                            cy: "100",
                            fill: "transparent",
                            "stroke-dasharray": "565.48"
                          }
                        }),
                        _vm._v(" "),
                        _c("circle", {
                          staticClass: "bar",
                          attrs: {
                            "stroke-dashoffset":
                              565.48 -
                              (565.48 * _vm.customer.current_tier.progress) /
                                100,
                            "stroke-width": "9",
                            r: "90",
                            cx: "100",
                            cy: "100",
                            fill: "transparent",
                            "stroke-dasharray": "565.48"
                          }
                        })
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-8" }, [
                  _c("div", { staticClass: "regular f16 margin-bottom-2x" }, [
                    _vm._v(
                      "\n                            Qualifying OSP points: " +
                        _vm._s(_vm.customer.current_tier.qualifying_points) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "regular f16 margin-bottom-2x " }, [
                    _vm._v(
                      "\n                            Qualifying reservations: " +
                        _vm._s(
                          _vm.customer.current_tier.qualifying_reservations
                        ) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.customer.current_tier.name.toLowerCase() != "gold"
                    ? _c(
                        "div",
                        { staticClass: "regular f16 margin-bottom-2x" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.customer.current_tier
                                  .reservations_to_next_tier
                              ) +
                              "\n                            remaining reservations to reach "
                          ),
                          _c("span", { staticClass: "capitalize" }, [
                            _vm._v(_vm._s(_vm.customer.next_tier.name))
                          ]),
                          _vm._v(" level\n                        ")
                        ]
                      )
                    : _vm._e()
                ])
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "points-tabs" }, [
        _c(
          "div",
          {
            staticClass: "points-tabs--tab",
            class: { active: _vm.view == "reservations" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.changeView("reservations")
              }
            }
          },
          [_vm._v("\n                Points From Reservations\n            ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "points-tabs--tab",
            class: { active: _vm.view == "points" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.changeView("points")
              }
            }
          },
          [_vm._v("\n                Manual Points\n            ")]
        )
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c("div", [
            _vm.view == "reservations"
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c(
                        "table",
                        { staticClass: "table table-striped table-hover" },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Date")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Reservation #")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Points Earned")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Points Spent")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Qualifying")]),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: ["RESERVATION_SHOW"],
                                      expression: "['RESERVATION_SHOW']",
                                      arg: "allow"
                                    }
                                  ]
                                },
                                [_vm._v("View")]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: ["RESERVATION_UPDATE"],
                                      expression: "['RESERVATION_UPDATE']",
                                      arg: "allow"
                                    }
                                  ]
                                },
                                [_vm._v("Edit")]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.reservations.data, function(
                              reservation
                            ) {
                              return _c("tr", { key: reservation.id }, [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        reservation.created_at,
                                        "MM/DD/YYYY"
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(reservation.reservation_number) +
                                      "\n                                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(reservation.history[0].points_earned)
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(reservation.history[0].points_used)
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  reservation.cancelled ||
                                  !reservation.history[0].points_earned ||
                                  !_vm.reservationEnded(
                                    reservation.history[0].to_date
                                  )
                                    ? _c("span", [_vm._v(" - ")])
                                    : _c("i", {
                                        staticClass: "fa fa-check",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["RESERVATION_SHOW"],
                                        expression: "['RESERVATION_SHOW']",
                                        arg: "allow"
                                      }
                                    ]
                                  },
                                  [
                                    _vm.view_loadings[reservation.id]
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fa fa-spinner fa-spin"
                                          })
                                        ])
                                      : _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.showReservationDetails(
                                                  reservation.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-eye",
                                              attrs: { "aria-hidden": "true" }
                                            })
                                          ]
                                        )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["RESERVATION_UPDATE"],
                                        expression: "['RESERVATION_UPDATE']",
                                        arg: "allow"
                                      }
                                    ]
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "reservations.edit",
                                            params: { id: reservation.id }
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-pencil-square-o",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("pagination", {
                      attrs: { "pages-number": _vm.reservations.last_page },
                      on: { pageChanged: _vm.loadReservations }
                    }),
                    _vm._v(" "),
                    _c("view-reservation", { ref: "view" })
                  ],
                  1
                )
              : _vm.view == "points"
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "text-right margin-bottom-2x" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openAddPointsModal($event)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus" }),
                          _vm._v(
                            "\n                        Add Points to " +
                              _vm._s(
                                _vm.customer.first_name +
                                  " " +
                                  _vm.customer.last_name
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-responsive" }, [
                      _c(
                        "table",
                        { staticClass: "table table-striped table-hover" },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Date")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Points Earned")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Qualifying")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Refunded")]),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: ["CUSTOMER_POINTS_DELETE"],
                                      expression: "['CUSTOMER_POINTS_DELETE']",
                                      arg: "allow"
                                    }
                                  ]
                                },
                                [_vm._v("Revoke")]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.points, function(record) {
                              return _c("tr", { key: record.id }, [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        record.created_at,
                                        "MM/DD/YYYY"
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(record.amount))]),
                                _vm._v(" "),
                                _c("td", [
                                  !record.deleted_at
                                    ? _c("i", {
                                        staticClass: "fa fa-check",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    : _c("span", [_vm._v("-")])
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  record.deleted_at
                                    ? _c("i", {
                                        staticClass: "fa fa-check",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    : _c("span", [_vm._v("-")])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["CUSTOMER_POINTS_DELETE"],
                                        expression:
                                          "['CUSTOMER_POINTS_DELETE']",
                                        arg: "allow"
                                      }
                                    ]
                                  },
                                  [
                                    record.deleted_at
                                      ? _c("span", [_vm._v("-")])
                                      : _vm.revoke_loadings[record.id]
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fa fa-spinner fa-spin"
                                          })
                                        ])
                                      : _c(
                                          "a",
                                          {
                                            staticClass: "text-danger",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.revokePoints(record)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times"
                                            })
                                          ]
                                        )
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("confirm", {
                      ref: "confirmRevoke",
                      attrs: { type: "danger" }
                    }),
                    _vm._v(" "),
                    _c("add-points", { ref: "addPointsModal" })
                  ],
                  1
                )
              : _vm._e()
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-019c8406", { render: render, staticRenderFns: staticRenderFns })
  }
}