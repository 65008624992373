var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm._l(_vm.types, function(type) {
        return _c("div", { staticClass: "mt-3" }, [
          _c("h4", { staticClass: "mb-2" }, [_vm._v(_vm._s(type.name))]),
          _vm._v(" "),
          _vm.loadings[type.id]
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", {
                  staticClass: "fa fa-circle-o-notch fa-spin fa-2x fa-fw"
                })
              ])
            : _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("From Date")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("To Date")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Price")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Eligible Days")]),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.location_id,
                                permits: ["RATE_DELETE"]
                              },
                              expression:
                                "{id: location_id, permits: ['RATE_DELETE']}",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v("Remove")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(type.rates, function(rate) {
                      return _c("tr", [
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm
                                  .moment(rate.from_date, ["YYYY-MM-DD"])
                                  .format("MM/DD/YYYY")
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                rate.to_date
                                  ? _vm
                                      .moment(rate.to_date, ["YYYY-MM-DD"])
                                      .format("MM/DD/YYYY")
                                  : "-"
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(rate.price) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.eligibleDays(rate)) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.location_id,
                                  permits: ["RATE_DELETE"]
                                },
                                expression:
                                  "{id: location_id, permits: ['RATE_DELETE']}",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _vm.remove_loadings[rate.id]
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa fa-spinner fa-spin"
                                  })
                                ])
                              : _c(
                                  "a",
                                  {
                                    staticClass: "text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.remove(rate)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-times",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]
                                )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ])
              ])
        ])
      }),
      _vm._v(" "),
      _c("confirm", { ref: "deleteConfirm", attrs: { type: "danger" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2db0b532", { render: render, staticRenderFns: staticRenderFns })
  }
}