import toastr from 'toastr'
import { dateFormat } from 'filters'
import viewReservation from 'layouts/partials/viewReservation'

const components = {
  viewReservation
}

const filters = {
  dateFormat
}

const methods = {
  showReservationDetails (item_id) {
    if (!item_id.length) return;
    this.validationProcessing = true;
    this.$store.dispatch('dashboards.validateReservation', {
      id:item_id,
      location_id:this.$route.params.id,
    }).then(() => {
      this.validationProcessing = false;
      let errors = _.cloneDeep(this.$store.state.dashboards.errors);
      if (errors) {
        toastr.error(errors.message);
      } else {
        this.reservation = this.$store.state.dashboards.reservation;
        this.$refs.view.open(this.reservation);
      }
    });

    return false;
  }
}


export default {
  name: 'validate-reservation',
  methods,
  components,
  filters,
  data () {
    return {
      reservation: null,
      validationProcessing: false
    }
  }
}
