import { permits } from 'services'

export default {
  name: "hasReportPermits",
  inserted (el, binding) {
    //if the user doesn't have any permission that listed in "allow", prevent his/her access
    if (binding.arg == 'allow' && binding.value) {      
      if (!permits.hasReportPermits(binding.value)) {
        $(el).remove();
      }
    }

    //if one of these permissions exists, prevent his/her access
    if (binding.arg == 'prevent' && binding.value) {
        if (permits.hasReportPermits(binding.value, true)) {
        $(el).remove();
      }
    }

  }
}
