import toastr from 'toastr'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'
import { stripTags } from 'filters'

const directives = {
  hasPermits
}

const components = {
  confirm
}

const computed = {
  extraFields () {
    return this.$store.state.locations.active.extra_fields;
  }
}

const methods = {
  getExtraFields () {
    this.loading = true;
    this.$store.dispatch('locations.getExtraFields', {id: this.$route.params.id})
    .then(() => {
      this.loading = false;
    });
  },

  submit () {
    this.form.extra_fields.forEach(field => {
      if (stripTags(field.display_name) == "") {
        toastr.error('Field Name empty');
        this.has_front_error = true;
      } else {
        this.has_front_error = false;
      }
    });
    if (this.has_front_error) return ;
    this.processing = true;
    this.errors = {};
    this.$store.dispatch('locations.batchAddExtraFields', {
      location_id: this.$route.params.id,
      data: this.form
    }).then(() => {
      if (this.$store.state.locations.errors) {
        this.errors = this.$store.state.locations.errors.errors;
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('Data has been saved');

        this.form = {
          extra_fields: [{
            display_name: "",
            per_car: false
          }]
        }

        this.open_form = false;
      }

      this.processing = false;
    });
  },

  remove (id) {
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.$set(this.loadings, id, true);

      this.$store.dispatch('locations.removeExtraField', id).then(() => {
        this.loadings[id] = false;
        let errors;
        if (this.$store.state.locations.errors) {
          errors = this.$store.state.locations.errors.errors;
          console.log('errors', errors);
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.success('Field removed successfully');
        }
      });
    })
  },

  addMoreFields () {
    this.form.extra_fields.push({
      display_name: "",
      per_car: false
    })
  },

  removeFields (i, e) {
    e.preventDefault();
    this.form.extra_fields.splice(i, 1);
  },
}

export default {
  name: 'extra-fields',
  methods,
  computed,
  components,
  directives,
  data () {
    return {
      open_form: false,
      processing: false,
      loading: false,
      loadings: {},
      errors: {},
      has_front_error: false,
      form: {
        extra_fields: [{
          display_name: "",
          per_car: false
        }]
      },
      location_id: this.$route.params.id
    }
  },

  created () {
    this.getExtraFields();
  }
}
