import toastr from 'toastr'
import { funcModal } from 'components/modals'

const components = {
    funcModal
}

const methods = {
    open (id) {
        this.form.id = id;
        this.$refs.modal.open()
    }, 

    send () {
        this.errors = {};
        this.processing = true;
        this.$store.dispatch('reservations.sendToFax', this.form).then(() => {
            if (this.$store.state.reservations.errors) {
                this.errors = _.cloneDeep(this.$store.state.reservations.errors.errors);
                toastr.error(this.$store.state.reservations.errors.message);
            } else {
                toastr.success('Reservation details have been sent');
                this.$refs.modal.close();
            }
            this.processing = false;
        });
    }
}

export default {
    name: "send-reservation-to-fax",
    components,
    methods,
    data () {
        return {
            form: {
                fax: null,
                id: null
            },
            errors: {},
            processing: false
        }
    }
}