var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mb-4" }, [
    _c("div", { staticClass: "card-block" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "text-center margin-bottom-3x" }, [
        _c("span", [
          _vm._v("Total Check-ins: " + _vm._s(_vm.total_checkins_count))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _c("input", {
          ref: "search",
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "Search for reservations or customers..."
          },
          on: {
            keyup: function($event) {
              return _vm.onSearchKeyup(_vm.$refs.search.value, $event)
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input-group-append" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.search(_vm.$refs.search.value)
                }
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-search",
                attrs: { "aria-hidden": "true" }
              })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "margin-top-2x" }, [
        _c("label", { staticClass: "radio-inline" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checked_in,
                expression: "checked_in"
              }
            ],
            attrs: { type: "radio" },
            domProps: { value: null, checked: _vm._q(_vm.checked_in, null) },
            on: {
              change: [
                function($event) {
                  _vm.checked_in = null
                },
                _vm.getList
              ]
            }
          }),
          _vm._v(" All\n      ")
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "radio-inline" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checked_in,
                expression: "checked_in"
              }
            ],
            attrs: { type: "radio", value: "not_checked_in" },
            domProps: { checked: _vm._q(_vm.checked_in, "not_checked_in") },
            on: {
              change: [
                function($event) {
                  _vm.checked_in = "not_checked_in"
                },
                _vm.getList
              ]
            }
          }),
          _vm._v(" Not Checked in\n      ")
        ])
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "margin-top-3x" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-lg fa-fw" })
          ])
        : _c(
            "div",
            [
              _vm.list.length
                ? _c("div", {}, [
                    _c(
                      "table",
                      { staticClass: "table table-hover margin-top-3x" },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: {
                                      id: _vm.location.id,
                                      permits: [
                                        "RESERVATION_CHECKIN",
                                        "RESERVATION_UNDO_CHECKIN"
                                      ]
                                    },
                                    expression:
                                      "{id: location.id, permits: ['RESERVATION_CHECKIN', 'RESERVATION_UNDO_CHECKIN']}",
                                    arg: "allow"
                                  }
                                ]
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-check-square-o",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("th", [_vm._v("Customer Name")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Res. #")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Drop Off")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("View")])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.list, function(reservation) {
                            return _c("tr", { key: reservation.id }, [
                              _c(
                                "td",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: {
                                        id: _vm.location.id,
                                        permits: [
                                          "RESERVATION_CHECKIN",
                                          "RESERVATION_UNDO_CHECKIN"
                                        ]
                                      },
                                      expression:
                                        "{id: location.id, permits: ['RESERVATION_CHECKIN', 'RESERVATION_UNDO_CHECKIN']}",
                                      arg: "allow"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.toggleCheck(reservation)
                                        }
                                      }
                                    },
                                    [
                                      !reservation.checked_in_time
                                        ? _c("i", {
                                            staticClass: "fa fa-square-o",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-check-square-o",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(reservation.history[0].customer_name)
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(reservation.reservation_number))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      reservation.history[0].from_date,
                                      "MM/DD/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.showReservationDetails(
                                          reservation
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm.loadings[reservation.id]
                                      ? _c("i", {
                                          staticClass: "fa fa-spinner fa-spin",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      : _c("i", {
                                          staticClass: "fa fa-eye",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                  ]
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  ])
                : _c("div", { staticClass: "margin-top-3x text-info" }, [
                    _vm._v("Nothing to show")
                  ]),
              _vm._v(" "),
              _c("confirm", { ref: "confirm", attrs: { type: "warning" } }),
              _vm._v(" "),
              _c("view-reservation", { ref: "view" })
            ],
            1
          )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-center f20 uppercase margin-bottom-3x" },
      [
        _c("i", {
          staticClass: "fa fa-sign-in text-info",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v(" Check In List\n    ")
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-38627457", { render: render, staticRenderFns: staticRenderFns })
  }
}