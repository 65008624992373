var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { staticClass: "reservation", attrs: { "no-sidebar": true } },
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "reservation-wrapper" }, [
            _c("iframe", {
              ref: "iframeRef",
              staticClass: "tripteo-frame",
              attrs: {
                id: "iframeId",
                src: _vm.tripTeoEditLink,
                frameborder: "0",
                scrolling: "no"
              }
            })
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-00e9fd87", { render: render, staticRenderFns: staticRenderFns })
  }
}