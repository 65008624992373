import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import sSelect from 'components/sSelect'
import { hasPermits } from 'directives'
import confirm from 'components/modals/confirm'

const directives = {
  hasPermits
}

const components = {
  layout,
  datepicker,
  sSelect,
  confirm
}

const computed = {
  locations () {
    let empty = [{
      text: '-- Select Location --',
      value: null
    }];

    if (!_.isEmpty(this.$store.state.locations.all)) {
      return empty.concat(this.$store.state.locations.all.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      }))
    }

    return empty;
  },
}

const methods = {
  onSubmitForm (force = false) {
    this.errors = {}
    this.processing = true;
    this.form.force = force;
    let data = _.cloneDeep(this.form);
    data.start_date = moment(data.start_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    data.end_date = moment(data.end_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    this.$store.dispatch('bulkCoupons.create', data).then(() => {
      this.processing = false;
      let errors = this.$store.state.bulkCoupons.errors;
      if (errors) {
        if(errors.status_code == 400) {
          this.$refs.warningConfirm.confirm('Group already exists. Do you want to merge with existing group?', () => {
            this.onSubmitForm(true);
          }, null, true);
        } else {
          this.errors = _.cloneDeep(this.$store.state.bulkCoupons.errors.errors);
          toastr.error(this.$store.state.bulkCoupons.errors.message);
        }

      } else {
        toastr.success('A new coupon has been created');
        this.$router.push({name: 'coupons.list'});
      }
    })
  },

  onSelectLocation (l) {
    if (this.form.locations.indexOf(l.value) === -1 && l.value != null) {
      this.form.locations.push(l.value);
      this.choosenLocations.push(l);
    }
    this.selectedLocation = {value: null, text: '-- Select Location --'};
  },

  onRemoveChosenLocation (location) {
    this.choosenLocations = this.choosenLocations.filter(
      (item) => item.value != location.value);
    this.form.locations = this.form.locations.filter(
      (item) => item != location.value);
  }
}

const watch = {
  ['form.all_locations'] (n) {
    if (n == true) {
      this.form.locations = [];
      this.choosenLocations = [];
    }
  }
}

export default {
  name: 'bulk-coupons',
  components,
  computed,
  methods,
  directives,
  watch,
  data () {
    return {
      loading: true,
      processing: false,
      errors: {},
      form: {
        name: null,
        group: null,
        start_date: null,
        end_date: null,
        one_time_use: true,
        minimum_qualifying_days: 1,
        amount_type: 'fixed',
        amount: null,
        discount_limit: null,
        number_of_coupons: null,
        all_locations: true,
        locations: [],
        force: false,
      },
      selectedLocation: {value: null, text: '-- Select Location --'},
      choosenLocations: []
    }
  },
  created () {
    this.$store.dispatch('locations.getAll', {_all: true}).then(() => {
      this.loading = false
    });
  }
}
