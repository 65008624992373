import toastr from 'toastr'
import pagination from 'components/pagination'
import viewReservation from 'layouts/partials/viewReservation'
import sendToEmail from 'layouts/partials/sendToEmail'
import sendToFax from 'layouts/partials/sendToFax'
import { dateFormat, currencySymbol } from 'filters'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
    pagination,
    viewReservation,
    sendToEmail,
    sendToFax
}

const filters = {
    dateFormat
}

const methods = {
    loadPage (page) {
        this.$emit('loadPage', page);
    },

    showReservationDetails (item_id) {
      this.$set(this.view_loadings, item_id, true);
      this.$store.dispatch('reservations.get', item_id).then(() => {
        let errors;
        if (errors = this.$store.state.reservations.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        } else {
          this.$refs.view.open();
        }
  
        this.view_loadings[item_id] = false;
      });
  
      return false;
    },
  
    openSendToEmailModal (id, email) {
      this.$refs.sendToEmail.open(id, email)
    },

    openSendToFaxModal (id) {
      this.$refs.sendToFax.open(id)
    },

    timeBeforeCaptureInMs(reservation) {
      const now = new Date();
      const captureBefore = new Date(reservation.capture_before);

      const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const captureBeforeDate = new Date(captureBefore.getFullYear(), captureBefore.getMonth(), captureBefore.getDate());

      return captureBeforeDate - nowDate;
    },

    captureReservation(reservation_id) {
      this.$set(this.capture_loadings, reservation_id, true);
      this.$store.dispatch('reservations.capture', reservation_id).then(() => {
        let errors;
        if (errors = this.$store.state.reservations.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        } else {
          toastr.success('Reservation captured successfully')
        }
        this.capture_loadings[reservation_id] = false;
      });
    },

}

export default {
    name: "reservationsList",
    props: ['reservations'],
    directives,
    components,
    filters,
    methods,
    data () {
        return {
            view_loadings: {},
            capture_loadings: {},
            currencySymbol
        }
    }
}