var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Revert Cancel" },
      on: { close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  class: [{ disabled: _vm.processing }],
                  attrs: { type: "button", disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm()
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                        _vm._v("\n        SAVING...\n      ")
                      ])
                    : _c("span", [_vm._v("Confirm")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              ),
              _vm._v(" "),
              _c("confirm", {
                ref: "warningConfirm",
                attrs: { type: "warning" }
              })
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "f16 bold margin-bottom-3x" }, [
          _vm._v(
            "Are you sure that you want to revert cancel this reservation?"
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "margin-top-3x" }, [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.charge_customer,
                  expression: "charge_customer"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                value: true,
                checked: Array.isArray(_vm.charge_customer)
                  ? _vm._i(_vm.charge_customer, true) > -1
                  : _vm.charge_customer
              },
              on: {
                change: function($event) {
                  var $$a = _vm.charge_customer,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = true,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.charge_customer = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.charge_customer = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.charge_customer = $$c
                  }
                }
              }
            }),
            _vm._v("\n        Charge Customer?\n      ")
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-be8cbed6", { render: render, staticRenderFns: staticRenderFns })
  }
}