import Swatches from 'vue-swatches'

    export default {
        components: {Swatches},
        props: {
            value: {
                type: String,
                default: ""
            },
            name: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                color: this.value
            }
        },
        methods: {
            changeColor() {
                this.$emit('input', this.color);
            }
        }
    }