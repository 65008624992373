<template lang="html">
  <div>
    <!-- Nav tabs -->
    <ul class="nav nav-tabs" :class="{'nav-justified': justified}" role="tablist">
      <li v-hasPermits:allow="title.permits" role="presentation" class="nav-item" v-for="(title, i) in titles" @click="activateTab(title)">
        <a class="nav-link" :class="{active: active == title.title}" :href="'#' + title.id" :aria-controls="title.id" role="tab" data-toggle="tab">{{title.title}}</a>
      </li>
    </ul>

    <!-- Tab panes -->
    <div class="tab-content">
      <slot></slot>
    </div>

  </div>
</template>

<script>
  /*
  * @INFO: this component renders bootstrap tabs along side with "tab" component
  */
  import { hasPermits } from 'directives'

  const directives = {
    hasPermits
  }

  const methods = {
    activateTab (title) {
      this.$emit('tabChange', title);
      this.active = title
    }
  }

  export default {
    props: ['justified'],
    methods,
    directives,
    data () {
      return {
        titles: [],
        active: null
      }
    },
    mounted () {
      this.active = this.titles[0].title
    },
  }
</script>
