import toastr from 'toastr'
import { currencySymbol } from 'filters'
import confirm from 'components/modals/confirm'
import { funcModal } from 'components/modals'

const components = {
  confirm,
  funcModal
}

const methods = {
  onSubmitForm () {
    this.processing = true;

    this.$store.dispatch('reservations.partialRefund', {id: this.$route.params.id, data: this.form}).then((res) => {
      this.processing = false

      if (this.$store.state.reservations.errors) {
        if (!_.isEmpty(this.$store.state.reservations.errors.errors)) {
          this.errors = _.cloneDeep(this.$store.state.reservations.errors.errors);
        }
        toastr.error(this.$store.state.reservations.errors.message);
      } else {
        let reslabFailedMessage = res.data.reslab_failed_message ? res.data.reslab_failed_message : false;
        toastr.success('Partial refund secceeded')
        if (res.data.refund_warning) {
          this.$refs.warningConfirm.confirm(res.data.refund_warning, () => {
            if (reslabFailedMessage) {
              this.$refs.warningConfirm.confirm(reslabFailedMessage, () => {
                this.$refs.modal.close();
              }, null, false)
            } else {
              this.$refs.modal.close();
            }
          }, null, false)
        } else {
          if (reslabFailedMessage) {
            this.$refs.warningConfirm.confirm(reslabFailedMessage, () => {
              this.$refs.modal.close();
            }, null, false)
          } else {
            this.$refs.modal.close();
          }
        }
        
      }
    })
  },

  changeRefundType () {
    let overall_amount = this.data.aggrigate_amount + this.data.points_money_value + this.data.customer_credit_used;
    if (this.form.osp_only && this.form.refund_type == 'partial_refund') {
      this.availableAmount = overall_amount - this.data.location_portion;
    } else {
      this.availableAmount = overall_amount;
    }
  },

  open () {
    this.$refs.modal.open();
  },

  onClose () {
    this.$emit('close');
  }
}

export default {
  name: "partial-refund-modal",
  props: ['data', 'currency_code'],
  methods,
  components,
  data () {
    return {
      processing: false,
      errors: {},
      currencySymbol,
      availableAmount: this.data.aggrigate_amount + this.data.points_money_value + this.data.customer_credit_used,
      form: {
        refund_type: 'full_refund',
        refund_from: 'refund_cash_first',
        amount: null,
        osp_only: null,
        notes: null
      }
    }
  }
}
