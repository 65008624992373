var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("h3", { staticClass: "mb-2" }, [_vm._v("Message Pmessage")]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c("div", { staticClass: "card mb-4" }, [
          _c("div", { staticClass: "card-block" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-sm-8" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { staticClass: "w-25" }, [
                        _vm._v("Sender Name")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.message.name) +
                            "\n                                "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("Sender Email")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                                    " +
                            _vm._s(_vm.message.email) +
                            "\n                                    \n                                "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("Send Date")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.message.created_at,
                              "MM/DD/YYYY hh:mm a"
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("Message Title")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.message.title))])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("Message Body")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.message.message))])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-content-end align-items-center"
                  },
                  [
                    _vm.message.prev_id || _vm.message.next_id
                      ? _c(
                          "div",
                          { staticClass: "margin-left-4x" },
                          [
                            _vm.message.prev_id
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "messages.show",
                                        params: { id: _vm.message.prev_id }
                                      }
                                    }
                                  },
                                  [_vm._v("Prev")]
                                )
                              : _vm._e(),
                            _vm._v(" |\n                            "),
                            _vm.message.next_id
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "messages.show",
                                        params: { id: _vm.message.next_id }
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e0d528e2", { render: render, staticRenderFns: staticRenderFns })
  }
}