import { funcModal } from 'components/modals'
import { tab, tabs } from "components/tabs"
import { dateFormat, currencySymbol } from 'filters'
const components = {
  funcModal,
  tab,
  tabs
}

const computed = {
  reservation_from_state () {
    return this.$store.state.unifiedReservations.active;
  },

  activeHistory () {
    if (!this.reservation) return {};
    return _.find(this.reservation.history, function (h) {return h.active == 1;});
  }
}

const filters = {
  dateFormat
}

const methods = {
  open (reservation = null) {
    this.reservation = reservation || _.cloneDeep(this.reservation_from_state);
    this.$refs.modal.open();
  },
  symbol (code) {
    return currencySymbol(code);
  }
}

export default {
  name: "unified-view-reservation-modal",
  components,
  methods,
  computed,
  filters,
  data () {
    return {
      reservation: null
    }
  }
}
