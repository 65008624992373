import layout from 'layouts/default'
import { dateFormat } from 'filters'

let components = {
    layout
}

let filters = {
    dateFormat
}

let computed = {
    message () {
        return this.$store.state.messages.active;
    }
}

let methods = {
}

export default {
    name: "show-message",
    components,
    computed,
    methods,
    filters,
    data () {
        return {
            loading: true,
        }
    },

    created () {
        this.$store.dispatch('messages.get', this.$route.params.id).then(() => {
            this.loading = false
        });
    }
}