var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { staticClass: "location-information" }, [
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c("div", [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("h3", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(_vm.location.name))
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["location_create"],
                    expression: "['location_create']",
                    arg: "allow"
                  }
                ],
                staticClass: "mb-3"
              },
              [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-default",
                        attrs: { to: { name: "locations.create" } }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-plus",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v("\n            New Location\n          ")
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card mb-4" }, [
            _c("div", { staticClass: "card-block " }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: [
                          "LOCATION_UPDATE",
                          "LOCATION_GENERAL_INFO_UPDATE"
                        ]
                      },
                      expression:
                        "{id: location.id, permits: ['LOCATION_UPDATE', 'LOCATION_GENERAL_INFO_UPDATE']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "text-right"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      class: { disabled: _vm.processing },
                      attrs: { disabled: _vm.processing },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      }
                    },
                    [
                      _vm.processing
                        ? _c("span", [
                            _c("i", {
                              staticClass: "fa fa-spinner fa-spin fa-fw"
                            })
                          ])
                        : _vm._e(),
                      _vm._v("\n            Save\n          ")
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationName" } }, [
                      _vm._v("Location Name")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.name,
                          expression: "form.name",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "locationName",
                        placeholder: "Location Name"
                      },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "name", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.name
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.name[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "slug" } }, [
                      _vm._v("Friendly URL")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.slug,
                          expression: "form.slug",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "slug",
                        placeholder: "Friendly URL"
                      },
                      domProps: { value: _vm.form.slug },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "slug", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.slug
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.slug[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "slug" } }, [
                      _vm._v("Old URL")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.old_slug,
                          expression: "form.old_slug",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "oldSlug",
                        placeholder: "Old URL"
                      },
                      domProps: { value: _vm.form.old_slug },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "old_slug",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.old_slug
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.old_slug[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "port" } }, [_vm._v("Port")]),
                      _vm._v(" "),
                      _c("s-select", {
                        attrs: {
                          id: "port",
                          options: _vm.ports,
                          selected: _vm.selectedAirport
                        },
                        on: { selectedChanged: _vm.onChangeAirport }
                      }),
                      _vm._v(" "),
                      _vm.errors.airport_id
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.airport_id[0]) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-8" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          { attrs: { for: "distance_from_airport" } },
                          [_vm._v("Distance From Port")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.form.distance_from_airport,
                              expression: "form.distance_from_airport",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "distance_from_airport",
                            placeholder: "Distance From Port"
                          },
                          domProps: { value: _vm.form.distance_from_airport },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "distance_from_airport",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.distance_from_airport
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.errors.distance_from_airport[0]) +
                                  "\n                  "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.location.id,
                                  permits: [
                                    "LOCATION_UPDATE",
                                    "LOCATION_GENERAL_INFO_UPDATE"
                                  ]
                                },
                                expression:
                                  "{id: location.id, permits: ['LOCATION_UPDATE', 'LOCATION_GENERAL_INFO_UPDATE']}",
                                arg: "allow"
                              }
                            ],
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.checkDistance()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Check Distance\n                  "
                            )
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "distance_unit" } }, [
                          _vm._v("Distance Unit")
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.distance_unit,
                                expression: "form.distance_unit"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { id: "distance_unit" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.form,
                                  "distance_unit",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "km" } }, [
                              _vm._v("Km")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "mi" } }, [
                              _vm._v("Mile")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors.distance_unit
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.errors.distance_unit[0]) +
                                  "\n                  "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationPhone" } }, [
                      _vm._v("Phone Number")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.phone,
                          expression: "form.phone",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "locationPhone",
                        placeholder: "Location Phone Number"
                      },
                      domProps: { value: _vm.form.phone },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "phone",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.phone
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.phone[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationFax" } }, [
                      _vm._v("Fax Number")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.fax,
                          expression: "form.fax",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "locationFax",
                        placeholder: "Location Fax Number"
                      },
                      domProps: { value: _vm.form.fax },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "fax", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.fax
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.fax[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.send_fax,
                            expression: "form.send_fax"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.form.send_fax)
                            ? _vm._i(_vm.form.send_fax, null) > -1
                            : _vm.form.send_fax
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.form.send_fax,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "send_fax",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "send_fax",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "send_fax", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v("\n                Send Fax\n              ")
                    ]),
                    _vm._v(" "),
                    _vm.errors.send_fax
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.send_fax[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "countries" } }, [
                      _vm._v("Country")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.country_id,
                            expression: "form.country_id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "countries", placeholder: "Country" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "country_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.countries, function(country) {
                        return _c(
                          "option",
                          { domProps: { value: country.id } },
                          [_vm._v(_vm._s(country.name))]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.errors.country_id
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.country_id[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "states" } }, [
                      _vm._v("State")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.state_id,
                            expression: "form.state_id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "states", placeholder: "Country" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "state_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.states, function(state) {
                        return _c("option", { domProps: { value: state.id } }, [
                          _vm._v(_vm._s(state.name))
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.errors.state_id
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.state_id[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "city" } }, [_vm._v("City")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.city,
                          expression: "form.city",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "city",
                        type: "text",
                        placeholder: "City name"
                      },
                      domProps: { value: _vm.form.city },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "city", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.city
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.city[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "timezone" } }, [
                      _vm._v("Timezone")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.timezone_id,
                            expression: "form.timezone_id"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "timezone" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "timezone_id",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { domProps: { value: null } }, [
                          _vm._v("Select Timezone")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.timezones, function(timezone) {
                          return _c(
                            "option",
                            { domProps: { value: timezone.id } },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(timezone.name) +
                                  "\n                "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.errors.timezone_id
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.timezone_id[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationAddress" } }, [
                      _vm._v("Address")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.address,
                          expression: "form.address",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "locationAddress",
                        placeholder: "Address"
                      },
                      domProps: { value: _vm.form.address },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "address",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.address
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.address[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "locationZipCode" } }, [
                      _vm._v("Zip/Postal Code")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.zipcode,
                          expression: "form.zipcode",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "locationZipCode",
                        placeholder: "Zip Code"
                      },
                      domProps: { value: _vm.form.zipcode },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "zipcode",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.zipcode
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.zipcode[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.form.number_of_rooms
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["super_admin"],
                              expression: "['super_admin']",
                              arg: "allow"
                            }
                          ],
                          staticClass: "form-group"
                        },
                        [
                          _c("label", { attrs: { for: "tripteo_slug" } }, [
                            _vm._v("Tripteo Slug")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.form.tripteo_slug,
                                expression: "form.tripteo_slug",
                                modifiers: { trim: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "tripteo_slug",
                              placeholder: "Tripteo Slug"
                            },
                            domProps: { value: _vm.form.tripteo_slug },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "tripteo_slug",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.tripteo_slug
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.errors.tripteo_slug[0]) +
                                    "\n              "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "locationLatitude" } }, [
                        _vm._v("Geo Coordinates")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group margin-bottom-3x" },
                        [
                          _c("placesAutocomplete", {
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              id: "locationPlace",
                              placeholder: "Search for your city or place"
                            },
                            model: {
                              value: _vm.googlePlace,
                              callback: function($$v) {
                                _vm.googlePlace = $$v
                              },
                              expression: "googlePlace"
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.latitude
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.errors.latitude[0]) +
                                    "\n                "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errors.longitude
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.errors.longitude[0]) +
                                    "\n                "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("u", [
                        _c("i", [
                          _vm._v(
                            "\n                  Search for your place and click on the map to pin your location\n                "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("google-maps-movable-marker", {
                        staticClass: "admin-location-map",
                        attrs: {
                          center: _vm.center,
                          "default-marker": {
                            lat: parseFloat(_vm.form.latitude),
                            lng: parseFloat(_vm.form.longitude)
                          }
                        },
                        on: { locationChanged: _vm.onLocationChanged }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "label" } }, [
                      _vm._v("Highlighted Label")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.label,
                          expression: "form.label",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "label",
                        placeholder:
                          "Text to be highlighted on the airports list"
                      },
                      domProps: { value: _vm.form.label },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "label",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.label
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.label[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "label-color" } }, [
                        _vm._v("Highlighted Label Color")
                      ]),
                      _vm._v(" "),
                      _c("color", {
                        attrs: { name: "label-color" },
                        on: { input: _vm.onChangeColor },
                        model: {
                          value: _vm.form.label_color,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "label_color", $$v)
                          },
                          expression: "form.label_color"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.label_color
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.label_color[0]) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "front_desk_hours" } }, [
                      _vm._v("Front Desk Hours")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.front_desk_hours,
                          expression: "form.front_desk_hours",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "front_desk_hours",
                        placeholder: "hours of operation of front desk"
                      },
                      domProps: { value: _vm.form.front_desk_hours },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "front_desk_hours",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.front_desk_hours
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.front_desk_hours[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "specific_directions" } }, [
                      _vm._v("Specific Directions")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.specific_directions,
                          expression: "form.specific_directions",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "specific_directions",
                        placeholder:
                          "Tell the customer if they need specific specific_directions"
                      },
                      domProps: { value: _vm.form.specific_directions },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "specific_directions",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.specific_directions
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.specific_directions[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Description")]),
                      _vm._v(" "),
                      _c("vue-html5-editor", {
                        attrs: {
                          height: 100,
                          content: _vm.form.description || ""
                        },
                        on: {
                          change: function(x) {
                            return (_vm.form.description = x)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.description
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.description[0]) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Special Conditions")]),
                      _vm._v(" "),
                      _c("vue-html5-editor", {
                        attrs: {
                          height: 100,
                          content: _vm.form.special_conditions || ""
                        },
                        on: {
                          change: function(x) {
                            return (_vm.form.special_conditions = x)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.special_conditions
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.special_conditions[0]) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.shuttle_service,
                            expression: "form.shuttle_service"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.form.shuttle_service)
                            ? _vm._i(_vm.form.shuttle_service, null) > -1
                            : _vm.form.shuttle_service
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.form.shuttle_service,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "shuttle_service",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "shuttle_service",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "shuttle_service", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(
                        "\n                Shuttle Service\n              "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.errors.shuttle_service
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.shuttle_service[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Shuttle Info Summary")]),
                      _vm._v(" "),
                      _c("vue-html5-editor", {
                        attrs: {
                          height: 100,
                          content: _vm.form.shuttle_summary || ""
                        },
                        on: {
                          change: function(x) {
                            return (_vm.form.shuttle_summary = x)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.shuttle_summary
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.shuttle_summary[0]) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Shuttle Info Details")]),
                      _vm._v(" "),
                      _c("vue-html5-editor", {
                        attrs: {
                          height: 100,
                          content: _vm.form.shuttle_details || ""
                        },
                        on: {
                          change: function(x) {
                            return (_vm.form.shuttle_details = x)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.shuttle_details
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.shuttle_details[0]) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["super_admin"],
                          expression: "['super_admin']",
                          arg: "allow"
                        }
                      ],
                      staticClass: "form-group"
                    },
                    [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.send_customer_review_email,
                              expression: "form.send_customer_review_email"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.form.send_customer_review_email
                            )
                              ? _vm._i(
                                  _vm.form.send_customer_review_email,
                                  null
                                ) > -1
                              : _vm.form.send_customer_review_email
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.form.send_customer_review_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "send_customer_review_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "send_customer_review_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.form,
                                  "send_customer_review_email",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _vm._v(
                          "\n                Send trustpilot/google customer reviews email\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.errors.send_customer_review_email
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.errors.send_customer_review_email[0]
                                ) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("h4", { staticClass: "mb-2" }, [_vm._v("SEO Properties")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "head_title" } }, [
                      _vm._v("Head Title")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.head_title,
                          expression: "form.head_title",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "head_title",
                        placeholder: "Head title for browser and search engines"
                      },
                      domProps: { value: _vm.form.head_title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "head_title",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.head_title
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.head_title[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "head_keywords" } }, [
                      _vm._v("Head Keywords")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.head_keywords,
                          expression: "form.head_keywords",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "head_keywords",
                        placeholder: "keywords for search engines"
                      },
                      domProps: { value: _vm.form.head_keywords },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "head_keywords",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.head_keywords
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.head_keywords[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "head_description" } }, [
                      _vm._v("Head Description")
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.head_description,
                          expression: "form.head_description",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control textarea-height",
                      attrs: {
                        id: "head_description",
                        placeholder: "page descripton for search engines"
                      },
                      domProps: { value: _vm.form.head_description },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "head_description",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.head_description
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.head_description[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: [
                          "LOCATION_UPDATE",
                          "LOCATION_GENERAL_INFO_UPDATE"
                        ]
                      },
                      expression:
                        "{id: location.id, permits: ['LOCATION_UPDATE', 'LOCATION_GENERAL_INFO_UPDATE']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "text-right"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      class: { disabled: _vm.processing },
                      attrs: { disabled: _vm.processing },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      }
                    },
                    [
                      _vm.processing
                        ? _c("span", [
                            _c("i", {
                              staticClass: "fa fa-spinner fa-spin fa-fw"
                            })
                          ])
                        : _vm._e(),
                      _vm._v("\n            Save\n          ")
                    ]
                  )
                ]
              )
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1d85e3ea", { render: render, staticRenderFns: staticRenderFns })
  }
}