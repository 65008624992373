import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
}

const computed = {
  currencies () {
    return [].concat(this.$store.state.currencies.all);
  },
}

const watch = {
}

const methods = {
  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('tripProtections.create', _.cloneDeep(this.form))
    .then(() => {
      this.submit_loading = false;
      if (this.$store.state.tripProtections.errors) {
        this.errors = _.cloneDeep(this.$store.state.tripProtections.errors.errors);
        toastr.error(this.$store.state.tripProtections.errors.message);
      } else {
        toastr.success('A new Trip protections plan has been created');
        this.$router.push({name: 'tripProtections.list'});
      }
    })
  },

  wysiwygDescriptionChanged (val) {
    this.form.description = val
  },

  wysiwygShortDescriptionChanged (val) {
    this.form.short_description = val
  },

  createPrice () {
    this.form.prices.push({
      currency_id: null, price: null
    });
  },

  removePrice (index) {
    this.form.prices.splice(index, 1)
  }
}

export default {
  name: 'new-trip-protection',
  components,
  computed,
  methods,
  watch,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      errors: {},
      form: {
        title: null,
        short_description: null,
        description: null,
        number_of_hours: null,
        prices: [
          {currency_id: null, price: null}
        ]
      }
    }
  },
  mounted () {
  },
  created () {
    this.loading = true;
    this.$store.dispatch('currencies.getAll').then(() => {
      this.loading = false;
    });
  }
}
