var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("h3", { staticClass: "mb-2" }, [_vm._v("App Logs")]),
    _vm._v(" "),
    _c("div", { staticClass: "card mb-4 logs" }, [
      _c(
        "div",
        { staticClass: "card-block" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12 margin-bottom-3x" }, [
              _c(
                "div",
                {
                  staticClass: "logs-search input-group margin-bottom-1x gap-1"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter.type,
                        expression: "filter.type"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "Type" },
                    domProps: { value: _vm.filter.type },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.filter, "type", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter.area,
                        expression: "filter.area"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "Area" },
                    domProps: { value: _vm.filter.area },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.filter, "area", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter.model_type,
                        expression: "filter.model_type"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "Model Type" },
                    domProps: { value: _vm.filter.model_type },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.filter, "model_type", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter.model_id,
                        expression: "filter.model_id"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "Model Id" },
                    domProps: { value: _vm.filter.model_id },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.filter, "model_id", $event.target.value)
                      }
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-inline mt-2" }, [
                _c("div", { staticClass: "form-group margin-right-2x" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label margin-right-1x",
                      attrs: { for: "from-date" }
                    },
                    [_vm._v(" From Date")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("datepicker", {
                        staticClass: "form-control reservation--date-picker",
                        attrs: { id: "from-date", "min-date": "none" },
                        model: {
                          value: _vm.filter.from_date,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "from_date", $$v)
                          },
                          expression: "filter.from_date"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c("span", { staticClass: "input-group-text" }, [
                          _c("i", { staticClass: "fa fa-calendar" })
                        ])
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group margin-right-2x" }, [
                  _c(
                    "label",
                    {
                      staticClass: "control-label margin-right-1x",
                      attrs: { for: "to-date" }
                    },
                    [_vm._v("To Date")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("datepicker", {
                        staticClass: "form-control reservation--date-picker",
                        attrs: { id: "to-date", "min-date": "none" },
                        model: {
                          value: _vm.filter.to_date,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "to_date", $$v)
                          },
                          expression: "filter.to_date"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c("span", { staticClass: "input-group-text" }, [
                          _c("i", { staticClass: "fa fa-calendar" })
                        ])
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default mt-4",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.loadPage(1)
                    }
                  }
                },
                [_vm._v("Search")]
              )
            ])
          ]),
          _vm._v(" "),
          _vm.loading
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", {
                  staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                })
              ])
            : _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("id")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Type")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Area")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Model type")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Model id")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Message")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Data")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Request")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("URL")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Created at")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.logs.data, function(log, i) {
                      return _c(
                        "tr",
                        { key: log.id, attrs: { id: "row-" + i } },
                        [
                          _c("td", [_vm._v(_vm._s(log.id))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(log.type))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(log.area))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(log.model_type))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(log.model_id))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(log.message))]),
                          _vm._v(" "),
                          _c("td", { attrs: { id: "column-data-" + i } }, [
                            _c(
                              "div",
                              [
                                _c("json-viewer", {
                                  attrs: { value: log.data }
                                }),
                                _vm._v(" "),
                                _vm.heightOver("data", i)
                                  ? _c(
                                      "span",
                                      {
                                        class: [
                                          "ellipsis-btn",
                                          "ellipsis-btn-" + i
                                        ],
                                        attrs: {
                                          id: "ellipsis-btn-data-" + i,
                                          title: "click to show hidden items"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.showHiddenItems(i)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-angle-double-down",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { attrs: { id: "column-request-" + i } }, [
                            _c(
                              "div",
                              [
                                _c("json-viewer", {
                                  attrs: { value: log.request }
                                }),
                                _vm._v(" "),
                                _vm.heightOver("request", i)
                                  ? _c(
                                      "span",
                                      {
                                        class: [
                                          "ellipsis-btn",
                                          "ellipsis-btn-" + i
                                        ],
                                        attrs: {
                                          id: "ellipsis-btn-request-" + i,
                                          title: "click to show hidden items"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.showHiddenItems(i)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-angle-double-down",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(log.url))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(log.created_at))])
                        ]
                      )
                    }),
                    0
                  )
                ])
              ]),
          _vm._v(" "),
          _c("pagination", {
            attrs: { "pages-number": _vm.logs.last_page },
            on: {
              pageChanged: function(page) {
                return _vm.loadPage(page)
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1408d81f", { render: render, staticRenderFns: staticRenderFns })
  }
}