import moment from 'moment'
import env from 'constants/env'
import datatable from 'components/datatable'
import { permits, user } from 'services'
import { currencySymbol } from 'filters'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.show_table = true;
        this.$nextTick(() => {
            this.$refs.table.run();
        })
    },

    setCols () {
        this.columns = [
            {label: "Reservation #", attribute: 'reservation.reservation_number',sortable: true, sort_key: "reservation_id",},
            {label: "Airport", attribute: 'location.port.name',sortable: true, sort_key: "port_name"},
            {label: "Location", attribute: 'location.name',sortable: true, sort_key: "location_name"},
            {label: "Start Date", attribute: (data) => (
                moment(data.from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "from_date"},
        ]
    }
}

let computed = {
}

let watch = {
}

export default {
    name: "accountant",
    methods,
    components,
    computed,
    watch,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/accountant',
        columns: [],
        user: null
    }),
    mounted () {
        this.user = user.getUser();
        this.setCols();
    }
}