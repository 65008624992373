import moment from 'moment'
import { permits } from 'services'
import env from 'constants/env'
import datatable from 'components/datatable'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.filters.cancelled = false;
        this.show_table = true;
        this.$nextTick(() => {
            this.$refs.table.run();
        })
    }
}

let computed = {
    user () {
        return this.$store.state.user.data;
    }
}

export default {
    name: "arrivals-report",
    methods,
    components,
    computed,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/arrivals',
        columns: []
    }),
    mounted () {        
        this.columns = [
            {label: "Customer Name", attribute: 'history.0.customer_name', sortable: true, sort_key: "customer_name"},
            {label: "Location", attribute: 'history.0.location.name',sortable: true, sort_key: "location_name"},
            {label: "Reservation #", attribute: 'reservation_number',
                sortable: true, 
                sort_key: "reservation_number",
                classes: ['clickable'],
                click: (data) => {
                    if (permits.hasPermit('reservation_update')) {
                        let route = this.$router.resolve({
                            name: 'reservations.edit',
                            params: {id: data.id}
                        });
                        
                        window.open(route.href, '_blank');
                    }
                }
            },
            {label: "Start Date", attribute: (data) => (
                moment(data.history[0].from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "from_date"},
            {label: "End Date", attribute: (data) => (
                moment(data.history[0].to_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "to_date"},
            {label: "Spots", attribute: "history.0.number_of_spots",sortable: true, sort_key: "number_of_spots"},
        ]
    }
}