var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card mb-4" },
    [
      _c("div", { staticClass: "card-block " }, [
        _c("h4", { staticClass: "mb-2" }, [_vm._v("Fees")]),
        _vm._v(" "),
        _vm.loading
          ? _c("div", { staticClass: "text-center" }, [
              _c("i", {
                staticClass: "fa fa-circle-o-notch fa-spin fa-2x fa-fw"
              })
            ])
          : _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Desc.")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Type")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("$/%")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Daily")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Amount")]),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.location_id,
                            permits: ["fee_delete"]
                          },
                          expression:
                            "{id: location_id, permits: ['fee_delete']}",
                          arg: "allow"
                        }
                      ]
                    },
                    [_vm._v("Remove")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.fees, function(item) {
                  return _c("tr", [
                    _c(
                      "td",
                      [
                        _c("editable-text", {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.location_id,
                                permits: ["fee_update"]
                              },
                              expression:
                                "{id: location_id, permits: ['fee_update']}",
                              arg: "allow"
                            }
                          ],
                          attrs: { value: item.name },
                          on: {
                            valueSaved: function(data) {
                              return _vm.onEditFee(data, item, "name")
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:prevent",
                                value: {
                                  id: _vm.location_id,
                                  permits: ["super_admin", "fee_update"]
                                },
                                expression:
                                  "{id: location_id, permits: ['super_admin', 'fee_update']}",
                                arg: "prevent"
                              }
                            ]
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("editable-text", {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.location_id,
                                permits: ["fee_update"]
                              },
                              expression:
                                "{id: location_id, permits: ['fee_update']}",
                              arg: "allow"
                            }
                          ],
                          attrs: { value: item.description },
                          on: {
                            valueSaved: function(data) {
                              return _vm.onEditFee(data, item, "description")
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:prevent",
                                value: {
                                  id: _vm.location_id,
                                  permits: ["super_admin", "fee_update"]
                                },
                                expression:
                                  "{id: location_id, permits: ['super_admin', 'fee_update']}",
                                arg: "prevent"
                              }
                            ]
                          },
                          [_vm._v(_vm._s(item.description))]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("editable-radio", {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.location_id,
                                permits: ["fee_update"]
                              },
                              expression:
                                "{id: location_id, permits: ['fee_update']}",
                              arg: "allow"
                            }
                          ],
                          attrs: {
                            value: item.fee_for,
                            options: {
                              osp: "OSP",
                              location: "Location"
                            }
                          },
                          on: {
                            valueSaved: function(data) {
                              return _vm.onEditFee(data, item, "fee_for")
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:prevent",
                                value: {
                                  id: _vm.location_id,
                                  permits: ["super_admin", "fee_update"]
                                },
                                expression:
                                  "{id: location_id, permits: ['super_admin', 'fee_update']}",
                                arg: "prevent"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  item.fee_for == "osp" ? "OSP" : "Location"
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("editable-radio", {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.location_id,
                                permits: ["fee_update"]
                              },
                              expression:
                                "{id: location_id, permits: ['fee_update']}",
                              arg: "allow"
                            }
                          ],
                          attrs: {
                            value: item.calculation_type,
                            options: {
                              percentage: "%",
                              fixed: "$"
                            }
                          },
                          on: {
                            valueSaved: function(data) {
                              return _vm.onEditFee(
                                data,
                                item,
                                "calculation_type"
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:prevent",
                                value: {
                                  id: _vm.location_id,
                                  permits: ["super_admin", "fee_update"]
                                },
                                expression:
                                  "{id: location_id, permits: ['super_admin', 'fee_update']}",
                                arg: "prevent"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  item.calculation_type == "percentage"
                                    ? "%"
                                    : "$"
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("editable-radio", {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.location_id,
                                permits: ["fee_update"]
                              },
                              expression:
                                "{id: location_id, permits: ['fee_update']}",
                              arg: "allow"
                            }
                          ],
                          attrs: {
                            value: item.daily,
                            options: {
                              1: "Daily",
                              0: "One Time"
                            }
                          },
                          on: {
                            valueSaved: function(data) {
                              return _vm.onEditFee(data, item, "daily")
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:prevent",
                                value: {
                                  id: _vm.location_id,
                                  permits: ["super_admin", "fee_update"]
                                },
                                expression:
                                  "{id: location_id, permits: ['super_admin', 'fee_update']}",
                                arg: "prevent"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.daily == 1 ? "Daily" : "One Time") +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("editable-text", {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: {
                                id: _vm.location_id,
                                permits: ["fee_update"]
                              },
                              expression:
                                "{id: location_id, permits: ['fee_update']}",
                              arg: "allow"
                            }
                          ],
                          attrs: { value: item.amount },
                          on: {
                            valueSaved: function(data) {
                              return _vm.onEditFee(data, item, "amount")
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:prevent",
                                value: {
                                  id: _vm.location_id,
                                  permits: ["super_admin", "fee_update"]
                                },
                                expression:
                                  "{id: location_id, permits: ['super_admin', 'fee_update']}",
                                arg: "prevent"
                              }
                            ]
                          },
                          [_vm._v(_vm._s(item.amount))]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.location_id,
                              permits: ["fee_delete"]
                            },
                            expression:
                              "{id: location_id, permits: ['fee_delete']}",
                            arg: "allow"
                          }
                        ]
                      },
                      [
                        _vm.loadings[item.id]
                          ? _c("div", [
                              _c("i", { staticClass: "fa fa-spinner fa-spin" })
                            ])
                          : _c(
                              "a",
                              {
                                staticClass: "text-danger",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.removeFee(item.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fa fa-trash" })]
                            )
                      ]
                    )
                  ])
                }),
                0
              )
            ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: { id: _vm.location_id, permits: ["fee_create"] },
                expression: "{id: location_id, permits: ['fee_create']}",
                arg: "allow"
              }
            ]
          },
          [
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.open_form = !_vm.open_form
                    }
                  }
                },
                [
                  !_vm.open_form
                    ? _c("i", {
                        staticClass: "fa fa-caret-down",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.open_form
                    ? _c("i", {
                        staticClass: "fa fa-caret-up",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e(),
                  _vm._v("\n          New Fee\n        ")
                ]
              )
            ]),
            _vm._v(" "),
            _vm.open_form
              ? _c("div", { staticClass: "margin-top-3x" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Fee Name")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.name,
                          expression: "form.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "name", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.name
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.name[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Amount")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.amount,
                          expression: "form.amount"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.amount },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "amount", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.amount
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.amount[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Description")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.description,
                          expression: "form.description"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.description },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "description", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.description
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.description[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Type")]),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio" }, [
                      _c("label", { staticClass: "margin-right-3x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.fee_for,
                              expression: "form.fee_for"
                            }
                          ],
                          attrs: { type: "radio", value: "osp" },
                          domProps: {
                            checked: _vm._q(_vm.form.fee_for, "osp")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "fee_for", "osp")
                            }
                          }
                        }),
                        _vm._v("\n              OSP\n            ")
                      ]),
                      _vm._v(" "),
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.fee_for,
                              expression: "form.fee_for"
                            }
                          ],
                          attrs: { type: "radio", value: "location" },
                          domProps: {
                            checked: _vm._q(_vm.form.fee_for, "location")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.form, "fee_for", "location")
                            }
                          }
                        }),
                        _vm._v("\n              Location\n            ")
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.errors.fee_for
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.fee_for[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Fee Calculation")]),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio" }, [
                      _c("label", { staticClass: "margin-right-3x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.daily,
                              expression: "form.daily"
                            }
                          ],
                          attrs: { type: "radio" },
                          domProps: {
                            value: 1,
                            checked: _vm._q(_vm.form.daily, 1)
                          },
                          on: {
                            change: [
                              function($event) {
                                return _vm.$set(_vm.form, "daily", 1)
                              },
                              _vm.handleFeesType
                            ]
                          }
                        }),
                        _vm._v("\n              Daily "),
                        _c("strong", [_vm._v("($, %)")])
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-right-3x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.daily,
                              expression: "form.daily"
                            }
                          ],
                          attrs: { type: "radio" },
                          domProps: {
                            value: 0,
                            checked: _vm._q(_vm.form.daily, 0)
                          },
                          on: {
                            change: [
                              function($event) {
                                return _vm.$set(_vm.form, "daily", 0)
                              },
                              _vm.handleFeesType
                            ]
                          }
                        }),
                        _vm._v("\n              One Time "),
                        _c("strong", [_vm._v("($)")])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.errors.daily
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors.daily[0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.form.daily
                    ? _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Calculation Type")]),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "radio" }, [
                          _c("label", { staticClass: "margin-right-3x" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.calculation_type,
                                  expression: "form.calculation_type"
                                }
                              ],
                              attrs: { type: "radio", value: "fixed" },
                              domProps: {
                                checked: _vm._q(
                                  _vm.form.calculation_type,
                                  "fixed"
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "calculation_type",
                                    "fixed"
                                  )
                                }
                              }
                            }),
                            _vm._v("\n              Fixed ($)\n            ")
                          ]),
                          _vm._v(" "),
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.calculation_type,
                                  expression: "form.calculation_type"
                                }
                              ],
                              attrs: { type: "radio", value: "percentage" },
                              domProps: {
                                checked: _vm._q(
                                  _vm.form.calculation_type,
                                  "percentage"
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "calculation_type",
                                    "percentage"
                                  )
                                }
                              }
                            }),
                            _vm._v(
                              "\n              Percentage (%)\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.errors.calculation_type
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errors.calculation_type[0]) +
                                  "\n          "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.processing },
                        attrs: { disabled: _vm.processing },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit()
                          }
                        }
                      },
                      [
                        _vm.processing
                          ? _c("span", [
                              _c("i", { staticClass: "fa fa-spinner fa-spin" })
                            ])
                          : _vm._e(),
                        _vm._v("\n            SAVE FEE\n          ")
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5eb817e3", { render: render, staticRenderFns: staticRenderFns })
  }
}