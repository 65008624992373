import toastr from 'toastr'
import layout from 'layouts/default'
import fileUpload from 'components/fileUpload'

const components = {
  layout,
  fileUpload
}

const methods = {

  logoToUpload (files) {
    if (files.length) {
      this.logo = files;
    } else {
      this.logo = [];
    }
  },

  faviconToUpload (files) {
    if (files.length) {
      this.favicon = files;
    } else {
      this.favicon = [];
    }
  },

  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('affiliates.create',{
      data: _.cloneDeep(this.form),
      logo: this.logo,
      favicon: this.favicon
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.affiliates.errors) {
        this.errors = _.cloneDeep(this.$store.state.affiliates.errors.errors);
        toastr.error(this.$store.state.affiliates.errors.message);
      } else {
        toastr.success('A new Affiliate has been created');
        this.$router.push({name: 'affiliates.list'});
      }
    })
  }
}

export default {
  name: 'new-affiliate',
  components,
  methods,
  data () {
    return {
      submit_loading: false,
      errors: {},
      logo: [],
      favicon: [],
      form: {
        name: null,
        active: true,
        attribute: null,
        website: null,
      }
    }
  },
  mounted () {
  },
  created () {
  }
}
