import layout from 'layouts/default'
import validateReservation from './validateReservation'
import checkin from './checkin'
import checkout from './checkout'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  validateReservation,
  checkin,
  checkout,
  layout
}

export default {
  name: 'location-dashboard',
  components,
  directives,
  data () {
    return {
      loading: true,
      location: null
    }
  },
  created () {
    this.$store.dispatch('locations.get', this.$route.params.id).then(() => {
      this.location = this.$store.state.locations.active;
      this.loading = false;
    });
  }
}
