import layout from 'layouts/default'
import general from './general'
import fees from './fees'
import extraFields from './extraFields'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  general,
  fees,
  // cancellationPolicy,
  extraFields
}

export default {
  name: "booking-conditions",
  components,
  directives,
  data () {
    return {
      loading: true,
      location: null
    }
  }, created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.getBarcodeEncodings'),
      this.$store.dispatch('currencies.getAll'),
    ]).then(() => {
      this.location = _.cloneDeep(this.$store.state.locations.active);
      this.loading = false;
    })
  }
}
