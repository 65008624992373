import { http } from 'services'
// initial state
const state = () => ({
  errors: null,
  active: {},
  all: {},
})

// getters
const getters = {
  // reversedMessage: state => state.message.reverse()
}

// actions
const actions = {
  
  ['unifiedReservations.getAll'] ({ commit }, params) {
    commit('CLEAR_ERRORS')
    console.log('params', params);
    return (http.get('unified-reservations', {
      params
    })).then ((res) => {
      commit('GET_ALL_UNIFIED_RESERVATIONS', res);
    }).catch ((res) => {
      commit('UNIFIED_RESERVATIONS_ERROR', res);
    })
  },

  //get single reservations by id
  ['unifiedReservations.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')

    return (http.get(`unified-reservations/${id}`))
    .then(res => {
      console.log('res', res);
      commit('GET_UNIFIED_RESERVATION', res);
    }).catch ((res) => {
      commit('UNIFIED_RESERVATIONS_ERROR', res);
    });
  },

  ['unifiedReservations.sendToEmail'] ({commit}, {id, email, reservation_email}) {
    commit('CLEAR_ERRORS');

    return (http.post(`unified-reservations/${id}/send-to-email`, {email, reservation_email})).then ((res) => {
    })
    .catch ((res) => {
      commit('UNIFIED_RESERVATIONS_ERROR', res);
    })
  }
}

// mutations
const mutations = {

  GET_ALL_UNIFIED_RESERVATIONS (state, res) {
    state.all = res.data;
    state.all = _.cloneDeep(state.all);
  },

  GET_UNIFIED_RESERVATION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  //ERRORS
  UNIFIED_RESERVATIONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
