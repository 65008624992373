var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("swatches", {
        attrs: { "show-fallback": "", "fallback-input-type": "color" },
        on: { input: _vm.changeColor },
        model: {
          value: _vm.color,
          callback: function($$v) {
            _vm.color = $$v
          },
          expression: "color"
        }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.color }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6d9f6b74", { render: render, staticRenderFns: staticRenderFns })
  }
}