var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c("div", [
          _c("h3", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.campaign.name))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card mb-4" }, [
            _c("div", { staticClass: "card-block " }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["port_update"],
                      expression: "['port_update']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "text-right"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      class: { disabled: _vm.processing },
                      attrs: { disabled: _vm.processing },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      }
                    },
                    [
                      _vm.processing
                        ? _c("span", [
                            _c("i", {
                              staticClass: "fa fa-spinner fa-spin fa-fw"
                            })
                          ])
                        : _vm._e(),
                      _vm._v("\n          Save\n        ")
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "portName" } }, [
                      _vm._v("Campaign Name")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.name,
                          expression: "form.name",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control disabled",
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "name", $event.target.value.trim())
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.name
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.name[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Airport")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.airport_id,
                            expression: "form.airport_id",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "airport_id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function($event) {
                              return _vm.onChangePort()
                            }
                          ]
                        }
                      },
                      _vm._l(_vm.ports, function(port) {
                        return _c("option", { domProps: { value: port.id } }, [
                          _vm._v(_vm._s(port.name))
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.errors.airport_id
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.airport_id[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-group-text",
                        attrs: { for: "status" }
                      },
                      [_vm._v("Status")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.status,
                          expression: "form.status",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control disabled",
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.form.status },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "status",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.status
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.status[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "code" } }, [
                      _vm._v("Available spots to Pause (0 mean sold out)")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.form.available_spots_to_pause,
                          expression: "form.available_spots_to_pause",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.available_spots_to_pause },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "available_spots_to_pause",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.available_spots_to_pause
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.available_spots_to_pause[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Pause based on")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.pause_based_on,
                            expression: "form.pause_based_on"
                          }
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "pause_based_on",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "all_soldout" } }, [
                          _vm._v("All location sold out")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "some_soldout" } }, [
                          _vm._v("Some location sold out")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "any_soldout" } }, [
                          _vm._v("Any one of selected location sold out")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.pause_based_on
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.pause_based_on[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.form.pause_based_on != "all_soldout"
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Locations")]),
                          _vm._v(" "),
                          _c("s-select", {
                            class: {
                              disabled: _vm.form.pause_based_on == "all_soldout"
                            },
                            attrs: {
                              options: _vm.portLocations,
                              selected: _vm.selectedLocations,
                              multiple: true,
                              disabled: _vm.form.pause_based_on == "all_soldout"
                            },
                            on: {
                              selectedChanged: _vm.onChangeSelectedLocations
                            }
                          }),
                          _vm._v(" "),
                          _vm.form.pause_based_on != "all_soldout"
                            ? _c(
                                "div",
                                _vm._l(_vm.selectedLocations, function(
                                  location,
                                  l
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: l,
                                      staticClass:
                                        "margin-top-1x margin-bottom-1x"
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text-danger",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.removeSelected(
                                                location
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-times",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(location.text) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errors.locations
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.errors.locations[0]) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "code" } }, [
                      _vm._v("Available spots to renable")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.form.available_spots_to_enable,
                          expression: "form.available_spots_to_enable",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.available_spots_to_enable },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "available_spots_to_enable",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.available_spots_to_enable
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.available_spots_to_enable[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.is_enabled,
                            expression: "form.is_enabled"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.form.is_enabled)
                            ? _vm._i(_vm.form.is_enabled, null) > -1
                            : _vm.form.is_enabled
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.form.is_enabled,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.form,
                                    "is_enabled",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.form,
                                    "is_enabled",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.form, "is_enabled", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v("\n              Enabled\n            ")
                    ]),
                    _vm._v(" "),
                    _vm.errors.is_enabled
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.is_enabled[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cb60f998", { render: render, staticRenderFns: staticRenderFns })
  }
}