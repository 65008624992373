import Login from 'pages/login'
import resetPassword from 'pages/login/resetPassword'

export default [
    { path: '/login', name: 'login', component: Login, meta: {public: true},
        children: [
            { 
                path: '/reset-password', 
                name: 'reset-password', 
                component: resetPassword, 
                meta: {public: true} 
            }
        ]  
    },
]