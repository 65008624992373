import toastr from 'toastr'
import { funcModal } from 'components/modals'
import { hasPermits } from 'directives'

const components = {
  funcModal
}

const directives = {
  hasPermits
}

const computed = {
  roles () {
    return this.$store.state.roles.all;
  },

  country () {
    let countries = this.$store.state.address.countries;
    for (let i in countries) {
      if (countries[i].id == this.selectedUser.user.country_id) {
        return countries[i];
      }
    }
    return {};
  },

  state () {
    for (let i in this.country.states) {
      if (this.country.states[i].id == this.selectedUser.user.state_id) {
        return this.country.states[i];
      }
    }
    return {};
  }
}

const methods = {
  submit (modal) {
    this.errors = {}

    this.processing = true;
    this.$store.dispatch('locations.updateEmployee', {
      id: this.selectedUser.id,
      location_id: this.location_id,
      data: this.form
    })
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.locations.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        this.$refs.modal.close();
      }
    });
  },
  open () {
    this.$refs.modal.open();
  },
}

export default {
  name: 'edit-employee-modal',
  props: ['selectedUser'],
  computed,
  components,
  directives,
  methods,
  data () {

    return {
      loading: true,
      processing: false,
      errors: {},
      location_id: this.$route.params.id,
      form: {
        role_id: this.selectedUser.role_id,
        receive_email: this.selectedUser.receive_email ? 1 : false
      }
    }
  },
  created () {
    this.loading = true;
    this.$store.dispatch('roles.getAll', {
      type: 'location',
      _all: true,
      location_id: this.$route.params.id
    })
    .then(() => {
      this.loading = false;
    });
  }
}
