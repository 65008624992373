var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("h3", { staticClass: "mb-2" }, [_vm._v("Edit Affiliates")]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c(
          "div",
          { staticClass: "card mb-4" },
          [
            _c(
              "tabs",
              { on: { tabChange: _vm.onChangeTab } },
              [
                _c(
                  "tab",
                  { attrs: { title: "General" } },
                  [_c("general", { attrs: { affiliate: _vm.affiliate } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "tab",
                  { attrs: { title: "Users" } },
                  [_c("users", { attrs: { activeTab: _vm.active_tab } })],
                  1
                )
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c0d5593e", { render: render, staticRenderFns: staticRenderFns })
  }
}