var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("no-ssr", [
    _c("div", { staticClass: "container-fluid", attrs: { id: "wrapper" } }, [
      _c("div", { staticClass: "row" }, [
        !_vm.noSidebar
          ? _c(
              "nav",
              { staticClass: "sidebar col-xs-12 col-sm-4 col-lg-3 col-xl-2" },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-default",
                    attrs: { href: "#menu-toggle", id: "menu-toggle" }
                  },
                  [_c("em", { staticClass: "fa fa-bars" })]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "nav nav-pills flex-column sidebar-nav" },
                  [
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.$route.params.id,
                              permits: ["location_user", "location_show"]
                            },
                            expression:
                              "{id: $route.params.id, permits: ['location_user', 'location_show']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "nav-item"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class: [
                              {
                                active:
                                  _vm.$route.name == "locations.guestManagement"
                              }
                            ],
                            attrs: {
                              to: {
                                name: "locations.guestManagement",
                                params: {
                                  id: _vm.$route.params.id
                                }
                              }
                            }
                          },
                          [
                            _c("em", { staticClass: "fa fa-user" }),
                            _vm._v(
                              "\n                Guest Management\n              "
                            ),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("(current)")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.$route.params.id,
                              permits: [
                                "location_user",
                                "location_show",
                                "LOCATION_GENERAL_INFO_LIST"
                              ]
                            },
                            expression:
                              "{id: $route.params.id, permits: ['location_user', 'location_show', 'LOCATION_GENERAL_INFO_LIST']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "nav-item"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class: [
                              {
                                active:
                                  _vm.$route.name == "locations.information"
                              }
                            ],
                            attrs: {
                              to: {
                                name: "locations.information",
                                params: {
                                  id: _vm.$route.params.id
                                }
                              }
                            }
                          },
                          [
                            _c("em", { staticClass: "fa fa-info-circle" }),
                            _vm._v(
                              "\n                Location Info\n            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.$route.params.id,
                              permits: ["EMPLOYEE_LIST"]
                            },
                            expression:
                              "{id: $route.params.id, permits: ['EMPLOYEE_LIST']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "nav-item"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class: [
                              {
                                active:
                                  _vm.$route.name.indexOf(
                                    "locations.administration."
                                  ) > -1
                              }
                            ],
                            attrs: {
                              to: {
                                name: "locations.administration.employees",
                                params: {
                                  id: _vm.$route.params.id
                                }
                              }
                            }
                          },
                          [
                            _c("em", { staticClass: "fa fa-lock" }),
                            _vm._v(
                              "\n                Administration\n            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.$route.params.id,
                              permits: ["locationtype_list"]
                            },
                            expression:
                              "{id: $route.params.id, permits: ['locationtype_list']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "nav-item"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class: [
                              { active: _vm.$route.name == "locations.types" }
                            ],
                            attrs: {
                              to: {
                                name: "locations.types",
                                params: {
                                  id: _vm.$route.params.id
                                }
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-car",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(
                              "\n                Location Types\n              "
                            ),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("(current)")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.$route.params.id,
                              permits: ["rate_list"]
                            },
                            expression:
                              "{id: $route.params.id, permits: ['rate_list']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "nav-item"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class: [
                              { active: _vm.$route.name == "locations.rates" }
                            ],
                            attrs: {
                              to: {
                                name: "locations.rates",
                                params: {
                                  id: _vm.$route.params.id
                                }
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-money",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(
                              "\n                Location Rates\n              "
                            ),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("(current)")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.$route.params.id,
                              permits: ["BLACKOUTDATE_LIST"]
                            },
                            expression:
                              "{id: $route.params.id, permits: ['BLACKOUTDATE_LIST']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "nav-item"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class: [
                              {
                                active: _vm.$route.name == "locations.blackouts"
                              }
                            ],
                            attrs: {
                              to: {
                                name: "locations.blackouts",
                                params: {
                                  id: _vm.$route.params.id
                                }
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-window-close",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(
                              "\n                Blackouts\n              "
                            ),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("(current)")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.$route.params.id,
                              permits: [
                                "location_show",
                                "LOCATION_BOOKING_CONDITIONS_LIST"
                              ]
                            },
                            expression:
                              "{id: $route.params.id, permits: ['location_show', 'LOCATION_BOOKING_CONDITIONS_LIST']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "nav-item"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class: [
                              {
                                active:
                                  _vm.$route.name ==
                                  "locations.bookingConditions"
                              }
                            ],
                            attrs: {
                              to: {
                                name: "locations.bookingConditions",
                                params: {
                                  id: _vm.$route.params.id
                                }
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-file-text-o",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(
                              "\n                Booking Conditions\n              "
                            ),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("(current)")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.$route.params.id,
                              permits: ["LOCATION_AMENITIES_AND_PHOTOS"]
                            },
                            expression:
                              "{id: $route.params.id, permits: ['LOCATION_AMENITIES_AND_PHOTOS']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "nav-item"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class: [
                              {
                                active:
                                  _vm.$route.name ==
                                  "locations.amenitiesAndPhotos"
                              }
                            ],
                            attrs: {
                              to: {
                                name: "locations.amenitiesAndPhotos",
                                params: {
                                  id: _vm.$route.params.id
                                }
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-picture-o",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(
                              "\n                Amenities And Photos\n              "
                            ),
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("(current)")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "main",
          {
            class: [
              { "col-12": _vm.noSidebar },
              {
                "col-xs-12 col-sm-8 col-lg-9 col-xl-10 pl-4 ml-auto": !_vm.noSidebar
              }
            ]
          },
          [
            _vm.allLocationsHasResLabId
              ? _c("div", [
                  _c("div", { staticClass: "res-lab-warning" }, [
                    _vm._v(
                      "\n            All property management has now moved to Reservations Lab, please go to \n            "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "res-lab-link",
                        attrs: { href: "https://reservationslab.com/login" }
                      },
                      [_vm._v("https://reservationslab.com/login")]
                    ),
                    _vm._v(
                      " \n            to access your property.\n          "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "header",
              { staticClass: "header-menu d-flex justify-content-between" },
              [
                _c("div", { staticClass: "logo" }, [
                  _c("img", {
                    attrs: { src: "/assets/images/osp-logo.svg", alt: "" }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "links" }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["location_user", "LOCATION_LIST"],
                            expression: "['location_user', 'LOCATION_LIST']",
                            arg: "allow"
                          }
                        ],
                        class: [
                          { active: _vm.$route.name.indexOf("locations.") > -1 }
                        ]
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "locations.list" } } },
                          [
                            _vm._v(
                              "\n                  Locations\n                "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["port_list"],
                            expression: "['port_list']",
                            arg: "allow"
                          }
                        ],
                        class: [
                          { active: _vm.$route.name.indexOf("ports.") > -1 }
                        ]
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "ports.list" } } },
                          [
                            _vm._v(
                              "\n                  Ports\n                "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["reservation_page"],
                            expression: "['reservation_page']",
                            arg: "allow"
                          }
                        ],
                        class: [
                          {
                            active:
                              _vm.$route.name.indexOf("reservations.") > -1
                          }
                        ]
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "reservations.list" } } },
                          [
                            _vm._v(
                              "\n                  Reservations\n                "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["reservation_unified_page"],
                            expression: "['reservation_unified_page']",
                            arg: "allow"
                          }
                        ],
                        class: [
                          {
                            active:
                              _vm.$route.name.indexOf("unifiedReservations.") >
                              -1
                          }
                        ]
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: { name: "unifiedReservations.list" } }
                          },
                          [
                            _vm._v(
                              "\n                  Unified Reservations\n                "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["customer_list"],
                            expression: "['customer_list']",
                            arg: "allow"
                          }
                        ]
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "customers.list" } } },
                          [
                            _vm._v(
                              "\n                  Customers\n                "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["user_list"],
                            expression: "['user_list']",
                            arg: "allow"
                          }
                        ],
                        class: [
                          { active: _vm.$route.name.indexOf("users.") > -1 }
                        ]
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "users.list" } } },
                          [
                            _vm._v(
                              "\n                  Users\n                "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "hasReportPermits",
                            rawName: "v-hasReportPermits:allow",
                            value: [
                              "role_list",
                              "coupon_list",
                              "review_list",
                              "message_list",
                              "amenity_list",
                              "CAROUSEL_LIST",
                              "tripprotection_list",
                              "REPORT_LIST"
                            ],
                            expression:
                              "['role_list', 'coupon_list', 'review_list', 'message_list', 'amenity_list', 'CAROUSEL_LIST', 'tripprotection_list', 'REPORT_LIST']",
                            arg: "allow"
                          }
                        ],
                        staticClass: "dropdown"
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-toggle",
                            attrs: {
                              href: "#",
                              id: "settings-drop-down",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            }
                          },
                          [_vm._v("\n                  More\n                ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu dropdown-menu-right",
                            attrs: { "aria-labelledby": "settings-drop-down" }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["role_list"],
                                    expression: "['role_list']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("roles.") > -1
                                  }
                                ],
                                attrs: { to: { name: "roles.list" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Roles\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["coupon_list"],
                                    expression: "['coupon_list']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("coupons.") > -1
                                  }
                                ],
                                attrs: { to: { name: "coupons.list" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Coupons\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasReportPermits",
                                    rawName: "v-hasReportPermits:allow",
                                    value: ["referrals_list"],
                                    expression: "['referrals_list']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("referrals") > -1
                                  }
                                ],
                                attrs: { to: { name: "referrals" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Referrals\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["review_list"],
                                    expression: "['review_list']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("reviews.") > -1
                                  }
                                ],
                                attrs: { to: { name: "reviews.list" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Reviews\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["message_list"],
                                    expression: "['message_list']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("messages.") > -1
                                  }
                                ],
                                attrs: { to: { name: "messages.list" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Messages\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["amenity_list"],
                                    expression: "['amenity_list']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("amenities.") > -1
                                  }
                                ],
                                attrs: { to: { name: "amenities.list" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Amenities\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["CAROUSEL_LIST"],
                                    expression: "['CAROUSEL_LIST']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("carousels.") > -1
                                  }
                                ],
                                attrs: { to: { name: "carousels.list" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Carousels\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["super_admin"],
                                    expression: "['super_admin']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("pages.") > -1
                                  }
                                ],
                                attrs: { to: { name: "pages.list" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Pages\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["tripprotection_list"],
                                    expression: "['tripprotection_list']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf(
                                        "tripProtections."
                                      ) > -1
                                  }
                                ],
                                attrs: { to: { name: "tripProtections.list" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Trip Protections\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["super_admin"],
                                    expression: "['super_admin']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("affiliates.") >
                                      -1
                                  }
                                ],
                                attrs: { to: { name: "affiliates.list" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Affiliates\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["super_admin"],
                                    expression: "['super_admin']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("googleAds.") > -1
                                  }
                                ],
                                attrs: { to: { name: "googleAds.list" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Google Ads\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasPermits",
                                    rawName: "v-hasPermits:allow",
                                    value: ["APP_LOGS"],
                                    expression: "['APP_LOGS']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("appLogs") > -1
                                  }
                                ],
                                attrs: { to: { name: "appLogs" } }
                              },
                              [
                                _vm._v(
                                  "\n                    App Logs\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.user && _vm.user.show_admin_api_requests
                              ? _c(
                                  "router-link",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["super_admin"],
                                        expression: "['super_admin']",
                                        arg: "allow"
                                      }
                                    ],
                                    staticClass: "dropdown-item",
                                    class: [
                                      {
                                        active:
                                          _vm.$route.name.indexOf(
                                            "adminApiRequests"
                                          ) > -1
                                      }
                                    ],
                                    attrs: { to: { name: "adminApiRequests" } }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Api Requests\n                  "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                directives: [
                                  {
                                    name: "hasReportPermits",
                                    rawName: "v-hasReportPermits:allow",
                                    value: ["report_list"],
                                    expression: "['report_list']",
                                    arg: "allow"
                                  }
                                ],
                                staticClass: "dropdown-item",
                                class: [
                                  {
                                    active:
                                      _vm.$route.name.indexOf("reports") > -1
                                  }
                                ],
                                attrs: { to: { name: "reports" } }
                              },
                              [
                                _vm._v(
                                  "\n                    Reports\n                  "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("li", { staticClass: "dropdown" }, [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-toggle",
                          attrs: {
                            href: "#",
                            id: "settings-drop-down",
                            "data-toggle": "dropdown",
                            "aria-haspopup": "true",
                            "aria-expanded": "false"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-cogs",
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-menu dropdown-menu-right",
                          attrs: { "aria-labelledby": "settings-drop-down" }
                        },
                        [
                          _vm.user
                            ? _c("div", { staticClass: "dropdown-item" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.user.email) +
                                    "\n                  "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "dropdown-divider" }),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "dropdown-item",
                              attrs: { to: { name: "settings" } }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-cog",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(
                                "\n                    Update Settings\n                  "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.logout($event)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-sign-out",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(
                                "\n                    Logout\n                  "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("section", [
              _c(
                "div",
                { staticClass: "main-container" },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _c("section", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 mt-1 mb-4" }, [
                  _c("hr"),
                  _vm._v("\n              © "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://onestopparking.com",
                        target: "_blank"
                      }
                    },
                    [_vm._v("One Stop Parking")]
                  ),
                  _vm._v(" " + _vm._s(_vm.year) + "\n            ")
                ])
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3f7e4975", { render: render, staticRenderFns: staticRenderFns })
  }
}