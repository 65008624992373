<template>
  <div class="">    
    <keep-alive max="1">
      <router-view v-if="$route.meta.keepAlive" :key="pageKey"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.path"></router-view>   
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>
<style lang="scss" src='sass/master.scss'></style>
<script>

import { user } from 'services'
const computed = {
  pageKey () {
    let key = this.$route.path;
    let _user = user.getUser();
    return _user ? key + "__" + _user.id : key;
  }
}

export default {
  name: 'main-component',
  computed,
  created () {
    console.log("<--- APP CREATED --->");
  }
}
</script>
