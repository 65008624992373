import { currencySymbol } from 'filters'

const methods = {
  removeCoupon () {
    this.$emit('removeCoupon');
  }
}

export default {
  name: "list-new-calculations",
  props: ['costs', 'currency_code'],
  methods,
  data () {
    return {
      currencySymbol
    }
  }
}
