import moment from 'moment'
import env from 'constants/env'
import { currencySymbol } from 'filters'
import { permits } from 'services'
import datatable from 'components/datatable'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.filters.cancelled = false;
        this.show_table = true;
        this.$nextTick(() => {
            this.$refs.table.run();
        })
    }
}

let computed = {
    user () {
        return this.$store.state.user.data;
    }
}

export default {
    name: "trip-protection-report",
    methods,
    components,
    computed,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/trip-protections',
        columns: []
    }),
    mounted () {
        this.columns = [
            {label: "Customer Name", attribute: "history.0.customer_name",sortable: true, sort_key: "customer_name"},
            {label: "Creation Date", attribute: (data) => (
                moment(data.created_at, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "created_at"},
            {label: "Start Date", attribute: (data) => (
                moment(data.history[0].from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "from_date"},
            {label: "End Date", attribute: (data) => (
                moment(data.history[0].to_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "to_date"},
            {label: "Reservation #", 
                attribute: 'reservation_number',
                sortable: true, 
                sort_key: "reservation_number",
                classes: ['clickable'],
                click: (data) => {
                    if (permits.hasPermit('reservation_update')) {
                        let route = this.$router.resolve({
                            name: 'reservations.edit',
                            params: {id: data.id}
                        });
                        
                        window.open(route.href, '_blank');
                    }
                }
            },
            {label: "Location", attribute: 'history.0.location.name',sortable: true, sort_key: "location_name"},
            {label: "Airport", attribute: 'history.0.location.port.name'},
            {label: "Spots", attribute: "history.0.number_of_spots"},
            {label: "Protection Plan", attribute: "history.0.trip_protection.plan.title"},
            {label: "Protection Cost", attribute: (data) => (
                this.$options.filters.currency(data.history[0].trip_protection_price, currencySymbol(data.currency_code))
            )},
            {label: "Currency", attribute: "currency_code",sortable: true, sort_key: "currency_code"},
            {label: "Total Cost", attribute: (data) => (
                this.$options.filters.currency(data.history[0].grand_total, currencySymbol(data.currency_code))
            )},
        ]
    }
}