var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card mb-4" },
    [
      _c(
        "div",
        { staticClass: "card-block " },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "file-upload",
            {
              directives: [
                {
                  name: "hasPermits",
                  rawName: "v-hasPermits:allow",
                  value: {
                    id: _vm.location.id,
                    permits: ["LOCATION_PHOTOS_UPDATE"]
                  },
                  expression:
                    "{id: location.id, permits: ['LOCATION_PHOTOS_UPDATE']}",
                  arg: "allow"
                }
              ],
              ref: "file_upload",
              attrs: { id: "photosUpload", multiple: true, accept: "image/*" },
              on: { filesChanged: _vm.imagesToUpload }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  class: { disabled: _vm.files_processing },
                  attrs: {
                    id: "uploadphotoBtn",
                    disabled: _vm.files_processing
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.uploadImages($event)
                    }
                  }
                },
                [
                  _vm.files_processing
                    ? _c("span", [
                        _vm.files_processing
                          ? _c("i", {
                              staticClass: "fa fa-spinner fa-spin fa-fw"
                            })
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v("\n        Upload\n      ")
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm.errors.images
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v("\n      " + _vm._s(_vm.errors.images[0]) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.photos.length
            ? _c("div", { staticClass: "margin-top-2x" }, [
                _c(
                  "ul",
                  { staticClass: "images-list" },
                  _vm._l(_vm.photos, function(photo, i) {
                    return _c("li", [
                      _c(
                        "div",
                        {
                          class: {
                            featured: photo.featured,
                            cover: photo.cover
                          },
                          attrs: {
                            title:
                              photo.featured && photo.cover
                                ? "This is the featured and cover photo"
                                : photo.cover
                                ? "This is the cover photo"
                                : photo.featured
                                ? "This is the featured photo"
                                : ""
                          },
                          on: {
                            mouseover: function($event) {
                              return _vm.showBtns(i)
                            },
                            mouseleave: function($event) {
                              return _vm.hideBtns(i)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "pointer thumbnail",
                            class: {
                              "port-featured-photo": photo.featured,
                              "port-cover-photo": photo.cover
                            },
                            attrs: {
                              src: photo.image_url,
                              alt: "",
                              "data-toggle": "tooltip"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "photo-btn-group",
                              attrs: {
                                id: "photo_" + i + "_btns",
                                role: "group",
                                "aria-label":
                                  "Button group with nested dropdown"
                              }
                            },
                            [
                              !photo.featured
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary mb-2",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.updatePhoto(
                                            "featured",
                                            photo
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Set Featured")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !photo.cover
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.updatePhoto("cover", photo)
                                        }
                                      }
                                    },
                                    [_vm._v("Set cover")]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: {
                                    id: _vm.location.id,
                                    permits: ["LOCATION_PHOTOS_UPDATE"]
                                  },
                                  expression:
                                    "{id: location.id, permits: ['LOCATION_PHOTOS_UPDATE']}",
                                  arg: "allow"
                                }
                              ],
                              staticClass: "remove text-danger",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removePhoto(photo)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-times" })]
                          )
                        ]
                      )
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("form", [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Featured Photo Width")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.fp_width,
                    expression: "form.fp_width"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.form.fp_width },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "fp_width", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.fp_width
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.errors.fp_width[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Featured Photo Height")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.fp_height,
                    expression: "form.fp_height"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.form.fp_height },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "fp_height", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.fp_height
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.errors.fp_height[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  class: { disabled: _vm.processing },
                  attrs: { disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
                      ])
                    : _vm._e(),
                  _vm._v("\n          Save\n        ")
                ]
              )
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-between margin-bottom-2x"
      },
      [
        _c("h4", { staticClass: "mb-2" }, [_vm._v("Photos")]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("span", { staticClass: "cover-style margin-right-1x" }),
          _c("span", { staticClass: "margin-right-3x" }, [_vm._v("cover")]),
          _vm._v(" "),
          _c("span", { staticClass: "featured-style margin-right-1x" }),
          _c("span", [_vm._v("featured")])
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-131cdba6", { render: render, staticRenderFns: staticRenderFns })
  }
}