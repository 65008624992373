import toastr from 'toastr'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const methods = {
  submit () {
    this.processing = true;
    this.$store.dispatch('locations.updateAmenities', {
      id: this.$route.params.id,
      data: {amenities: this.location_amenities}
    })
    .then(() => {
      if (this.$store.state.locations.errors) {
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success("Data has been saved");
      }

      this.processing = false;
    });
  }
}


export default {
  name: "location-amenities",
  props: ['location', 'amenities'],
  methods,
  directives,
  data () {
    return {
      processing: false,
      location_amenities: [],
      location_id: this.$route.params.id
    }
  },
  created () {
    this.location_amenities = this.location.amenities.map(item => item.id);
  }
}
