import toastr from 'toastr'
import layout from 'layouts/default'
import confirm from 'components/modals/confirm'
import { tabs, tab } from 'components/tabs'
import env from 'constants/env' 

const components = {
  layout,
  tabs,
  tab,
  confirm
}

export default {
  name: 'edit-reservation',
  components,
  data () {
    return {
      tripTeoEditLink: '',
      errors: {},
      loading: false,
    }
  },
  created () {
    let reservation_id = this.$route.params.id;
    this.$store.dispatch('unifiedReservations.get', reservation_id).then(() => {
      let errors;
      if (errors = this.$store.state.unifiedReservations.errors) {
        if (errors.message) {
          toastr.error(errors.message);
        } else {
          toastr.error(errors.error);
        }
      } else {
        let tripteoUrl = env.default.tripteoUrl;
        let token = this.$store.state.unifiedReservations.active.token;
        this.tripTeoEditLink = `${tripteoUrl}/reservations/edit-frame/${reservation_id}?token=${token}`;
        console.log('tripteoUrl ',this.tripTeoEditLink)
      }
    });
  },

  mounted () {
    var _self = this;
    window.addEventListener('message', function(e) {

      let iframe = document.querySelector("#iframeId");
      // message that was passed from iframe page
      let message = e.data;
      if (message.height) {
        iframe.style.height = message.height + 'px';
      }

      if (message.name && message.name == 'error') {
        toastr.error(message.errorMessage);
      }

      if (message.name && message.name == 'popup') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }

      if (message.name && message.name == 'updated') {
        _self.$router.go();
      }
    } , false);
  }
}
