import toastr from 'toastr'
import moment from 'moment'
import datepicker from 'components/datepicker'
import { funcModal } from 'components/modals'

const components = {
  funcModal,
  datepicker
}

const computed = {
  types () {
    return this.$store.state.locations.active.types;
  }
}

const methods = {
  open () {
    this.$refs.modal.open();
  },

  selectAll () {
    this.types.forEach((item) => {
      this.form.types.push(item.id);
    });
  },

  selectNone () {
    this.form.types = [];
  },

  otherDaysSelected (day) {
    const days = [
      'saturday', 'sunday', 'monday', 
      'tuesday', 'wednesday', 'thursday', 'friday'
    ];

    for (const i in days) {
      if (this.form[days[i]] && day != days[i]) {
        return true
      }
    }

    return false
  },

  submit () {
    this.processing = true;

    let data = _.clone(this.form);
    if (data.from_date) {
      data.from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    if (data.to_date) {
      data.to_date = moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    this.$store.dispatch('locations.createRateAdjustment', {
      location_id: this.$route.params.id,
      data
    }).then(() => {
      this.processing = false;

      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        this.$refs.modal.close();
        toastr.success('Data has been saved');
      }
    });
  }
}

export default {
  name: 'rate-adjusments-modal',
  props: ['onClose'],
  computed,
  methods,
  components,
  data () {
    return {
      errors: {},
      processing: false,
      form: {
        types: [],
        from_date: null,
        to_date: null,
        modification_type: 'increase',
        modification_amount_type: 'fixed',
        modification_amount: null,
        saturday: 1,
        sunday: 1,
        monday: 1,
        tuesday: 1,
        wednesday: 1,
        thursday: 1,
        friday: 1,
      }
    }
  }
}
