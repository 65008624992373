import moment from 'moment'
import env from 'constants/env'
import datatable from 'components/datatable'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.show_table = true;
        this.$nextTick(() => {
            this.$refs.table.run();
        })
    }
}

let computed = {
    user () {
        return this.$store.state.user.data;
    }
}

export default {
    name: "blackout-report",
    methods,
    components,
    computed,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/blackouts',
        columns: []
    }),
    mounted () {
        this.columns = [
            {label: "Location Name", attribute: 'location_name',sortable: true, sort_key: "location_name"},
            {label: "User Name", attribute: 'user.name',sortable: true, sort_key: "user_name"},
            {label: "Creation Date", attribute: (data) => (
                moment(data.created_at, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "created_at"},
            {label: "Start Date", attribute: (data) => (
                moment(data.from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "from_date"},
            {label: "To Date", attribute: (data) => (
                data.to_date ? moment(data.to_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY') : "-"
            ),sortable: true, sort_key: "to_date"},
            {label: "Number of Spots", attribute: 'number_of_spots',sortable: true, sort_key: "number_of_spots"},
        ]
    }
}