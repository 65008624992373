import layout from 'layouts/default'
import listView from './list'
import calendarView from './calendar'
import newRate from './newRate'
import rateAdjustments from './rateAdjustments'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  listView,
  calendarView,
  newRate,
  rateAdjustments
}

const methods = {
  showNewRateModal () {
    this.shouldShowNewRateModal = true;
    this.$nextTick(() => {
      this.$refs.newRateModal.open();
    });
  },

  onCloseNewRateModal () {
    this.shouldShowNewRateModal = false;
    if (this.view == 'calendar') {
      this.$refs.calendar.loadRates();
    } else {
      this.$refs.list.loadRates();
    }
    console.log('closed');
  },

  showRateAdjustmentModal () {
    this.shouldShowRateAdjustmentModal = true;
    this.$nextTick(() => {
      this.$refs.newRateAdjustmentModal.open();
    });
  },

  onCloseRateAdjustmentModal () {
    this.shouldShowRateAdjustmentModal = false;
    if (this.view == 'calendar') {
      this.$refs.calendar.loadRates();
    } else {
      this.$refs.list.loadRates();
    }
    console.log('closed');
  }
}

export default {
  name: 'location-type-rates',
  components,
  methods,
  directives,
  data () {
    return {
      loading: true,
      location: null,
      types: null,
      view: 'list', //list, calendar
      shouldShowNewRateModal: false,
      shouldShowRateAdjustmentModal: false
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.getLocationTypes', {location_id: this.$route.params.id}),
    ]).then(() => {
      this.loading = false;
      this.location = this.$store.state.locations.active;
      this.types = this.$store.state.locations.active.types;
    });
  }
}
