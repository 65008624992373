import toastr from 'toastr'
import confirm from 'components/modals/confirm'
import fileUpload from 'components/fileUpload'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  fileUpload,
  confirm
}

const methods = {
  imagesToUpload (files) {
    if (files.length) {

      let maxFileSize = 2 * 1024 * 1024; // 2MB
      if (files[0].size >= maxFileSize) {
        toastr.error("Maximum file size (2MB) has been exceeded!!")
        this.$refs.file_upload.removeFiles()
        return;
      }

      this.images = files;
    } else {
      this.images = [];
    }
  },

  uploadImages () {
    this.errors = {};
    this.files_processing = true;
    this.$store.dispatch('locations.uploadImages', {
      id: this.$route.params.id,
      files: this.images
    }).then(() => {
      this.files_processing = false;

      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('Data has been saved');
        this.$refs.file_upload.removeFiles();
        $('[data-toggle="tooltip"]').tooltip();
      }
    });
  },

  submit () {
    this.processing = true;
    this.$store.dispatch('locations.edit', {id: this.$route.params.id, data: this.form}).then(() => {
      this.processing = false;
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  },

  removePhoto (image) {
    this.$refs.confirmDelete.confirm('Are you sure you want to remove this photo?', () => {
      this.$store.dispatch('locations.deleteImage', image.id);
    })
  },

  hideBtns(i) {
    $(`#photo_${i}_btns`).css('display', 'none');
  },
  
  showBtns(i) {
    $(`#photo_${i}_btns`).css('display', 'flex');
  },

  updatePhoto (update, image) {

    this.errors = {}
    this.processing = true;
    this.$store.dispatch(update == 'featured' ? 'locations.setFeaturedImage' : 'locations.setCoverImage', image.id).then(() => {
      this.processing = false;
      if (this.$store.state.locations.errors) {
        this.errors.images = [this.$store.state.locations.errors.message];
        // toastr.error(this.errors.images[0]);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  },
}

export default {
  name: 'location-photos',
  props: ['location', 'photos'],
  components,
  directives,
  methods,
  data () {
    return {
      errors: {},
      processing: false,
      files_processing:false,
      form: this.location,
    }
  }
}
