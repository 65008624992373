import layout from 'layouts/default'
import pagination from 'components/pagination'
import confirm from 'components/modals/confirm'
import { hasPermits } from 'directives'
import { dateFormat } from 'filters'
import datepicker from 'components/datepicker'
import moment from 'moment'
import autocomplete from 'components/autocomplete'
import env from 'constants/env'

const filters = {
  dateFormat
}

const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  datepicker,
  confirm,
  autocomplete,
}

const computed = {
  coupons () {
    return _.cloneDeep(this.$store.state.coupons.all);
  }
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    let data = {
      page,
      _q: this.filter.code,
      _sort: '-id'
    };
    data = _.assign(data, this.filter);
    data.created_at = data.created_at ? moment(data.created_at, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null;
    data.applied_date = data.applied_date ? moment(data.applied_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null;
    data.from_date = data.from_date ? moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null;
    data.to_date = data.to_date ? moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null;

    this.loading = true;

    this.$store.dispatch('coupons.getAllUsageCoupons', data).then(() => {
      this.loading = false;
    });
  },

  onSelectLocation ({item}) {
    let location_name = item;
    if (item) {
      location_name = item.name;
    }    
    this.filter.location_name = location_name
  },

  onSelectAirport ({item}) {
    let airport_name = item;
    if (item) {
        airport_name = item.name;
    }    
    this.filter.airport_name = airport_name;
  },
  
}

export default {
  name: 'usage-coupons',
  components,
  computed,
  methods,
  directives,
  filters,
  data () {
    return {
      loading: false,
      env: env.default,
      filter: {
        code: null,
        group: null,
        reservation_id: null,
        email: null,
        location_name: null,
        airport_name: null,
        used: 'all',
        from_date: null,
        to_date: null,
        created_at: null,
        applied_date: null
      }
    }
  },
  created () {
    this.loadPage(this.$route.query.page || 1);
  }
}
