import layout from 'layouts/default'
import pagination from 'components/pagination'
import { dateFormat } from 'filters'
import datepicker from 'components/datepicker'
import moment from 'moment'
import env from 'constants/env'
import { exportReport } from 'components/modals'

const filters = {
  dateFormat
}

const components = {
  layout,
  pagination,
  datepicker,
  exportReport,
}

const computed = {
  referrals () {
    return _.cloneDeep(this.$store.state.referrals.all);
  }
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    let data = {
      page,
    };
    data = _.assign(data, this.filter);
    data = this.formatDateToSend(data);

    this.loading = true;

    this.$store.dispatch('referrals.getReferrals', data).then(() => {
      this.loading = false;
    });
  },

  exportReport (ext) {
    let filters = _.cloneDeep(this.filter);
    let url = env.default.api + 'referrals' + '?ext=' + ext;
    let params = '';
    filters = this.formatDateToSend(filters);

    _.each(filters, function (value, key) {
        if (value)
          params += '&' + (key + '=' + value);
    });

    url += params;

    this.$refs.export.open(url, ext);
  },

  formatDate (date) {
    return date ? moment(date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null;
  },

  formatDateToSend (data) {
    data.invitation_from_date = this.formatDate(data.invitation_from_date);
    data.invitation_to_date = this.formatDate(data.invitation_to_date);
    data.used_from_date = this.formatDate(data.used_from_date);
    data.used_to_date = this.formatDate(data.used_to_date);
    data.credits_from_date = this.formatDate(data.credits_from_date);
    data.credits_to_date = this.formatDate(data.credits_to_date);
    return data;
  },
}

const watch = {
  'filter.invitation_from_date' (n, o) {    
    if (n == o || n == null) return;
    if (n > this.filter.invitation_to_date && this.filter.invitation_to_date != '') {
      this.filter.invitation_to_date = n;
    }
  },

  'filter.used_from_date' (n, o) {    
    if (n == o || n == null) return;
    if (n > this.filter.used_to_date && this.filter.used_to_date != '') {
      this.filter.used_to_date = n;
    }
  },

  'filter.credits_from_date' (n, o) {    
    if (n == o || n == null) return;
    if (n > this.filter.credits_to_date && this.filter.credits_to_date != '') {
      this.filter.credits_to_date = n;
    }
  },
}

export default {
  name: 'referrals',
  components,
  computed,
  methods,
  filters,
  watch,
  data () {
    return {
      loading: false,
      env: env.default,
      processing: false,
      filter: {
        code: null,
        ip: null,
        reservation_id: null,
        customer_email: null,
        referral_email: null,
        used: 'all',
        invitation_from_date: null,
        invitation_to_date: null,
        used_from_date: null,
        used_to_date: null,
        credits_from_date: null,
        credits_to_date: null
      }
    }
  },
  created () {
    this.loadPage(this.$route.query.page || 1);
  }
}
