var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("h3", { staticClass: "mb-2" }, [_vm._v("New Location")]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c("div", { staticClass: "card mb-4" }, [
          _c("div", { staticClass: "card-block" }, [
            _c("form", {}, [
              _c("div", { staticClass: "text-right" }, [
                _vm.submit_loading
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { disabled: _vm.submit_loading, type: "button" }
                      },
                      [
                        _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                        _vm._v("\n            SAVEING...\n          ")
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onSubmitForm($event)
                          }
                        }
                      },
                      [_vm._v("\n            SAVE\n          ")]
                    )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "col-sm-8 margin-top-3x" }, [
                  _c("div", { staticClass: "form-group " }, [
                    _c("label", [_vm._v("Location type")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio" }, [
                      _c("label", { staticClass: "mr-2" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.location_type,
                              expression: "formData.location_type"
                            }
                          ],
                          attrs: { type: "radio", value: "osp" },
                          domProps: {
                            checked: _vm._q(_vm.formData.location_type, "osp")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.formData,
                                "location_type",
                                "osp"
                              )
                            }
                          }
                        }),
                        _vm._v(
                          "\n                  OSP location\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.location_type,
                              expression: "formData.location_type"
                            }
                          ],
                          attrs: { type: "radio", value: "reslab" },
                          domProps: {
                            checked: _vm._q(
                              _vm.formData.location_type,
                              "reslab"
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.formData,
                                "location_type",
                                "reslab"
                              )
                            }
                          }
                        }),
                        _vm._v(
                          "\n                  Integrated location(From ReservationsLab)\n                "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.errors.location_type
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.location_type[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm.formData.location_type == "osp"
                ? _c("div", { staticClass: "row justify-content-center" }, [
                    _c("div", { staticClass: "col-sm-8 margin-top-3x" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "locationName" } }, [
                          _vm._v("Location Name")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.formData.name,
                              expression: "formData.name",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "locationName",
                            placeholder: "Location Name"
                          },
                          domProps: { value: _vm.formData.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "name",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.name
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.name[0]) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Port")]),
                          _vm._v(" "),
                          _c("s-select", {
                            attrs: {
                              options: _vm.ports,
                              selected: _vm.selectedAirport
                            },
                            on: { selectedChanged: _vm.onChangeAirport }
                          }),
                          _vm._v(" "),
                          _vm.errors.airport_id
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.errors.airport_id[0]) +
                                    "\n              "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "locationPhone" } }, [
                          _vm._v("Phone Number")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.formData.phone,
                              expression: "formData.phone",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "locationPhone",
                            placeholder: "Location Phone Number"
                          },
                          domProps: { value: _vm.formData.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "phone",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.phone
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.phone[0]) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Currency")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.currency_id,
                                expression: "formData.currency_id"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { placeholder: "Currency" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.formData,
                                  "currency_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.currencies, function(curr) {
                            return _c(
                              "option",
                              { domProps: { value: curr.id } },
                              [_vm._v(_vm._s(curr.name))]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.errors.currency_id
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.currency_id[0]) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Country")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.country_id,
                                expression: "formData.country_id"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { placeholder: "Country" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.formData,
                                  "country_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.countries, function(country) {
                            return _c(
                              "option",
                              { domProps: { value: country.id } },
                              [_vm._v(_vm._s(country.name))]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.errors.country_id
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.country_id[0]) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("State")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.state_id,
                                expression: "formData.state_id"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { placeholder: "Country" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.formData,
                                  "state_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.states, function(state) {
                            return _c(
                              "option",
                              { domProps: { value: state.id } },
                              [_vm._v(_vm._s(state.name))]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.errors.state_id
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.state_id[0]) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "timezone" } }, [
                          _vm._v("Timezone")
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.timezone_id,
                                expression: "formData.timezone_id"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { id: "timezone" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.formData,
                                  "timezone_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.timezones, function(timezone) {
                            return _c(
                              "option",
                              { domProps: { value: timezone.id } },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(timezone.name) +
                                    "\n                "
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.errors.timezone_id
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.timezone_id[0]) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("City")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.formData.city,
                              expression: "formData.city",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeholder: "City name" },
                          domProps: { value: _vm.formData.city },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "city",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.city
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.city[0]) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "locationAddress" } }, [
                          _vm._v("Address")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.formData.address,
                              expression: "formData.address",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "locationAddress",
                            placeholder: "Address"
                          },
                          domProps: { value: _vm.formData.address },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "address",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.address
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.address[0]) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "locationZipCode" } }, [
                          _vm._v("Zip/Postal Code")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.formData.zipcode,
                              expression: "formData.zipcode",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "locationZipCode",
                            placeholder: "Zip/Postal Code"
                          },
                          domProps: { value: _vm.formData.zipcode },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "zipcode",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.zipcode
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.zipcode[0]) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.formData.location_type == "reslab"
                ? _c("div", { staticClass: "row justify-content-center" }, [
                    _c("div", { staticClass: "col-sm-8 margin-top-3x" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("ReservationsLab ID")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.formData.reslab_id,
                              expression: "formData.reslab_id",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.formData.reslab_id },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "reslab_id",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.reslab_id
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.errors.reslab_id[0]) +
                                  "\n              "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Port")]),
                          _vm._v(" "),
                          _c("s-select", {
                            attrs: {
                              options: _vm.ports,
                              selected: _vm.selectedAirport
                            },
                            on: { selectedChanged: _vm.onChangeAirport }
                          }),
                          _vm._v(" "),
                          _vm.errors.airport_id
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.errors.airport_id[0]) +
                                    "\n              "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "text-right" }, [
                _vm.submit_loading
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { disabled: _vm.submit_loading, type: "button" }
                      },
                      [
                        _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                        _vm._v("\n            SAVEING...\n          ")
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onSubmitForm($event)
                          }
                        }
                      },
                      [_vm._v("\n            SAVE\n          ")]
                    )
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-41649494", { render: render, staticRenderFns: staticRenderFns })
  }
}