var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown s-select" }, [
    _c(
      "div",
      {
        staticClass: "toggler",
        attrs: {
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_vm._v("\n    " + _vm._s(_vm.value.text) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        ref: "menu",
        staticClass: "dropdown-menu",
        attrs: { "aria-labelledby": "dLabel" }
      },
      [
        _c("li", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filter_value,
                expression: "filter_value"
              }
            ],
            staticClass: "form-control margin-bottom-2x",
            attrs: { type: "text", placeholder: "start typing to filter" },
            domProps: { value: _vm.filter_value },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.filter_value = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _vm._l(_vm.filterredOptions, function(option) {
          return _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.selectOption(option)
                }
              }
            },
            [
              _vm.multiple
                ? _c("span", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedOpts,
                          expression: "selectedOpts"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: option,
                        checked: Array.isArray(_vm.selectedOpts)
                          ? _vm._i(_vm.selectedOpts, option) > -1
                          : _vm.selectedOpts
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.selectedOpts,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = option,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.selectedOpts = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectedOpts = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectedOpts = $$c
                          }
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v("\n      " + _vm._s(option.text) + "\n    ")
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-be7c981e", { render: render, staticRenderFns: staticRenderFns })
  }
}