import { http } from 'services'
// initial state
const state = () => ({
    errors: null,
    active: {},
    all: {},
})

// getters
const getters = {
}

// actions
const actions = {
    ['reviews.getAll']({ commit }, params) {
        if (!params._q) params._q = null

        commit('CLEAR_ERRORS')

        return (http.get('reviews', {
            params
        })).then((res) => {
            commit('GET_ALL_REVIEWS', res);
        })
        .catch((res) => {
            commit('REVIEWS_ERROR', res);
        })
    },

    ['reviews.get'] ({ commit }, id) {
        commit('CLEAR_ERRORS')
        return (http.get('reviews/' + id)).then((res) => {
            commit('GET_REVIEW', res);
        })
        .catch((res) => {
            commit('REVIEWS_ERROR', res);
        })
    },

    ['reviews.remove'] ({ commit }, id) {
        commit('CLEAR_ERRORS')
    
        return (http.delete('reviews/' + id))
        .then ((res) => {
            commit('REMOVE_REVIEW', id);
        })
        .catch ((res) => {
            commit('REVIEWS_ERROR', res);
        })
    },

    ['reviews.publish'] ({ commit }, {id, data}) {
        commit('CLEAR_ERRORS')
    
        return (http.put('reviews/' + id, data))
        .then ((res) => {
            commit('PUBLISH_REVIEWS', res);
        })
        .catch ((res) => {
            commit('REVIEWS_ERROR', res);
        })
    },

    //sync Trust Pilot Reviews
    ['reviews.syncTrustPilot'] ({dispatch, commit}, params = {}) {
        commit('CLEAR_ERRORS');

        return (http.post(`reviews/sync-trust-pilot-reviews`)).then (() => {
        }).catch ((res) => {
            commit('REVIEWS_ERROR', res);
        })
    },
}

// mutations
const mutations = {
    GET_ALL_REVIEWS (state, res) {
        state.all = res.data;
    },

    GET_REVIEW (state, res) {
        state.active = res.data;
    },

    REMOVE_REVIEW (state, id) {
        state.all.data = _.filter(state.all.data, (item) => item.id != id);
    },

    PUBLISH_REVIEWS (state, res) {
        state.active.published = res.data.published;
        state.active = _.cloneDeep(state.active);

        let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);
        if (indx > -1) {
            state.all.data[indx] = _.assign(state.all.data[indx], res.data);
            state.all = _.cloneDeep(state.all);
        }
    },

    //ERRORS
    REVIEWS_ERROR(state, err) {
        try {
            state.errors = err.response.data;
        } catch (e) {
            state.errors = err;
        }
    },

    CLEAR_ERRORS(state) {
        state.errors = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}