var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Validate Reservations" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  class: { disabled: _vm.processing },
                  attrs: { disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit(props)
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spin fa-spinner" }),
                        _vm._v("\n        Sending...\n      ")
                      ])
                    : _c("span", [_vm._v("Validate Reservation")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.reservation_id,
                expression: "reservation_id"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", placeholder: "Reservation Number" },
            domProps: { value: _vm.reservation_id },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.reservation_id = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.id
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.errors.id[0]))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("autocomplete", {
              attrs: {
                placeholder: "Location Name",
                "input-class": "form-control",
                url: _vm.api_url + "locations?_all=true&_q=",
                label: "name"
              },
              on: { select: _vm.onSelectLocation }
            }),
            _vm._v(" "),
            _vm.errors.token
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.token[0]))
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.reservation
          ? _c("div", [
              _c("table", { staticClass: "table" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("From Date")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.reservation.history[0].from_date,
                            "MM/DD/YYYY hh:mm A"
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("To Date")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.reservation.history[0].to_date,
                            "MM/DD/YYYY hh:mm A"
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Location Name")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm.reservation.history[0].location.name))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Airport Name")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.reservation.history[0].location.port.name)
                      )
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5a0414df", { render: render, staticRenderFns: staticRenderFns })
  }
}