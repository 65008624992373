import _ from "lodash"

const methods = {
  initMap () {
    this.$store.dispatch('googleMaps.loadGoogleMapsAPI').then(() => {

      this.map = new google.maps.Map(this.$refs.map, {
        zoom: 10,
        scrollwheel: false,
        center: this.center || {lat: 40.7127837, lng: -74.00594130000002}
      });

      if (this.defaultMarker) {
        this.placeMarker(this.defaultMarker);
      }

      google.maps.event.addListener(this.map, 'click', (function(event) {
        this.placeMarker(event.latLng);
        this.$emit('locationChanged', {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        });
      }).bind(this));
    });
  },
  placeMarker(location) {
    console.log('this.marker', this.marker);
    console.log('location', location);
    if ( this.marker ) {
      this.marker.setPosition(location);
    } else {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map
      });
    }
  }
}

const computed = {
  // marker () {
  //   console.log("this.defaultMarker changed", this.defaultMarker);
  //   return _.cloneDeep(this.defaultMarker);
  // }
}

const watch = {
  defaultMarker (n) {
    console.log("defaultMarker has changed", n);
    this.placeMarker(n);
  },
  center (n) {
    console.log("center of map has changed", n);
    this.map.setCenter(n);
    this.map.setZoom(10);
  }
}

export default {
  name: 'google-maps-movable-marker',
  template: require('./googleMapsMovableMarker.html'),
  props: ['defaultMarker', 'center'],
  methods,
  computed,
  watch,
  data () {
    return {
      marker: null,
      map: null
    }
  },
  mounted () {
    this.initMap();
  }
}
