import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
}

const computed = {
}

const methods = {
  onSubmitForm () {
    this.errors = {}
    this.processing = true;
    let data = _.cloneDeep(this.form);
    this.$store.dispatch('automatedCoupons.update', {id: this.$route.params.id, data: data}).then(() => {
      this.processing = false;
      if (this.$store.state.automatedCoupons.errors) {
        this.errors = _.cloneDeep(this.$store.state.automatedCoupons.errors.errors);
        toastr.error(this.$store.state.automatedCoupons.errors.message);
      } else {
        toastr.success('Automated Coupon has been created');
      }
    })
  },

}

const watch = {
}

export default {
  name: 'automated-coupons',
  components,
  computed,
  methods,
  directives,
  watch,
  data () {
    return {
      loading: true,
      processing: false,
      errors: {},
      form: {
        name: 'WELCOME',
        start_date: 0,
        end_date: 30,
        one_time_use: true,
        amount_type: 'fixed',
        amount: null,
        discount_limit: null,
      },
    }
  },
  created () {
    this.$store.dispatch('automatedCoupons.get', 1).then(() => {
      this.form = Object.assign(this.form, this.$store.state.automatedCoupons.active);
      this.loading = false;
  });
  }
}
