import layout from 'layouts/default'
import pagination from 'components/pagination'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'

let directives = {
    hasPermits
}

let filters = {
}

let components = {
    layout,
    confirm,
    pagination
}

let computed = {
    messages () {
        return this.$store.state.messages.all;
    }
}

let methods = {
    search (searchKey) {
        this.searchKey = searchKey;
        this.loadPage(1);
    },

    loadPage (page = 1) {
        this.loading = true
        this.$store.dispatch('messages.getAll', {
            page, _q: this.searchKey, _sort: '-id'
        }).then(() => {
            this.loading = false;
        });
    }
}

export default {
    name: "list-messages",
    components,
    methods,
    computed,
    filters,
    directives,
    data () {
        return {
            loading: false,
            searchKey: null,
            messages_loadings: {}
        }
    },
    created () {
        this.loadPage();
    }
}