var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Send To Fax" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { disabled: _vm.processing, type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.send($event)
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
                    : _vm._e(),
                  _vm._v("\n          Send\n      ")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-link",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Fax Number")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.fax,
                expression: "form.fax"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.form.fax },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "fax", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.fax
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.errors.fax[0]) + "\n          "
                )
              ])
            : _vm._e()
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cac155c2", { render: render, staticRenderFns: staticRenderFns })
  }
}