var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c("div", [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("h3", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(_vm.location.name))
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["location_create"],
                    expression: "['location_create']",
                    arg: "allow"
                  }
                ],
                staticClass: "mb-3"
              },
              [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-default",
                        attrs: { to: { name: "locations.create" } }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-plus",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v("\n            New Location\n          ")
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "validate-reservation margin-bottom-6x" },
            [_c("validate-reservation")],
            1
          ),
          _vm._v(" "),
          _vm.location
            ? _c(
                "div",
                { staticClass: "checkin-checkout-list margin-bottom-6x" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.location.id,
                              permits: ["LOCATION_ARRIVALS_LIST"]
                            },
                            expression:
                              "{id: location.id, permits: ['LOCATION_ARRIVALS_LIST']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "col-md-6"
                      },
                      [
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            { staticClass: "box-body" },
                            [
                              _c("checkin", {
                                attrs: { location: _vm.location }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.location.id,
                              permits: ["LOCATION_DEPARTURES_LIST"]
                            },
                            expression:
                              "{id: location.id, permits: ['LOCATION_DEPARTURES_LIST']}",
                            arg: "allow"
                          }
                        ],
                        staticClass: "col-md-6"
                      },
                      [
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            { staticClass: "box-body" },
                            [
                              _c("checkout", {
                                attrs: { location: _vm.location }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ])
                ]
              )
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0faa32b9", { render: render, staticRenderFns: staticRenderFns })
  }
}