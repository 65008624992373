import reservationsList from 'layouts/partials/reservationsList'

const components = {
    reservationsList
}

const methods = {
    loadPage (page = this.page) {
        this.loading = true;
        this.$store.dispatch('customers.getCustomerReservations', {
            id: this.$route.params.id, page
        }).then(() => {
            this.page = page
            this.reservations = this.$store.state.customers.active.reservations;
            this.loading = false;
        });
    }
}

let watch = {
    activeTab (n) {
        console.log(n);
        
        if (n && n.title == "Reservations") {
            this.$router.replace({ query: Object.assign({}, this.$route.query, { page: this.page }) })
        }
    }
} 

export default {
    name: 'customer-reservations',
    props: ['activeTab'],
    watch,
    components,
    methods,
    data () {
        return {
            loading: false,
            reservations: {},
            page: 1
        }
    },
    created () {
        this.loadPage();
    }
}