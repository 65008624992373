import toastr from 'toastr'
import { funcModal } from 'components/modals'

const components = {
    funcModal
}

const methods = {
    open (id, email) {
        this.form.id = id;
        this.form.reservation_email = email;
        this.form.email = email;
        this.$refs.modal.open()
    }, 

    send () {
        this.errors = {};
        this.processing = true;
        this.$store.dispatch('unifiedReservations.sendToEmail', this.form).then(() => {
            if (this.$store.state.unifiedReservations.errors) {
                this.errors = _.cloneDeep(this.$store.state.unifiedReservations.errors.errors);
                toastr.error(this.$store.state.unifiedReservations.errors.message);
            } else {
                toastr.success('Reservation details have been sent');
                this.$refs.modal.close();
            }
            this.processing = false;
        });
    }
}

export default {
    name: "unified-send-reservation-to-email",
    components,
    methods,
    data () {
        return {
            form: {
                reservation_email: null,
                email: null,
                id: null
            },
            errors: {},
            processing: false
        }
    }
}