var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-sidebar": true } },
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Messages")]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "card mb-4" }, [
            _c(
              "div",
              { staticClass: "card-block" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-4 margin-bottom-3x" }, [
                    _c("div", { staticClass: "input-group margin-bottom-1x" }, [
                      _c("input", {
                        ref: "search",
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "Search for..." },
                        domProps: { value: _vm.searchKey },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              $event.keyCode !== 13
                            ) {
                              return null
                            }
                            return _vm.search(_vm.$refs.search.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "input-group-append" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.search(_vm.$refs.search.value)
                              }
                            }
                          },
                          [_vm._v("Go!")]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table table-striped" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Message Title")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Email")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Name")]),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: { permits: ["message_show"] },
                                expression: "{permits: ['message_show']}",
                                arg: "allow"
                              }
                            ]
                          },
                          [_vm._v("Show")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.messages.data, function(item) {
                        return _c("tr", { key: item.id }, [
                          _c("td", [_vm._v(_vm._s(item.title))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.email))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.name))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: { permits: ["message_show"] },
                                  expression: "{permits: ['message_show']}",
                                  arg: "allow"
                                }
                              ]
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "messages.show",
                                      params: { id: item.id }
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-eye",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("pagination", {
                  attrs: { "pages-number": _vm.messages.last_page },
                  on: {
                    pageChanged: function(page) {
                      return _vm.loadPage(page)
                    }
                  }
                })
              ],
              1
            )
          ]),
      _vm._v(" "),
      _c("confirm", { ref: "deleteConfirm", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9dbf2820", { render: render, staticRenderFns: staticRenderFns })
  }
}