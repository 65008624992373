import layout from 'layouts/default'
import confirm from 'components/modals/confirm'
import newUser from './newUserModal'
import editEmployee from './editUserModal'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  newUser,
  editEmployee,
  confirm,
}

const computed = {
  affiliate () {
    return _.cloneDeep(this.$store.state.affiliates.active);
  }
}

const methods = {
  openEditModal(emp) {
    this.selectedUser = emp;
    this.shouldShowEditModal = true;
    this.$nextTick(() => {
      this.$refs.editModal.open();
    })
  },

  onCloseEditModal () {
    this.shouldShowEditModal = false;
  },

  openNewUserModal () {
    this.$refs.newModal.open();
  },
  removeEmployee (user) {
    this.$refs.removeConfirm.confirm("Are you sure?", () => {
      this.delete_loadings[user.id] = true;
      this.delete_loadings = _.cloneDeep(this.delete_loadings);

      this.$store.dispatch('affiliates.removeUser', {
        id: user.id,
      }).then(() => {
        this.delete_loadings[user.id] = false;
        let errors = this.$store.state.affiliates.errors;
        if (errors) {
          if (errors.status_code == 422) {
            this.errors = _.cloneDeep(errors.errors);
            toastr.error('Check the errors below');
          } else {
            toastr.error(errors.message);
          }
        }
      })
    })
  }
}

export default {
  name: 'users-tab',
  computed,
  directives,
  methods,
  components,
  data () {
    return {
      delete_loadings: {},
      selectedUser: {},
      loading: false,
      shouldShowEditModal: false
    }
  },

  created () {
    this.$store.dispatch('address.getCountries');
    this.loading = true;
    this.$store.dispatch('affiliates.getUsers', this.$route.params.id).then(() => {
      this.loading = false;
    });
  }
}
