import toastr from 'toastr'
import layout from 'layouts/default'
import sSelect from 'components/sSelectM'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  sSelect,
}

const computed = {
  campaign () {
    return this.$store.state.googleAds.active;
  },
  locations () {
   return this.$store.state.locations.all;
  },
  portLocations () {
    let _locations = [];
    if (this.form.airport_id) {

      for (let i = 0; i < this.locations.length; i++) {
        if (this.locations[i].airport_id == this.form.airport_id) {
          _locations = _locations.concat(this.locations[i]);
        }
      }
    }

    return _.map(_.cloneDeep(_locations), item => {
      return {
        value: item.id,
        text: item.name
      }
    });
  },
  ports () {
    return [{name: "Select Port", id: null}].concat(this.$store.state.ports.all);
  }
}

const methods = {
  
  submit () {
    this.errors = {}
    this.processing = true;
    let data = _.cloneDeep(this.form);
    
    this.processing = false;
    let locations = [];
    for (let i = 0; i < this.selectedLocations.length; i++) {
      locations.push(this.selectedLocations[i].value);
    }
    data.locations = locations;

    this.$store.dispatch('googleAds.editCampaign', {id: this.$route.params.id, data}).then(() => {
      this.processing = false;
      if (this.$store.state.googleAds.errors) {
        this.errors = _.cloneDeep(this.$store.state.googleAds.errors.errors);
        toastr.error(this.$store.state.googleAds.errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    })
  },

  onChangePort () {
    this.selectedLocations = [];
  },

  onChangeSelectedLocations (locations) {
    this.selectedLocations = locations;
  },

  removeSelected (location) {
    this.selectedLocations = _.filter(this.selectedLocations, x => x.value != location.value);
  },
}

export default {
  name: "edit-campaign",
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: true,
      form: {},
      errors: {},
      processing: false,
      selectedLocations: [],
    }
  },
  created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('googleAds.getCampaign', this.$route.params.id),
      this.$store.dispatch('ports.getAll', {_all: true}),
      this.$store.dispatch('locations.getAll', {_all: true}),
    ]).then(() => {
      this.form = Object.assign({}, this.campaign);

      //this.selectedLocations = this.form.locations;
      for (let i = 0; i < this.form.locations.length; i++) {
        for (let j = 0; j < this.locations.length; j++) {
          if (this.form.locations[i]== this.locations[j].id) {
            this.selectedLocations.push({
              value: this.locations[j].id,
              text: this.locations[j].name
            });
          }
        }
        
      }

      this.loading = false;
    });
  }
}
