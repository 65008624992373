import ListLocations from 'pages/locations/list'
import CreateLocation from 'pages/locations/create'
import GuestManagement from 'pages/locations/guestManagement'
import LocationInformation from 'pages/locations/information'
import LocationTypes from 'pages/locations/types'
import Blackouts from 'pages/locations/blackouts'
import LocationRates from 'pages/locations/rates'
import BookingConditions from 'pages/locations/bookingConditions'
import AmenitiesAndPhotos from 'pages/locations/amenitiesAndPhotos'

export default [
  { path: '/locations/create', name: 'locations.create', component: CreateLocation, meta: {permits: ['LOCATION_CREATE']} },
  { path: '/locations/:id/guest-management', name: 'locations.guestManagement', component: GuestManagement, meta: {permits: ['location_user', 'LOCATION_SHOW']} },
  { path: '/locations/:id/information', name: 'locations.information', component: LocationInformation, meta: {permits: ['location_user', 'LOCATION_SHOW', 'LOCATION_GENERAL_INFO_LIST']} },
  { path: '/locations/:id/types', name: 'locations.types', component: LocationTypes, meta: {permits: ['location_user', 'LOCATIONTYPE_LIST']} },
  { path: '/locations/:id/blackouts', name: 'locations.blackouts', component: Blackouts, meta: {permits: ['location_user', 'BLACKOUTDATE_LIST']} },
  { path: '/locations/:id/rates', name: 'locations.rates', component: LocationRates, meta: {permits: ['location_user', 'RATE_LIST']} },
  { path: '/locations/:id/booking-conditions', name: 'locations.bookingConditions', component: BookingConditions, meta: {permits: ['location_user', 'LOCATION_SHOW', 'LOCATION_BOOKING_CONDITIONS_LIST']} },
  { path: '/locations/:id/amenities-and-photos', name: 'locations.amenitiesAndPhotos', component: AmenitiesAndPhotos, meta: {permits: ['location_user', 'LOCATION_AMENITIES_AND_PHOTOS']} },
  { path: '/locations', name: 'locations.list', component: ListLocations, meta: {permits: ['location_user', 'LOCATION_LIST']} },
]
