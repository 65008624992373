import toastr from 'toastr'
import layout from 'layouts/default'
import {dateTime} from 'services'
import sSelect from 'components/sSelect'
import placesAutocomplete from 'components/placesAutocomplete'
import googleMapsMovableMarker from 'components/googleMapsMovableMarker'
import { hasPermits } from 'directives'
import color from 'components/color'

const directives = {
  hasPermits
}

const components = {
  layout,
  sSelect,
  placesAutocomplete,
  googleMapsMovableMarker,
  color
}

const computed = {
  location () {
    return this.$store.state.locations.active;
  },
  countries () {
    return [{name: "Select Country", id: null}].concat(this.$store.state.address.countries);
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }
    console.log(states);
    return states;
  },
  timezones () {
    return [{name: "Select Timezone", id: null}].concat(this.$store.state.timezones.all);
  },
  center () {
    if (this.googlePlace) {
      let location = {
        lat: this.googlePlace.geometry.location.lat(),
        lng: this.googlePlace.geometry.location.lng()
      };
      this.form.latitude = this.googlePlace.geometry.location.lat();
      this.form.longitude = this.googlePlace.geometry.location.lng();
      this.googlePlace = null;
      return location;
    } else if (this.form.latitude && this.form.longitude) {
      let location = {
        lat: parseFloat(this.form.latitude),
        lng: parseFloat(this.form.longitude)
      };
      return location;
    }

    return null
  },
}

const methods = {
  onChangeAirport (port) {
    this.selectedAirport = port;
    this.form.airport_id = null;
    if(port) {
      this.form.airport_id = port.value;
    }
  },
  onLocationChanged (location) {
    this.form.latitude = location.lat;
    this.form.longitude = location.lng;
  },
  checkDistance(){
    try {
      let origin = `${this.location.latitude},${this.location.longitude}`;
      let destination = `${this.location.port.latitude},${this.location.port.longitude}`;

      let mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving`;
      console.log('checkDistance url:', mapUrl);
      window.open(mapUrl);
    } catch (error) {
      console.log('checkDistance error:', error);
    }
    
  },
  submit () {
    this.errors = {}
    this.processing = true;
    let data = _.cloneDeep(this.form);
    this.$store.dispatch('locations.edit', {id: this.$route.params.id, data}).then(() => {
      this.processing = false;
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        this.form.old_slug = _.cloneDeep(this.$store.state.locations.active.old_slug)
        toastr.success('Data has been saved');
      }
    })
   },
  onChangeColor(value) {
    this.form.label_color = value;
  }
}

export default {
  name: "location-information",
  components,
  computed,
  methods,
  directives,
  data () {
    let ports = [{text: "-- Select Airport --", value: null}];

    return {
      loading: true,
      form: {},
      errors: {},
      ports,
      selectedAirport: ports[0],
      // timezones: [],
      googlePlace: null,
      processing: false,
      times: dateTime.getHalfHours(),
    }
  },
  created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('ports.getAll', {_all: true}),
      this.$store.dispatch('address.getCountries'),
      this.$store.dispatch('timezones.getAll')
    ]).then(() => {
      this.form = Object.assign({}, this.location);
      this.ports = this.ports.concat(this.$store.state.ports.all.map(item => ({text: item.name + ` (${item.code})`, value: item.id})));

      if (this.form.airport_id) {
        let selectedAirportIndx = _.findIndex(this.ports, p => p.value == this.form.airport_id);
        this.onChangeAirport(this.ports[selectedAirportIndx]);
      }

      this.loading = false;
      // this.timezones = _.cloneDeep(this.$store.state.timezones.all);
    });
  }
}
