import layout from 'layouts/default'
import toastr from 'toastr'
import env from 'constants/env'

const components = {
    layout,
}

const computed = {
  routes () {    
    return this.$store.state.adminApiRequests.all
  }
}

const methods = {
  toggleDescription(index) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  },
  isExpanded(index) {
    return this.expandedIndex === index;
  },
  send(index) {
    let route = this.routes[index];
    this.$store.dispatch('adminApiRequests.send', {url: route.url, type: route.type}).then(() => {
      let errors = this.$store.state.adminApiRequests.errors;
      if(errors) {
        toastr.error(errors);
      } else {
        toastr.success('Request sent successfully');
      }
    })
  },
  sendCustom(type, url) {
    if(type.trim() == "" || url == "") {
      return ;
    }
    this.$store.dispatch('adminApiRequests.send', {url: url, type: type}).then(() => {
      let errors = this.$store.state.adminApiRequests.errors;
      if(errors) {
        toastr.error(errors);
      } else {
        toastr.success('Request sent successfully');
      }
    })
  },
}

export default {
  name: 'api-requests',
  methods,
  computed,
  components,
  data () {
    return {
        api:env.default.api,
        loading: true,
        expandedIndex: null,
        type: '',
        url: '',
        types: ['post', 'get', 'put', 'delete']
    }
  },
  mounted() {
    this.$store.dispatch('adminApiRequests.getAll').then(() => {
      this.loading = false
    })
  }
}
