var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("label", { attrs: { for: "couponCode" } }, [
      _vm._v("Do you have discount code?")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "reservation-coupon d-flex" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.coupon_code,
            expression: "coupon_code"
          }
        ],
        attrs: { id: "couponCode", type: "text" },
        domProps: { value: _vm.coupon_code },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.coupon_code = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          attrs: { id: "applyCouponCode" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.apply($event)
            }
          }
        },
        [_vm._v("\n      Apply\n    ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-79550a3d", { render: render, staticRenderFns: staticRenderFns })
  }
}