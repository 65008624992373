import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import reservationsList from 'layouts/partials/reservationsList'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  datepicker,
  reservationsList
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    let data = {
      page,
      _q: this.search_key,
      search_by: this.search_by,
      _sort: '-id'
    };

    data = _.assign(data, this.filter);
    data.created_at = data.created_at ? moment(data.created_at, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null
    data.from_date = data.from_date ? moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null
    data.to_date = data.to_date ? moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null
    data.capture_before = data.capture_before ? moment(data.capture_before, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null

    this.loading = true;

    return (this.$store.dispatch('reservations.getAll', data)).then (() => {
      this.loading = false;
    });
  },
}

const computed = {
  reservations () {
    return _.cloneDeep(this.$store.state.reservations.all);
  }
}

export default {
  name: 'list-reservations',
  methods,
  components,
  computed,
  directives,
  data () {
    return {
      loading: false,
      search_key: null,
      search_by: null,
      filter: {
        cancelled: null,
        status: null,
        captured: null,
        from_date: null,
        to_date: null,
        created_at: null,
        capture_before: null,
      },
    }
  },
  created () {
    this.loadPage(1);
  }
}
