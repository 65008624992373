import moment from 'moment'
import env from 'constants/env'
import datatable from 'components/datatable'
import { permits, user } from 'services'
import { currencySymbol } from 'filters'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.show_table = true;
        this.$nextTick(() => {
            this.$refs.table.run();
        })
    },

    setCols () {
        console.log('columns set');
        this.columns = [
            {label: "Cancelled", attribute: (data) => data.cancelled ? 
                `<div class="text-danger bold">Cancelled</div>` : 
                `<div class="bold">Active</div>`
            ,sortable: true, sort_key: "cancelled"},
            {label: "Airport", attribute: 'history.0.location.port.name',sortable: true, sort_key: "port_name"},
            {label: "Location", attribute: 'history.0.location.name',sortable: true, sort_key: "location_name"},
            {label: "Start Date", attribute: (data) => (
                moment(data.history[0].from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "from_date"},
            {label: "End Date", attribute: (data) => (
                moment(data.history[0].to_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "to_date"},
            {label: "Reservation #", 
                attribute: 'reservation_number',
                sortable: true, 
                sort_key: "reservation_number",
                classes: ['clickable'],
                click: (data) => {
                    if (permits.hasPermit('reservation_update')) {
                        let route = this.$router.resolve({
                            name: 'reservations.edit',
                            params: {id: data.id}
                        });
                        
                        window.open(route.href, '_blank');
                    }
                }
            },
            {label: "Creation Date", attribute: (data) => {
                let tz = 'UTC';
                if (this.filters.filter_date_on == "created_at" && this.filters.timezone == 'EST') {
                    tz = 'Etc/GMT+4';
                }
                return moment.tz(data.created_at, 'YYYY-MM-DD HH:mm:ss', 'UTC').tz(tz).format('MM/DD/YYYY');
            },sortable: true, sort_key: "created_at"},
            {label: "Customer Name", attribute: "history.0.customer_name",sortable: true, sort_key: "customer_name"},
            {label: "Customer Email", attribute: "history.0.contact_email", visible: this.user.type == 'admin'},
            {label: "Spots", attribute: "history.0.number_of_spots"},
            {label: "Currency", attribute: "currency_code", visible: Array('admin','affiliate').includes(this.user.type) ,sortable: true, sort_key: "currency_code"},
            {label: "Subtotal", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.subtotal, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            ), visible: Array('admin','affiliate').includes(this.user.type)},
            {label: "Fees", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.total_fees, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            ), visible: Array('admin','affiliate').includes(this.user.type)},
            {label: "Location Fees", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.location_fees, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            )},
            {label: "Taxes", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.total_taxes, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            )},
            {label: "OSP Tax", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.osp_tax, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            ), visible: this.user.type == 'admin'},
            {label: "Discount", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.total_discount, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            ), visible: Array('admin','affiliate').includes(this.user.type)},
            {label: "Coupon Name", attribute: 'history.0.coupon_code', visible: this.user.type == 'admin'},
            {label: "Points Spent", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.points_money_value, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            ), visible: this.user.type == 'admin'},
            {label: "Points Refunded", attribute: (data) => (
                this.$options.filters.currency(data.partial_refunds && data.partial_refunds.length ? data.partial_refunds.reduce((sum, item) => {
                    if (!item.reverted) {
                        return sum + (item.refunded_points_money_value || 0)
                    } else {
                        return sum + 0
                    }
                  }, 0) : 0, currencySymbol(data.history[0].convertion_in_usd.currency_code))
                
            ), visible: this.user.type == 'admin'},
            {label: "Credit Used", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.customer_credit_used, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            ), visible: Array('admin','affiliate').includes(this.user.type)},
            {label: "Trip Protection", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.trip_protection_price, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            ), visible: this.user.type == 'admin'},
            {label: "Grand Total", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.grand_total, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            )},
            {label: "Refunded", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.refunded_amount, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            ), visible: this.user.type == 'admin'},
            {label: "Commission %", attribute: (data) => (
                `${data.history[0].commission_calculation_value}${data.history[0].location_commission_type == 'percentage' ? "%" : " FLAT"}`
            ), visible: this.user.type == 'admin'},
            {label: "Commission", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.commission_total, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            )},
            {label: "Location Total Commission", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.location_portion, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            )},
            {label: "Due at Location", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.due_at_location, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            )},
            {label: "OSP Commission", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.osp_commission, currencySymbol(data.history[0].convertion_in_usd.currency_code)
                )
            ), visible: this.user.type == 'admin'},
            {label: "Created by OSP", attribute: (data) => data.created_by_osp ? 'Yes' : 'No', 
                visible: this.user.type == 'admin'},
            {label: "Affiliate", attribute: (data) => (data.affiliate ? data.affiliate.name : ""), visible: Array('admin','affiliate').includes(this.user.type)},
            {label: "Affiliate Commission", attribute: (data) => (
                this.$options.filters.currency(data.history[0].convertion_in_usd.affiliate_commission, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            ), visible: Array('admin','affiliate').includes(this.user.type)},
            {label: "Stripe Fees", attribute: (data) => this.$options.filters.currency(data.stripe_net_reservation_fees, currencySymbol(data.history[0].convertion_in_usd.currency_code)), visible: this.user.type == 'admin'},
            {label: "OSP CM", attribute: (data) => this.$options.filters.currency(data.history[0].convertion_in_usd.osp_cm, currencySymbol(data.history[0].convertion_in_usd.currency_code)), visible: this.user.type == 'admin'},
            {label: "Convenience Fee", attribute: (data) => this.$options.filters.currency(data.history[0].fees.length ? data.history[0].fees.find(obj => obj.name == "Convenience Fee") ? 
            data.history[0].fees.find(obj => obj.name == "Convenience Fee" && obj.location_fee_id == null && obj.fee_for == 'osp').total : 0 : 0, currencySymbol(data.history[0].convertion_in_usd.currency_code))
            , visible: this.user.type == 'admin'},
            {label: "Send Monthly Special Deals", attribute: (data) => data.send_special_deals ? 
                `<input type="checkbox" checked disabled/>` : 
                `<input type="checkbox" disabled/>`
            , visible: this.user.type == 'admin', sortable: true, sort_key: "send_special_deals"},
        ]

        if (this.user.type == 'affiliate') {
            let params = this.user.affiliates[0].affiliate.extra_parameters
            _.forEach(params, (item) => {
                this.columns.push({label: item.name, attribute: 'affiliate_extra_parameters.' + item.name})
            })
        }
    }
}

let computed = {
}

let watch = {
}

export default {
    name: "commission-report",
    methods,
    components,
    computed,
    watch,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/commission',
        columns: [],
        user: null
    }),
    mounted () {
        this.user = user.getUser();
        this.setCols();
    }
}