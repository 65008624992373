import sSelect from 'components/sSelect'
import datepicker from 'components/datepicker'
import { dateTime } from 'services'

const components = {
  sSelect,
  datepicker
}

const methods = {
  search () {
    this.$emit('search', {
      form: this.form,
      amenities: this.editMode ? [] : this.selected_amenities
    });
  },

  onChangeAirport (port) {
    if (this.editMode) return;
    
    this.$emit('selectAirport', port);
    this.port = port;
    this.form.airport_id = port.value;
  },

  changeNumberOfSpots () {
    this.$emit('changeSpotsNum', this.form.number_of_spots);
  }
}

export default {
  name: 'search-cirteria',
  props: ['formData', 'processing', 'errors', 'amenities', 'selectedAmenities', 'ports', 'selectedAirport', 'editMode'],
  methods,
  components,
  data () {
    return {
      times: dateTime.getHalfHours(),
      selected_amenities: this.selectedAmenities,
      port: this.selectedAirport,
      form: {
        airport_id: this.formData.airport_id,
        from_date: this.formData.from_date,
        to_date: this.formData.to_date,
        from_time: this.formData.from_time,
        to_time: this.formData.to_time,
        number_of_spots: this.formData.number_of_spots,
      }
    }
  }
}
