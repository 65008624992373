var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("form", { staticClass: "form-signin" }, [
        _c("h2", { staticClass: "form-signin-heading" }, [
          _vm._v("Please sign in")
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "sr-only", attrs: { for: "inputEmail" } }, [
          _vm._v("Email address")
        ]),
        _vm._v(" "),
        _c("input", {
          ref: "email",
          staticClass: "form-control",
          attrs: {
            type: "email",
            id: "inputEmail",
            placeholder: "Email address",
            required: "",
            autofocus: ""
          }
        }),
        _vm._v(" "),
        _vm.errors.email
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v("\n      " + _vm._s(_vm.errors.email[0]) + "\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "sr-only", attrs: { for: "inputPassword" } },
          [_vm._v("Password")]
        ),
        _vm._v(" "),
        _c("input", {
          ref: "password",
          staticClass: "form-control",
          attrs: {
            type: "password",
            id: "inputPassword",
            placeholder: "Password",
            required: ""
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "text-right margin-bottom-2x" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.openSendEmail($event)
                }
              }
            },
            [_vm._v("\n        Forgot your password?\n      ")]
          )
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-lg btn-primary btn-block btn-flat",
            class: { disabled: _vm.loading },
            attrs: { disabled: _vm.loading },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.submit(
                  _vm.$refs.email.value,
                  _vm.$refs.password.value
                )
              }
            }
          },
          [
            _vm.loading
              ? _c("span", [
                  _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                  _vm._v("\n        Signing In...\n      ")
                ])
              : _c("span", [_vm._v("\n        Sign in\n      ")])
          ]
        )
      ]),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("validate-reservation", { ref: "validateReservation" }),
      _vm._v(" "),
      _c(
        "func-modal",
        {
          ref: "forgotPass",
          attrs: { title: "Reset Password" },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function(props) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      class: { disabled: _vm.processing },
                      attrs: { disabled: _vm.processing },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submitSendEmail(props)
                        }
                      }
                    },
                    [
                      _vm.processing
                        ? _c("span", [
                            _c("i", { staticClass: "fa fa-spin fa-spinner" }),
                            _vm._v("\n          Sending...\n        ")
                          ])
                        : _c("span", [_vm._v("\n          Send\n        ")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return props.close()
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "body" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.forgotPass.email,
                  expression: "forgotPass.email"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "email", placeholder: "Type your email" },
              domProps: { value: _vm.forgotPass.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.forgotPass, "email", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.email
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.errors.email[0]) + "\n      "
                  )
                ])
              : _vm._e()
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-247e7dd8", { render: render, staticRenderFns: staticRenderFns })
  }
}