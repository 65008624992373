import toastr from 'toastr'
import layout from 'layouts/default'
import { confirm } from 'components/modals'
import { dateFormat } from 'filters'
import newBlackout from './create'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  confirm,
  newBlackout
}

const filters = {
  dateFormat
}

const computed = {
  blackouts () {
    return this.$store.state.locations.active.blackouts;
  }
}

const methods = {
  eligibleDays (item) {
    let weekDays = [
      'Monday', 'Tuesday', 'Wednesday', 'Thursday',
      'Friday', 'Saturday', 'Sunday'
    ];

    let days = [];
    for (let i in weekDays) {
      if (item[weekDays[i].toLowerCase()]) {
        days.push(weekDays[i]);
      }
    }

    return days.join(', ');
  },

  showNewBlackoutModal () {
    this.shouldShowNewBlackoutModal = true;
    this.$nextTick(() => {
      this.$refs.newBlackoutModal.open();
    });
  },

  onCloseNewBlackoutModal () {
    this.shouldShowNewBlackoutModal = false;
    console.log('closed');
  },

  remove (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.$set(this.remove_loadings, item.id, true);
      this.$store.dispatch(`locations.removeBlackout`, item.id).then(() => {
        this.$set(this.remove_loadings, item.id, false);
        let errors = this.$store.state.locations.errors;
        if (errors) {
          toastr.error(errors.message);
        }
      })
    });
  },
}

export default {
  name: "blackouts",
  components,
  filters,
  methods,
  computed,
  directives,
  data () {
    return {
      errors: {},
      remove_loadings: {},
      loading: false,
      shouldShowNewBlackoutModal: false,
      location: null,
      types: [],
    }
  },
  created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.getLocationTypes', {location_id: this.$route.params.id}),
      this.$store.dispatch('locations.getLocationBlackouts', this.$route.params.id)
    ]).then(() => {
      this.location = this.$store.state.locations.active;
      this.types = this.location.types;
      // this.blackouts = this.location.blackouts;
      this.loading = false;
    });
  }
}
