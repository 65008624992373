/*
* @DESC: this component makes an element editable checkbox when double click on it.
* @param value: the value that you need to change
* @param autoCloseOnSave: true to close the component without loading
* @param options: A key-value object represents value-label options
* @callback: v-on:valueSaved="doSomething(data: {value, close(), startLoading(), startLoading()})"
*/

const methods = {
  cancel () {
    this.editedValue = this.cachedVal;
    this.active = false;
  },
  close () {
    this.active = false;
  },
  startLoading () {
    this.loading = true;
  },
  stopLoading () {
    this.loading = false;
  },
  save () {
    console.log(this.editedValue);
    this.$emit('valueSaved', {
      value: this.editedValue,
      close: this.close,
      startLoading: this.startLoading,
      stopLoading: this.stopLoading
    });

    if (this.autoCloseOnSave) {
      this.active = false;
      this.stopLoading();
    }
  },
  enterActiveMode () {
    this.active = true;
    console.log('double clicked');
  }
}

const computed = {

}

const watch = {
  active (n) {
    if (n) {
      setTimeout(() => {
        $(this.$refs.input).focus();
      }, 100)
      this.cachedVal = _.cloneDeep(this.value);
    }
    console.log(this.cachedVal);
  },
  value (n, o) {
    if(n == o) return;
    this.editedValue = _.cloneDeep(n);
  }
}

export default {
  name: 'editable-radio',
  props: ['value', 'autoCloseOnSave', 'options'],
  methods,
  computed,
  watch,
  data () {
    return {
      active: false,
      cachedVal: null,
      loading: false,
      editedValue: _.cloneDeep(this.value)
    }
  },
  mounted () {

  }
}
