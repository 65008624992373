import { http } from 'services'
// initial state
const state = () => ({
  errors: null,
  charge_card: null,
  refund_cards: [],
  active: {},
  all: {},
  min_prices: {},
  type_costs: {},
  costs: null
})

// getters
const getters = {
  // reversedMessage: state => state.message.reverse()
}

// actions
const actions = {
  ['reservations.getCosts'] ({ commit }, {data, reservation_id = null}) {
    commit('CLEAR_ERRORS')

    let reservation = '';
    if (reservation_id) {
      reservation = `/` + reservation_id;
    }

    return (http.post(`costs` + reservation, data))
    .then((res) => {
      commit('RESERVATIONS_GET_COSTS', {res, data});
    })
    .catch ((res) => {
      commit('RESERVATIONS_COSTS_ERROR', {res, data});
    });
  },

  ['reservations.getMinPrice'] ({ commit }, {data, location_id}) {
    commit('CLEAR_ERRORS')

    return (http.post(`min-price/${location_id}`, data))
    .then((res) => {
      commit('RESERVATIONS_GET_MIN_PRICE', {location_id, res});
    })
    .catch ((res) => {
      commit('RESERVATIONS_GET_MIN_PRICE', {location_id, res: res.response});
    });
  },

  ['reservations.clearMinPrices'] ({ commit }) {
    commit('RESERVATIONS_CLEAR_MIN_PRICES');
  },

  ['reservations.getAll'] ({ commit }, params) {
    commit('CLEAR_ERRORS')
    console.log('params', params);
    return (http.get('reservations', {
      params
    })).then ((res) => {
      commit('GET_ALL_RESERVATIONS', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  //get single reservations by id
  ['reservations.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')

    return (http.get(`reservations/${id}`))
    .then(res => {
      console.log('res', res);
      commit('GET_RESERVATION', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    });
  },

  ['reservations.capture'] ({commit, state}, reservation_id) {
    commit('CLEAR_ERRORS')

    return (http.post(`reservations/${reservation_id}/manual-capture`))
    .then(res => {
      commit('CAPTURE_RESERVATION', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    });
  },

  ['reservations.create'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`reservations`, data)).then ((res) => {
      commit('NEW_RESERVATION', res);
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.sendToEmail'] ({commit}, {id, email, reservation_email}) {
    commit('CLEAR_ERRORS');

    return (http.post(`reservations/${id}/send-to-email`, {email, reservation_email})).then ((res) => {
      commit('NEW_RESERVATION', res);
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.sendToFax'] ({commit}, {id, fax}) {
    commit('CLEAR_ERRORS');

    return (http.post(`reservations/${id}/send-to-fax`, {fax})).then ((res) => {
      commit('NEW_RESERVATION', res);
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.updateReservationLink'] ({commit, dispatch}, {reservation_id, data}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.post(`reservations/${reservation_id}/update-reservation-link`, data).then((res) => {
        dispatch('reservations.get', reservation_id)
        .then(resolve(res))
        .catch(reject)
      })
      .catch(reject)
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.getChargeOrRefundList'] ({commit}, {reservation_id, data}) {
    commit('CLEAR_ERRORS');

    return (http.post(`reservations/${reservation_id}/charge-refund-list`, data)).then ((res) => {
      commit('GET_CHARGE_OR_REFUNDLIST', res);
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.update'] ({commit, dispatch}, {reservation_id, data}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.put(`reservations/${reservation_id}`, data).then((res) => {
        dispatch('reservations.get', reservation_id)
        .then(resolve(res))
        .catch(reject)
      })
      .catch(reject)
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.cancel'] ({commit, dispatch}, {id, refund_trip_protection = false, refund_type = 'without_refund', amount = 0,  reason = ""}) {
    commit('CLEAR_ERRORS');

    let request = `reservations/${id}?refund_trip_protection=${refund_trip_protection}&refund_type=${refund_type}&reason=${reason}`;
    if (refund_type == 'partial_refund') {
      request = `reservations/${id}?refund_trip_protection=${refund_trip_protection}&refund_type=${refund_type}&amount=${amount}&reason=${reason}`;
    }
    return new Promise((resolve, reject) => {
      http.delete(request).then((res) => {
        dispatch('reservations.get', id)
        .then(resolve(res))
        .catch(reject)
      })
      .catch(reject)
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.revertCancel'] ({commit, dispatch}, {id, data}) {
    commit('CLEAR_ERRORS');

    return new Promise((resolve, reject) => {
      http.post(`reservations/${id}/revert-cancel`, data).then((res) => {
        dispatch('reservations.get', id)
        .then(resolve(res))
        .catch(reject)
      })
      .catch(reject)
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.disputeReservation'] ({commit, dispatch}, {id}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.put(`reservations/${id}/dispute`)
      .then((res) => {
        dispatch('reservations.get', id)
        .then(resolve(res))
        .catch(reject)
      })
      .catch(reject)
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },
  
  ['reservations.updateCustomerEmail'] ({commit, dispatch}, {id, data}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.put(`reservations/${id}/customer-email`, data).then(() => {
        dispatch('reservations.get', id)
        .then(resolve)
        .catch(reject)
      })
      .catch(reject)
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.revertPartialRefund'] ({commit, dispatch}, {id, data}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.put(`reservations/${id}/revert-partial-refund`, data).then((res) => {
        dispatch('reservations.get', id)
        .then(resolve(res))
        .catch(reject)
      })
      .catch(reject)
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.partialRefund'] ({commit, dispatch}, {id, data}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.put(`reservations/${id}/partial-refund`, data).then((res) => {
        dispatch('reservations.get', id)
        .then(resolve(res))
        .catch(reject)
      })
      .catch(reject)
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.delete'] ({dispatch, commit}, {id, params}) {
    commit('CLEAR_ERRORS');

    return (http.delete(`reservations/${id}/guest-delete`, {params})).then ((res) => {
      commit('DELETE_RESERVATION', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.confirmPayment'] ({dispatch, commit}, {id, params}) {
    commit('CLEAR_ERRORS');
    return (http.post(`reservations/${id}/confirm-payment`, {params})).then ((res) => {
      commit('CONFIRM_RESERVATION_PAYMENT', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  RESERVATIONS_GET_COSTS (state, {res, data}) {
    state.type_costs[data.type_id] = res.data;
    state.type_costs = _.cloneDeep(state.type_costs);
    state.costs = res.data;
  },

  RESERVATIONS_COSTS_ERROR (state, {res, data}) {
    state.type_costs[data.type_id] = res.response.data;
    state.type_costs = _.cloneDeep(state.type_costs);
  },

  RESERVATIONS_GET_MIN_PRICE (state, {location_id, res}) {
    state.min_prices[location_id] = res.data;
    state.min_prices = _.cloneDeep(state.min_prices);
  },

  RESERVATIONS_CLEAR_MIN_PRICES (state) {
    state.min_prices = {};
  },

  GET_ALL_RESERVATIONS (state, res) {
    state.all = res.data;
    state.all = _.cloneDeep(state.all);
  },

  GET_RESERVATION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  CAPTURE_RESERVATION (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
    }
    state.all = _.cloneDeep(state.all);
  },

  NEW_RESERVATION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  GET_CHARGE_OR_REFUNDLIST (state, res) {
    state.charge_cards = res.data.cards_to_be_charged;
    state.refund_cards = res.data.cards_to_refund;

    state.charge_card = _.cloneDeep(state.charge_card);
    state.refund_cards = _.cloneDeep(state.refund_cards);
  },

  // UPDATE_RESERVATION (state, res) {
  //   state.active = _.assign(state.active, res.data);
  //   state.active = _.cloneDeep(state.active);
  // },
  CANCEL_RESERVATION (state, res) {
    state.active.cancelled = 1;
    state.active = _.cloneDeep(state.active);
  },

  DELETE_RESERVATION (state, res) {
    state.active = {};
    state.active = _.cloneDeep(state.active);
  },

  CONFIRM_RESERVATION_PAYMENT (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  //ERRORS
  RESERVATIONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
