import moment from 'moment'
import env from 'constants/env'
import datatable from 'components/datatable'
import { permits } from 'services'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.show_table = true;
        this.$nextTick(() => {
            this.$refs.table.run();
        })
    }
}

let computed = {
    user () {
        return this.$store.state.user.data;
    }
}

export default {
    name: "reviews-report",
    methods,
    components,
    computed,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/reviews',
        columns: []
    }),
    mounted () {
        this.columns = [
            {label: "Customer Name", attribute: 'customer_name',sortable: true, sort_key: "customer_name"},
            {label: "Email", attribute: 'customer_email',sortable: true, sort_key: "customer_email"},
            {label: "Overall Rating", attribute: 'overall_rating',sortable: true, sort_key: "overall_rating"},
            {label: "published", attribute: (data) => (
                data.published ? 'Yes' : 'No'
            ),sortable: true, sort_key: "published"},
            {label: "Review Title", attribute: 'title'},
            {label: "Comment", attribute: 'comment'},
            {label: "Location", attribute: 'location_name',sortable: true, sort_key: "location_name"},
            {label: "Visit Location", attribute: () => (
                `<i class="fa fa-external-link" aria-hidden="true"></i>`
            ), click: (data) => {
                let route = this.$router.resolve({name: 'locations.information', params: {id: data.location_id}});
                window.open(route.href, '_blank');
            }, permits: ['location_show']},
            {label: "Visit Review", attribute: () => (
                `<i class="fa fa-external-link" aria-hidden="true"></i>`
            ), click: (data) => {
                let route = this.$router.resolve({name: 'reviews.edit', params: {id: data.id}});
                window.open(route.href, '_blank');
            }, permits: ['review_show']},
        ]
    }
}