import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
import title from './modules/title'
import nav from './modules/nav'
import user from './modules/user'
import googleMaps from './modules/googleMaps'
import appLogs from './modules/appLogs'
import ports from './modules/ports'
import locations from './modules/locations'
import reservations from './modules/reservations'
import unifiedReservations from './modules/unifiedReservations'
import address from './modules/address'
import currencies from './modules/currencies'
import timezones from './modules/timezones'
import amenities from './modules/amenities'
import tripProtections from './modules/tripProtections'
import coupons from './modules/coupons'
import referrals from './modules/referrals'
import automatedCoupons from './modules/automatedCoupons'
import bulkCoupons from './modules/bulkCoupons'
import roles from './modules/roles'
import users from './modules/users'
import customers from './modules/customers'
import carousels from './modules/carousels'
import reviews from './modules/reviews'
import messages from './modules/messages'
import dashboards from './modules/dashboards'
import affiliates from './modules/affiliates'
import adminApiRequests from './modules/adminApiRequests'
import googleAds from './modules/googleAds'
import pages from './modules/pages'
import deferredRevenuesEmails from './modules/deferredRevenuesEmails'


Vue.use(Vuex)

export function createStore () {
  const debug = process.env.NODE_ENV !== 'prod'
  return new Vuex.Store({
    actions,
    getters,
    mutations,
    modules: {
      title,
      nav,
      user,
      googleMaps,
      appLogs,
      ports,
      locations,
      reservations,
      unifiedReservations,
      address,
      currencies,
      timezones,
      amenities,
      tripProtections,
      coupons,
      referrals,
      automatedCoupons,
      bulkCoupons,
      roles,
      users,
      customers,
      carousels,
      reviews,
      messages,
      dashboards,
      affiliates,
      adminApiRequests,
      googleAds,
      pages,
      deferredRevenuesEmails
    },
    strict: debug,
  })
}
