var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Customer Email" },
      on: { close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  class: [{ disabled: _vm.processing }],
                  attrs: { type: "button", disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm($event)
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                        _vm._v("\n        SAVING...\n      ")
                      ])
                    : _c("span", [_vm._v("Change")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _vm.data
        ? _c("template", { slot: "body" }, [
            _c("div", { staticClass: "margin-bottom-2x" }, [
              _c("label", [_vm._v(" Customer email ")]),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control owned-by-email",
                attrs: { type: "text", readonly: "" },
                domProps: { value: _vm.email }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "margin-top-3x" }, [
              _c("label", [_vm._v("New Customer email ")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.owned_by,
                    expression: "form.owned_by"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.form.owned_by },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "owned_by", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.owned_by
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.errors.owned_by[0]) +
                        "   \n      "
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7236d9fd", { render: render, staticRenderFns: staticRenderFns })
  }
}