var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.affiliate
    ? _c("div", { staticClass: "text-center" }, [
        _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
      ])
    : _c(
        "div",
        [
          _c("h3", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.affiliate.name))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card mb-4" }, [
            _c("div", { staticClass: "card-block " }, [
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: {
                      click: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "pervent",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        return _vm.openNewUserModal($event)
                      }
                    }
                  },
                  [_vm._v("New User")]
                )
              ]),
              _vm._v(" "),
              _vm.affiliate.users.length
                ? _c(
                    "div",
                    { staticClass: "table-responsive" },
                    [
                      _c(
                        "table",
                        { staticClass: "table table-striped margin-top-3x" },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.affiliate.users, function(emp) {
                              return _c("tr", { key: emp.id }, [
                                _c("td", [
                                  _vm._v(" " + _vm._s(emp.user.name) + " ")
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(" " + _vm._s(emp.user.email) + " ")
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  emp.role
                                    ? _c(
                                        "div",
                                        { staticClass: "text-primary" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(emp.role.display_name) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-info",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.openEditModal(emp)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-ellipsis-h",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm.delete_loadings[emp.id]
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "fa fa-spin fa-spinner"
                                        })
                                      ])
                                    : _c(
                                        "a",
                                        {
                                          staticClass: "text-danger",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.removeEmployee(emp)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-times",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        ]
                                      )
                                ])
                              ])
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("confirm", {
                        ref: "removeConfirm",
                        attrs: { type: "danger" }
                      })
                    ],
                    1
                  )
                : _c("div", { staticClass: "text-warning" }, [
                    _vm._v(
                      "\n        There are no users assigned to this Affiliate\n      "
                    )
                  ])
            ])
          ]),
          _vm._v(" "),
          _c("new-user", { ref: "newModal" }),
          _vm._v(" "),
          _vm.shouldShowEditModal
            ? _c("edit-employee", {
                ref: "editModal",
                attrs: { selectedUser: _vm.selectedUser },
                on: { close: _vm.onCloseEditModal }
              })
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" User Name ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" Email ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" Role ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" Details ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" Remove ")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ac7a324c", { render: render, staticRenderFns: staticRenderFns })
  }
}