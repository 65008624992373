var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table" }, [
    _c(
      "tbody",
      [
        _c("tr", [
          _c("td", [_vm._v("Number Of Days")]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.costs.number_of_days))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("Subtotal")]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(
                _vm._f("currency")(
                  _vm.costs.subtotal,
                  _vm.currencySymbol(_vm.currency_code)
                )
              )
            )
          ])
        ]),
        _vm._v(" "),
        _vm._l(_vm.costs.fees, function(fee) {
          return _c("tr", [
            _c("td", [_vm._v(_vm._s(fee.name))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(
                  _vm._f("currency")(
                    fee.total,
                    _vm.currencySymbol(_vm.currency_code)
                  )
                )
              )
            ])
          ])
        }),
        _vm._v(" "),
        _vm.costs.trip_protection_price
          ? _c("tr", [
              _c("td", [_vm._v("Trip Protection")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(
                      _vm.costs.trip_protection_price,
                      _vm.currencySymbol(_vm.currency_code)
                    )
                  )
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.costs.total_discount
          ? _c("tr", [
              _c("td", [
                _vm.costs.coupon_id
                  ? _c(
                      "a",
                      {
                        staticClass: "text-danger",
                        attrs: { href: "#" },
                        on: { click: _vm.removeCoupon }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-times-circle-o",
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v("\n        Discount\n      ")
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "-" +
                    _vm._s(
                      _vm._f("currency")(
                        _vm.costs.total_discount,
                        _vm.currencySymbol(_vm.currency_code)
                      )
                    )
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.costs.customer_credit_used
          ? _c("tr", [
              _c("td", [_vm._v("\n        Credit Used\n      ")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "-" +
                    _vm._s(
                      _vm._f("currency")(
                        _vm.costs.customer_credit_used,
                        _vm.currencySymbol(_vm.currency_code)
                      )
                    )
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.costs.points_used
          ? _c("tr", [
              _c("td", [_vm._v("\n        Points Used\n      ")]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.costs.points_used) + " Points")])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.costs.points_money_value
          ? _c("tr", [
              _c("td", [_vm._v("\n        Saved Using Points\n      ")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "-" +
                    _vm._s(
                      _vm._f("currency")(
                        _vm.costs.points_money_value,
                        _vm.currencySymbol(_vm.currency_code)
                      )
                    )
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.costs.total_taxes > 0
          ? _c("tr", [
              _c("td", [_vm._v("Taxes")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(
                      _vm.costs.total_taxes,
                      _vm.currencySymbol(_vm.currency_code)
                    )
                  )
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("tr", [
          _c("th", [_vm._v("Grand Total")]),
          _vm._v(" "),
          _c("th", [
            _vm._v(
              _vm._s(
                _vm._f("currency")(
                  _vm.costs.grand_total,
                  _vm.currencySymbol(_vm.currency_code)
                )
              )
            )
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("th", { staticClass: "padding-left-5x" }, [_vm._v("Pay Now")]),
          _vm._v(" "),
          _c("th", [
            _vm._v(
              _vm._s(
                _vm._f("currency")(
                  _vm.costs.amount_to_pay,
                  _vm.currencySymbol(_vm.currency_code)
                )
              )
            )
          ])
        ]),
        _vm._v(" "),
        _vm.costs.due_at_location > 0
          ? _c("tr", [
              _c("th", { staticClass: "padding-left-5x" }, [
                _vm._v("Due At Location")
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(
                      _vm.costs.due_at_location,
                      _vm.currencySymbol(_vm.currency_code)
                    )
                  )
                )
              ])
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-38213a3e", { render: render, staticRenderFns: staticRenderFns })
  }
}