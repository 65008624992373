var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canShow
    ? _c(
        "div",
        {
          ref: "modal",
          staticClass: "modal fade",
          attrs: { tabindex: "-1", role: "dialog", "aria-labelledby": "modal" }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog",
              class: {
                "modal-sm": _vm.size == "small",
                "modal-lg": _vm.size == "large",
                "modal-dialog-centered": _vm.centered
              },
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  !_vm.hideHeader
                    ? _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.title))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        "aria-label": "Close"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.close($event)
                        }
                      }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("×")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [_vm._t("body", null, { close: _vm.close })],
                  2
                ),
                _vm._v(" "),
                !_vm.hideFooter
                  ? _c(
                      "div",
                      { staticClass: "modal-footer" },
                      [_vm._t("footer", null, { close: _vm.close })],
                      2
                    )
                  : _vm._e()
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-15e99173", { render: render, staticRenderFns: staticRenderFns })
  }
}