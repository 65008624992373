import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import confirm from 'components/modals/confirm'
import { currencySymbol, dateFormat } from 'filters'
import { permits, dateTime } from 'services'
import { tabs, tab } from 'components/tabs'
import searchCriteria from '../searchCriteria'
import calculations from '../calculations'
import tripProtections from '../tripProtections'
import updateModal from '../updateModal'
import partialRefund from '../partialRefund'
import revertPartialRefund from '../revertPartialRefund'
import customerEmail from '../customerEmail'
import coupon from '../coupon'
import { hasPermits } from 'directives'
import revertCancel from '../revertCancel'

const directives = {
  hasPermits
}

const components = {
  layout,
  searchCriteria,
  calculations,
  tripProtections,
  updateModal,
  partialRefund,
  revertPartialRefund,
  customerEmail,
  tabs,
  tab,
  confirm,
  coupon,
  revertCancel
}

const filters = {
  dateFormat
}

const computed = {
  plans () {
    if (this.costs) {
      let plans = this.costs.trip_protections;
      return plans;
    } else {
      if (this.selected_location && this.selected_location.currency) {
        let plans = this.$store.state.tripProtections.all;
        return plans.map(plan => {
          const filteredPrices = plan.prices.filter(
            price => price.currency.id === this.selected_location.currency.id);
          // Return the plan only if it has matching prices
          if (filteredPrices.length > 0) {
            return {...plan,prices: filteredPrices};
          }
          return null;
        }).filter(plan => plan !== null);
      }
    }
  },

  active_history () {
    return _.filter(this.reservation.history, h => h.active)[0];
  },

  reservation () {
    return _.cloneDeep(this.$store.state.reservations.active);
  },

  location () {
    return this.active_history.location;
  },

  timezone () {
    return this.location.port.timezone.code;
  },

  currency_code () {
    if (this.active_history.location_type_id == this.form.type_id) {
      return this.reservation.currency_code
    }

    return this.selected_location ? this.selected_location.currency.code : ""
  }
}

const methods = {
  changeSpotsNum (value) {
     this.form.number_of_spots = value
     _.forEach(this.current_extra_fields, (field) => {
      this.form.extra_fields[field.name].length = value
    })
  },

  loadData () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('ports.getAll', {_all: true}),
      this.$store.dispatch('amenities.getAll'),
      this.$store.dispatch('tripProtections.getAll', {_all: true}),
      this.$store.dispatch('reservations.get', this.$route.params.id)
    ])
    .then(() => {
      let res_error = this.$store.state.reservations.errors;
      if (res_error) {
        if (res_error.message) {
          toastr.error(res_error.message);
        } else {
          toastr.error(res_error.error);
        }
      } else {
        this.amenities = this.$store.state.amenities.all;
        this.trip_protections = this.$store.state.tripProtections.all;

        this.ports = this.ports.concat(this.$store.state.ports.all.map(item => ({
          value: item.id,
          text: item.name + ` (${item.code})`,
          timezone: item.timezone
        })));

        for (let port of this.ports) {
          if (port.value == this.active_history.location.airport_id) {
            this.selectedAirport = port;
            this.form.airport_id = port.value;
            break;
          }
        }

        this.selected_location = this.location;
        this.current_extra_fields = _.cloneDeep(this.location.extra_fields);

        this.form.location_id = this.location.id;
        this.form.type_id = this.active_history.location_type_id;
        this.form.from_date = moment(this.active_history.from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
        this.form.from_time = moment(this.active_history.from_date, ['YYYY-MM-DD HH:mm:ss']).format('HH:mm');
        this.form.to_date = moment(this.active_history.to_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
        this.form.to_time = moment(this.active_history.to_date, ['YYYY-MM-DD HH:mm:ss']).format('HH:mm');
        this.form.number_of_spots = this.active_history.number_of_spots;
        this.form.customer_name = this.active_history.customer_name;
        this.form.contact_email = this.active_history.contact_email;
        this.form.phone = this.active_history.phone;
        this.form.zipcode = this.active_history.zipcode;
        this.form.extra_fields = this.active_history.extra_fields;
        this.form.coupon_code = this.active_history.coupon_code;
        this.form.points_used = this.active_history.points_used;

        if (!this.form.extra_fields || _.isEmpty(this.form.extra_fields)) {
          this.form.extra_fields = {};
          for (let i in this.current_extra_fields) {
            this.$set(this.form.extra_fields, this.current_extra_fields[i].name, [""]);
          }
        }

        for(let field in this.form.extra_fields) {
          if (!Array.isArray(this.form.extra_fields[field])) {
            this.form.extra_fields[field] = this.form.extra_fields[field].split();
          }
        }

        _.forEach(this.location.extra_fields, (field) => {
          if (!this.active_history.extra_fields[field.name]) {
              this.form.extra_fields[field.name]= [];
              if (field.per_car && this.form.number_of_spots > 1) {
                  for (let i = 0; i < this.form.number_of_spots; i++) {
                      this.form.extra_fields[field.name].push("")
                  }
              } else {
                  this.form.extra_fields[field.name] = [""]
              } 
          }
        })  
        if (this.active_history && this.active_history.trip_protection) {
          this.form.trip_protection_id = this.active_history.trip_protection.trip_protection_id
        }
      }
      if (!this.reservation.cancelled) {
        this.getTypeCosts(this.form.type_id).then(() => {
          this.tripCosts = true;
        });
      }
      this.loading = false;
    })
  },

  getLocations (data) {
    this.errors = {};
    this.search_processing = true;
    this.costs = null;
    this.locations = [];
    this.selected_location = {};

    let formData = _.assign(this.form, data.form);
    this.selected_amenities = data.amenities;

    let required_fields = [
      'airport_id',
      'from_date',
      'from_time',
      'to_date',
      'to_time',
      'number_of_spots',
    ]

    for (let i in required_fields) {
      if (!formData[required_fields[i]]) {
        this.errors[required_fields[i]] = ['the ' + required_fields[i].replace(/_/g, ' ') + ' field is required'];
        this.search_processing = false;
      }
    }

    if (!this.search_processing) return;

    this.$store.dispatch('ports.getLocations', {
      airport_id: formData.airport_id,
      params: {
        amenities: this.selected_amenities.length ? this.selected_amenities.join(',') : null,
        sync_reslab: this.reservation.reslab_id ? 'true' : 'false'
      }
    }).then(() => {

      if (this.$store.state.ports.errors) {
        this.errors = _.cloneDeep(this.$store.state.ports.errors.errors);
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        this.locations = _.cloneDeep(this.$store.state.ports.active.locations);

        this.getMinPrices();
      }

      this.search_processing = false;

    });
  },

  onSelectAirport (port) {
    this.selectedAirport = port;
  },

  getMinPrices () {
    this.$store.dispatch('reservations.clearMinPrices');

    let data = {
      airport_id: this.form.airport_id,
      number_of_spots: this.form.number_of_spots,
      trip_protection_id: this.form.trip_protection_id,
      coupon_code: this.form.coupon_code
    };

    data = _.assign(data, this.formatDatesToSend());

    for (let i in this.locations) {
      this.locations[i].grand_total = 'Calculating...';
      this.locations[i].error = false;


      this.$store.dispatch('reservations.getMinPrice', {
        location_id: this.locations[i].id,
        data,
      }).then(() => {
        console.log('this.locations[i].id', this.locations[i].id);
        console.log('this.$store.state.reservations.min_prices', this.$store.state.reservations.min_prices);
        let min_response = this.$store.state.reservations.min_prices[this.locations[i].id];
        let grand_total = min_response.grand_total;

        if (!this.locations[i].reslab_id && min_response.number_of_days < this.locations[i].min_booking_days && min_response.number_of_days !=0) {
          this.locations[i].grand_total = `Minimum ${this.locations[i].min_booking_days} days required`;
          this.locations[i].error = true;
          this.$set(this.locations, i, this.locations[i]);
          return;
        }

        if (grand_total || grand_total === 0) {
          this.locations[i].grand_total = grand_total;
        } else {
          this.locations[i].grand_total = min_response.message;
          this.locations[i].error = true;
        }

        this.$set(this.locations, i, this.locations[i]);
      });
    }
  },

  datesValid (location) {
    if (!this.selectedAirport.timezone.code) return true;
    let now = moment().tz(this.selectedAirport.timezone.code).add(location.hours_before_booking, 'hours');
    let formated_now = now.format('MM/DD/YYYY');
    let diff = dateTime.diffFromTimezone(
      this.form.from_date,
      this.selectedAirport.timezone.code,
      'MM/DD/YYYY',
      'hours',
      location.hours_before_booking
    );
    
    if (diff < 0) {
      location.grand_total = 'Please select date after ' + formated_now
      location.error = true;
      return false;
    }

    return true;
  },

  search (data) {
    if (this.change_location) {
      this.getLocations(data)
      return;
    }


    this.form = _.assign(this.form, data.form);
    this.costs_processing = true;
    this.form.coupon_code = this.active_history.coupon_code;
    this.getTypeCosts(this.form.type_id).then(() => {
      this.costs_processing = false;
    });
  },

  onSelectType (type) {
    console.log('type.soldout', type.soldout);
    console.log('isNaN(type.grand_total)', isNaN(type.grand_total));
    if (type.soldout || isNaN(type.grand_total)) return;

    this.selected_type = type;
    this.form.type_id = type.id;

    this.costs_processing = true;
    this.getTypeCosts(type.id).then(() => {
      this.costs_processing = false;
    });
  },

  getAllTypesCosts () {

    for (let i in this.types) {
      this.types[i].grand_total = 'Calculating...';
      this.types[i].number_of_days = 'Calculating...';
      this.types[i].soldout = false;
      this.types[i].error = false;

      this.getTypeCosts(this.types[i].id).then(() => {
        if (this.$store.state.reservations.type_costs[this.types[i].id].errors) {
          this.types[i].grand_total = _.cloneDeep(this.$store.state.reservations.type_costs[this.types[i].id].message);
          this.types[i].number_of_days = "";
          this.types[i].error = true;
        } else {
          let costs = _.cloneDeep(this.$store.state.reservations.type_costs[this.types[i].id])
          this.types[i].grand_total = costs.grand_total;
          this.types[i].number_of_days = costs.number_of_days;
          this.types[i].soldout = costs.soldout;
        }

        this.$set(this.types, i, this.types[i]);
      })
    }
  },

  getTypeCosts (type_id) {
    this.errors = {};
    this.costs = null;
    this.tripCosts = false;

    let data = {
      location_id: this.selected_location.id,
      number_of_spots: this.form.number_of_spots,
      type_id,
      trip_protection_id: this.form.trip_protection_id,
      coupon_code: this.form.coupon_code,
      include_trip_protections: true
    };

    data = _.assign(data, this.formatDatesToSend());

    let promise = this.$store.dispatch('reservations.getCosts', {
      data, reservation_id: this.form.reservation_id
    });

    promise.then(() => {
      if (this.$store.state.reservations.type_costs[type_id].errors) {
        this.errors = _.cloneDeep(this.$store.state.reservations.type_costs[type_id].errors);
        toastr.error(this.$store.state.reservations.type_costs[type_id].message);
      } else {
        this.costs = _.cloneDeep(this.$store.state.reservations.type_costs[type_id]);
      }
    })

    return promise;
  },

  onChangeLocation (location) {
    console.log('change location');
    
    this.types_loading = true;
    this.selected_location = location;
    this.form.location_id = location.id;
    this.types = [];
    let from_reslab = this.reservation.reslab_id ? 'true' : 'false';
    Promise.all([
      this.$store.dispatch('locations.getLocationTypes', {location_id: location.id, from_reslab: from_reslab}),
      this.$store.dispatch('locations.getExtraFields', {id: location.id, from_reslab: from_reslab})
    ]).then(() => {
      this.types = _.cloneDeep(this.$store.state.locations.active.types);
      this.extra_fields = _.cloneDeep(this.$store.state.locations.active.extra_fields);

      this.form.extra_fields = {};
      for (let i in this.extra_fields) {
        this.form.extra_fields[this.extra_fields[i].name] = [""];
      }

      this.getAllTypesCosts();
      this.types_loading = false;
    })
  },

  formatDatesToSend () {
    let dates = {
      from_date: null,
      to_date: null
    };

    if (this.form.from_date) {
      dates.from_date = moment(this.form.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    if (this.form.to_date) {
      dates.to_date = moment(this.form.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    if (this.form.from_time) {
      dates.from_date = dates.from_date + ' ' + this.form.from_time + ":00"
    }

    if (this.form.to_time) {
      dates.to_date = dates.to_date + ' ' + this.form.to_time + ":00"
    }

    return dates
  },

  onSelectTripProtection (trip_protection_id) {
    this.form.trip_protection_id = trip_protection_id;
    this.costs_processing = true;
    this.getTypeCosts(this.form.type_id).then(() => {
      this.costs_processing = false;
    });
  },

  onApplyCoupon (code) {
    if (!this.costs && this.active_history.coupon_id) {
      toastr.error("You already applied a coupon code");
      return
    }

    if (!this.costs && this.active_history.points_used) {
      toastr.error("Coupons can't be used with points");
      return
    }

    this.form.coupon_code = code;
    this.costs_processing = true;
    this.getTypeCosts(this.form.type_id).then(() => {
      this.costs_processing = false;
    });
  },

  onRemoveCoupon () {
    this.$refs.removeCoupon.confirm("Are you sure that you want to remove discount code?", () => {
      this.form.coupon_code = null;
      this.costs_processing = true;
      this.getTypeCosts(this.form.type_id).then(() => {
        this.costs_processing = false;
      });
    });
  },

  openUpdateReservationModal(cancel = false) {
    this.should_show_update_modal = true;
    this.is_cancel_request = cancel;
    this.$nextTick(() => {
      this.$refs.updateModal.open();
    })
  },

  openRevertCancelReservationModal() {
    this.$nextTick(() => {
      this.$refs.revertCancelModal.open();
    })
  },

  onCloseUpdateModal () {
    console.log('on close sss');
    this.should_show_update_modal = false;
  },

  openPartialRefundModal () {
    this.should_show_partial_refund_modal = true;
    this.$nextTick(() => {
      this.$refs.partialRefund.open();
    })
  },

  openRevertPartialRefundModal () {
    this.should_show_revert_partial_refund_modal = true;
    this.$nextTick(() => {
      this.$refs.revertPartialRefund.open();
    })
  },

  openCustomerEmailModal () {
    this.should_show_customer_email_modal = true;
    this.$nextTick(() => {
      this.$refs.customerEmail.open();
    })
  },

  onCloseCustomerEmailModal () {
    this.should_show_customer_email_modal = false;
  },

  onChangeSearchType (change_location) {
    if (!change_location) {
     this.selected_location = this.location;
     this.form.type_id = this.active_history.location_type_id;
    }
  },

  onCloseRevertPartialRefundModal () {
    this.should_show_revert_partial_refund_modal = false;
  },

  onClosePartialRefundModal () {
    this.should_show_partial_refund_modal = false;
  },

  copyPaymentlink () {
    navigator.clipboard.writeText(this.active_history.checkout_session.url);
  },

  setError (errors) {
    this.errors  =errors;
  },
  disputeReservation () {
    let confirmMsg = "Are you sure you want to dispute this reservation?";
    this.$refs.confirmDialog.confirm(confirmMsg, () => {
      this.$store.dispatch('reservations.disputeReservation', {
        id: this.form.reservation_id
      }).then(() => {
        let errors = _.cloneDeep(this.$store.state.reservations.errors);
        if (errors) {
          toastr.error(errors.message);
        } else {
          let message = `Reservation has been disputed successfully.`
          toastr.success(message);
          this.$refs.modal.close();
        }
      })
    })
  },
}

export default {
  name: 'edit-reservation',
  components,
  methods,
  computed,
  filters,
  directives,
  data () {
    let ports = [{
      text: "-- Select Airport --", 
      value: null,
      timezone: {}
    }];

    return {
      errors: {},
      loading: false,
      tripCosts: false,
      costs_processing: false,
      search_processing: false,
      costs: null,
      should_show_update_modal: false,
      should_show_partial_refund_modal: false,
      should_show_revert_partial_refund_modal: false,
      should_show_customer_email_modal: false,
      is_cancel_request: false,
      ports,
      selectedAirport: ports[0],
      amenities: [],
      selected_amenities: [],
      extra_fields: [],
      current_extra_fields: [],
      locations: [],
      types:[],
      selected_location: {},
      selected_type: {},
      currencySymbol,
      change_location: false,
      moment,
      form: {
        reservation_id: this.$route.params.id,
        trip_protection_id: null,
        coupon_code: null,
        airport_id: null,
        location_id: null,
        type_id: null,
        from_date: null,
        to_date: null,
        from_time: null,
        to_tim: null,
        customer_name: null,
        number_of_spots: null,
        contact_email: null,
        phone: null,
        zipcode: null,
        extra_fields: {},
        points_used: null
      }
    }
  },
  created () {
    if (!isServer) {
      if (!permits.hasPermit('reservation_show')) {
        toastr.error('you don\'t have permission to do this action');
        this.$router.replace({name: "home"});
        return
      }
    }

    this.loadData();
  }
}
