import toastr from 'toastr'
import layout from 'layouts/default'
import { confirm } from 'components/modals'
import { editableText } from 'components/editable'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  confirm,
  editableText
}

const computed = {
  types () {
    return _.cloneDeep(this.$store.state.locations.active.types);
  }
}

const methods = {
  submit () {
    this.processing = true;
    this.errors = {};
    this.$store.dispatch('locations.newType', {
      location_id: this.$route.params.id,
      data: this.form
    }).then(() => {
      this.processing = false;

      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        this.form = {
          name: null,
          number_of_spots: null
        }
        toastr.success('Data has been saved');
      }
    });
  },

  onEditType (editableData, item, key) {
    let oldValue = item[key];
    item[key] = editableData.value;

    editableData.startLoading();
    this.$store.dispatch(`locations.updateType`, {
      id: item.id, data: item
    }).then(() => {
      editableData.stopLoading();
      let errors;
      if (errors = this.$store.state.locations.errors) {
        item[key] = oldValue;
        console.log('errors', errors);
        if (errors.errors) {
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.error(error.message);
        }
      } else {
        editableData.close();
      }
    })
  },

  remove (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.$set(this.loadings, item.id, true);
      this.$store.dispatch(`locations.removeType`, item.id).then(() => {
        this.$set(this.loadings, item.id, false);
        let errors = this.$store.state.locations.errors;
        if (errors) {
          toastr.error(errors.message);
        }
      })
    });
  },
}

export default {
  name: 'location-types',
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      loading: true,
      loadings: {},
      processing: false,
      location: null,
      errors: {},
      form: {
        name: null,
        number_of_spots: null
      }
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.getLocationTypes', {location_id: this.$route.params.id}),
    ]).then(() => {
      this.loading = false;
      this.location = this.$store.state.locations.active;
    });
  }
}
