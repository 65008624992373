var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-sidebar": true } },
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Reviews")]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "card mb-4" }, [
            _c(
              "div",
              { staticClass: "card-block" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-4 margin-bottom-3x" }, [
                    _c("div", { staticClass: "input-group margin-bottom-1x" }, [
                      _c("input", {
                        ref: "search",
                        staticClass: "form-control",
                        attrs: { type: "text", placeholder: "Search for..." },
                        domProps: { value: _vm.searchKey },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              $event.keyCode !== 13
                            ) {
                              return null
                            }
                            return _vm.search(_vm.$refs.search.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "input-group-append" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.search(_vm.$refs.search.value)
                              }
                            }
                          },
                          [_vm._v("Go!")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group radio" }, [
                      _c("label", { staticClass: "margin-right-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter,
                              expression: "filter"
                            }
                          ],
                          attrs: { type: "radio", value: "all" },
                          domProps: { checked: _vm._q(_vm.filter, "all") },
                          on: {
                            change: [
                              function($event) {
                                _vm.filter = "all"
                              },
                              function($event) {
                                return _vm.loadPage(1)
                              }
                            ]
                          }
                        }),
                        _vm._v(
                          "\n                            All\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-right-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter,
                              expression: "filter"
                            }
                          ],
                          attrs: { type: "radio", value: "published" },
                          domProps: {
                            checked: _vm._q(_vm.filter, "published")
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.filter = "published"
                              },
                              function($event) {
                                return _vm.loadPage(1)
                              }
                            ]
                          }
                        }),
                        _vm._v(
                          "\n                            Published\n                        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("label", {}, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter,
                              expression: "filter"
                            }
                          ],
                          attrs: { type: "radio", value: "unpublished" },
                          domProps: {
                            checked: _vm._q(_vm.filter, "unpublished")
                          },
                          on: {
                            change: [
                              function($event) {
                                _vm.filter = "unpublished"
                              },
                              function($event) {
                                return _vm.loadPage(1)
                              }
                            ]
                          }
                        }),
                        _vm._v(
                          "\n                            Unpublished\n                        "
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table table-striped" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Review Title")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Preview")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Rating")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Date")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Location Name")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Customer Email")]),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: { permits: ["review_show"] },
                                expression: "{permits: ['review_show']}",
                                arg: "allow"
                              }
                            ]
                          },
                          [_vm._v("Show")]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: { permits: ["review_publish"] },
                                expression: "{permits: ['review_publish']}",
                                arg: "allow"
                              }
                            ]
                          },
                          [_vm._v("Published")]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: { permits: ["review_delete"] },
                                expression: "{permits: ['review_delete']}",
                                arg: "allow"
                              }
                            ]
                          },
                          [_vm._v("Remove")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.reviews.data, function(item) {
                        return _c("tr", { key: item.id }, [
                          _c("td", [_vm._v(_vm._s(item.title))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(item.comment.substring(0, 60)) + "..."
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.overall_rating))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  item.created_at,
                                  "MM/DD/YYYY hh:mm a"
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.location_name))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.customer_email))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: { permits: ["review_show"] },
                                  expression: "{permits: ['review_show']}",
                                  arg: "allow"
                                }
                              ]
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "reviews.edit",
                                      params: { id: item.id }
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-eye",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: { permits: ["review_publish"] },
                                  expression: "{permits: ['review_publish']}",
                                  arg: "allow"
                                }
                              ]
                            },
                            [
                              _vm.reviews_loadings[item.id]
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa fa-spinner fa-spin fa-fw"
                                    })
                                  ])
                                : _c(
                                    "a",
                                    {
                                      staticClass: "f18",
                                      class: [
                                        { "text-success": item.published },
                                        { "text-secondary": !item.published }
                                      ],
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.publish(item)
                                        }
                                      }
                                    },
                                    [
                                      item.published
                                        ? _c("i", {
                                            staticClass: "fa fa-check-square-o",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-square-o",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                    ]
                                  )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: { permits: ["review_delete"] },
                                  expression: "{permits: ['review_delete']}",
                                  arg: "allow"
                                }
                              ]
                            },
                            [
                              _vm.reviews_loadings[item.id]
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa fa-spinner fa-spin fa-fw"
                                    })
                                  ])
                                : _c(
                                    "a",
                                    {
                                      staticClass: "text-danger",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.removeReview(item.id)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-times",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("pagination", {
                  attrs: { "pages-number": _vm.reviews.last_page },
                  on: {
                    pageChanged: function(page) {
                      return _vm.loadPage(page)
                    }
                  }
                })
              ],
              1
            )
          ]),
      _vm._v(" "),
      _c("confirm", { ref: "deleteConfirm", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-69027999", { render: render, staticRenderFns: staticRenderFns })
  }
}