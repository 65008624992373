import toastr from 'toastr'
import layout from 'layouts/default'
import { user } from 'services'

let components = {
    layout
}

let methods = {
    submit() {
        let data = _.clone(this.form);

        try {
        if (data.role_id == null) {
            delete data.role_id;
        }
        } catch(e) {}

        this.errors = {};
        this.processing = true;
        this.$store.dispatch('user.updatePersonalData', data).then(() => {
            let error = this.$store.state.user.errors;
            if (error) {
                toastr.error(error.message);
                this.errors = _.cloneDeep(error.errors);
            } else {
                toastr.success("Data has been saved");
            }
            
            this.processing = false;
        });
    }
}

let computed = {
    countries() {
        let countries = [{ name: "Select Country", id: null }]
            .concat(this.$store.state.address.countries)

        return countries
    },
    states() {
        let states = [{ name: "Select State", id: null }];
        if (this.form.country_id) {

            let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
            if (indx > -1) {
                states = states.concat(this.countries[indx].states);
            }
        }

        return states;
    }
}

export default {
    name: "user-settings",
    methods,
    components,
    computed,
    data() {
        return {
            errors: {},
            form: null,
            processing: false
        }
    },
    created() {
        this.$store.dispatch('address.getCountries');
    },
    mounted() {
        this.form = user.getUser();
    }
}