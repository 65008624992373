import toastr from 'toastr'

const computed = {
  costs () {
    return this.$store.state.reservations.costs;
  }
}

const methods = {
  apply () {
    try {
      if (this.costs.coupon_code && this.coupon_code) {
        toastr.error('You have already applied a coupon code');
        return;
      }

      if (this.costs.points_used) {
        toastr.error("Coupons can't be used with points");
        return;
      }
    } catch(e) {}

    this.$emit('apply', this.coupon_code);
  },
}

export default {
  name: "reservation-coupon",
  methods,
  computed,
  data () {
    return {
      coupon_code: null
    }
  }
}
