var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mb-4" }, [
    _c("div", { staticClass: "card-block" }, [
      _c("form", {}, [
        _c("div", { staticClass: "text-right" }, [
          _vm.submit_loading
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { disabled: _vm.submit_loading, type: "button" }
                },
                [
                  _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                  _vm._v("\n          SAVING...\n        ")
                ]
              )
            : _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm($event)
                    }
                  }
                },
                [_vm._v("\n          SAVE\n        ")]
              )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row margin-top-4x" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "AffiliateName" } }, [
                _vm._v("Name")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.name,
                    expression: "formData.name",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "AffiliateName",
                  placeholder: "Affiliate Name"
                },
                domProps: { value: _vm.formData.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.formData, "name", $event.target.value.trim())
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.name
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.name[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "AffiliateCommission" } }, [
                _vm._v("Commission")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.commission,
                    expression: "formData.commission",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "AffiliateCommission",
                  placeholder: "80"
                },
                domProps: { value: _vm.formData.commission },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.formData,
                      "commission",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.commission
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.commission[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Logo")]),
                _vm._v(" "),
                _c("file-upload", {
                  ref: "file_upload",
                  attrs: { multiple: false, accept: "image/*" },
                  on: { filesChanged: _vm.logoToUpload }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "affiliate--logo-2x",
                  attrs: { src: _vm.formData.logo }
                }),
                _vm._v(" "),
                _vm.errors.logo
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.errors.logo[0]) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Favicon")]),
                _vm._v(" "),
                _c("file-upload", {
                  ref: "file_upload",
                  attrs: { multiple: false, accept: "image/*" },
                  on: { filesChanged: _vm.faviconToUpload }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "affiliate--logo-2x",
                  attrs: { src: _vm.formData.favicon }
                }),
                _vm._v(" "),
                _vm.errors.favicon
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.errors.favicon[0]) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.active,
                      expression: "formData.active"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.formData.active)
                      ? _vm._i(_vm.formData.active, null) > -1
                      : _vm.formData.active
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.formData.active,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.formData, "active", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.formData,
                              "active",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.formData, "active", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n              Active\n            ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "AffiliateAttribute" } }, [
                _vm._v("Attribute")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.attribute,
                    expression: "formData.attribute",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "AffiliateAttribute",
                  placeholder: "Affiliate Attribute"
                },
                domProps: { value: _vm.formData.attribute },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.formData,
                      "attribute",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.attribute
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.attribute[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "AffiliateWebsite" } }, [
                _vm._v("Website")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.website,
                    expression: "formData.website",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "AffiliateWebsite",
                  placeholder: "https://www.domain.com"
                },
                domProps: { value: _vm.formData.website },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.formData,
                      "website",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.website
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.website[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "AffiliateHost" } }, [
                _vm._v("White lable host domain")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.host,
                    expression: "formData.host",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "AffiliateHost",
                  placeholder: "https://www.parking.domain.com"
                },
                domProps: { value: _vm.formData.host },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.formData, "host", $event.target.value.trim())
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.host
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.host[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "AffiliateEmailSender" } }, [
                _vm._v("Email sender")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.email_sender,
                    expression: "formData.email_sender",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "AffiliateEmailSender",
                  placeholder: "info@domain.com"
                },
                domProps: { value: _vm.formData.email_sender },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.formData,
                      "email_sender",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.email_sender
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.email_sender[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "AffiliateTermAndConditionLink" } }, [
                _vm._v("Term and condition link")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.term_and_condition_link,
                    expression: "formData.term_and_condition_link",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "AffiliateTermAndConditionLink",
                  placeholder: "info@domain.com"
                },
                domProps: { value: _vm.formData.term_and_condition_link },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.formData,
                      "term_and_condition_link",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.term_and_condition_link
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.term_and_condition_link[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "AffiliateWebsite" } }, [
                _vm._v("Daily Email")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.daily_email,
                    expression: "formData.daily_email",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "AffiliateDailyEmail",
                  placeholder: "info@domain.com"
                },
                domProps: { value: _vm.formData.daily_email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.formData,
                      "daily_email",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.daily_email
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.daily_email[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Extra Parameters")]),
              _vm._v(" "),
              _c("table", { staticClass: "table" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.formData.extra_parameters, function(item, i) {
                    return _c("tr", [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.extra_parameters[i].name,
                              expression: "formData.extra_parameters[i].name"
                            }
                          ],
                          staticClass: "form-control",
                          domProps: {
                            value: _vm.formData.extra_parameters[i].name
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData.extra_parameters[i],
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors["extra_parameters." + i + ".name"]
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.errors[
                                      "extra_parameters." + i + ".name"
                                    ][0]
                                  ) +
                                  "\n                      "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.formData.extra_parameters[i].default_value,
                              expression:
                                "formData.extra_parameters[i].default_value"
                            }
                          ],
                          staticClass: "form-control",
                          domProps: {
                            value:
                              _vm.formData.extra_parameters[i].default_value
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData.extra_parameters[i],
                                "default_value",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors["extra_parameters." + i + ".default_value"]
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.errors[
                                      "extra_parameters." + i + ".default_value"
                                    ][0]
                                  ) +
                                  "\n                      "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.removeExtraParameter(i)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-trash",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      ])
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.createExtraParameter($event)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-plus",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(
                      "\n                  Add Parameter\n                "
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Default Value")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-30b937fa", { render: render, staticRenderFns: staticRenderFns })
  }
}