var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { staticClass: "reservation", attrs: { "no-sidebar": true } },
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("New Reservation")]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-7 col-lg-8" }, [
                _c("div", { staticClass: "card mb-4" }, [
                  _c("div", { staticClass: "card-block" }, [
                    _c(
                      "div",
                      { staticClass: "search-criteria" },
                      [
                        _c("h4", { staticClass: "mb-3" }, [
                          _vm._v("Search Criteria")
                        ]),
                        _vm._v(" "),
                        _c("search-criteria", {
                          attrs: {
                            formData: _vm.form,
                            amenities: _vm.amenities,
                            selectedAmenities: _vm.selected_amenities,
                            ports: _vm.ports,
                            selectedAirport: _vm.selectedAirport,
                            processing: _vm.search_processing,
                            editMode: false,
                            errors: _vm.errors
                          },
                          on: {
                            search: _vm.search,
                            selectAirport: _vm.onSelectAirport,
                            changeSpotsNum: _vm.changeSpotsNum
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.locations.length
                      ? _c("div", { staticClass: "locations-list mt-4" }, [
                          _c("h4", { staticClass: "mt-4 mb-3" }, [
                            _vm._v("Availale Locations")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Select")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Location Name")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Distance")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Due At Location")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Grand Total")])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.locations, function(location) {
                                  return _c("tr", [
                                    _c("td", [
                                      !location.error && !location.soldout
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "clickable",
                                              on: {
                                                click: function($event) {
                                                  return _vm.onChangeLocation(
                                                    location
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.selected_location.id,
                                                    expression:
                                                      "selected_location.id"
                                                  }
                                                ],
                                                attrs: { type: "radio" },
                                                domProps: {
                                                  value: location.id,
                                                  checked: _vm._q(
                                                    _vm.selected_location.id,
                                                    location.id
                                                  )
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.$set(
                                                      _vm.selected_location,
                                                      "id",
                                                      location.id
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(location.name))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(location.distance_from_airport) +
                                          " " +
                                          _vm._s(location.distance_unit)
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          location.due_at_location
                                            ? "yes"
                                            : "no"
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      location.error
                                        ? _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(location.grand_total) +
                                                  "\n                        "
                                              )
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    location.grand_total,
                                                    _vm.currencySymbol(
                                                      location.currency.code
                                                    )
                                                  )
                                                ) +
                                                "\n                          "
                                            ),
                                            location.soldout
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v("(sold out)")]
                                                )
                                              : _vm._e()
                                          ])
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.selected_location.id
                            ? _c("div", {}, [
                                _vm.types_loading
                                  ? _c("div", { staticClass: "text-center" }, [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-circle-o-notch fa-spin fa-2x fa-fw"
                                      })
                                    ])
                                  : _c("div", {}, [
                                      _c("div", [
                                        _c("h4", { staticClass: "mt-4 mb-3" }, [
                                          _vm._v("Booking Options")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          { staticClass: "row" },
                                          _vm._l(_vm.types, function(type) {
                                            return _c(
                                              "li",
                                              {
                                                key: type.id,
                                                staticClass:
                                                  "col-md-6 col-sm-12 col-lg-4"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "booking-option clickable",
                                                    class: [
                                                      {
                                                        disabled:
                                                          isNaN(
                                                            type.grand_total
                                                          ) ||
                                                          !type.grand_total ||
                                                          type.soldout
                                                      }
                                                    ],
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.onSelectType(
                                                          type
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "booking-option--select"
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .selected_type
                                                                  .id,
                                                              expression:
                                                                "selected_type.id"
                                                            }
                                                          ],
                                                          attrs: {
                                                            type: "radio",
                                                            disabled:
                                                              isNaN(
                                                                type.grand_total
                                                              ) ||
                                                              !type.grand_total ||
                                                              type.soldout
                                                          },
                                                          domProps: {
                                                            value: type.id,
                                                            checked: _vm._q(
                                                              _vm.selected_type
                                                                .id,
                                                              type.id
                                                            )
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                _vm.selected_type,
                                                                "id",
                                                                type.id
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "booking-option--data"
                                                      },
                                                      [
                                                        _c(
                                                          "table",
                                                          {
                                                            staticClass: "table"
                                                          },
                                                          [
                                                            _c("tbody", [
                                                              _c("tr", [
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Type Name"
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      type.name
                                                                    )
                                                                  )
                                                                ])
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("tr", [
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Parking Days"
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      type.number_of_days
                                                                    )
                                                                  )
                                                                ])
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("tr", [
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Grand Total"
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "currency"
                                                                      )(
                                                                        type.grand_total,
                                                                        _vm.currencySymbol(
                                                                          _vm
                                                                            .selected_location
                                                                            .currency
                                                                            .code
                                                                        )
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ])
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        type.soldout
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-danger"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              Sold Out\n                            "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        isNaN(type.grand_total)
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-danger"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      type.grand_total
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _vm.errors.type_id
                                          ? _c(
                                              "div",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.errors.type_id[0]
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      _vm.extra_fields.length
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "h4",
                                                { staticClass: "mt-4 mb-3" },
                                                [_vm._v("Required Fields")]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(_vm.extra_fields, function(
                                                field
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-input mb-2"
                                                  },
                                                  [
                                                    _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          field.display_name
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    field.per_car &&
                                                    _vm.form.number_of_spots > 1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass: "row"
                                                          },
                                                          _vm._l(
                                                            Number(
                                                              _vm.form
                                                                .number_of_spots
                                                            ),
                                                            function(i) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-md-6"
                                                                },
                                                                [
                                                                  _c(
                                                                    "h6",
                                                                    {
                                                                      staticClass:
                                                                        "margin-bottom-1x"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            field.display_name +
                                                                              i
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("input", {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .extra_fields[
                                                                            field
                                                                              .name
                                                                          ][
                                                                            i -
                                                                              1
                                                                          ],
                                                                        expression:
                                                                          "form.extra_fields[field.name][i-1]"
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "form-control margin-bottom-2x",
                                                                    attrs: {
                                                                      type:
                                                                        "text"
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm.form
                                                                          .extra_fields[
                                                                          field
                                                                            .name
                                                                        ][i - 1]
                                                                    },
                                                                    on: {
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        ) {
                                                                          return
                                                                        }
                                                                        _vm.$set(
                                                                          _vm
                                                                            .form
                                                                            .extra_fields[
                                                                            field
                                                                              .name
                                                                          ],
                                                                          i - 1,
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _c("div", [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.form
                                                                    .extra_fields[
                                                                    field.name
                                                                  ][0],
                                                                expression:
                                                                  "form.extra_fields[field.name][0]"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "form-control margin-bottom-2x",
                                                            attrs: {
                                                              type: "text"
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.form
                                                                  .extra_fields[
                                                                  field.name
                                                                ][0]
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.form
                                                                    .extra_fields[
                                                                    field.name
                                                                  ],
                                                                  0,
                                                                  $event.target
                                                                    .value
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ]),
                                                    _vm._v(" "),
                                                    _vm.errors[
                                                      "extra_fields." +
                                                        field.name
                                                    ]
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.errors[
                                                                    "extra_fields." +
                                                                      field.name
                                                                  ][0]
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ]),
                                _vm._v(" "),
                                _vm.trip_protections.length
                                  ? _c(
                                      "div",
                                      {},
                                      [
                                        _c("h4", { staticClass: "mt-4 mb-3" }, [
                                          _vm._v("Trip Protection")
                                        ]),
                                        _vm._v(" "),
                                        _c("trip-protections", {
                                          attrs: {
                                            selected:
                                              _vm.form.trip_protection_id,
                                            plans: _vm.plans,
                                            costs: _vm.costs,
                                            currency_code:
                                              _vm.selected_location.currency
                                                .code
                                          },
                                          on: {
                                            select: _vm.onSelectTripProtection
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.selected_location.id,
                                        expression: "selected_location.id"
                                      }
                                    ]
                                  },
                                  [
                                    _c("h4", { staticClass: "mt-4 mb-3" }, [
                                      _vm._v("Convenience Fee")
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "form-input" }, [
                                      _c(
                                        "label",
                                        { staticClass: "margin-right-2x" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.form.convenience_fee,
                                                expression:
                                                  "form.convenience_fee"
                                              }
                                            ],
                                            attrs: {
                                              type: "radio",
                                              value: "true"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.form.convenience_fee,
                                                "true"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.form,
                                                  "convenience_fee",
                                                  "true"
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(
                                            "\n                      Add Convenience Fee\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.convenience_fee,
                                              expression: "form.convenience_fee"
                                            }
                                          ],
                                          attrs: {
                                            type: "radio",
                                            value: "false"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.form.convenience_fee,
                                              "false"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.form,
                                                "convenience_fee",
                                                "false"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(
                                          "\n                      Do not Convenience Fee\n                    "
                                        )
                                      ])
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.selected_location.id,
                                        expression: "selected_location.id"
                                      }
                                    ]
                                  },
                                  [
                                    _c("h4", { staticClass: "mt-4 mb-3" }, [
                                      _vm._v("Confirm Payment")
                                    ]),
                                    _vm._v(" "),
                                    _c("payment-form", {
                                      key: _vm.selected_location.currency.code,
                                      attrs: {
                                        processing: _vm.form_processing,
                                        newCard: false,
                                        errors: _vm.errors
                                      },
                                      on: { submit: _vm.submit }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-5 col-lg-4" }, [
                _c("div", { staticClass: "card mb-4 sticky-calculations" }, [
                  _c("div", { staticClass: "card-block" }, [
                    _c(
                      "div",
                      { staticClass: "calculation" },
                      [
                        _c("h4", { staticClass: "mb-3" }, [
                          _vm._v("Calculations")
                        ]),
                        _vm._v(" "),
                        _vm.costs_processing
                          ? _c("div", { staticClass: "text-center" }, [
                              _c("i", {
                                staticClass:
                                  "fa fa-circle-o-notch fa-spin fa-2x fa-fw"
                              })
                            ])
                          : _c(
                              "div",
                              {},
                              [
                                _vm.costs
                                  ? _c("calculations", {
                                      attrs: {
                                        costs: _vm.costs,
                                        currency_code:
                                          _vm.selected_location.currency.code
                                      },
                                      on: { removeCoupon: _vm.onRemoveCoupon }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _c("coupon", { on: { apply: _vm.onApplyCoupon } })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-59cb3f93", { render: render, staticRenderFns: staticRenderFns })
  }
}