var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card mb-4" },
    [
      _c("div", { staticClass: "card-block " }, [
        _c("h4", { staticClass: "mb-2" }, [_vm._v("Extra Fields")]),
        _vm._v(" "),
        _vm.loading
          ? _c("div", { staticClass: "text-center" }, [
              _c("i", {
                staticClass: "fa fa-circle-o-notch fa-spin fa-2x fa-fw"
              })
            ])
          : _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Field Name")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Per car")]),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: {
                            id: _vm.location_id,
                            permits: ["EXTRAFIELDS_DELETE"]
                          },
                          expression:
                            "{id: location_id, permits: ['EXTRAFIELDS_DELETE']}",
                          arg: "allow"
                        }
                      ]
                    },
                    [_vm._v("Delete")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.extraFields, function(field) {
                  return _c("tr", [
                    _c("td", [_vm._v(_vm._s(field.display_name))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(!!field.per_car))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: {
                              id: _vm.location_id,
                              permits: ["EXTRAFIELDS_DELETE"]
                            },
                            expression:
                              "{id: location_id, permits: ['EXTRAFIELDS_DELETE']}",
                            arg: "allow"
                          }
                        ]
                      },
                      [
                        _vm.loadings[field.id]
                          ? _c("div", [
                              _c("i", { staticClass: "fa fa-spinner fa-spin" })
                            ])
                          : _c(
                              "a",
                              {
                                staticClass: "text-danger",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.remove(field.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fa fa-trash" })]
                            )
                      ]
                    )
                  ])
                }),
                0
              )
            ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: { id: _vm.location_id, permits: ["EXTRAFIELDS_CREATE"] },
                expression:
                  "{id: location_id, permits: ['EXTRAFIELDS_CREATE']}",
                arg: "allow"
              }
            ]
          },
          [
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.open_form = !_vm.open_form
                    }
                  }
                },
                [
                  !_vm.open_form
                    ? _c("i", {
                        staticClass: "fa fa-caret-down",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.open_form
                    ? _c("i", {
                        staticClass: "fa fa-caret-up",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e(),
                  _vm._v("\n          New Fields\n        ")
                ]
              )
            ]),
            _vm._v(" "),
            _vm.open_form
              ? _c(
                  "div",
                  {},
                  [
                    _vm._l(_vm.form.extra_fields, function(field, i) {
                      return _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-8 mb-2" }, [
                          _c("label", [_vm._v("Field Name")]),
                          _vm._v(" "),
                          i > 0
                            ? _c("div", { staticClass: "pull-right" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeFields(i, $event)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-times",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: field.display_name,
                                expression: "field.display_name"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: field.display_name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  field,
                                  "display_name",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors["extra_fields." + i + ".display_name"]
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.errors[
                                        "extra_fields." + i + ".display_name"
                                      ][0]
                                    ) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c("label", [_vm._v("Per car")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: field.per_car,
                                expression: "field.per_car"
                              }
                            ],
                            staticStyle: { display: "block" },
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(field.per_car)
                                ? _vm._i(field.per_car, null) > -1
                                : field.per_car
                            },
                            on: {
                              change: function($event) {
                                var $$a = field.per_car,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        field,
                                        "per_car",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        field,
                                        "per_car",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(field, "per_car", $$c)
                                }
                              }
                            }
                          })
                        ])
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-right margin-top-3x" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          on: { click: _vm.addMoreFields }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-plus",
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          class: { disabled: _vm.processing },
                          attrs: { disabled: _vm.processing },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.submit()
                            }
                          }
                        },
                        [
                          _vm.processing
                            ? _c("span", [
                                _c("i", {
                                  staticClass: "fa fa-spinner fa-spin"
                                })
                              ])
                            : _vm._e(),
                          _vm._v("\n            SAVE Fields\n          ")
                        ]
                      )
                    ])
                  ],
                  2
                )
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4f491f09", { render: render, staticRenderFns: staticRenderFns })
  }
}