import toastr from 'toastr'
import { editableRadio, editableText } from 'components/editable'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  editableRadio, editableText,
  confirm
}

const methods = {
  getFees () {
    this.loading = true;
    this.$store.dispatch('locations.getFees', this.$route.params.id).then(() => {
      this.loading = false;
    });
  },

  handleFeesType (event) {
    let daily = event.target.value;
    if (!daily) {
      this.form.calculation_type = 'fixed';
    }
  },

  submit () {
    this.errors = {};
    this.processing = true;
    this.$store.dispatch('locations.newFee', {
      id: this.$route.params.id,
      data:this.form
    }).then(() => {
      this.processing = false;
      let errors =  this.$store.state.locations.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        toastr.success('data has been saved successfully');
        this.open_form = false;
        this.form = {
          name: null,
          fee_for: 'osp',
          calculation_type: 'fixed',
          daily: 1,
          amount: null
        }
      }
    });
  },

  onEditFee (editableData, item, key) {
    item[key] = editableData.value;
    editableData.startLoading();

    this.$store.dispatch('locations.updateFee', {
      id: item.id, data: item
    }).then(() => {
      editableData.stopLoading();
      let errors;
      if (this.$store.state.locations.errors) {
        errors = this.$store.state.locations.errors.errors;
        _.forEach(errors, (error) => {
          toastr.error(error[0]);
        })
      } else {
        editableData.close();
      }
    })
  },

  removeFee (id) {
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.$set(this.loadings, id, true);

      this.$store.dispatch('locations.removeFee', id).then(() => {
        this.loadings[id] = false;
        let errors;
        if (this.$store.state.locations.errors) {
          errors = this.$store.state.locations.errors.errors;
          console.log('errors', errors);
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.success('fee removed successfully');
        }
      });
    })
  }
}

const computed = {
  fees () {
    return _.cloneDeep(this.$store.state.locations.active.fees);
  }
}

export default {
  name: 'fees',
  methods,
  computed,
  components,
  directives,
  data () {
    return {
      loading: false,
      processing: false,
      open_form: false,
      loadings: {},
      errors: {},
      form: {
        name: null,
        fee_for: 'osp', // osp, location
        calculation_type: 'fixed', // fixed, percentage
        daily: 1, // 1 for daily and 0 for one time
        amount: null
      },
      location_id: this.$route.params.id
    }
  },

  created () {
    this.getFees();
  }
}
