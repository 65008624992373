var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c("div", [
            _c("h3", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(_vm.location.name))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: ["LOCATIONTYPE_CREATE"]
                      },
                      expression:
                        "{id: location.id, permits: ['LOCATIONTYPE_CREATE']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "col-sm-12 col-md-5"
                },
                [
                  _c("div", { staticClass: "card mb-4" }, [
                    _c("div", { staticClass: "card-block " }, [
                      _c("h5", { staticClass: "mb-2" }, [_vm._v("New Type")]),
                      _vm._v(" "),
                      _c("form", [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Type Name")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.name,
                                expression: "form.name"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.form.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.form, "name", $event.target.value)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.name
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.errors.name[0]) +
                                    "\n                "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Number of Spots")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.number_of_spots,
                                expression: "form.number_of_spots"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.form.number_of_spots },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "number_of_spots",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.number_of_spots
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.errors.number_of_spots[0]) +
                                    "\n                "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-right" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              class: { disabled: _vm.processing },
                              attrs: { disabled: _vm.processing },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.submit()
                                }
                              }
                            },
                            [
                              _vm.processing
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa fa-spinner fa-spin fa-fw"
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n                  Submit\n                "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: ["LOCATIONTYPE_LIST"]
                      },
                      expression:
                        "{id: location.id, permits: ['LOCATIONTYPE_LIST']}",
                      arg: "allow"
                    }
                  ],
                  staticClass: "col-sm-12 col-md-7"
                },
                [
                  _c("div", { staticClass: "card mb-4" }, [
                    _c("div", { staticClass: "card-block " }, [
                      _c("h5", { staticClass: "mb-2" }, [
                        _vm._v("Available Types")
                      ]),
                      _vm._v(" "),
                      !_vm.types.length
                        ? _c("div", { staticClass: "text-warning" }, [
                            _vm._v(
                              "\n              There are no types yet\n            "
                            )
                          ])
                        : _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      "\n                      Type\n                    "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      "\n                      Number of Spots\n                    "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: {
                                            id: _vm.location.id,
                                            permits: ["LOCATIONTYPE_DELETE"]
                                          },
                                          expression:
                                            "{id: location.id, permits: ['LOCATIONTYPE_DELETE']}",
                                          arg: "allow"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Delete\n                    "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.types, function(type) {
                                  return _c("tr", [
                                    _c(
                                      "td",
                                      [
                                        _c("editable-text", {
                                          directives: [
                                            {
                                              name: "hasPermits",
                                              rawName: "v-hasPermits:allow",
                                              value: {
                                                id: _vm.location.id,
                                                permits: ["LOCATIONTYPE_UPDATE"]
                                              },
                                              expression:
                                                "{id: location.id, permits: ['LOCATIONTYPE_UPDATE']}",
                                              arg: "allow"
                                            }
                                          ],
                                          attrs: { value: type.name },
                                          on: {
                                            valueSaved: function(data) {
                                              return _vm.onEditType(
                                                data,
                                                type,
                                                "name"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "hasPermits",
                                                rawName: "v-hasPermits:prevent",
                                                value: {
                                                  id: _vm.location.id,
                                                  permits: [
                                                    "super_admin",
                                                    "LOCATIONTYPE_UPDATE"
                                                  ]
                                                },
                                                expression:
                                                  "{id: location.id, permits: ['super_admin', 'LOCATIONTYPE_UPDATE']}",
                                                arg: "prevent"
                                              }
                                            ]
                                          },
                                          [_vm._v(_vm._s(type.name))]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c("editable-text", {
                                          directives: [
                                            {
                                              name: "hasPermits",
                                              rawName: "v-hasPermits:allow",
                                              value: {
                                                id: _vm.location.id,
                                                permits: ["LOCATIONTYPE_UPDATE"]
                                              },
                                              expression:
                                                "{id: location.id, permits: ['LOCATIONTYPE_UPDATE']}",
                                              arg: "allow"
                                            }
                                          ],
                                          attrs: {
                                            value: type.number_of_spots
                                          },
                                          on: {
                                            valueSaved: function(data) {
                                              return _vm.onEditType(
                                                data,
                                                type,
                                                "number_of_spots"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "hasPermits",
                                                rawName: "v-hasPermits:prevent",
                                                value: {
                                                  id: _vm.location.id,
                                                  permits: [
                                                    "super_admin",
                                                    "LOCATIONTYPE_UPDATE"
                                                  ]
                                                },
                                                expression:
                                                  "{id: location.id, permits: ['super_admin', 'LOCATIONTYPE_UPDATE']}",
                                                arg: "prevent"
                                              }
                                            ]
                                          },
                                          [_vm._v(_vm._s(type.number_of_spots))]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:allow",
                                            value: {
                                              id: _vm.location.id,
                                              permits: ["LOCATIONTYPE_DELETE"]
                                            },
                                            expression:
                                              "{id: location.id, permits: ['LOCATIONTYPE_DELETE']}",
                                            arg: "allow"
                                          }
                                        ]
                                      },
                                      [
                                        _vm.loadings[type.id]
                                          ? _c("span", [
                                              _c("i", {
                                                staticClass:
                                                  "fa fa-spinner fa-spin"
                                              })
                                            ])
                                          : _c(
                                              "a",
                                              {
                                                staticClass: "text-danger",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.remove(type)
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-times",
                                                  attrs: {
                                                    "aria-hidden": "true"
                                                  }
                                                })
                                              ]
                                            )
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            ])
                          ])
                    ])
                  ])
                ]
              )
            ])
          ]),
      _vm._v(" "),
      _c("confirm", { ref: "deleteConfirm", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4be14137", { render: render, staticRenderFns: staticRenderFns })
  }
}