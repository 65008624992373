import ListPages from 'pages/pages/list'
import CreatePages from 'pages/pages/create'
import EditPages from 'pages/pages/edit'

export default [
  { path: '/pages/create', name: 'pages.create', component: CreatePages, meta: {permits: ['SUPER_ADMIN']} },
  { path: '/pages/:id', name: 'pages.update', component: EditPages, meta: {permits: ['SUPER_ADMIN']} },
  { path: '/pages', name: 'pages.list', component: ListPages, meta: {permits: ['SUPER_ADMIN']} },
]

