var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "New Credit" },
      on: { close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(scope) {
            return [
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    class: { disabled: _vm.processing },
                    attrs: { disabled: _vm.processing },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.submit()
                      }
                    }
                  },
                  [
                    _vm.processing
                      ? _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
                      : _vm._e(),
                    _vm._v("\n                Save\n            ")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "text-danger ml-2",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return scope.close()
                      }
                    }
                  },
                  [_vm._v("cancel")]
                )
              ])
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "amount" } }, [_vm._v("Amount")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.amount,
                expression: "form.amount"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.form.amount },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "amount", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.amount
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.amount[0]) +
                    "\n            "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "expiry_date" } }, [_vm._v("Notes")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.notes,
                expression: "form.notes"
              }
            ],
            staticClass: "form-control",
            domProps: { value: _vm.form.notes },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "notes", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.notes
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.errors.notes[0]) +
                    "\n            "
                )
              ])
            : _vm._e()
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0f589ed2", { render: render, staticRenderFns: staticRenderFns })
  }
}