import { http } from 'services'

import axios from 'axios'
// initial state
const state = () => ({
  errors: null,
  all: [],
  active: {},
  uploaded_file: {
    file: null
  },
})

// getters
const getters = {
}

// actions
const actions = {
  ['amenities.clearFile'] ({ commit }) {
    commit('AMENITIES_CLEAR_FILE');
  },

  ['amenities.getAll'] ({ commit }) {
    commit('CLEAR_ERRORS')

    return (http.get('amenities?_all=true')).then((res) => {
      commit('AMENITIES_GET_ALL', res);
    });
  },

  ['amenities.create'] ({ commit, dispatch }, {display_name, file}) {
    commit('CLEAR_ERRORS')

    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();
    if (file) {
      formData.append('icon', file);
    }

    formData.append('display_name', display_name);

    return new Promise((resolve, reject) => {
      http.post(`amenities`, formData, config).then(res => {
        dispatch('amenities.getAll')
          .then(resolve)
          .catch(reject);
      })
      .catch(reject)
    }).catch(err => {
      commit('AMENITIES_ERROR', err);
    });
  },

  ['amenities.update'] ({ commit, dispatch }, {id, file, display_name}) {
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();
    if (file) {
      formData.append('icon', file);
    }

    formData.append('display_name', display_name);
    formData.append('_method', 'put');

    return new Promise((resolve, reject) => {
      http.post(`amenities/` + id, formData, config).then(res => {
        dispatch('amenities.getAll')
          .then(resolve)
          .catch(reject);
      })
      .catch(reject)
    }).catch(err => {
      commit('AMENITIES_ERROR', err);
    });

  },

  ['amenities.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`amenities/${id}`)).then ((res) => {
      commit('AMENITIES_REMOVE', res);
    }).catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  AMENITIES_FILE_UPLOADED (state, res) {
    state.uploaded_file = res.data[0]
  },

  AMENITIES_CLEAR_FILE (state) {
    state.uploaded_file.file = null
  },

  AMENITIES_GET_ALL (state, res) {
    state.all = res.data;
  },

  // AMENITIES_UPDATE (state, res) {
  //   let indx = _.findIndex(state.all, (item) => item.id == res.data.id);
  //
  //   if (indx > -1) {
  //     state.all[indx] = _.assign(state.all[indx], res.data);
  //     state.all = _.cloneDeep(state.all);
  //   }
  // },

  AMENITIES_REMOVE (state, res) {
    state.all = _.filter(state.all, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  //ERRORS
  AMENITIES_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
