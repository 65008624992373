import toastr from 'toastr'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const computed = {
  roles () {
    return this.$store.state.roles.all;
  },
  countries () {
    let countries = [{name: "Select Country", id: null}]
      .concat(this.$store.state.address.countries)

    return countries
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }

    return states;
  }
}

const methods = {
  submit () {
    this.errors = {}
    if (this.form.password) {
      if (this.form.password !== this.form.password_confirmation) {
        this.errors.password = ['Password and password confirmation do not match']
        return;
      }
    }

    let data = _.clone(this.form);

    this.processing = true;
    this.$store.dispatch('customers.editCustomer', {id: this.$route.params.id, data})
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.customers.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  }
}

export default {
  name: 'edit-customer',
  props: ['customer'],
  computed,
  methods,
  directives,
  data () {
    return {
      processing: false,
      errors: {},
      form: {
        country_id: null,
        state_id: null,
      }
    }
  },
  created () {
    this.$store.dispatch('address.getCountries');
    this.form = _.cloneDeep(this.customer);
  }
}
