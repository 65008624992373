import moment from 'moment'
import { funcModal } from 'components/modals'
import datepicker from 'components/datepicker'

let components = {
    funcModal, datepicker
}

let methods = {
    open () {
        this.$refs.modal.open();
    },

    submit () {
        this.errors = {}
        this.processing = true

        let data = _.cloneDeep(this.form);
        data.expiry_date = moment(data.expiry_date, ['MM/DD/YYYY']).format('YYYY-MM-DD')

        this.$store.dispatch('customers.newCredit', {
            id: this.$route.params.id,
            data
        }).then(() => {
            if (this.$store.state.customers.errors) {
                this.errors = _.cloneDeep(this.$store.state.customers.errors.errors);
            } else {
                this.$refs.modal.close();
            }

            this.processing = false
        });
    },

    onClose () {
        this.form = {
            amount: null,
            expiry_date: null
        }
    }
}

export default {
    name: "new-credit",
    components,
    methods,
    data () {
        return {
            errors: {},
            processing: false,
            form: {
                amount: null,
                expiry_date: null,
            }
        }
    }
}