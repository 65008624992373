import toastr from 'toastr'
import { funcModal } from 'components/modals'

let components = {
    funcModal
}

let methods = {
    resetPassword () {
        this.errors = {}
        this.processing = true;
        this.$store.dispatch('user.resetPassword', this.form).then(() => {
            if (this.$store.state.user.errors) {
                this.errors = _.cloneDeep(this.$store.state.user.errors.errors);
                toastr.error(this.$store.state.user.errors.message);
            } else {
                toastr.success("Your password has been changed successfully, please login");
                this.$refs.modal.close();
            }

            this.processing = false;
        });
    },

    onClose () {
        this.$router.replace({name: 'login'});
    }
}

export default {
    name: "reset-password",
    methods,
    components,
    data () {
        return {
            processing: false,
            errors: {},
            form: {
                password: null,
                password_confirmation: null,
                token: this.$route.query.token
            }
        }
    },
    mounted () {
        this.$refs.modal.open();
    }
}