import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'


const directives = {
  hasPermits
}

const components = {
  layout
}

const computed = {

}

const methods = {
  submit () {
    this.errors = {};
    this.processing = true;

    //to verify permission are from the filtered options
    let permits = []
    for (let i in this.permissions) {
      for (let j in this.form.permissions) {
        let permission = this.permissions[i].filter(item => item.id == this.form.permissions[j]);
        if (permission.length) {
          permits.push(this.form.permissions[j]);
        }
      }
    }

    this.form.permissions = permits;


    this.$store.dispatch('roles.editRole', {id: this.$route.params.id, data: this.form})
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.roles.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  },

  filterPermissions (for_admin, for_affiliate = false) {
    if (for_admin) {
      this.permissions = this.all_permissions;
      return
    }

    this.permissions = {};
    for (let i in this.all_permissions) {
      let items = for_affiliate ? this.all_permissions[i].filter(item => item.used_by_affiliate == for_affiliate) : this.all_permissions[i].filter(item => item.for_admin == for_admin)
      if (items.length) {
        this.permissions[i] = items
      }
    }
  }
}

export default {
  name: 'edit-role',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: true,
      processing: false,
      errors: {},
      form: {
        type: 'admin'
      },
      all_permissions: {},
      permissions: {}
    }
  },
  created () {
    this.$store.dispatch('roles.getRole', this.$route.params.id).then(() => {
      this.loading = false;
      let role = _.cloneDeep(this.$store.state.roles.active);
      role.permissions = _.map(role.permissions, (item) => {
        return item.id
      });

      this.form = _.assign(this.form, role);
      console.log('this.form', this.form);
      this.all_permissions = _.cloneDeep(this.$store.state.roles.permissions);
      this.filterPermissions(role.type == 'admin' ? true : false, role.type == 'affiliate' ? true : false);
    });

  }
}
