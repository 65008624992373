var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "trip-protection-plans" },
    _vm._l(_vm.tripPlans, function(plan) {
      return _c("li", [
        _c(
          "a",
          {
            class: { active: _vm.selected == plan.id },
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onSelect(plan)
              }
            }
          },
          [
            _c("div", { staticClass: "tripProtection-content" }, [
              _c("h4", [_vm._v(_vm._s(plan.title))]),
              _vm._v(" "),
              _c("div", { domProps: { innerHTML: _vm._s(plan.description) } })
            ]),
            _vm._v(" "),
            plan.calculated_price === 0
              ? _c("div", { staticClass: "tripProtection-price" })
              : plan.calculated_price
              ? _c("div", { staticClass: "tripProtection-price" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("currency")(
                          plan.calculated_price,
                          _vm.currencySymbol(_vm.currency_code)
                        )
                      ) +
                      "\n      "
                  )
                ])
              : _c("div", { staticClass: "tripProtection-price" }, [
                  _vm._v("\n        Calculating...\n      ")
                ])
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-408aa534", { render: render, staticRenderFns: staticRenderFns })
  }
}