import toastr from 'toastr'
import layout from 'layouts/default'
import pagination from 'components/pagination'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'
import { dateFormat } from 'filters'

let directives = {
    hasPermits
}

let filters = {
    dateFormat
}

let components = {
    layout,
    confirm,
    pagination
}

let computed = {
    reviews () {
        return this.$store.state.reviews.all;
    }
}

let methods = {
    search (searchKey) {
        this.searchKey = searchKey;
        this.loadPage(1);
    },

    loadPage (page = 1) {
        this.loading = true
        this.$store.dispatch('reviews.getAll', {
            page, _q: this.searchKey, published: this.filter != "all" ? this.filter : null
        }).then(() => {
            this.loading = false;
        });
    },

    publish (item) {
        this.$set(this.reviews_loadings, item.id, true);
        this.$store.dispatch('reviews.publish', {id: item.id, data: {publish: !item.published}}).then(() => {    
            this.$set(this.reviews_loadings, item.id, false);
            if (this.$store.state.reviews.errors) {
                this.errors = _.cloneDeep(this.$store.state.reviews.errors.errors);
                if (this.errors.hasOwnProperty('message')) {
                    toastr.error(this.errors.message);
                }
            }
        })
    },

    removeReview (id) {
        this.$refs.deleteConfirm.confirm('Are you sure?', () => {      
            this.$set(this.reviews_loadings, id, true);

            this.$store.dispatch('reviews.remove', id).then(() => {
                this.$set(this.reviews_loadings, id, false);
        
                if (this.$store.state.reviews.errors) {
                    this.errors = _.cloneDeep(this.$store.state.reviews.errors.errors);
                    if (this.errors.hasOwnProperty('message')) {
                        toastr.error(this.errors.message);
                    }
                }
            })
        });
    }
}

export default {
    name: "list-reviews",
    components,
    methods,
    computed,
    filters,
    directives,
    data () {
        return {
            loading: false,
            filter: "all",
            searchKey: null,
            reviews_loadings: {}
        }
    },
    created () {
        this.loadPage();
    }
}