import toastr from 'toastr'
import layout from 'layouts/default'
import placesAutocomplete from 'components/placesAutocomplete'
import googleMapsMovableMarker from 'components/googleMapsMovableMarker'

const directives = {
}

const components = {
  layout,
  placesAutocomplete,
  googleMapsMovableMarker
}

const methods = {
  onLocationChanged (location) {
    this.form.latitude = location.lat;
    this.form.longitude = location.lng;
  },
  updateDropCode() {
    const name = this.form.name;
    const closingParenIndex = name.indexOf(")");
    if (closingParenIndex !== -1) {
      this.form.drop_code = name.substring(0, closingParenIndex + 1);
    } else {
      this.form.drop_code = name;
    }
  },
  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;

    this.$store.dispatch('ports.add', _.cloneDeep(this.form)).then(() => {
      this.submit_loading = false;
      if (this.$store.state.ports.errors) {
        this.errors = _.cloneDeep(this.$store.state.ports.errors.errors) || {};
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        toastr.success('A new port has been created');

        this.form = {
          name: null,
          longitude: null,
          latitude: null,
          code: null,
          drop_code:null,
          timezone_id: null,
          state_id: null,
          country_id: null,
          city: null,
          address: null,
          type: 'airport',
        }

        this.$router.push({name: 'ports.edit', params: {
          id: this.$store.state.ports.active.id
        }});
      }
    })
  }
}

const computed = {
  countries () {
    return [{name: "Select Country", id: null}].concat(this.$store.state.address.countries);
  },

  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }

    return states;
  },

  timezones () {
    return [{name: "Select Timezone", id: null}].concat(this.$store.state.timezones.all);
  },

  center () {
    if (this.googlePlace) {
      let location = {
        lat: this.googlePlace.geometry.location.lat(),
        lng: this.googlePlace.geometry.location.lng()
      };
      this.form.latitude = this.googlePlace.geometry.location.lat();
      this.form.longitude = this.googlePlace.geometry.location.lng();
      this.googlePlace = null;
      return location;
    } else if (this.form.latitude && this.form.longitude) {
      let location = {
        lat: parseFloat(this.form.latitude),
        lng: parseFloat(this.form.longitude)
      };
      return location;
    }

    return null
  },
}

export default {
  name: 'new-port',
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      errors: {},
      loading: true,
      submit_loading: false,
      googlePlace: null,
      form: {
        name: null,
        longitude: null,
        latitude: null,
        code: null,
        drop_code:null,
        timezone_id: null,
        state_id: null,
        country_id: null,
        city: null,
        address: null,
        type: 'airport',
      }
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('address.getCountries'),
      this.$store.dispatch('timezones.getAll')
    ]).then(() => {
      this.loading = false;
    });
  }
}
