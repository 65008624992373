import toastr from 'toastr'
import { currencySymbol } from 'filters'
import confirm from 'components/modals/confirm'
import { funcModal } from 'components/modals'
import { stripeService } from 'services'

const components = {
  funcModal,
  confirm
}

const methods = {
  open () {
    this.$refs.modal.open();
  },

  onClose () {
    this.$emit('close');
  },

  
  changeRevertType () {
    if (this.revert_type == 'revert_last') {
        this.amount = this.last_partial_refund.partial_refund_amount;
    } else {
        this.selectChanged();
    }
  },
  selectChanged () {
      let sum = 0;
      this.history_ids_to_revert = [];
      for (let i = 0; i < this.partial_refunds.length; i++) {
          if (!this.partial_refunds[i].reverted && this.partial_refunds[i].selected) {
              sum += this.partial_refunds[i].partial_refund_amount;
              this.history_ids_to_revert.push(this.partial_refunds[i].reservation_history_id);
          }
      }
      this.amount = sum;
  },

  onSubmitForm (payment_intent_id = null) {
    let data = { 
      history_ids_to_revert: this.history_ids_to_revert
    };

    data.payment_intent_id = payment_intent_id;
    
    if (this.revert_type == 'select_revert' && !this.history_ids_to_revert.length) {
      this.errors = {
          history_ids_to_revert : [
              "You should select at least one item"
          ]
      }
      return;
    }
    this.processing = true;

    this.$store.dispatch('reservations.revertPartialRefund', {id : this.$route.params.id, data: data}).then(async (res) => {      
      if (this.$store.state.reservations.errors) {
        if (!_.isEmpty(this.$store.state.reservations.errors.errors)) {
          this.errors = _.cloneDeep(this.$store.state.reservations.errors.errors);
        }
        toastr.error(this.$store.state.reservations.errors.message);
        this.processing = false
      } 
      else {
        var succeeded = res.data.succeeded;
        
        if(succeeded) {
          this.processing = false
          let reslabFailedMessage = res.data.reslab_failed_message ? res.data.reslab_failed_message : false;
          toastr.success('Revert Partial refund secceeded')
          if (reslabFailedMessage) {
            this.$refs.warningConfirm.confirm(reslabFailedMessage, () => {
              this.$refs.modal.close();
            }, null, false)
          } else {
            this.$refs.modal.close();
          }
        }
        else {
          var clientSecret = res.data.client_secret;
          var requiresAction = res.data.requires_action;  

          if (requiresAction) {
              //3d secure required from stored card
              if(!this.stripe) {
                  const { stripe, elements } = await stripeService.init(this.currency_code);
                  this.stripe = stripe;
              }
              const { error: errorAction, paymentIntent } =  await this.stripe.handleCardAction(clientSecret);                        
              if (errorAction) {
                  this.processing = false;
                  toastr.error(errorAction.message);
              } else {
                this.onSubmitForm (paymentIntent.id)
                return;  
              }
          } else {
            toastr.error('Error while revert partial refund reservation.');
          }
        }
      }
    })
  },
}

export default {
  name: "revert-partial-refund-modal",
  props: ['reservation', 'active_history', 'currency_code'],
  methods,
  components,
  data () {
    return {
      processing: false,
      errors: {},
      stripe: null,
      currencySymbol,
      amount: 0,
      partial_refunds: [],
      history_ids_to_revert: [],
      not_reverted_partial_refunds_count: 0,
      last_partial_refund: null,
      revert_type: 'revert_last',
    }
  },

  created () {

    this.partial_refunds = this.reservation.partial_refunds;
    this.revert_type = 'revert_last';
    this.not_reverted_partial_refunds_count = 0;
    let last_pr_found = false;
    for (let i = this.partial_refunds.length -1; i >= 0; i--) {
        this.partial_refunds[i].selected = false;
        if (!this.partial_refunds[i].reverted ) {
            this.not_reverted_partial_refunds_count++;
            if (!last_pr_found) {
                this.last_partial_refund = this.partial_refunds[i];
                this.partial_refunds[i].selected = true;
                last_pr_found = true;
            }
        }
    }
    this.amount = this.last_partial_refund.partial_refund_amount;
  }
}
