var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "func-modal",
        {
          ref: "modal",
          attrs: { title: "New Profile Email Address" },
          on: { close: _vm.onClose },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function(props) {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: _vm.processing, type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        }
                      }
                    },
                    [
                      _vm.processing
                        ? _c("i", {
                            staticClass: "fa fa-spinner fa-spin fa-fw"
                          })
                        : _vm._e(),
                      _vm._v("\n            Ok\n        ")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return props.close()
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Email")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.email
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.errors.email) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("confirm", {
        ref: "deleteConfirm",
        attrs: { type: "danger" },
        on: { closeDeleteModal: _vm.closeDeleteModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-136c97e8", { render: render, staticRenderFns: staticRenderFns })
  }
}