var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { staticClass: "reservation", attrs: { "no-sidebar": true } },
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Edit Reservation")]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : !_vm.active_history
        ? _c("div", { staticClass: "text-center" }, [
            _vm._v("\n    Reservation not found\n  ")
          ])
        : _c(
            "div",
            { staticClass: "reservation-wrapper" },
            [
              _vm.reservation.cancelled
                ? _c("div", { staticClass: "cancelled-reservation" }, [
                    _vm._v("Cancelled")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "tabs",
                [
                  _c("tab", { attrs: { title: "Reservation Info" } }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-7 col-lg-8" }, [
                        _c("div", { staticClass: "card mb-4" }, [
                          _c("div", { staticClass: "card-block" }, [
                            _c("div", {}, [
                              _c("div", { staticClass: "radio" }, [
                                _c("label", { staticClass: "mr-2" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.change_location,
                                        expression: "change_location"
                                      }
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "same_location"
                                    },
                                    domProps: {
                                      value: false,
                                      checked: _vm._q(
                                        _vm.change_location,
                                        false
                                      )
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          _vm.change_location = false
                                        },
                                        function($event) {
                                          return _vm.onChangeSearchType(
                                            _vm.change_location
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _vm._v(
                                    "\n                      Same Location\n                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.change_location,
                                        expression: "change_location"
                                      }
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "change_location"
                                    },
                                    domProps: {
                                      value: true,
                                      checked: _vm._q(_vm.change_location, true)
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          _vm.change_location = true
                                        },
                                        function($event) {
                                          return _vm.onChangeSearchType(
                                            _vm.change_location
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _vm._v(
                                    "\n                      Change Location\n                    "
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "margin-bottom-8x" }, [
                              !_vm.change_location
                                ? _c(
                                    "table",
                                    { staticClass: "table table-striped" },
                                    [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("th", [_vm._v("Reservation No.")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.reservation
                                                  .reservation_number
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Reserved By")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.reservation.reserved_by
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Contact Name")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.active_history.customer_name
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Contact Email")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.active_history.contact_email
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Dates")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    _vm.active_history
                                                      .from_date,
                                                    "MM/DD/YYYY hh:mm a"
                                                  )
                                                ) +
                                                "\n                        -\n                        " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    _vm.active_history.to_date,
                                                    "MM/DD/YYYY hh:mm a"
                                                  )
                                                ) +
                                                "\n                      "
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Number of Cars")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.active_history
                                                    .number_of_spots
                                                ) +
                                                " of type\n                        " +
                                                _vm._s(
                                                  _vm.active_history
                                                    .location_type_name
                                                ) +
                                                "\n                      "
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Port Name")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.location.port.name)
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Location Name")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(_vm._s(_vm.location.name))
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Timezone")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.location.port.timezone.name
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("IP Address")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.reservation.history[0].ip
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _vm.reservation.affiliate
                                          ? _c("tr", [
                                              _c("th", [_vm._v("Affiliate")]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.reservation.affiliate
                                                      .name
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.reservation.history[0].checkout_session &&
                              _vm.reservation.history[0].checkout_session
                                .payment_status != "paid"
                                ? _c("div", { staticClass: "row" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-outline-secondary float-left",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.copyPaymentlink($event)
                                          }
                                        }
                                      },
                                      [_vm._v("Copy payment link")]
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {},
                              [
                                _c("search-criteria", {
                                  attrs: {
                                    formData: _vm.form,
                                    amenities: _vm.amenities,
                                    selectedAmenities: _vm.selected_amenities,
                                    ports: _vm.ports,
                                    selectedAirport: _vm.selectedAirport,
                                    processing:
                                      _vm.costs_processing ||
                                      _vm.search_processing,
                                    editMode: _vm.change_location
                                      ? false
                                      : true,
                                    errors: _vm.errors
                                  },
                                  on: {
                                    search: _vm.search,
                                    selectAirport: _vm.onSelectAirport,
                                    changeSpotsNum: _vm.changeSpotsNum
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.locations.length && _vm.change_location
                              ? _c(
                                  "div",
                                  { staticClass: "locations-list mt-4" },
                                  [
                                    _c("h4", { staticClass: "mt-4 mb-3" }, [
                                      _vm._v("Availale Locations")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "table-responsive" },
                                      [
                                        _c("table", { staticClass: "table" }, [
                                          _c("thead", [
                                            _c("tr", [
                                              _c("th", [_vm._v("Select")]),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v("Location Name")
                                              ]),
                                              _vm._v(" "),
                                              _c("th", [_vm._v("Distance")]),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v("Due At Location")
                                              ]),
                                              _vm._v(" "),
                                              _c("th", [_vm._v("Grand Total")])
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "tbody",
                                            _vm._l(_vm.locations, function(
                                              location
                                            ) {
                                              return _c("tr", [
                                                _c("td", [
                                                  !location.error
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "clickable",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onChangeLocation(
                                                                location
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .selected_location
                                                                    .id,
                                                                expression:
                                                                  "selected_location.id"
                                                              }
                                                            ],
                                                            attrs: {
                                                              type: "radio"
                                                            },
                                                            domProps: {
                                                              value:
                                                                location.id,
                                                              checked: _vm._q(
                                                                _vm
                                                                  .selected_location
                                                                  .id,
                                                                location.id
                                                              )
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.selected_location,
                                                                  "id",
                                                                  location.id
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(_vm._s(location.name))
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.distance_from_airport
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        location.distance_unit
                                                      )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      location.due_at_location
                                                        ? "yes"
                                                        : "no"
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  location.error
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                location.grand_total
                                                              ) +
                                                              "\n                            "
                                                          )
                                                        ]
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          "\n                              " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                location.grand_total,
                                                                _vm.currencySymbol(
                                                                  location
                                                                    .currency
                                                                    .code
                                                                )
                                                              )
                                                            ) +
                                                            "\n                            "
                                                        )
                                                      ])
                                                ])
                                              ])
                                            }),
                                            0
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.selected_location.id
                                      ? _c("div", {}, [
                                          _vm.types_loading
                                            ? _c(
                                                "div",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-circle-o-notch fa-spin fa-2x fa-fw"
                                                  })
                                                ]
                                              )
                                            : _c("div", [
                                                _c(
                                                  "h4",
                                                  { staticClass: "mt-4 mb-3" },
                                                  [_vm._v("Booking Options")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "ul",
                                                  { staticClass: "row" },
                                                  _vm._l(_vm.types, function(
                                                    type
                                                  ) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        staticClass:
                                                          "col-md-6 col-sm-12 col-lg-4"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "booking-option clickable",
                                                            class: [
                                                              {
                                                                disabled:
                                                                  isNaN(
                                                                    type.grand_total
                                                                  ) ||
                                                                  type.soldout
                                                              }
                                                            ],
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.onSelectType(
                                                                  type
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "booking-option--select"
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .selected_type
                                                                          .id,
                                                                      expression:
                                                                        "selected_type.id"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    type:
                                                                      "radio",
                                                                    disabled:
                                                                      isNaN(
                                                                        type.grand_total
                                                                      ) ||
                                                                      type.soldout
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      type.id,
                                                                    checked: _vm._q(
                                                                      _vm
                                                                        .selected_type
                                                                        .id,
                                                                      type.id
                                                                    )
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm.selected_type,
                                                                        "id",
                                                                        type.id
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "booking-option--data"
                                                              },
                                                              [
                                                                _c(
                                                                  "table",
                                                                  {
                                                                    staticClass:
                                                                      "table"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "tbody",
                                                                      [
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  "Type Name"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    type.name
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  "Parking Days"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    type.number_of_days
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  "Grand Total"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm._f(
                                                                                      "currency"
                                                                                    )(
                                                                                      type.grand_total,
                                                                                      _vm.currencySymbol(
                                                                                        _vm
                                                                                          .selected_location
                                                                                          .currency
                                                                                          .code
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                type.soldout
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                Sold Out\n                              "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }),
                                                  0
                                                ),
                                                _vm._v(" "),
                                                _vm.errors.type_id
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-danger"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.errors
                                                                .type_id[0]
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]),
                                          _vm._v(" "),
                                          _vm.extra_fields.length
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass: "mt-4 mb-3"
                                                    },
                                                    [_vm._v("Required Fields")]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.extra_fields,
                                                    function(field) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-input mb-2"
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                field.display_name
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          field.per_car &&
                                                          _vm.form
                                                            .number_of_spots > 1
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row"
                                                                },
                                                                _vm._l(
                                                                  Number(
                                                                    _vm.form
                                                                      .number_of_spots
                                                                  ),
                                                                  function(i) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-md-6"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "h6",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  field.display_name +
                                                                                    i
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives: [
                                                                              {
                                                                                name:
                                                                                  "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm
                                                                                    .form
                                                                                    .extra_fields[
                                                                                    field
                                                                                      .name
                                                                                  ][
                                                                                    i -
                                                                                      1
                                                                                  ],
                                                                                expression:
                                                                                  "form.extra_fields[field.name][i-1]"
                                                                              }
                                                                            ],
                                                                            staticClass:
                                                                              "form-control margin-bottom-2x",
                                                                            attrs: {
                                                                              type:
                                                                                "text"
                                                                            },
                                                                            domProps: {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .extra_fields[
                                                                                  field
                                                                                    .name
                                                                                ][
                                                                                  i -
                                                                                    1
                                                                                ]
                                                                            },
                                                                            on: {
                                                                              input: function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  $event
                                                                                    .target
                                                                                    .composing
                                                                                ) {
                                                                                  return
                                                                                }
                                                                                _vm.$set(
                                                                                  _vm
                                                                                    .form
                                                                                    .extra_fields[
                                                                                    field
                                                                                      .name
                                                                                  ],
                                                                                  i -
                                                                                    1,
                                                                                  $event
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _c("div", [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.form
                                                                          .extra_fields[
                                                                          field
                                                                            .name
                                                                        ][0],
                                                                      expression:
                                                                        "form.extra_fields[field.name][0]"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type: "text"
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm.form
                                                                        .extra_fields[
                                                                        field
                                                                          .name
                                                                      ][0]
                                                                  },
                                                                  on: {
                                                                    input: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm.form
                                                                          .extra_fields[
                                                                          field
                                                                            .name
                                                                        ],
                                                                        0,
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              ]),
                                                          _vm._v(" "),
                                                          _vm.errors[
                                                            "extra_fields." +
                                                              field.name
                                                          ]
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .errors[
                                                                          "extra_fields." +
                                                                            field.name
                                                                        ][0]
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.current_extra_fields.length &&
                            !_vm.change_location
                              ? _c(
                                  "div",
                                  [
                                    _c("h4", { staticClass: "mt-4 mb-3" }, [
                                      _vm._v("Required Fields")
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.current_extra_fields, function(
                                      field
                                    ) {
                                      return _c(
                                        "div",
                                        { staticClass: "form-input mb-2" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(_vm._s(field.display_name))]
                                          ),
                                          _vm._v(" "),
                                          field.per_car &&
                                          _vm.form.number_of_spots > 1
                                            ? _c(
                                                "div",
                                                { staticClass: "row" },
                                                _vm._l(
                                                  Number(
                                                    _vm.form.number_of_spots
                                                  ),
                                                  function(i) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        staticClass: "col-md-6"
                                                      },
                                                      [
                                                        _c("h6", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.display_name +
                                                                  i
                                                              ) +
                                                              " "
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.form
                                                                  .extra_fields[
                                                                  field.name
                                                                ][i - 1],
                                                              expression:
                                                                "form.extra_fields[field.name][i-1]"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "form-control margin-bottom-2x",
                                                          attrs: {
                                                            type: "text"
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.form
                                                                .extra_fields[
                                                                field.name
                                                              ][i - 1]
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm.form
                                                                  .extra_fields[
                                                                  field.name
                                                                ],
                                                                i - 1,
                                                                $event.target
                                                                  .value
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c("div", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.form.extra_fields[
                                                          field.name
                                                        ][0],
                                                      expression:
                                                        "form.extra_fields[field.name][0]"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    value:
                                                      _vm.form.extra_fields[
                                                        field.name
                                                      ][0]
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.form.extra_fields[
                                                          field.name
                                                        ],
                                                        0,
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ]),
                                          _vm._v(" "),
                                          _vm.errors[
                                            "extra_fields." + field.name
                                          ]
                                            ? _c(
                                                "div",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.errors[
                                                          "extra_fields." +
                                                            field.name
                                                        ][0]
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.trip_protections.length
                              ? _c(
                                  "div",
                                  {},
                                  [
                                    _c("h4", { staticClass: "mt-4 mb-3" }, [
                                      _vm._v("Trip Protection")
                                    ]),
                                    _vm._v(" "),
                                    _c("trip-protections", {
                                      attrs: {
                                        selected: _vm.form.trip_protection_id,
                                        plans: _vm.plans,
                                        costs: _vm.costs,
                                        currency_code: _vm.currency_code
                                      },
                                      on: { select: _vm.onSelectTripProtection }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "customer-information" }, [
                              _c("h4", { staticClass: "mt-4 mb-3" }, [
                                _vm._v("Customer Information")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-input margin-bottom-2x" },
                                [
                                  _c("label", [_vm._v("Contact Email")]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.contact_email,
                                        expression: "form.contact_email"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "contact_email",
                                      type: "text"
                                    },
                                    domProps: { value: _vm.form.contact_email },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "contact_email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.errors.contact_email
                                    ? _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.errors.contact_email[0]
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-12" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-input margin-bottom-2x"
                                    },
                                    [
                                      _c("label", [_vm._v("Customer Name")]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.customer_name,
                                            expression: "form.customer_name"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "customer_name",
                                          type: "text"
                                        },
                                        domProps: {
                                          value: _vm.form.customer_name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.form,
                                              "customer_name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.customer_name
                                        ? _c(
                                            "div",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.errors.customer_name[0]
                                                  ) +
                                                  "\n                        "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-md-6" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-input margin-bottom-2x"
                                    },
                                    [
                                      _c("label", [_vm._v("Phone Number")]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.phone,
                                            expression: "form.phone"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: { id: "phone", type: "text" },
                                        domProps: { value: _vm.form.phone },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.form,
                                              "phone",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.phone
                                        ? _c(
                                            "div",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(_vm.errors.phone[0]) +
                                                  "\n                        "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-md-6" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-input margin-bottom-2x"
                                    },
                                    [
                                      _c("label", [_vm._v("Zip/Postal Code")]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.zipcode,
                                            expression: "form.zipcode"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: { id: "zipcode", type: "text" },
                                        domProps: { value: _vm.form.zipcode },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.form,
                                              "zipcode",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.errors.zipcode
                                        ? _c(
                                            "div",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(
                                                    _vm.errors.zipcode[0]
                                                  ) +
                                                  "\n                        "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-actions margin-top-4x text-right"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["super_admin"],
                                        expression: "['super_admin']",
                                        arg: "allow"
                                      }
                                    ],
                                    staticClass:
                                      "btn customer-email float-left",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openCustomerEmailModal(
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Change Customer Email\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["super_admin"],
                                        expression: "['super_admin']",
                                        arg: "allow"
                                      }
                                    ],
                                    staticClass: "btn btn-danger",
                                    class: [
                                      {
                                        "allow-dispute":
                                          _vm.reservation.cancelled
                                      }
                                    ],
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.disputeReservation()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Dispute\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.active_history.partial_refund > 0
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:allow",
                                            value: [
                                              "RESERVATION_PARTIAL_REFUND"
                                            ],
                                            expression:
                                              "['RESERVATION_PARTIAL_REFUND']",
                                            arg: "allow"
                                          }
                                        ],
                                        staticClass:
                                          "btn btn-outline-secondary",
                                        class: [
                                          {
                                            "allow-partial-refund":
                                              _vm.reservation.cancelled
                                          }
                                        ],
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.openRevertPartialRefundModal(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Revert Refund\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["RESERVATION_PARTIAL_REFUND"],
                                        expression:
                                          "['RESERVATION_PARTIAL_REFUND']",
                                        arg: "allow"
                                      }
                                    ],
                                    staticClass: "btn btn-outline-secondary",
                                    class: [
                                      {
                                        "allow-partial-refund":
                                          _vm.reservation.cancelled
                                      }
                                    ],
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openPartialRefundModal(
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Process Refund\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["reservation_cancel"],
                                        expression: "['reservation_cancel']",
                                        arg: "allow"
                                      }
                                    ],
                                    staticClass: "btn btn-danger",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openUpdateReservationModal(
                                          true
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Cancel Reservation\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.reservation.cancelled
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "hasPermits",
                                            rawName: "v-hasPermits:allow",
                                            value: [
                                              "reservation_revert_cancel"
                                            ],
                                            expression:
                                              "['reservation_revert_cancel']",
                                            arg: "allow"
                                          }
                                        ],
                                        staticClass: "btn btn-outline-danger",
                                        class: [
                                          {
                                            "allow-revert-cancel":
                                              _vm.reservation.cancelled
                                          }
                                        ],
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.openRevertCancelReservationModal(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Revert Cancel\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn go-back",
                                    class: [
                                      {
                                        "allow-go-back":
                                          _vm.reservation.cancelled
                                      }
                                    ],
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.$router.back()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Go Back\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openUpdateReservationModal(
                                          false
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Update\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-5 col-lg-4" }, [
                        _c(
                          "div",
                          { staticClass: "card mb-4 sticky-calculations" },
                          [
                            _c("div", { staticClass: "card-block" }, [
                              _c(
                                "div",
                                { staticClass: "calculation" },
                                [
                                  _vm.costs_processing
                                    ? _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-circle-o-notch fa-spin fa-2x fa-fw"
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.costs && !_vm.tripCosts
                                    ? _c(
                                        "div",
                                        { staticClass: "margin-bottom-8x" },
                                        [
                                          _c("h4", { staticClass: "mb-3" }, [
                                            _vm._v("Updated Calculations")
                                          ]),
                                          _vm._v(" "),
                                          _c("calculations", {
                                            attrs: {
                                              costs: _vm.costs,
                                              currency_code: _vm.currency_code
                                            },
                                            on: {
                                              removeCoupon: _vm.onRemoveCoupon
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", {}, [
                                    _c("h4", { staticClass: "mb-3" }, [
                                      _vm._v("Current Calculations")
                                    ]),
                                    _vm._v(" "),
                                    _c("table", { staticClass: "table" }, [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("td", [_vm._v("Number Of Days")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.active_history
                                                  .number_of_days
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("td", [_vm._v("Subtotal")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.active_history.subtotal,
                                                  _vm.currencySymbol(
                                                    _vm.reservation
                                                      .currency_code
                                                  )
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _vm.active_history.total_fees
                                          ? _c("tr", [
                                              _c("td", [_vm._v("Total Fees")]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.active_history
                                                        .total_fees,
                                                      _vm.currencySymbol(
                                                        _vm.reservation
                                                          .currency_code
                                                      )
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.active_history
                                          .trip_protection_price_id
                                          ? _c("tr", [
                                              _c("td", [
                                                _vm._v("Trip Protection")
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.active_history
                                                        .trip_protection_price,
                                                      _vm.currencySymbol(
                                                        _vm.reservation
                                                          .currency_code
                                                      )
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.active_history.total_taxes
                                          ? _c("tr", [
                                              _c("td", [_vm._v("Total Taxes")]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.active_history
                                                        .total_taxes,
                                                      _vm.currencySymbol(
                                                        _vm.reservation
                                                          .currency_code
                                                      )
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.active_history.total_discount
                                          ? _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "\n                            Total Discount\n                          "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "-" +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.active_history
                                                          .total_discount,
                                                        _vm.currencySymbol(
                                                          _vm.reservation
                                                            .currency_code
                                                        )
                                                      )
                                                    )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.active_history.customer_credit_used
                                          ? _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "\n                            Credit Used\n                          "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "-" +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.active_history
                                                          .customer_credit_used,
                                                        _vm.currencySymbol(
                                                          _vm.reservation
                                                            .currency_code
                                                        )
                                                      )
                                                    )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.active_history.points_used
                                          ? _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "\n                            Points Used\n                          "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.active_history
                                                      .points_used
                                                  ) + " Points"
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.active_history.points_money_value
                                          ? _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "\n                            Saved Using Points\n                          "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "-" +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.active_history
                                                          .points_money_value,
                                                        _vm.currencySymbol(
                                                          _vm.currency_code
                                                        )
                                                      )
                                                    )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Grand Total")]),
                                          _vm._v(" "),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.active_history
                                                    .grand_total,
                                                  _vm.currencySymbol(
                                                    _vm.reservation
                                                      .currency_code
                                                  )
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticClass: "padding-left-5x" },
                                            [_vm._v("Already Paid")]
                                          ),
                                          _vm._v(" "),
                                          _c("th", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.active_history
                                                    .aggrigate_amount,
                                                  _vm.currencySymbol(
                                                    _vm.reservation
                                                      .currency_code
                                                  )
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _vm.active_history.due_at_location > 0
                                          ? _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "padding-left-5x"
                                                },
                                                [_vm._v("Due At Location")]
                                              ),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.active_history
                                                        .due_at_location,
                                                      _vm.currencySymbol(
                                                        _vm.reservation
                                                          .currency_code
                                                      )
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("coupon", {
                                    on: { apply: _vm.onApplyCoupon }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { title: "History" } },
                    _vm._l(_vm.reservation.history, function(item, i) {
                      return _vm.reservation.history
                        ? _c("div", {}, [
                            _c("h4", { staticClass: "mt-2" }, [
                              _vm._v("\n            Created at "),
                              item.active == 1
                                ? _c("span", [_vm._v("(Current State)")])
                                : _vm._e(),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.created_at,
                                      "MM/DD/YYYY HH:mm"
                                    )
                                  ) +
                                  ".\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table table-bordered margin-top-2x"
                              },
                              [
                                _c("tbody", [
                                  _c("tr", [
                                    _c("th", [_vm._v("Contact Email")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(item.contact_email))
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("th", [_vm._v("Updated By")]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(item.updated_by))])
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("th", [_vm._v("From")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            item.from_date,
                                            "MM/DD/YYYY HH:mm"
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("th", [_vm._v("To")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            item.to_date,
                                            "MM/DD/YYYY HH:mm"
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("th", [_vm._v("Subtotal")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.subtotal,
                                            _vm.currencySymbol(
                                              _vm.reservation.currency_code
                                            )
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("th", [_vm._v("Total Fees")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.total_fees,
                                            _vm.currencySymbol(
                                              _vm.reservation.currency_code
                                            )
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("th", [_vm._v("Total Taxes")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.total_taxes,
                                            _vm.currencySymbol(
                                              _vm.reservation.currency_code
                                            )
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  item.trip_protection_price
                                    ? _c("tr", [
                                        _c("th", [
                                          _vm._v("Trip protection price")
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                item.trip_protection_price,
                                                _vm.currencySymbol(
                                                  _vm.reservation.currency_code
                                                )
                                              )
                                            )
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.total_discount
                                    ? _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            "\n                  Discount\n                "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "-" +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  item.total_discount,
                                                  _vm.currencySymbol(
                                                    _vm.reservation
                                                      .currency_code
                                                  )
                                                )
                                              )
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.customer_credit_used
                                    ? _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            "\n                  Credit Used\n                "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "-" +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  item.customer_credit_used,
                                                  _vm.currencySymbol(
                                                    _vm.reservation
                                                      .currency_code
                                                  )
                                                )
                                              )
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.points_used
                                    ? _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            "\n                  Points Used\n                "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(item.points_used))
                                        ])
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.points_money_value
                                    ? _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            "\n                  Saved Using Points\n                "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "-" +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  item.points_money_value,
                                                  _vm.currencySymbol(
                                                    _vm.reservation
                                                      .currency_code
                                                  )
                                                )
                                              )
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.partial_refund
                                    ? _c("tr", [
                                        _c("th", [
                                          _vm._v(
                                            "\n                  Partial Refund\n                "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "-" +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  item.partial_refund,
                                                  _vm.currencySymbol(
                                                    _vm.reservation
                                                      .currency_code
                                                  )
                                                )
                                              )
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("th", [_vm._v("Grand Total")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.grand_total,
                                            _vm.currencySymbol(
                                              _vm.reservation.currency_code
                                            )
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr")
                          ])
                        : _vm._e()
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    {
                      directives: [
                        {
                          name: "hasPermits",
                          rawName: "v-hasPermits:allow",
                          value: ["super_admin"],
                          expression: "['super_admin']",
                          arg: "allow"
                        }
                      ],
                      attrs: { title: "Emails" }
                    },
                    [
                      _vm.reservation.email_events
                        ? _c("div", {}, [
                            _c("h4", { staticClass: "mt-4 mb-3" }, [
                              _vm._v("Email events")
                            ]),
                            _vm._v(" "),
                            _vm.reservation.email_events
                              ? _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-striped margin-bottom-8x"
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", [_vm._v("Email")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Date")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Status")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Category")])
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.reservation.email_events,
                                        function(event, i) {
                                          return _c("tr", [
                                            _c("td", [
                                              _vm._v(_vm._s(event.email))
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.moment
                                                    .tz(
                                                      event.created_at,
                                                      "YYYY-MM-DD HH:mm:ss",
                                                      "UTC"
                                                    )
                                                    .tz(_vm.timezone)
                                                    .format("MM/DD/YYYY HH:mm")
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(_vm._s(event.event))
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(_vm._s(event.category))
                                            ])
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("hr")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    {
                      attrs: {
                        title: "Cancellation Failed",
                        permits: ["super_admin"]
                      }
                    },
                    _vm._l(_vm.reservation.failed_cancellation, function(item) {
                      return _vm.reservation.failed_cancellation
                        ? _c(
                            "table",
                            {
                              staticClass: "table table-bordered margin-top-2x"
                            },
                            [
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", [_vm._v("Canceled By")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.user))])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Ip Address")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.ip_address))])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Reason")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.reason))])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Status")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.status))])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Failed At")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.failed_at,
                                          "MM/DD/YYYY HH:mm"
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.should_show_update_modal
                ? _c("update-modal", {
                    ref: "updateModal",
                    attrs: {
                      cancel: _vm.is_cancel_request,
                      "form-data": _vm.form,
                      type_id: _vm.change_location
                        ? _vm.selected_type.id
                        : _vm.form.type_id,
                      currency_code: _vm.currency_code,
                      onClose: _vm.onCloseUpdateModal
                    },
                    on: {
                      "update:formData": function($event) {
                        _vm.form = $event
                      },
                      "update:form-data": function($event) {
                        _vm.form = $event
                      },
                      error: _vm.setError
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.should_show_partial_refund_modal
                ? _c("partial-refund", {
                    ref: "partialRefund",
                    attrs: {
                      data: _vm.active_history,
                      currency_code: _vm.currency_code
                    },
                    on: { close: _vm.onClosePartialRefundModal }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.should_show_revert_partial_refund_modal
                ? _c("revert-partial-refund", {
                    ref: "revertPartialRefund",
                    attrs: {
                      active_history: _vm.active_history,
                      reservation: _vm.reservation,
                      currency_code: _vm.currency_code
                    },
                    on: { close: _vm.onCloseRevertPartialRefundModal }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.should_show_customer_email_modal
                ? _c("customer-email", {
                    ref: "customerEmail",
                    attrs: { data: _vm.reservation },
                    on: { close: _vm.onCloseCustomerEmailModal }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("confirm", {
                ref: "removeCoupon",
                attrs: { type: "warning" }
              }),
              _vm._v(" "),
              _c("confirm", { ref: "revertCancel", attrs: { type: "danger" } }),
              _vm._v(" "),
              _c("confirm", {
                ref: "confirmDialog",
                attrs: { type: "danger" }
              }),
              _vm._v(" "),
              _c("revert-cancel", {
                ref: "revertCancelModal",
                attrs: {
                  currency_code: _vm.currency_code,
                  reservation: _vm.reservation
                }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-41010221", { render: render, staticRenderFns: staticRenderFns })
  }
}