import user from './user'

class Permits {

  hasPermit(permit, prevent = false) {
    let u = user.getUser();
    if (!u) return false;
    if (u.permissions.indexOf('super_admin') > -1 && !prevent)
      return true;

    return u.permissions.indexOf(permit.toLowerCase()) > -1;
  }

  //check if the user has at least one permission from the list
  hasPermits(arr = [], prevent = false) {
    for (let i in arr) {
      if (this.hasPermit(arr[i], prevent)) {
        return true;
      }
    }

    return false;
  }

  hasLocationPermit (locationId, permit, prevent = false) {
    if (this.hasPermit(permit, prevent)) {
      return true;
    }

    let u = user.getUser();

    let i = _.findIndex(u.locations, (item) => item.location_id == locationId);

    if (i < 0) return false

    return _.findIndex(u.locations[i].role.permissions, (item) => item.name.toLowerCase() == permit.toLowerCase()) > -1
  }

  hasLocationPermits (locationId, arr = [], prevent = false) {
    for (let i in arr) {
      if (this.hasLocationPermit(locationId, arr[i], prevent)) {
        return true;
      }
    }

    return false;
  }

  hasReportPermit (permit, prevent = false) {
    if (this.hasPermit(permit, prevent)) {
      return true;
    }

    let u = user.getUser();
    
    for(let i in u.locations) {
      if (_.findIndex(u.locations[i].role.permissions, (item) => (
        item.name.toLowerCase() == permit.toLowerCase()
      )) > -1) {
        return true
      }
    }

    if (u.type == 'affiliate') {
      for(let i in u.affiliates) {
        if (_.findIndex(u.affiliates[i].role.permissions, (item) => (
          item.name.toLowerCase() == permit.toLowerCase()
        )) > -1) {
          return true
        }
      }
    }

    return false
  }

  hasReportPermits (arr = [], prevent = false) {
    for (let i in arr) {
      if (this.hasReportPermit(arr[i], prevent)) {
        return true;
      }
    }

    return false;
  }
}

export default new Permits();
