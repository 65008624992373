import toastr from 'toastr'
import moment from 'moment'
import { currencySymbol } from 'filters'
import { funcModal } from 'components/modals'
import confirm from 'components/modals/confirm'
import paymentForm from '../paymentForm'
import { hasPermits } from 'directives'
import { stripeService } from 'services'

const directives = {
  hasPermits
}

const components = {
  paymentForm,
  funcModal,
  confirm,
}

const computed = {
  costs () {
    return _.cloneDeep(this.$store.state.reservations.type_costs[this.form.type_id]);
  },
  
  location () {
    return _.cloneDeep(this.$store.state.reservations.active.history[0].location)
  },

  activeHistory () {
    let reservation = _.cloneDeep(this.$store.state.reservations.active);
    if (!reservation) return {};
    return _.find(reservation.history, function (h) {return h.active == 1;});
  },

  is_reservation_started () {
    let from_date = this.$store.state.reservations.active.history[0].from_date;
    let timezone = this.location.port.timezone.code;
    let now = moment().tz(timezone);
    let date = moment.tz(from_date, "YYYY-MM-DD HH:mm:ss", timezone);
    return now.isAfter(date);
  }
}

const methods = {
  open () {
    this.$refs.modal.open();
  },

  submit ({form, elements, stripe}) {
    this.errors = {}
    this.processing = true;

    this.form = _.assign(this.form, form);
    if (!this.form.click_to_pay) {
      stripe.createPaymentMethod({type: 'card', card: elements.card_element}).then((result) => {
        this.payment = result;
        if (result.error) {
            if (result.error.code == 'incomplete_expiry') {
                this.errors = Object.assign({}, this.errors, {expiration: [result.error.message]})
            } else if (result.error.code == 'incomplete_cvc') {
                this.errors = Object.assign({}, this.errors, {cvc: [result.error.message]})
            } else {
                this.errors = Object.assign({}, this.errors, {card_number: [result.error.message]})
            }

            this.processing = false;
            return;
        }
        this.form.payment_method_id = result.paymentMethod.id
        this.form.card_number = result.paymentMethod.card.last4
        this.form.expiry_month = result.paymentMethod.card.exp_month
        this.form.expiry_year = result.paymentMethod.card.exp_year
        this.form.brand = result.paymentMethod.card.brand
        this.form.wallet = result.paymentMethod.card.wallet ? result.paymentMethod.card.wallet.type : null
        this.sendData();
      });
    } else {
      this.sendData();
    }
  },

 updateReservation (data) {
    this.$store.dispatch('reservations.update', {
      reservation_id: this.$route.params.id,
      data
    }).then(async (response) => {
      let errors = this.$store.state.reservations.errors;
      if (errors) {
        if (errors.status_code == 400) {
            toastr.error(this.$store.state.reservations.errors.message);
        } else {
            toastr.error(this.$store.state.reservations.errors.message);
            this.errors = _.cloneDeep(errors.errors) || {};
        }
        this.processing = false;
      } else {
        var succeeded = response.data.succeeded;
        
        if(succeeded) {
          this.processing = false;
          let reslabFailedMessage = response.data.reslab_failed_message ? response.data.reslab_failed_message : false;
          toastr.success('Data has been saved');
          if (reslabFailedMessage) {
            this.$refs.warningConfirm.confirm(reslabFailedMessage, () => {
              this.$refs.modal.close();
              this.$router.go();
            }, null, false)
          } else {
            this.$refs.modal.close();
            this.$router.go();
          }
            
        }
        else {
          var clientSecret = response.data.client_secret;
          var requiresAction = response.data.requires_action;  

          if (requiresAction) {
              //3d secure required from stored card
              if(!this.stripe) {
                  const { stripe, elements } = await stripeService.init(this.currency_code);
                  this.stripe = stripe;
              }
              const { error: errorAction, paymentIntent } =  await this.stripe.handleCardAction(clientSecret);                        
              if (errorAction) {
                  this.processing = false;
                  toastr.error(errorAction.message);
              } else {
                data.payment_intent_id = paymentIntent.id;
                this.updateReservation (data)
                return;  
              }
          } else {
            toastr.error('Payment Error, Please use new card.');
          }

        }
      }
    })
 },

  sendData () {
    if (this.cancel) {
      this.sendCancelRequest();
      return;
    }

    this.errors = {}
    this.processing = true;
    let data = {
      payment_method_id: this.form.payment_method_id,
      customer_name: this.form.customer_name,
      zipcode: this.form.zipcode,
      number_of_spots: this.form.number_of_spots,
      phone: this.form.phone,
      contact_email: this.form.contact_email,
      click_to_pay: this.form.click_to_pay,
      external_payment_link: this.form.externalPaymentLink,
      location_id: this.form.location_id,
      type_id: this.form.type_id,
      extra_fields: this.form.extra_fields,
      trip_protection_id: this.form.trip_protection_id,
      refund_type: this.form.refund_type,
      amount: this.form.amount,
      reason: this.form.reason,
      from_date: this.form.from_date,
      to_date: this.form.to_date,
      coupon_code: this.form.coupon_code,
      points_used: this.form.points_used
    }

    if (this.use_new_card) {
      data.card_number = this.form.card_number;
      data.expiry_month = this.form.expiry_month;
      data.expiry_year = this.form.expiry_year;
      data.card_id = this.form.card_id;
      data.brand = this.form.brand;
      data.wallet = this.form.wallet;
    }

    if (this.use_new_card && this.form.externalPaymentLink) {
      data.number_of_days = this.costs.number_of_days;
      data.amount_to_pay = this.costs.amount_to_pay;
      data.grand_total = this.costs.grand_total;
      data.points_earned = this.costs.points_earned;
      data.subtotal = this.costs.subtotal;
      data.fees = this.costs.fees;
      data.trip_protection_price = this.costs.trip_protection_price;
      data.total_discount = this.costs.total_discount;
      data.points_money_value = this.costs.points_money_value;
      data.total_taxes = this.costs.total_taxes;

      this.$store.dispatch('reservations.updateReservationLink', {
        reservation_id: this.$route.params.id,
        data
      }).then((res) => {
        this.processing = false;
        let errors = this.$store.state.reservations.errors;
        if (errors) {
          toastr.error(this.$store.state.reservations.errors.message);
          this.errors = _.cloneDeep(errors.errors);
          this.$emit('error', this.errors);
        } else {
          toastr.success("A payment link has been sent to the customer's email");
          this.$refs.modal.close();
          this.$router.go();          
        }
      })
    }

    if (!(this.use_new_card && this.form.externalPaymentLink)) {
      this.updateReservation(data);
    }
  },

  formatDatesToSend () {
    let dates = {
      from_date: null,
      to_date: null
    };

    if (this.form.from_date) {
      dates.from_date = moment(this.form.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    if (this.form.to_date) {
      dates.to_date = moment(this.form.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    if (this.form.from_time) {
      dates.from_date = dates.from_date + ' ' + this.form.from_time + ":00"
    }

    if (this.form.to_time) {
      dates.to_date = dates.to_date + ' ' + this.form.to_time + ":00"
    }

    return dates
  },

  getChargeOrRefundList () {
    this.form = _.assign(this.form, this.formData);
    this.form = _.assign(this.form, this.formatDatesToSend());
    this.form.type_id = this.type_id;
    this.loading = true;
    this.$store.dispatch('reservations.getChargeOrRefundList', {
      reservation_id: this.$route.params.id,
      data: this.form
    })
    .then(() => {
      this.charge_cards = this.$store.state.reservations.charge_cards;
      this.refund_cards = this.$store.state.reservations.refund_cards;
      this.loading = false;
    });
  },

  sendCancelRequest () {
    this.processing = true;
    this.$store.dispatch('reservations.cancel',{
      id: this.$route.params.id, 
      refund_trip_protection: this.form.refund_trip_protection,
      refund_type: this.form.refund_type,
      amount: this.form.amount,
      reason: this.form.reason
    })
    .then((res) => {
      this.processing = false

      if (this.$store.state.reservations.errors) {
        let errors = this.$store.state.reservations.errors;
        if (!_.isEmpty(errors.errors)) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        let reslabFailedMessage = res.data.reslab_failed_message ? res.data.reslab_failed_message : false;
        toastr.success('Reservation has been cancelled');
        if (reslabFailedMessage) {
          this.$refs.warningConfirm.confirm(reslabFailedMessage, () => {
            this.$refs.modal.close();
          }, null, false)
        } else {
          this.$refs.modal.close();
        }
      }
    })
  }
}

export default {
  name: "update-reservation-modal",
  props: ['cancel', 'formData', 'type_id', 'currency_code', 'onClose'],
  components,
  computed,
  directives,
  methods,
  data () {
    return {
      errors: {},
      loading: false,
      processing: false,
      use_new_card: false,
      charge_cards: null,
      refund_cards: [],
      form: {
        card_number: null,
        expiry_month: null,
        expiry_year: null,
        card_id: null,
        brand: null,
        card_token: null,
        three_d_secure: null,
        cancel: this.cancel,
        type_id: null,
        refund_trip_protection: false,
        refund_type: 'without_refund',
        amount: "",
        reason: "",
        coupon_code: null,
        points_used: null
      },
      avaliable_amount: '',
      currencySymbol
    }
  },
  created () {
    this.getChargeOrRefundList();
  },

  mounted () {
    if (this.activeHistory) this.avaliable_amount = this.activeHistory.aggrigate_amount + this.activeHistory.points_money_value + this.activeHistory.customer_credit_used;
  }
}
