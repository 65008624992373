import { http } from 'services'
import env from 'constants/env'

class StripeService {
  init (currencyCode = 'USD') {
    return new Promise(resolve => {
      if (isServer) resolve({stripe: {}, elements: {}})
      let stripeKey = currencyCode == 'CAD'? env.default.tripTeoStripeKey : env.default.stripeKey;
      let script = document.createElement('script');
      let head = document.getElementsByTagName('head')[0];
      script.src = 'https://js.stripe.com/v3/';
      script.async = true;
      script.onload = () => {
        let stripe = Stripe(stripeKey)
        let elements = stripe.elements()
        resolve({stripe, elements})
      }
      head.appendChild(script);
    });
  }
}

export default new StripeService()
