import layout from 'layouts/default'
import confirm from 'components/modals/confirm'
import newEmployee from './newEmployeeModal'
import editEmployee from './editEmployeeModal'
import tabs from '../tabs'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  tabs,
  newEmployee,
  editEmployee,
  confirm,
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  }
}

const methods = {
  openEditModal(emp) {
    this.selectedUser = emp;
    this.shouldShowEditModal = true;
    this.$nextTick(() => {
      this.$refs.editModal.open();
    })
  },

  onCloseEditModal () {
    this.shouldShowEditModal = false;
  },

  openNewEmployeeModal () {
    this.$refs.newModal.open();
  },
  removeEmployee (emp) {
    this.$refs.removeConfirm.confirm("Are you sure?", () => {
      this.delete_loadings[emp.id] = true;
      this.delete_loadings = _.cloneDeep(this.delete_loadings);

      this.$store.dispatch('locations.removeEmployee', {
        locId: this.$route.params.id,
        id: emp.id
      }).then(() => {
        this.delete_loadings[emp.id] = false;
        let errors = this.$store.state.locations.errors;
        if (errors) {
          if (errors.status_code == 422) {
            this.errors = _.cloneDeep(errors.errors);
            toastr.error('Check the errors below');
          } else {
            toastr.error(errors.message);
          }
        }
      })
    })
  }
}

export default {
  name: 'employees-tab',
  computed,
  directives,
  methods,
  components,
  data () {
    return {
      delete_loadings: {},
      selectedUser: {},
      loading: false,
      shouldShowEditModal: false
    }
  },

  created () {
    this.$store.dispatch('address.getCountries');

    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.getEmployees', this.$route.params.id),
    ])
    .then(() => {
      this.loading = false;
    });
  }
}
