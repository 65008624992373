import layout from 'layouts/default'
import { hasPermits } from 'directives'
import closeableNote from 'components/closeableNote'

const directives = {
  hasPermits
}

const components = {
  layout,
  closeableNote
}

export default {
  name: 'main-page',
  components,
  directives,
  data () {
    return {
    }
  },
  mounted () {
    if (this.$route.query.source && this.$route.query.client_secret) {
      if (window.parent) {
        window.parent.setSource(this.$route.query.source);
      } else {
        toastr.error('Could not reach parent window');
      }
    }
  }
}
