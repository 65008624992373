import moment from 'moment'
import env from 'constants/env'
import datatable from 'components/datatable'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.show_table = true;
        this.$nextTick(() => {
            this.$refs.table.run();
        })
    }
}

let computed = {
    user () {
        return this.$store.state.user.data;
    }
}

export default {
    name: "arrivals-report",
    methods,
    components,
    computed,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/credits',
        columns: []
    }),
    mounted () {
        this.columns = [
            {label: "Customer Name", attribute: (data) => (
                data.customer.first_name + " " + data.customer.last_name
            ),sortable: true, sort_key: "customer_name"},
            {label: "Credit Issue Date", attribute: (data) => (
                moment(data.created_at, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "created_at"},
            {label: "Expiry Date", attribute: (data) => (
                data.expiry_date ? moment(data.expiry_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY') : "-"
            ),sortable: true, sort_key: "expiry_date"},
            {label: "Date Used", attribute: (data) => (
                data.used_date ? moment(data.used_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY') : "-"
            ),sortable: true, sort_key: "used_date"},
            {label: "Credit Issued", attribute: (data) => (
                this.$options.filters.currency(data.amount)
            ),sortable: true, sort_key: "amount"},
            {label: "Credit Used", attribute: (data) => (
                this.$options.filters.currency(data.transaction_amount)
            ),sortable: true, sort_key: "transaction_amount"},
            {label: "Remaining Credit", attribute: (data) => (
                this.$options.filters.currency(data.amount - data.accumulative_used_amount) || '0'
            )},
        ]
    }
}