import toastr from 'toastr'
import layout from 'layouts/default'
import pagination from 'components/pagination'
import confirm from 'components/modals/confirm'
import { hasPermits } from 'directives'
import { dateFormat } from 'filters'
import { exportReport } from 'components/modals'
import env from 'constants/env'


const filters = {
  dateFormat
}

const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  confirm,
  exportReport,
}

const computed = {
  coupons () {
    return _.cloneDeep(this.$store.state.coupons.all);
  }
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    let data = {page, _sort: '-id'};
    data = _.assign(data, this.filters);

    this.loading = true;
    data._get = this.filter

    this.$store.dispatch('coupons.getAll', data).then(() => {
      this.loading = false;
    });
  },

  exportReport (ext) {
    let filters = _.cloneDeep(this.filters);
    let url = env.default.api + 'reports/coupons' + '?ext=' + ext;
    let params = '';
    _.each(filters, function (value, key) {
        if (value)
          params += '&' + (key + '=' + value);
    });

    url += params;

    this.$refs.export.open(url, ext);
  },

  removeCoupon (item) {
    this.$refs.confirmDelete.confirm(
    'are you sure that you want to delete this coupon?',
    () => {
      this.$set(this.loadings, item.id, true);

      this.$store.dispatch('coupons.remove', item.id).then(() => {
        this.loadings[item.id] = false;
        console.log('this.$store.state.coupons.errors', this.$store.state.coupons.errors);
        let errors;
        if (errors = this.$store.state.coupons.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },

  undoRemove (item) {
    this.$refs.confirmUndoDelete.confirm(
    'Are you sure that you want to undelete this coupon?',
    () => {
      this.$set(this.loadings, item.id, true);

      this.$store.dispatch('coupons.undoRemove', item.id).then(() => {
        this.loadings[item.id] = false;
        console.log('this.$store.state.coupons.errors', this.$store.state.coupons.errors);
        let errors;
        if (errors = this.$store.state.coupons.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },

}

export default {
  name: 'all-coupons',
  components,
  computed,
  methods,
  directives,
  filters,
  data () {
    return {
      loading: false,
      processing: false,
      loadings: {},
      filter: 'active',
      filters: {
        code: null,
        group: null
      }
    }
  },
  created () {
    this.loadPage(this.$route.query.page || 1);
  }
}
