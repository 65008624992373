var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table", { staticClass: "table table-striped" }, [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("Res. No.")]),
            _vm._v(" "),
            _c("th", [_vm._v("Customer Name")]),
            _vm._v(" "),
            _c("th", [_vm._v("Contact Email")]),
            _vm._v(" "),
            _c("th", [_vm._v("Creation Date")]),
            _vm._v(" "),
            _c("th", [_vm._v("From")]),
            _vm._v(" "),
            _c("th", [_vm._v("To")]),
            _vm._v(" "),
            _c("th", [_vm._v("Grand Total")]),
            _vm._v(" "),
            _c("th", [_vm._v("Cancelled")]),
            _vm._v(" "),
            _c(
              "th",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["super_admin"],
                    expression: "['super_admin']",
                    arg: "allow"
                  }
                ]
              },
              [_vm._v("Captured")]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["super_admin"],
                    expression: "['super_admin']",
                    arg: "allow"
                  }
                ]
              },
              [_vm._v("Must Capture Before")]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["reservation_show"],
                    expression: "['reservation_show']",
                    arg: "allow"
                  }
                ]
              },
              [_vm._v("View")]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["reservation_update"],
                    expression: "['reservation_update']",
                    arg: "allow"
                  }
                ]
              },
              [_vm._v("Edit")]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["reservation_list"],
                    expression: "['reservation_list']",
                    arg: "allow"
                  }
                ]
              },
              [_vm._v("Resend Email")]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["super_admin"],
                    expression: "['super_admin']",
                    arg: "allow"
                  }
                ]
              },
              [_vm._v("Capture")]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.reservations.data, function(reservation) {
            return _c("tr", [
              _c("td", [_vm._v(_vm._s(reservation.reservation_number))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(reservation.history[0].customer_name))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(reservation.history[0].contact_email))]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("dateFormat")(reservation.created_at, "MM/DD/YYYY")
                  )
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("dateFormat")(
                      reservation.history[0].from_date,
                      "MM/DD/YYYY HH:mm"
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("dateFormat")(
                      reservation.history[0].to_date,
                      "MM/DD/YYYY HH:mm"
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(
                      reservation.history[0].grand_total,
                      _vm.currencySymbol(reservation.currency_code)
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(reservation.cancelled ? "Yes" : "No"))]),
              _vm._v(" "),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["super_admin"],
                      expression: "['super_admin']",
                      arg: "allow"
                    }
                  ],
                  style:
                    !reservation.captured_at &&
                    !reservation.cancelled &&
                    _vm.timeBeforeCaptureInMs(reservation) <= 172800000
                      ? { color: "red", fontWeight: "bold" }
                      : {}
                },
                [
                  _vm._v(
                    _vm._s(
                      reservation.captured_at
                        ? "Yes (" + reservation.captured_at + ")"
                        : "No"
                    )
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["super_admin"],
                      expression: "['super_admin']",
                      arg: "allow"
                    }
                  ],
                  style:
                    !reservation.captured_at &&
                    !reservation.cancelled &&
                    _vm.timeBeforeCaptureInMs(reservation) <= 172800000
                      ? { color: "red", fontWeight: "bold" }
                      : {}
                },
                [_vm._v(_vm._s(reservation.capture_before))]
              ),
              _vm._v(" "),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["reservation_show"],
                      expression: "['reservation_show']",
                      arg: "allow"
                    }
                  ]
                },
                [
                  _vm.view_loadings[reservation.id]
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spinner fa-spin" })
                      ])
                    : _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.showReservationDetails(reservation.id)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-eye",
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      )
                ]
              ),
              _vm._v(" "),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["reservation_update"],
                      expression: "['reservation_update']",
                      arg: "allow"
                    }
                  ]
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "reservations.edit",
                          params: { id: reservation.id }
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-pencil-square-o",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["reservation_list"],
                      expression: "['reservation_list']",
                      arg: "allow"
                    }
                  ]
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openSendToEmailModal(
                            reservation.id,
                            reservation.owned_by
                          )
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-refresh",
                        attrs: { "aria-hidden": "true" }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "td",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["super_admin"],
                      expression: "['super_admin']",
                      arg: "allow"
                    }
                  ]
                },
                [
                  _vm.capture_loadings[reservation.id]
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spinner fa-spin" })
                      ])
                    : !reservation.captured_at &&
                      !reservation.cancelled &&
                      _vm.timeBeforeCaptureInMs(reservation) > 0
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.captureReservation(reservation.id)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-check",
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("pagination", {
        attrs: { "pages-number": _vm.reservations.last_page },
        on: { pageChanged: _vm.loadPage }
      }),
      _vm._v(" "),
      _c("view-reservation", { ref: "view" }),
      _vm._v(" "),
      _c("send-to-email", { ref: "sendToEmail" }),
      _vm._v(" "),
      _c("send-to-fax", { ref: "sendToFax" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c9645b14", { render: render, staticRenderFns: staticRenderFns })
  }
}