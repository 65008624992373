import layout from 'layouts/default'
import {tab, tabs} from 'components/tabs'
import general from './general'
import reservations from './reservations'
import credits from './credits'
import points from './points'
import confirm from 'components/modals/confirm'
import toastr from 'toastr'

const components = {
  layout, tabs, tab, general, reservations, credits, points, confirm
}

const methods = {
  activeCustomer () {
    this.$refs.confirmDialog.confirm("Are you sure that you want to Active this customer?", () => {
      this.activating = true;
      this.$store.dispatch('customers.confirmation', this.$route.params.id)
      .then(() => {
        this.activating = false;
        let errors = this.$store.state.customers.errors;
        if (errors) {
          if (errors.errors) {
            this.errors = _.cloneDeep(errors.errors);
          }
          toastr.error(errors.message);
        } else {
          this.customer = _.cloneDeep(this.$store.state.customers.active);
          toastr.success('Customer activated successfully');
        }
        
      });
    });
  },

  resendActiveCode () {
    
    this.$refs.confirmDialog.confirm("Are you sure that you want to resend activation code?", () => {
      this.sending = true;
      this.$store.dispatch('customers.resendActivationCode', this.$route.params.id)
      .then(() => {
        this.sending = false;
        let errors = this.$store.state.customers.errors;
        if (errors) {
          if (errors.errors) {
            this.errors = _.cloneDeep(errors.errors);
          }
          toastr.error(errors.message);
        } else {
          toastr.success('Activation code sent');
        }
        
      });
    });
  },

  onChangeTab (title) {
    this.active_tab = title;
  }
}

export default {
  name: "edit-customer",
  components,
  methods,
  data () {
    return {
      loading: true,
      activating: false,
      sending: false,
      customer: null,
      active_tab: null
    }
  },

  created () {
    this.$store.dispatch('customers.getCustomer', this.$route.params.id)
    .then(() => {
      this.loading = false;
      this.customer = _.cloneDeep(this.$store.state.customers.active);
    });
  }
}