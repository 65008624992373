import { http } from 'services'
// initial state
const state = () => ({
  all: {},
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {

  ['referrals.getReferrals'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._get) params._get = null

    commit('CLEAR_ERRORS')

    return (http.get('referrals', {
      params
    })).then ((res) => {
      commit('GET_REFERRALS', res);
    }).catch ((res) => {
      commit('REFERRALS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_REFERRALS (state, res) {
    state.all = res.data;
  },

  //ERRORS
  REFERRALS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
