var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("h3", { staticClass: "mb-2" }, [_vm._v("New Role")]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c("div", { staticClass: "card mb-4" }, [
          _c("div", { staticClass: "card-block" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-sm-8 " },
                [
                  _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.processing },
                        attrs: { disabled: _vm.processing },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit($event)
                          }
                        }
                      },
                      [
                        _vm.processing
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-spinner fa-spin fa-fw"
                              }),
                              _vm._v(
                                "\n                SAVING...\n              "
                              )
                            ])
                          : _c("span", [_vm._v("SAVE")])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-bottom-3x" }, [
                    _c("label", [_vm._v("Role Name")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.name,
                          expression: "form.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "name", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.name
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.name[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-bottom-3x" }, [
                    _c("label", [_vm._v("Role Display Name")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.display_name,
                          expression: "form.display_name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.display_name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "display_name",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.display_name
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.display_name[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-bottom-3x" }, [
                    _c("label", [_vm._v("Role Description")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.description,
                          expression: "form.description"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.description },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "description", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.description
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.description[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-bottom-4x" }, [
                    _c("label", [_vm._v("Role Type: ")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio" }, [
                      _c("label", { staticClass: "margin-right-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.type,
                              expression: "form.type"
                            }
                          ],
                          attrs: { type: "radio", value: "admin" },
                          domProps: { checked: _vm._q(_vm.form.type, "admin") },
                          on: {
                            change: [
                              function($event) {
                                return _vm.$set(_vm.form, "type", "admin")
                              },
                              function($event) {
                                return _vm.filterPermissions(true)
                              }
                            ]
                          }
                        }),
                        _vm._v("\n                Admin Role\n              ")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-right-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.type,
                              expression: "form.type"
                            }
                          ],
                          attrs: { type: "radio", value: "location" },
                          domProps: {
                            checked: _vm._q(_vm.form.type, "location")
                          },
                          on: {
                            change: [
                              function($event) {
                                return _vm.$set(_vm.form, "type", "location")
                              },
                              function($event) {
                                return _vm.filterPermissions(false)
                              }
                            ]
                          }
                        }),
                        _vm._v(
                          "\n                Location Role\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("label", {}, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.type,
                              expression: "form.type"
                            }
                          ],
                          attrs: { type: "radio", value: "affiliate" },
                          domProps: {
                            checked: _vm._q(_vm.form.type, "affiliate")
                          },
                          on: {
                            change: [
                              function($event) {
                                return _vm.$set(_vm.form, "type", "affiliate")
                              },
                              function($event) {
                                return _vm.filterPermissions(false, true)
                              }
                            ]
                          }
                        }),
                        _vm._v(
                          "\n                Affiliate Role\n              "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.errors.type
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.type[0]) +
                              "\n            "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", {}, [_c("label", [_vm._v("Permissions:")])]),
                  _vm._v(" "),
                  _vm.errors.permissions
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.permissions[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(
                    Math.ceil(_vm._.keys(_vm.permissions).length / 2),
                    function(i) {
                      return _c(
                        "ul",
                        { staticClass: "row no-padding-all checkbox" },
                        _vm._l(
                          _vm._.keys(_vm.permissions).slice((i - 1) * 2, i * 2),
                          function(category, index) {
                            return _c(
                              "li",
                              { staticClass: "col-sm-6 no-bullet mb-5" },
                              [
                                _c("h5", { staticClass: "mb-2" }, [
                                  _vm._v(
                                    _vm._s(_vm._f("capitalize")(category)) + " "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  { staticClass: "no-bullet d-flex flex-wrap" },
                                  _vm._l(_vm.permissions[category], function(
                                    permit
                                  ) {
                                    return _c(
                                      "li",
                                      { staticClass: "w-50 f14" },
                                      [
                                        _c("label", { staticClass: "mr-2" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.form.permissions,
                                                expression: "form.permissions"
                                              }
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              value: permit.id,
                                              checked: Array.isArray(
                                                _vm.form.permissions
                                              )
                                                ? _vm._i(
                                                    _vm.form.permissions,
                                                    permit.id
                                                  ) > -1
                                                : _vm.form.permissions
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a = _vm.form.permissions,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = permit.id,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.form,
                                                        "permissions",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.form,
                                                        "permissions",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "permissions",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(permit.display_name)
                                          ),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c("small", [
                                            _vm._v(_vm._s(permit.description))
                                          ])
                                        ])
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          }
                        ),
                        0
                      )
                    }
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.processing },
                        attrs: { disabled: _vm.processing },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit($event)
                          }
                        }
                      },
                      [
                        _vm.processing
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-spinner fa-spin fa-fw"
                              }),
                              _vm._v(
                                "\n                SAVING...\n              "
                              )
                            ])
                          : _c("span", [_vm._v("SAVE")])
                      ]
                    )
                  ])
                ],
                2
              )
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-66b505d6", { render: render, staticRenderFns: staticRenderFns })
  }
}