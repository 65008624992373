class User {

  setUser (user) {
    if (user) {
      user.permissions = [];
      if (user.role) {
        user.permissions = user.role.permissions.map((perm) => {
          return perm.name.toLowerCase()
        });
      }

      if (!_.isEmpty(user.locations)) {
        user.permissions.push('location_user');
      }

    }

    sessionStorage.setItem("__user__", JSON.stringify(user));
  }

  getUser () {
    let user = sessionStorage.getItem("__user__");
    console.log('user set');
    if(user == "null") return null;
    return JSON.parse(user);
  }

  setToken (token) {
    sessionStorage.setItem("__token__", token);
  }

  getToken () {
    let token = sessionStorage.getItem("__token__");

    if(token == "null") return null;
    return token;
  }
}

export default isServer ? {
  setUser () {

  },
  getUser () {
    return null
  },
  setToken () {

  },
  getToken () {
    return null
  },
} : new User()
