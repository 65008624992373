import toastr from 'toastr'
import layout from 'layouts/default'
import sSelect from 'components/sSelect'

const directives = {
}

const components = {
  layout,
  sSelect
}

const methods = {
  onChangeAirport (port) {
    this.selectedAirport = port;
    this.formData.airport_id = port.value;
  },

  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;

    this.$store.dispatch('locations.add', _.cloneDeep(this.formData)).then(() => {
      this.submit_loading = false;
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors) || {};
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('A new location has been created');

        this.formData = {
          airport_id: null,
          name: null,
          phone: null,
          address: null,
          number_of_rooms: null,
          number_of_parkings: null,
          country_id: null,
          state_id: null,
          currency_id: null,
          zipcode: null
        }

        this.$router.push({name: 'locations.information', params: {
          id: this.$store.state.locations.active.id
        }});
      }
    })
  }
}

const computed = {
  countries () {
    return [{name: "Select Country", id: null}].concat(this.$store.state.address.countries);
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.formData.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.formData.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }

    return states;
  },
  currencies () {
    let currencies = [{name: "Select Currency", id: null}];
    currencies = currencies.concat(this.$store.state.currencies.all.map(item => {
      return { id: item.id, name: item.name + " - " + item.code }
    }));

    return currencies;
  },

  timezones () {
    return [{name: "Select Timezone", id: null}].concat(this.$store.state.timezones.all);
  },
}

export default {
  name: 'new-location',
  components,
  methods,
  computed,
  directives,
  data () {
    let ports = [{text: "-- Select Airport --", value: null}];
    return {
      loading: true,
      submit_loading: false,
      errors: {},
      ports,
      selectedAirport: ports[0],
      formData: {
        location_type: 'osp',
        airport_id: null,
        name: null,
        phone: null,
        address: null,
        number_of_rooms: null,
        number_of_parkings: null,
        country_id: null,
        state_id: null,
        timezone_id: null,
        currency_id: null,
        zipcode: null
      }
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('ports.getAll', {_all: true}),
      this.$store.dispatch('address.getCountries'),
      this.$store.dispatch('currencies.getAll'),
      this.$store.dispatch('timezones.getAll')
    ]).then(() => {
      this.ports = this.ports.concat(this.$store.state.ports.all.map(item => ({text: item.name, value: item.id})));

      this.loading = false;
    });
  }
}
