var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "table-responsive" }, [
            _c("table", { class: _vm.tableClass }, [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.columns, function(column) {
                    return column.visible
                      ? _c(
                          "th",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: column.permits,
                                expression: "column.permits",
                                arg: "allow"
                              }
                            ],
                            key: column.label + Math.random() * 10000,
                            on: {
                              click: function($event) {
                                return _vm._sort(
                                  column.sort_key,
                                  column.sortable
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(column.label) +
                                "\n          "
                            ),
                            column.sortable
                              ? _c("span", {
                                  staticClass: "fa fa-sort-alpha-asc",
                                  class: {
                                    "fa-sort-alpha-asc":
                                      _vm.sort_by === column.sort_key &&
                                      _vm.sort_dir === "asc",
                                    "fa-sort-alpha-desc":
                                      _vm.sort_by === column.sort_key &&
                                      _vm.sort_dir === "desc"
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.rows, function(row) {
                  return _c(
                    "tr",
                    _vm._l(_vm.columns, function(column) {
                      return column.visible
                        ? _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: column.permits,
                                  expression: "column.permits",
                                  arg: "allow"
                                }
                              ],
                              key: column.label + Math.random() * 10000,
                              class: column.classes,
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return column.click(row)
                                },
                                hover: function($event) {
                                  $event.preventDefault()
                                  return column.hover(row)
                                }
                              }
                            },
                            [
                              _vm._.isFunction(column.attribute)
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        column.attribute(row, _vm.$parent)
                                      )
                                    }
                                  })
                                : _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.objectPath.get(
                                          row,
                                          column.attribute
                                        )
                                      )
                                    )
                                  ])
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                }),
                0
              )
            ])
          ]),
      _vm._v(" "),
      _vm.last_page > 1
        ? _c("pagination", {
            attrs: { "pages-number": _vm.last_page },
            on: {
              pageChanged: function(page) {
                return _vm.getDataFromAjax(page)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7782dff2", { render: render, staticRenderFns: staticRenderFns })
  }
}