var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("h3", { staticClass: "mb-2" }, [_vm._v("Edit Customer")]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c(
          "div",
          [
            _vm.customer && !_vm.customer.confirmed
              ? _c("div", { staticClass: "text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.resendActiveCode($event)
                        }
                      }
                    },
                    [
                      _vm.sending
                        ? _c("span", [
                            _c("i", {
                              staticClass: "fa fa-spinner fa-spin fa-fw"
                            }),
                            _vm._v("\n              SENDING...\n          ")
                          ])
                        : _c("span", [_vm._v("RESEND ACTIVATION CODE")])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.activeCustomer($event)
                        }
                      }
                    },
                    [
                      _vm.activating
                        ? _c("span", [
                            _c("i", {
                              staticClass: "fa fa-spinner fa-spin fa-fw"
                            }),
                            _vm._v("\n              ACTIVATING...\n          ")
                          ])
                        : _c("span", [_vm._v("ACTIVATE")])
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "tabs",
              { on: { tabChange: _vm.onChangeTab } },
              [
                _c(
                  "tab",
                  { attrs: { title: "General" } },
                  [_c("general", { attrs: { customer: _vm.customer } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "tab",
                  { attrs: { title: "Reservations" } },
                  [
                    _c("reservations", { attrs: { activeTab: _vm.active_tab } })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "tab",
                  {
                    attrs: {
                      title: "Credits",
                      permits: ["CUSTOMER_CREDIT_CREATE"]
                    }
                  },
                  [_c("credits")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "tab",
                  {
                    attrs: {
                      title: "Rewards",
                      permits: ["CUSTOMER_POINTS_LIST"]
                    }
                  },
                  [_c("points", { attrs: { activeTab: _vm.active_tab } })],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("confirm", { ref: "confirmDialog", attrs: { type: "warning" } })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-78f7dcea", { render: render, staticRenderFns: staticRenderFns })
  }
}