import { http } from 'services'
// initial state
const state = () => ({
  all: [],
  active: {
    reservations: {},
    points: [],
    credits: [],
  },
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['customers.getAll'] ({ commit }, params) {
    if (!params._q) params._q = null

    commit('CLEAR_ERRORS')

    return (http.get('customer', {
      params
    })).then ((res) => {
      commit('GET_ALL_CUSTOMERS', res);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },

  ['customers.editCustomer'] ({ commit }, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put('customer/' + id , data))
    .then ((res) => {
      commit('EDIT_CUSTOMER', res);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },

  ['customers.confirmation'] ({ commit }, id) {
    commit('CLEAR_ERRORS')

    return (http.put(`customer/${id}/activate`))
    .then ((res) => {
      commit('EDIT_CUSTOMER', res);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },

  ['customers.resendActivationCode'] ({ commit }, id) {
    commit('CLEAR_ERRORS')

    return (http.post(`customer/${id}/resend-activation-code`))
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },

  ['customers.removeCustomer'] ({ commit }, {id, params}) {
    commit('CLEAR_ERRORS')

    return (http.delete('customer/' + id, {params}))
    .then ((res) => {
      commit('REMOVE_CUSTOMER', id);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },

  ['customers.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`customer/${id}?_restore=true`)).then ((res) => {
      commit('REMOVE_CUSTOMER', id);
    }).catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },

  ['customers.getCustomer'] ({ commit }, id) {
    commit('CLEAR_ERRORS')
    return (http.get('customer/' + id))
      .then ((res) => {
        commit('GET_CUSTOMER', res);
      })
      .catch ((res) => {
        commit('CUSTOMERS_ERROR', res);
      })
  },
  
  ['customers.getCustomerReservations'] ({ commit }, {id, page}) {
    commit('CLEAR_ERRORS')
    return (http.get('customer/' + id + '/reservations?page=' + page))
      .then ((res) => {
        commit('GET_CUSTOMER_RESERVATIONS', res);
      })
      .catch ((res) => {
        commit('CUSTOMERS_ERROR', res);
      })
  },

  ['customers.getCustomerPoints'] ({ commit }, {id, params}) {
    commit('CLEAR_ERRORS')
    return (http.get('customer/' + id + '/points', {params}))
      .then ((res) => {
        commit('GET_CUSTOMER_POINTS', res);
      })
      .catch ((res) => {
        commit('CUSTOMERS_ERROR', res);
      })
  },

  ['customers.addPoints'] ({ commit, dispatch }, {id, data}) {
    commit('CLEAR_ERRORS')
    return new Promise((resolve, reject) => {
      http.post(`customer/${id}/points`, data).then(() => {
        dispatch('customers.getCustomer', id).then(() => {
          dispatch('customers.getCustomerPoints', {id, params: {_all: true}})
          .then(resolve)
          .catch(reject)
        })
      })
      .catch(reject)
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },

  ['customers.revokePoints'] ({ commit }, {customer_id, points_id}) {
    commit('CLEAR_ERRORS')

    return (http.delete('customer/' + customer_id + '/points/' + points_id))
    .then ((res) => {
      commit('REMOVE_CUSTOMER_POINTS', points_id);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },
  
  ['customers.getCredits'] ({ commit }, id) {
    commit('CLEAR_ERRORS')
    return (http.get('customer/' + id + '/credits?_all=true'))
      .then ((res) => {
        commit('GET_CUSTOMER_CREDITS', res);
      })
      .catch ((res) => {
        commit('CUSTOMERS_ERROR', res);
      })
  },

  ['customers.removeCredit'] ({ commit }, {customer_id, credit_id}) {
    commit('CLEAR_ERRORS')

    return (http.delete('customer/' + customer_id + '/credits/' + credit_id))
    .then ((res) => {
      commit('REMOVE_CUSTOMER_CREDIT', credit_id);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },

  ['customers.newCredit'] ({commit, dispatch}, {id, data}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.post(`customer/` + id + `/credits`, data).then(res => {
        dispatch('customers.getCredits', id)
          .then(resolve)
          .catch(reject);
      })
      .catch(reject)
    }).catch(err => {
      commit('CUSTOMERS_ERROR', err);
    });
  }
}

// mutations
const mutations = {
  GET_ALL_CUSTOMERS (state, res) {

    state.all = res.data;
  },

  GET_CUSTOMER (state, res) {
    state.active = res.data
  },

  GET_CUSTOMER_RESERVATIONS (state, res) {
    state.active.reservations = res.data;
    state.active = _.cloneDeep(state.active);
  },

  GET_CUSTOMER_POINTS (state, res) {
    state.active.points = res.data;
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_CUSTOMER_POINTS (state, id) {
    //NOTE: subtract points from the total qualifying without calling a new request
    let indx = _.findIndex(state.active.points, (item) => item.id == id);
    state.active.qualifying_points -= state.active.points[indx].amount;
    state.active.points[indx].deleted_at = true;
    state.active = _.cloneDeep(state.active);
  },

  GET_CUSTOMER_CREDITS (state, res) {
    state.active.credits = res.data;
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_CUSTOMER (state, id) {
    state.all.data = _.filter(state.all.data, (item) => item.id != id);
  },

  REMOVE_CUSTOMER_CREDIT (state, id) {
    state.active.credits = _.filter(state.active.credits, (item) => item.id != id);
    state.active = _.cloneDeep(state.active);
  },

  EDIT_CUSTOMER (state, res) {
    state.active = res.data
  },

  //ERRORS
  CUSTOMERS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
