import toastr from 'toastr'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const computed = {
  location () {
    return this.$store.state.locations.active;
  },

  currencies () {
    return this.$store.state.currencies.all;
  },

  barcode_encodings () {
    return this.$store.state.locations.barcode_encodings;
  }
}

const methods = {
  submit () {
    this.errors = {}
    this.processing = true;
    let data = _.cloneDeep(this.form);

    this.$store.dispatch('locations.edit', {
      id: this.$route.params.id, data,
      params: {'booking-conditions': true}
    }).then(() => {
      this.processing = false;
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    })
  }
}

export default {
  name: "booking-conditions-general-tab",
  computed,
  methods,
  directives,
  data () {
    return {
      processing: false,
      errors: {},
      form: {},
      location_id: this.$route.params.id
    }
  },
  created () {
    this.form = Object.assign({}, this.location);
  }
}
