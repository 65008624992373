var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("span", { staticClass: "f22" }, [
        _vm._v("This report is for export only.")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-46db291e", { render: render, staticRenderFns: staticRenderFns })
  }
}