import layout from 'layouts/default'
import toastr from 'toastr'
import fileUpload from 'components/fileUpload'

const components = {
  layout,
  fileUpload
}

const methods = {
  
  logoToUpload (files) {
    if (files.length) {
      this.logo = files;
    } else {
      this.logo = [];
    }
  },

  faviconToUpload (files) {
    if (files.length) {
      this.favicon = files;
    } else {
      this.favicon = [];
    }
  },
  
  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('affiliates.update', {
      id: this.$route.params.id,
      data: this.formData,
      logo: this.logo,
      favicon: this.favicon,
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.affiliates.errors) {
        this.errors = _.cloneDeep(this.$store.state.affiliates.errors.errors);
        toastr.error(this.$store.state.affiliates.errors.message);
      } else {
        this.formData.logo = this.$store.state.affiliates.active.logo;
        this.formData.favicon = this.$store.state.affiliates.active.favicon;
        toastr.success('Data has been updated successfully!');
      }
    })
  },

  createExtraParameter() {
    if (!_.isArray(this.formData.extra_parameters)) this.formData.extra_parameters = [];
    this.formData.extra_parameters.push({
      name: null, default_value: null
    });
  },

  removeExtraParameter(index) {
    this.formData.extra_parameters.splice(index, 1)
  }
}

export default {
  name: 'edit-affiliates',
  props: ['affiliate'],
  components,
  methods,
  data () {
    return {
      loading: false,
      submit_loading: false,
      errors: {},
      logo: [],
      favicon: [],
      formData: {},
    }
  },
  created () {
    this.formData = _.cloneDeep(this.affiliate);
  }
}
