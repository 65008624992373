import toastr from 'toastr'
import { funcModal } from 'components/modals'

let components = {
    funcModal
}

let methods = {
    open() {
        this.$refs.modal.open();
    },

    submit () {
        this.errors = {};
        this.processing = true;
        this.$store.dispatch('customers.addPoints', {
            id: this.$route.params.id,
            data: this.form
        }).then(() => {
            if (this.$store.state.customers.errors) {
                this.errors = _.cloneDeep(this.$store.state.customers.errors.errors);
            } else {
                toastr.success('Data has been saved');
                this.$refs.modal.close();
            }

            this.processing = false;
        });
    },

    onClose () {
        this.form = {
            amount: null,
            notes: null
        }
    }
}

export default {
    name: 'add-points',
    components,
    methods,
    data () {
        return {
            errors: {},
            processing: false,
            form: {
                amount: null,
                notes: null
            }
        }
    }
}