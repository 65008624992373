import toastr from 'toastr'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  confirm
}

const computed = {
  deferredRevenuesEmails () {
    return _.cloneDeep(this.$store.state.deferredRevenuesEmails.all);
  }
}

const methods = {
  submit () {
    this.processing = true;
    this.errors = {};
    this.$store.dispatch('deferredRevenuesEmails.new', this.form)
    .then(() => {
      this.processing = false;
      let errors;
      if (errors = this.$store.state.deferredRevenuesEmails.errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }

        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
        this.form = {
          emails: [null]
        }
      }
    });
  },

  deleteEmail (id) {
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.$set(this.loadings, id, true);

      this.$store.dispatch('deferredRevenuesEmails.remove', id).then(() => {
        this.$set(this.loadings, id, false);
        let errors;
        if (errors = this.$store.state.deferredRevenuesEmails.errors) {
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.success('data has been deleted successfully');

        }
      });
    })
  },

  addMoreEmails () {
    this.form.emails.push(null);
  },

  removeEmail (i, e) {
    e.preventDefault();
    this.form.emails.splice(i, 1);
  },


  updateEmail(id, e) {    
    this.$store.dispatch('deferredRevenuesEmails.edit', id).then(() => {
      let errors = _.cloneDeep(this.$store.state.deferredRevenuesEmails.errors);
      if (errors) {
        this.errors = errors.errors;
        toastr.error(errors.message);
      } else {
        toastr.success('Deferred Revenues Email updated successfully');
      }      
    })
  },
}

export default {
  name: "deferred-revenues-emails-list",
  computed,
  methods,
  components,
  directives,
  data () {
    return {
      errors: {},
      processing: false,
      loadings: {},
      formActive: false,
      form: {
          emails: [null],
        }
    }
  },
}
