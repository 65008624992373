import ListReservations from 'pages/reservations/list'
import CreateReservation from 'pages/reservations/create'
import EditReservation from 'pages/reservations/edit'
import ListUnifiedReservations from 'pages/unifiedReservations/list'
import UnifiedEditReservation from 'pages/unifiedReservations/edit'

export default [
  { path: '/reservations/create', name: 'reservations.create', component: CreateReservation, meta: {permits: ['reservation_create']}},
  { path: '/reservations/edit/:id', name: 'reservations.edit', component: EditReservation, meta: {permits: ['reservation_update']}},
  { path: '/reservations', name: 'reservations.list', component: ListReservations, meta: {permits: ['reservation_page'], keepAlive: true}},
  { path: '/unified-reservations/edit/:id', name: 'unifiedReservations.edit', component: UnifiedEditReservation, meta: {permits: ['reservation_update']}},
  { path: '/unified-reservations', name: 'unifiedReservations.list', component: ListUnifiedReservations, meta: {permits: ['reservation_unified_page'], keepAlive: true}},
]
