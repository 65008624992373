import toastr from 'toastr'
import { funcModal, confirm } from 'components/modals'

const components = {
    funcModal, confirm
}

const methods = {
    open (item) {
        this.item = item;
        this.$refs.modal.open();
    }, 

    submit () {
        this.errors = {};
        this.processing = true;
        
        if (!this.email.trim().length) {
            this.$refs.deleteConfirm.confirm('Are you sure that you want to delete this customer?', () => {
                this.$emit('customerLoading', {id: this.item.id, loading: true});
    
                this.$store.dispatch('customers.removeCustomer', {id: this.item.id, params: {email: this.email.trim()}}).then(() => {
                    this.$emit('customerLoading', {id: this.item.id, loading: false});

                    let errors;
                    if (errors = this.$store.state.customers.errors) {
                      if (errors.message) {
                        toastr.error(errors.message);
                      } else {
                        toastr.error(errors.error);
                      }
                    } else {
                        this.$refs.modal.close();
                    }
                    this.processing = false;
                })
            });
        } else {
            let isEmail = this.email.trim().toLowerCase().match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

            if (!isEmail) {
                this.errors.email = "invalid email address";
            } else {
                this.$refs.deleteConfirm.confirm('Are you sure that you want to move profile to new added email?', () => {
                    this.$emit('customerLoading', {id: this.item.id, loading: true});
                    
                    this.$store.dispatch('customers.removeCustomer', {id: this.item.id, params: {email: this.email.trim()}}).then(() => {
                        this.$emit('customerLoading', {id: this.item.id, loading: false});
        
                        let errors;
                        if (errors = this.$store.state.customers.errors) {
                            if (errors.message) {
                                toastr.error(errors.message);
                            } else {
                                toastr.error(errors.error);
                            }
                        } else {
                            this.$refs.modal.close();
                        }
                        this.processing = false;
                    })
                });
            }
        }
    },

    onClose () {
        this.email = '';
        this.errors.email = '';
        this.processing = false;
    },

    closeDeleteModal () {
        this.processing = false;
    },

}

export default {
    name: "new-profile-email",
    components,
    methods,
    data () {
        return {
            email: '',
            errors: {},
            processing: false,
            item: null,
        }
    },

    created () {
    }
}