import { currencySymbol } from 'filters'

const methods = {
  onSelect (plan) {
    this.$emit('select', plan.id);
  }
}

const computed = {
  tripPlans () {
    return [{
      id: null,
      title: "No, Thanks!",
      calculated_price: 0
    }].concat(this.plans);
  }
}

export default {
  name: 'trip-protection-list',
  props: ['selected', 'plans', 'currency_code','costs'],
  methods,
  computed,
  data () {
    return {
      processed_plans: this.plans,
      currencySymbol
    }
  },

  created () {
    this.processed_plans = this.processed_plans.map(plan => {
      let price = plan.prices.filter(price => price.currency.code == this.currency_code);
      if (price.length) {
        price = price[0].price;
      } else {
        price = 0;
      }

      return _.assign(plan, {price})
    });

    this.processed_plans = [{
      id: null,
      title: "No, Thanks!",
      price: 0
    }].concat(this.processed_plans);
  }
}
