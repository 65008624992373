import { http } from 'services'

// initial state
const state = () => ({
  errors: null,
  all: [],
  active: {
    types: [],
    blackouts: [],
    fees: [],
    extra_fields: [],
    employees: [],
    reservations: {}
  },
  barcode_encodings: [],
  active_employee: {}
})

// getters
const getters = {
  // reversedMessage: state => state.message.reverse()
}

// actions
const actions = {
  ['locations.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._get) params._get = null

    commit('CLEAR_ERRORS')

    return (http.get('locations', {
      params
    })).then ((res) => {
      commit('GET_ALL_LOCATIONS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  //get single location by id
  ['locations.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')

    return (http.get(`locations/${id}`)).then((res) => {
      commit('GET_LOCATION', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    });
  },

  //update location
  ['locations.edit'] ({dispatch, commit, state}, {id, data, params}) {
    commit('CLEAR_ERRORS');

    return (http.put(`locations/${id}`, data, {params})).then ((res) => {
      commit('EDIT_LOCATION_INFO', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  //sync locations
  ['locations.sync'] ({dispatch, commit}, params = {}) {
    commit('CLEAR_ERRORS');

    return (http.post(`locations/sync`)).then (() => {
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`locations/${id}`)).then ((res) => {
      commit('REMOVE_LOCATION', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`locations/${id}?_restore=true`)).then ((res) => {
      commit('UNDO_REMOVE_LOCATION', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.add'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`locations`, data)).then ((res) => {
      commit('NEW_LOCATION', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  //Extra Fields
  ['locations.getExtraFields'] ({commit}, {id, from_reslab = 'false'}) {
    return (http.get(`locations/${id}/extra-fields?_all=true&from_reslab=${from_reslab}`)).then((res) => {
      commit('GET_LOCATION_EXTRA_FIELDS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    });
  },

  ['locations.batchAddExtraFields'] ({commit, dispatch}, {location_id, data}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.post(`locations/${location_id}/extra-fields`, data).then(res => {
        dispatch('locations.getExtraFields', {id: location_id})
          .then(resolve)
          .catch(reject);
      })
      .catch(reject)
    }).catch(err => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeExtraField'] ({commit}, field_id) {
    commit('CLEAR_ERRORS');
    return (http.delete(`extra-fields/${field_id}`)).then(res => {
      commit('REMOVE_EXTRA_FIELD', res);
    })
    .catch(err => {
      commit('LOCATIONS_ERROR', err);
    })
  },

  //Amenities
  ['locations.updateAmenities'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`locations/${id}/amenities`, data)).then ((res) => {
      commit('EDIT_LOCATION_AMENITIES', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  //TYPES
  ['locations.getLocationTypes'] ({commit}, {location_id, from_reslab = 'false'}) {
    commit('CLEAR_ERRORS');
    return (http.get(`locations/${location_id}/types?_all=true&from_reslab=${from_reslab}`)).then(res => {
      commit('GET_LOCATION_TYPES', res);
    })
    .catch(res => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.newType'] ({commit, dispatch}, {location_id, data}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.post(`locations/${location_id}/types`, data).then(res => {
        dispatch('locations.getLocationTypes', {location_id: location_id})
          .then(resolve)
          .catch(reject);
      })
      .catch(reject)
    }).catch(err => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.updateType'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`types/${id}`, data)).then((res) => {
      commit('UPDATE_TYPE', res);
    }).catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeType'] ({commit}, id) {
    commit('CLEAR_ERRORS');
    return (http.delete(`types/${id}`)).then(res => {
      commit('REMOVE_LOCATION_TYPE', res);
    })
    .catch(err => {
      commit('LOCATIONS_ERROR', err);
    })
  },

  //blackouts
  ['locations.getLocationBlackouts'] ({commit}, id) {
    commit('CLEAR_ERRORS');
    return (http.get(`locations/${id}/blackouts?_all=true`)).then(res => {
      commit('GET_LOCATION_BLACKOUTS', res);
    })
    .catch(res => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.newBlackout'] ({commit, dispatch}, {location_id, data}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.post(`blackouts`, data).then(res => {
        dispatch('locations.getLocationBlackouts', location_id)
          .then(resolve)
          .catch(reject);
      })
      .catch(reject)
    }).catch(err => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeBlackout'] ({commit}, id) {
    commit('CLEAR_ERRORS');
    return (http.delete(`blackouts/${id}`)).then(res => {
      commit('REMOVE_LOCATION_BLACKOUT', res);
    })
    .catch(err => {
      commit('LOCATIONS_ERROR', err);
    })
  },

  //Rates
  ['locations.getTypeRates'] ({commit}, {type_id, params}) {
    commit('CLEAR_ERRORS');
    return (http.get(`types/${type_id}/rates?_all=true`, {
      params
    })).then(res => {
      commit('GET_TYPE_RATES', {type_id, res});
    })
    .catch(res => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.newRate'] ({commit}, {type_id, data}) {
    commit('CLEAR_ERRORS');
    return http.post(`types/${type_id}/rates`, data)
    .catch(res => {
      commit('LOCATIONS_ERROR', res);
    });
  },

  ['locations.createRateAdjustment'] ({commit, dispatch}, {location_id, data}) {
    commit('CLEAR_ERRORS');
    return new Promise((resolve, reject) => {
      http.post(`locations/${location_id}/rate-adjustment`, data).then(res => {
        let promises = [];
        console.log('eeee', res);
        for (let i in res.data) {
          console.log(i, res.data);
          promises.push(dispatch('locations.getTypeRates', {type_id: i, params: {
            modified: 'all'
          }}));
        }

        Promise.all(promises)
        .then(resolve)
        .catch(reject)
      })
      .catch(reject)
    })
    .catch(res => {
      commit('LOCATIONS_ERROR', res);
    });
  },

  ['locations.removeRate'] ({commit}, rate_id) {
    commit('CLEAR_ERRORS');
    return (http.delete(`rates/${rate_id}`)).then(res => {
      commit('REMOVE_TYPE_RATE', res);
    })
    .catch(err => {
      commit('LOCATIONS_ERROR', err);
    })
  },

  //Fees
  ['locations.getFees'] ({commit}, location_id) {
    commit('CLEAR_ERRORS');
    return (http.get(`locations/${location_id}/fees?_all=true`)).then(res => {
      commit('GET_LOCATION_FEES', res);
    })
    .catch(res => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.newFee']({commit, dispatch}, {id, data}) {
    commit('CLEAR_ERRORS')
    return new Promise((resolve, reject) => {
      http.post(`locations/${id}/fees`, data).then(res => {
        dispatch('locations.getFees', id)
        .then(resolve)
        .catch(reject)
      })
      .catch(reject)
    })
    .catch(res => {
      commit('LOCATIONS_ERROR', res);
    });
  },

  ['locations.updateFee'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put(`fees/${id}`, data)).then((res) => {
      commit('UPDATE_LOCATION_FEE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeFee']({commit}, id) {
    commit('CLEAR_ERRORS')
    return (http.delete(`fees/${id}`)).then((res) => {
      commit('REMOVE_LOCATION_FEE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.getEmployees'] ({commit}, location_id) {
    commit('CLEAR_ERRORS');
    return (http.get(`locations/${location_id}/employees?_all=true`)).then(res => {
      commit('GET_LOCATION_EMPLOYEES', res);
    })
    .catch(res => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.getReservations'] ({commit}, {location_id, params}) {
    commit('CLEAR_ERRORS');
    return (http.get(`locations/${location_id}/reservations`,{
      params
    })).then(res => {
      commit('GET_LOCATION_RESERVATIONS', res);
    })
    .catch(res => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.getEmployee'] ({commit}, id) {
    commit('CLEAR_ERRORS');
    return (http.get(`employees/${id}`)).then(res => {
      commit('GET_LOCATION_EMPLOYEE', res);
    })
    .catch(res => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.addEmployee'] ({ commit, dispatch }, {id, data}) {
    commit('CLEAR_ERRORS')
    return new Promise((resolve, reject) => {
      http.post(`locations/${id}/employees`, data)
      .then (() => {
        dispatch('locations.getEmployees', id)
        .then(resolve)
        .catch(reject);
      })
      .catch (reject)
    })
    .catch((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.updateEmployee'] ({ commit, dispatch }, {id, location_id, data}) {
    commit('CLEAR_ERRORS')
    return new Promise((resolve, reject) => {
      http.put(`employees/${id}`, data)
      .then (() => {
        dispatch('locations.getEmployees', location_id)
        .then(resolve)
        .catch(reject);
      })
      .catch (reject)
    })
    .catch((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.removeEmployee'] ({ commit }, {locId, id}) {
    commit('CLEAR_ERRORS')

    return (http.delete(`employees/${id}`))
    .then ((res) => {
      commit('REMOVE_EMPLOYEE', res);
    })
    .catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  //photos

  ['locations.getPhotos'] ({commit, state}, location_id) {
    commit('CLEAR_ERRORS')
    return (http.get(`locations/${location_id}/photos?_all=true`)).then(res => {
      commit('GET_LOCATION_PHOTOS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    });
  },

  ['locations.uploadImages'] ({commit}, {id, files}) {
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("images[]", files[i]);
      }
    }

    return (http.post(`locations/${id}/photos`, formData, config)).then((res) => {
      console.log('tesstt');
      commit('LOCATION_UPLOAD_IMAGES', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.setFeaturedImage'] ({commit}, id) {
    commit('CLEAR_ERRORS')
    return (http.post(`location-photos/${id}`)).then((res) => {
      commit('LOCATION_SET_IMAGE_AS_FEATURED', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.setCoverImage'] ({commit}, id) {
    commit('CLEAR_ERRORS')
    return (http.post(`location-photos/${id}/cover`)).then((res) => {
      commit('LOCATION_SET_IMAGE_AS_COVER', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.deleteImage'] ({dispatch, commit, state}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`location-photos/${id}`)).then ((res) => {
      commit('REMOVE_IMAGE_FROM_PORT', res)
    })
    .catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.getBarcodeEncodings'] ({ commit }) {
    commit('CLEAR_ERRORS')
    return (http.get(`barcode-encodings?_all=true`)).then(res => {
      commit('GET_BARCODE_ENCODINGS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    });
  }
}

// mutations
const mutations = {
  GET_ALL_LOCATIONS (state, res) {
    state.all = res.data;
  },

  GET_LOCATION (state, location) {
    if (location) {
      state.active = _.assign(state.active, location.data);
    }

    state.active = _.cloneDeep(state.active);
  },

  NEW_LOCATION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  EDIT_LOCATION_INFO (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_LOCATION (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_LOCATION (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  //Extra Fields
  GET_LOCATION_EXTRA_FIELDS (state, res) {
    state.active.extra_fields = res.data;
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_EXTRA_FIELD (state, res) {
    state.active.extra_fields = _.filter(state.active.extra_fields, (item) => item.id != res.data.id);
    state.active = _.cloneDeep(state.active);
  },

  //Amenities
  EDIT_LOCATION_AMENITIES (state, res) {

  },

  //Types
  GET_LOCATION_TYPES (state, res) {
    state.active.types = res.data;
    state.active = _.cloneDeep(state.active);
  },

  UPDATE_TYPE (state, res) {
    let indx = _.findIndex(state.active.types, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.active.types[indx] = _.assign(state.active.types[indx], res.data);
      state.active = _.cloneDeep(state.active);
    }
  },

  REMOVE_LOCATION_TYPE (state, res) {
    state.active.types = state.active.types.filter(item => item.id != res.data.id)
    state.active = _.cloneDeep(state.active);
  },
  // NEW_LOCATION_TYPE (state, res) {
  //   state.active.types = state.active.types.concat(res.data);
  // },

  //BLACKOUTS
  GET_LOCATION_BLACKOUTS (state, res) {
    state.active.blackouts = res.data;
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_LOCATION_BLACKOUT (state, res) {
    state.active.blackouts = state.active.blackouts.filter(item => item.id != res.data.id)
    state.active = _.cloneDeep(state.active);
  },

  //RATES
  GET_TYPE_RATES (state, {type_id, res}) {
    let indx = _.findIndex(state.active.types, (item) => item.id == type_id);

    if (indx > -1) {
      state.active.types[indx].rates = _.assign(state.active.types[indx].rates, res.data);
      state.active = _.cloneDeep(state.active);
    }
  },

  REMOVE_TYPE_RATE (state, res) {
    let indx = _.findIndex(state.active.types, (item) => item.id == res.data.location_type_id);
    console.log('indx', indx);
    if (indx > -1) {
      state.active.types[indx].rates = _.filter(state.active.types[indx].rates, item => item.id != res.data.id)
      state.active = _.cloneDeep(state.active);
    }
  },

  //Fees
  GET_LOCATION_FEES (state, res) {
    state.active.fees = res.data;
    state.active = _.cloneDeep(state.active);
  },

  // NEW_LOCATION_FEE (state, res) {
  //   state.active.fees.push(res.data);
  //   state.active = _.cloneDeep(state.active);
  // },

  UPDATE_LOCATION_FEE (state, res) {
    let indx = _.findIndex(state.active.fees, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.active.fees[indx] = _.assign(state.active.fees[indx], res.data);
    }
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_LOCATION_FEE (state, res) {
    console.log('res.data.id', res.data.id)
    state.active.fees = state.active.fees.filter((item) => {
      return item.id !== res.data.id
    });

    state.active = _.cloneDeep(state.active);
  },

  //Employees
  GET_LOCATION_EMPLOYEES (state, res) {
    state.active.employees = res.data;
    state.active = _.cloneDeep(state.active);
  },

  GET_LOCATION_EMPLOYEE (state, res) {
    state.active_employee = res.data;
    state.active_employee = _.cloneDeep(state.active_employee);
  },

  REMOVE_EMPLOYEE (state, res) {
    state.active.employees = state.active.employees.filter(
      (user) => user.id != res.data.id);
  },

  //Reservations
  GET_LOCATION_RESERVATIONS (state, res) {
    state.active.reservations = res.data;
    state.active = _.cloneDeep(state.active);
  },
  
  //Photos
  LOCATION_UPLOAD_IMAGES (state, res) {
    state.active.photos = _.concat(state.active.photos, res.data);
    state.active = _.cloneDeep(state.active);
  },

  LOCATION_SET_IMAGE_AS_FEATURED (state, res) {
    _.forEach(state.active.photos, (photo, i) => {
      state.active.photos[i].featured = 1;
      if (photo.id != res.data.id) {
        state.active.photos[i].featured = 0;
      }
    });

    state.active = _.cloneDeep(state.active);
  },

  LOCATION_SET_IMAGE_AS_COVER (state, res) {
    _.forEach(state.active.photos, (photo, i) => {
      state.active.photos[i].cover = 1;
      if (photo.id != res.data.id) {
        state.active.photos[i].cover = 0;
      }
    });
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_IMAGE_FROM_PORT (state, res) {
    state.active.photos = state.active.photos.filter((item) => item.id != res.data.id);
    state.active = _.cloneDeep(state.active);
  },

  GET_LOCATION_PHOTOS (state, res) {
    state.active.photos = res.data;
    state.active = _.cloneDeep(state.active);
  },

  GET_BARCODE_ENCODINGS (state, res) {
    state.barcode_encodings = [].concat(res.data);
  },

  //ERRORS
  LOCATIONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
