var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "autocomplete-container" }, [
    _vm.loading
      ? _c("div", { staticClass: "autocomplete-loading" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      ref: "input",
      class: _vm.inputClass,
      attrs: { placeholder: _vm.placeholder, type: "text" },
      on: {
        keyup: function($event) {
          $event.preventDefault()
          return _vm.startAutocomplete($event)
        }
      }
    }),
    _vm._v(" "),
    _vm.active
      ? _c(
          "ul",
          { ref: "results", staticClass: "autocomplete-results" },
          _vm._l(_vm.items, function(item) {
            return _c(
              "li",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.select(item)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.formatedItem(item)))]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cf0e8f0a", { render: render, staticRenderFns: staticRenderFns })
  }
}