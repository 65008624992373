var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { size: "large", title: "View Reservation Details" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _vm.reservation
            ? _c(
                "tabs",
                [
                  _vm.reservation.cancelled
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "text-center text-danger f18 padding-top-3x"
                        },
                        [_vm._v("\n        CANCELLED\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tab", { attrs: { title: "Current State" } }, [
                    _vm.activeHistory
                      ? _c(
                          "table",
                          { staticClass: "table table-bordered margin-top-2x" },
                          [
                            _c("tbody", [
                              _vm.reservation.cancelled
                                ? _c("tr", [
                                    _c("th", [_vm._v("Cancellation Date")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            _vm.reservation.updated_at,
                                            "MM/DD/YYYY HH:mm"
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.reservation.cancelled_by
                                ? _c("tr", [
                                    _c("th", [_vm._v("Cancelled By")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reservation.cancelled_by["name"]
                                        ) +
                                          " (" +
                                          _vm._s(
                                            _vm.reservation.cancelled_by[
                                              "user_type"
                                            ]
                                          ) +
                                          ")"
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.reservation.cancellation_reason
                                ? _c("tr", [
                                    _c("th", [_vm._v("Cancellation reason")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reservation.cancellation_reason
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Creation Date")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.reservation.created_at,
                                        "MM/DD/YYYY HH:mm"
                                      )
                                    ) + " \n                "
                                  ),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: ["super_admin"],
                                          expression: "['super_admin']",
                                          arg: "allow"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.activeHistory.location.port
                                            .timezone.name
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("From")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.activeHistory.from_date,
                                        "MM/DD/YYYY HH:mm"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("To")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.activeHistory.to_date,
                                        "MM/DD/YYYY HH:mm"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.activeHistory.location
                                ? _c("tr", [
                                    _c("th", [_vm._v("Airport Name")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.activeHistory.location.port.name
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.activeHistory.location
                                ? _c("tr", [
                                    _c("th", [_vm._v("Location Name")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.activeHistory.location.name)
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Parking Type: ")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.activeHistory.location_type_name
                                      ) +
                                      "\n              "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Number of Spots: ")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.activeHistory.number_of_spots
                                      ) +
                                      "\n              "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Number of Days: ")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.activeHistory.number_of_days) +
                                      "\n              "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Subtotal")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.activeHistory.subtotal,
                                        _vm.symbol(
                                          _vm.reservation.currency_code
                                        )
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.activeHistory.total_fees
                                ? _c("tr", [
                                    _c("th", [_vm._v("Total Fees")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.activeHistory.total_fees,
                                            _vm.symbol(
                                              _vm.reservation.currency_code
                                            )
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.activeHistory.total_taxes
                                ? _c("tr", [
                                    _c("th", [_vm._v("Total Taxes")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.activeHistory.total_taxes,
                                            _vm.symbol(
                                              _vm.reservation.currency_code
                                            )
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.activeHistory.trip_protection_price
                                ? _c("tr", [
                                    _c("th", [_vm._v("Trip protection price")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.activeHistory
                                              .trip_protection_price,
                                            _vm.symbol(
                                              _vm.reservation.currency_code
                                            )
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.activeHistory.total_discount
                                ? _c("tr", [
                                    _c("th", [_vm._v("Discount")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "-" +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.activeHistory.total_discount,
                                              _vm.symbol(
                                                _vm.reservation.currency_code
                                              )
                                            )
                                          )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.activeHistory.customer_credit_used
                                ? _c("tr", [
                                    _c("th", [_vm._v("Credit Used")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "-" +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.activeHistory
                                                .customer_credit_used,
                                              _vm.symbol(
                                                _vm.reservation.currency_code
                                              )
                                            )
                                          )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.activeHistory.points_used
                                ? _c("tr", [
                                    _c("th", [_vm._v("Points Used")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.activeHistory.points_used) +
                                          " Points"
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.activeHistory.points_money_value
                                ? _c("tr", [
                                    _c("th", [_vm._v("Saved Using Points")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "-" +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.activeHistory
                                                .points_money_value,
                                              _vm.symbol(
                                                _vm.reservation.currency_code
                                              )
                                            )
                                          )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.activeHistory.partial_refund
                                ? _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        "\n                Partial Refund\n              "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "-" +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.activeHistory.partial_refund,
                                              _vm.symbol(
                                                _vm.reservation.currency_code
                                              )
                                            )
                                          )
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Grand Total")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.activeHistory.grand_total,
                                        _vm.symbol(
                                          _vm.reservation.currency_code
                                        )
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Reserved By")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.activeHistory.customer_name) +
                                      "\n                -\n                " +
                                      _vm._s(_vm.reservation.reserved_by) +
                                      "\n              "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Contact Email")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.activeHistory.contact_email)
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Contact Phone")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.activeHistory.phone))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("IP Address")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.reservation.history[0].ip))
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.reservation.affiliate
                                ? _c("tr", [
                                    _c("th", [_vm._v("Affiliate")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.reservation.affiliate.name)
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.reservation.affiliate &&
                              _vm.reservation.affiliate_extra_parameters
                                ? _c("tr", [
                                    _c("th", [
                                      _vm._v("Affiliate Extra Params")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      _vm._l(
                                        _vm.reservation
                                          .affiliate_extra_parameters,
                                        function(value, name) {
                                          return _c("div", { key: value }, [
                                            value
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(name) +
                                                      " : " +
                                                      _vm._s(value)
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { title: "History" } },
                    _vm._l(_vm.reservation.history, function(item) {
                      return _vm.reservation.history
                        ? _c(
                            "table",
                            {
                              staticClass: "table table-bordered margin-top-2x"
                            },
                            [
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", [_vm._v("Creation Date")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.created_at,
                                          "MM/DD/YYYY HH:mm"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Contact Email")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.contact_email))])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Updated By")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.updated_by))])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("From")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.from_date,
                                          "MM/DD/YYYY HH:mm"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("To")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.to_date,
                                          "MM/DD/YYYY HH:mm"
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Subtotal")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          item.subtotal,
                                          _vm.symbol(
                                            _vm.reservation.currency_code
                                          )
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Total Fees")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          item.total_fees,
                                          _vm.symbol(
                                            _vm.reservation.currency_code
                                          )
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Total Taxes")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          item.total_taxes,
                                          _vm.symbol(
                                            _vm.reservation.currency_code
                                          )
                                        )
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                item.trip_protection_price
                                  ? _c("tr", [
                                      _c("th", [
                                        _vm._v("Trip protection price")
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              item.trip_protection_price,
                                              _vm.symbol(
                                                _vm.reservation.currency_code
                                              )
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                item.total_discount
                                  ? _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          "\n                Discount\n              "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "-" +
                                            _vm._s(
                                              _vm._f("currency")(
                                                item.total_discount,
                                                _vm.symbol(
                                                  _vm.reservation.currency_code
                                                )
                                              )
                                            )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                item.customer_credit_used
                                  ? _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          "\n                Credit Used\n              "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "-" +
                                            _vm._s(
                                              _vm._f("currency")(
                                                item.customer_credit_used,
                                                _vm.symbol(
                                                  _vm.reservation.currency_code
                                                )
                                              )
                                            )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                item.points_used
                                  ? _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          "\n                Points Used\n              "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(item.points_used))
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                item.points_money_value
                                  ? _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          "\n                Saved Using Points\n              "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "-" +
                                            _vm._s(
                                              _vm._f("currency")(
                                                item.points_money_value,
                                                _vm.symbol(
                                                  _vm.reservation.currency_code
                                                )
                                              )
                                            )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                item.partial_refund
                                  ? _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          "\n                Partial Refund\n              "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "-" +
                                            _vm._s(
                                              _vm._f("currency")(
                                                item.partial_refund,
                                                _vm.symbol(
                                                  _vm.reservation.currency_code
                                                )
                                              )
                                            )
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Grand Total")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          item.grand_total,
                                          _vm.symbol(
                                            _vm.reservation.currency_code
                                          )
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    {
                      attrs: {
                        title: "Cancellation Failed",
                        permits: ["super_admin"]
                      }
                    },
                    _vm._l(_vm.reservation.failed_cancellation, function(item) {
                      return _vm.reservation.failed_cancellation
                        ? _c(
                            "table",
                            {
                              staticClass: "table table-bordered margin-top-2x"
                            },
                            [
                              _c("tbody", [
                                _c("tr", [
                                  _c("th", [_vm._v("Canceled By")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.user))])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Ip Address")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.ip_address))])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Reason")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.reason))])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Status")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.status))])
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("Failed At")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          item.failed_at,
                                          "MM/DD/YYYY HH:mm"
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ])
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1196a54c", { render: render, staticRenderFns: staticRenderFns })
  }
}