var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mb-4" }, [
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c(
          "div",
          { staticClass: "card-block" },
          [
            _c("reservations-list", {
              attrs: { reservations: _vm.reservations },
              on: { loadPage: _vm.loadPage }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-52c44701", { render: render, staticRenderFns: staticRenderFns })
  }
}