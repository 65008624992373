var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.closeFlag
    ? _c(
        "div",
        { staticClass: "main" },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close close-btn",
              attrs: { type: "button", "aria-label": "Close" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.close($event)
                }
              }
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ff8a2ff2", { render: render, staticRenderFns: staticRenderFns })
  }
}