import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import viewReservation from 'layouts/partials/viewReservation'
import unifiedViewReservation from '../unifiedViewReservation'
import unifiedSendToEmail from '../unifiedSendToEmail'
import sendToEmail from 'layouts/partials/sendToEmail'
import { dateFormat, currencySymbol } from 'filters'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  datepicker,
  viewReservation,
  unifiedViewReservation,
  unifiedSendToEmail,
  sendToEmail
}

const filters = {
  dateFormat
}

const methods = {
  loadPage (page) {

    this.view_loadings = {};

    this.$route.query.page = page;
    let data = {
      page: 1,
      _q: this.search_key,
      search_by: this.search_by,
      _sort: '-id'
    };

    data = _.assign(data, this.filter);
    data.created_at = data.created_at ? moment(data.created_at, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null
    data.from_date = data.from_date ? moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null
    data.to_date = data.to_date ? moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null

    this.loading = true;

    return (this.$store.dispatch('unifiedReservations.getAll', data)).then (() => {
      this.loading = false;
    });
  },

  showReservationDetails (item_id, system) {
    this.$set(this.view_loadings, item_id, true);
    if (system == 'osp') {
      this.$store.dispatch('reservations.get', item_id).then(() => {
        let errors;
        if (errors = this.$store.state.reservations.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        } else {
          this.$refs.view.open();
        }
  
        this.view_loadings[item_id] = false;
      });
    } else {
      // view reservation from TripTeo
      this.$store.dispatch('unifiedReservations.get', item_id).then(() => {
        let errors;
        if (errors = this.$store.state.unifiedReservations.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        } else {
          this.$refs.unifiedView.open(this.$store.state.unifiedReservations.active);
        }
  
        this.view_loadings[item_id] = false;
      });
    }
    

    return false;
  },

  openSendToEmailModal (system, id, email) { 
    if (system == 'osp') {
      this.$refs.sendToEmail.open(id, email)
    } else {
      this.$refs.unifiedSendToEmail.open(id, email)
    }
    
  },
}

const computed = {
  reservations () {
    return _.cloneDeep(this.$store.state.unifiedReservations.all);
  }
}

export default {
  name: 'list-unified-reservations',
  methods,
  filters,
  components,
  computed,
  directives,
  data () {
    return {
      loading: false,
      search_key: null,
      search_by: null,
      filter: {
        search_in: null,
        cancelled: null,
        status: null,
        from_date: null,
        to_date: null,
        created_at: null
      },
      view_loadings: {},
      currencySymbol
    }
  },
  created () {
    this.loadPage(1);
  }
}
