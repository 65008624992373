import env from 'constants/env'
import datatable from 'components/datatable'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.show_table = true;
        this.$nextTick(() => {
            this.$refs.table.run();
        })
    }
}

let computed = {
    user () {
        return this.$store.state.user.data;
    }
}

export default {
    name: "location-report",
    methods,
    components,
    computed,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/locations',
        columns: []
    }),
    mounted () {
        this.columns = [
            {label: "Active", attribute: (data) => (
                data.deleted_at ?
                `<span class="text-danger bold">Inactive</span>` :
                `<span class="bold">Active</span>`
            ),sortable: true, sort_key: "active"},
            {label: "Location Name", attribute: 'name',sortable: true, sort_key: "name"},
            {label: "Airport", attribute: 'port.name',sortable: true, sort_key: "port_name"},
            {label: "Country", attribute: 'country.name',sortable: true, sort_key: "country_name"},
            {label: "City", attribute: 'city',sortable: true, sort_key: "city"},
            {label: "Address", attribute: 'address'},
            {label: "Phone", attribute: 'phone'},
            {label: "Fax", attribute: 'fax'},
            {label: "Due at Location", attribute: (data) => data.due_at_location ? 'Yes' : 'No'},
            {label: "Netpark Key", attribute: 'netpark_key'},
            {label: "Number of Spots", attribute: (data) => data.number_of_spots || '0'},
            {label: "Available Spots", attribute: (data) => data.available_spots_today || '0'},
            {label: "Commission %", attribute: (data) => data.commission + '%'},
            {label: "Currency", attribute: 'currency.code',sortable: true, sort_key: "currency"},
        ]
    }
}