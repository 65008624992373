import { stripeService } from 'services'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const methods = {
  autoFillForm() {
    if (this.activeHistory) {
      this.form.contact_email = this.activeHistory.contact_email;
      this.form.zipcode = this.activeHistory.zipcode;
      this.form.phone = this.activeHistory.phone;
    }
  },
  submit () {
    this.form.click_to_pay = this.form.externalPaymentLink;
    this.$emit('submit', {
      elements: this.elements,
      stripe: this.stripe,
      form: this.form
    });
  },

  onChangeCardElement () {
    this.errors.card_number = null;
  },

  onChangeExpirationElement () {
    this.errors.expiration = null;
  },

  onChangeCvcElement () {
    this.errors.cvc = null;
  },
}

export default {
  name: 'payment-form',
  props: ['processing', 'errors', 'newCard', 'activeHistory'],
  directives,
  methods,
  data () {
    return {
      loadingStripe: false,
      stripe: null,
      elements: {
        card_element: null,
        expiration_element: null,
        cvc_element: null,
      },
      form: {
        customer_name: null,
        zipcode: null,
        phone: null,
        contact_email: null,
        click_to_pay: false,
        externalPaymentLink: false,
      }
    }
  },
  mounted () {
    let currencyCode = this.$vnode.key;
    this.autoFillForm();
    this.loadingStripe = true;
    stripeService.init(currencyCode).then(({stripe, elements}) => {
      this.loadingStripe = false;
      this.stripe = stripe;

      let classes = {
        base: 'payment-field form-control'
      }

      let style = {
        base: {
          color: '#545962',
          fontSize: '16px',
          fontWeight: '300',
          // '::placeholder': {
          //   fontWeight: '300',
          //   color: '#bbb'
          // }
        }
      }

      this.elements.card_element = elements.create('cardNumber', {classes, style});
      this.elements.expiration_element = elements.create('cardExpiry', {classes, style});
      this.elements.cvc_element = elements.create('cardCvc', {classes, style});

      this.elements.card_element.mount('#cardNumber-element');
      this.elements.expiration_element.mount('#expiration-element');
      this.elements.cvc_element.mount('#cvc-element');

      this.elements.card_element.addEventListener('change', this.onChangeCardElement);
      this.elements.expiration_element.addEventListener('change', this.onChangeExpirationElement);
      this.elements.cvc_element.addEventListener('change', this.onChangeCvcElement);

    });
  },

  destroyed () {
    console.log('destroy');
    try {
      this.elements.card_element.removeEventListener('change', this.onChangeCardElement);
      this.elements.expiration_element.removeEventListener('change', this.onChangeExpirationElement);
      this.elements.cvc_element.removeEventListener('change', this.onChangeCvcElement);
    } catch (e) {}

    try {
      this.elements.card_element.destroy()
      this.elements.expiration_element.destroy()
      this.elements.cvc_element.destroy()
    } catch (e) {}
  }
}
