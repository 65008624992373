import moment from 'moment'
import { user } from 'services'
import { hasPermits, hasReportPermits } from 'directives'

const directives = {
  hasPermits, hasReportPermits
}

const computed = {
  allLocationsHasResLabId () {
    if (!isServer && this.$store.state.user.data && this.$store.state.user.data.locations && this.user.type==='location') {
      return !this.$store.state.user.data.locations.some( item => item.location && item.location.reslab_id === null );
    }
    return false;
  }
}

const methods = {
  logout () {
    this.$store.dispatch('user.logout');
    // this.$router.replace({name: 'login'});
    window.location.href = "/login";
  }
}

export default {
  name: 'default-layout',
  props: ['noSidebar'],
  methods,
  computed,
  directives,
  data () {
    return {
      year: moment().format('YYYY'),
      user: user.getUser()
    }
  },
}
