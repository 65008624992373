var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c("div", [
            _c("h3", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(_vm.location.name))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card mb-4" }, [
              _c(
                "div",
                { staticClass: "card-block " },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12 col-md-6 mb-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-subtle btn-sm",
                          class: [{ "btn-primary": _vm.view == "list" }],
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.view = "list"
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-list",
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-subtle btn-sm",
                          class: [{ "btn-primary": _vm.view == "calendar" }],
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.view = "calendar"
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-calendar",
                            attrs: { "aria-hidden": "true" }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-12 col-md-6 text-right" },
                      [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.location.id,
                                  permits: ["RATE_CREATE"]
                                },
                                expression:
                                  "{id: location.id, permits: ['RATE_CREATE']}",
                                arg: "allow"
                              }
                            ],
                            staticClass: "btn btn-default",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.showRateAdjustmentModal($event)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-sliders",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(
                              "\n              Rate Adjustment\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: {
                                  id: _vm.location.id,
                                  permits: ["RATE_CREATE"]
                                },
                                expression:
                                  "{id: location.id, permits: ['RATE_CREATE']}",
                                arg: "allow"
                              }
                            ],
                            staticClass: "btn btn-default",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.showNewRateModal($event)
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-plus",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v("\n              New Rate\n            ")
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.view == "list"
                    ? _c("list-view", { ref: "list" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.view == "calendar"
                    ? _c("calendar-view", { ref: "calendar" })
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
      _vm._v(" "),
      _vm.shouldShowNewRateModal
        ? _c("new-rate", {
            ref: "newRateModal",
            attrs: { "on-close": _vm.onCloseNewRateModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowRateAdjustmentModal
        ? _c("rate-adjustments", {
            ref: "newRateAdjustmentModal",
            attrs: { "on-close": _vm.onCloseRateAdjustmentModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-256b9fd1", { render: render, staticRenderFns: staticRenderFns })
  }
}