var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("h3", { staticClass: "mb-2" }, [_vm._v("New Affiliate")]),
    _vm._v(" "),
    _c("div", { staticClass: "card mb-4" }, [
      _c("div", { staticClass: "card-block" }, [
        _c("form", {}, [
          _c("div", { staticClass: "text-right" }, [
            _vm.submit_loading
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { disabled: _vm.submit_loading, type: "button" }
                  },
                  [
                    _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                    _vm._v("\n            SAVING...\n          ")
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onSubmitForm($event)
                      }
                    }
                  },
                  [_vm._v("\n            SAVE\n          ")]
                )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row margin-top-4x" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "AffiliateName" } }, [
                  _vm._v("Name")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.form.name,
                      expression: "form.name",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "AffiliateName",
                    placeholder: "Affiliate Name"
                  },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "name", $event.target.value.trim())
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.name
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.errors.name[0]) +
                          "\n              "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v("Logo")]),
                  _vm._v(" "),
                  _c("file-upload", {
                    ref: "file_upload",
                    attrs: { multiple: false, accept: "image/*" },
                    on: { filesChanged: _vm.logoToUpload }
                  }),
                  _vm._v(" "),
                  _vm.errors.logo
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.errors.logo[0]) +
                            "\n              "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v("Favicon")]),
                  _vm._v(" "),
                  _c("file-upload", {
                    ref: "file_upload",
                    attrs: { multiple: false, accept: "image/*" },
                    on: { filesChanged: _vm.faviconToUpload }
                  }),
                  _vm._v(" "),
                  _vm.errors.favicon
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.errors.favicon[0]) +
                            "\n              "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.active,
                        expression: "form.active"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.form.active)
                        ? _vm._i(_vm.form.active, null) > -1
                        : _vm.form.active
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.form.active,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.form, "active", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.form,
                                "active",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.form, "active", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v("\n                Active\n              ")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "AffiliateAttribute" } }, [
                  _vm._v("Attribute")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.form.attribute,
                      expression: "form.attribute",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "AffiliateAttribute",
                    placeholder: "Affiliate Attribute"
                  },
                  domProps: { value: _vm.form.attribute },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.form,
                        "attribute",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.attribute
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.errors.attribute[0]) +
                          "\n              "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "AffiliateWebsite" } }, [
                  _vm._v("Website")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.form.website,
                      expression: "form.website",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "AffiliateWebsite",
                    placeholder: "https://www.domain.com"
                  },
                  domProps: { value: _vm.form.website },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "website", $event.target.value.trim())
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.website
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.errors.website[0]) +
                          "\n              "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-58e857d4", { render: render, staticRenderFns: staticRenderFns })
  }
}