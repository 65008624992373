import { phone } from 'filters'
import layout from 'layouts/default'
import pagination from 'components/pagination'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'
import newProfileEmail from '../newProfileEmail'

const directives = {
  hasPermits
}

const filters = {
  phone
}

const components = {
  layout,
  pagination,
  confirm,
  newProfileEmail
}

const methods = {
  loadPage (page) {
    this.loading = true;

    let data = {page, _q: this.searchKey, _sort: '-id'};
    data._get = this.filter

    this.$store.dispatch('customers.getAll', data).then (() => {
      this.loading = false;
    });
  },
  search (searchKey) {
    this.searchKey = searchKey;
    this.loadPage(1);
  },

  filterChanged () {
    console.log(this.filter);
    this.loadPage(1);
  },

  removeCustomer (item) {
    this.$refs.newProfileEmail.open(item);
  },

  undoRemove (item) {
    this.$refs.confirmUndoDelete.confirm(
    'Are you sure that you want to undelete this customer?',
    () => {
      this.customers_loadings[item.id] = true

      this.$store.dispatch('customers.undoRemove', item.id).then(() => {
        this.customers_loadings[item.id] = false;

        let errors;
        if (errors = this.$store.state.customers.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },

  customerLoading (obj) {
    this.customers_loadings[obj.id] = obj.loading;
  },
}

const computed = {
  customers () {
    let customers = _.cloneDeep(this.$store.state.customers.all);
    _.each(customers.data, (customer) => {
      this.customers_loadings[customer.id] = false;
    });
    this.customers_loadings = _.clone(this.customers_loadings);
    return customers;
  }
}

export default {
  name: 'all-customers',
  components,
  methods,
  computed,
  filters,
  directives,
  data () {
    return {
      loading: true,
      searchKey: null,
      filter: 'active',
      customers_loadings: {}
    }
  },
  created () {
    this.loadPage (this.$route.query.page || 1);
  }
}
