/*
* @INFO: this component is a rebuild of "select" element
* @PROP selected: is the selected option -> {text: 'the text to show', value: 'the value of the option'}
* @PROP options: an array of options -> [{text: '...', value: '...'}]
* @CALLBACK selectedChanged: fires when the selected option has changed
*/
const computed = {
  value () {
    let fallback = {
      value: "",
      text: "-- wrong selected prop --"
    };
    try {
      return typeof this.selected.value == 'undefined' ? fallback : this.selected;
    } catch (e) {
      return typeof this.selected == 'undefined' ? fallback : this.selected;
    }
  },
  filterredOptions () {
    if (this.filter_value)
      return this.options.filter((item) => item.text.toLowerCase().indexOf(this.filter_value.toLowerCase()) > -1);

    return this.options;
  }
}

const methods = {
  selectOption (option) {
    this.$emit('selectedChanged', option);
    this.filter_value = null;
  }
}

export default {
  name: 's-select',
  props: {
    'selected': {
      required: true,
      type: Object
    },
    'options': {
      required: true,
      type: Array
    }
  },
  computed,
  methods,
  data () {
    return {
      filter_value: null
      // active: this.selected
    }
  },
  mounted () {
    $('.dropdown').on('shown.bs.dropdown', () => {
      $(this.$refs.search_field).focus();
    })
  }
}
