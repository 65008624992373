var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-upload" }, [
    _c("input", {
      ref: "file",
      staticClass: "hidden",
      attrs: { type: "file", multiple: _vm.multiple, accept: _vm.accept },
      on: { change: _vm.selectFile }
    }),
    _vm._v(" "),
    _c("div", { ref: "name", staticClass: "file-upload--name" }),
    _vm._v(" "),
    _vm.files.length
      ? _c("div", { staticClass: "file-upload--choose" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-danger",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.removeFiles($event)
                }
              }
            },
            [_vm._v("\n      Reset\n    ")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "file-upload--choose" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.openUpload($event)
            }
          }
        },
        [_vm._v("\n      Choose\n    ")]
      )
    ]),
    _vm._v(" "),
    _c("div", {}, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-38a9ea20", { render: render, staticRenderFns: staticRenderFns })
  }
}