/*
* @INFO: this component is a custom text div with close button
* @slot: is the text that will be displaed
*/

  const methods = {
    close () {
      this.closeFlag = true;
    }
  }
  
  export default {
    name: 'closeableNote',
    methods,
    data () {
      return {
        closeFlag: false
      }
    }
  }
  