import toastr from 'toastr'
import { dateFormat } from 'filters'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'
import viewReservation from 'layouts/partials/viewReservation'


const directives = {
  hasPermits
}

const filters = {
  dateFormat
}

const components = {
  confirm, viewReservation
}

const methods = {
  showReservationDetails (reservation) {
    this.$set(this.loadings, reservation.id, true);
    this.$store.dispatch('reservations.get', reservation.id).then(() => {
      this.loadings[reservation.id] = false;
      let errors = _.cloneDeep(this.$store.state.locations.errors);
      if (errors) {
        toastr.error(errors.message);
      } else {
        this.reservation = this.$store.state.locations.active_reservation;
        this.$refs.view.open(this.reservation);
      }
    });

    return false;
  },
  getList () {
    this.loading = true;

    this.$store.dispatch('dashboards.getCheckOutList', {
      location_id: this.location.id,
      params: {
        filter: this.checked_out,
        _q: this.search_key
      }
    }).then(() => {
      this.loading = false;
      this.total_checkouts_count = this.list.length;
    });
  },

  toggleCheck (item) {
    if (item.checked_out_time) {
      this.$refs.confirm.confirm('Are you sure?', () => {
        this.checkReservation(item.id);
      });
    } else {
      this.checkReservation(item.id);
    }
  },

  checkReservation (id) {
    this.$store.dispatch('dashboards.checkOutReservation', id);
  },

  search (value) {
    if (value.length) {
      this.search_key = value;
    } else {
      this.search_key = null;
    }
    this.getList();
  },
  onSearchKeyup(value, e) {
    if (!value.length || e.keyCode == 13) {
      this.search(value);
    }
  }
}

const computed = {
  list () {
    return _.cloneDeep(this.$store.state.dashboards.checkout_list) || []
  },
}

const watch = {
  location (n) {
    this.getList();
  }
}

export default {
  name: 'checkout-list',
  props: ['location'],
  methods,
  watch,
  computed,
  filters,
  components,
  directives,
  data () {
    return {
      loading: false,
      loadings: {},
      checked_out: null,
      search_key: null,
      reservation: null,
      total_checkouts_count: 0,
    }
  },
  created () {
    if (this.location)
      this.getList();
  }
}
