var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "nav nav-pills nav-fill margin-bottom-4x" }, [
    _c(
      "li",
      {
        staticClass: "nav-item nav-link",
        class: {
          active: _vm.$route.name == "locations.administration.employees"
        },
        attrs: { role: "presentation" }
      },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: {
                name: "locations.administration.employees",
                params: {
                  id: _vm.$route.params.id
                }
              }
            }
          },
          [_vm._v("\n        Employees\n      ")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "li",
      {
        directives: [
          {
            name: "hasPermits",
            rawName: "v-hasPermits:allow",
            value: { id: _vm.location_id, permits: ["reservation_list"] },
            expression: "{id: location_id, permits: ['reservation_list']}",
            arg: "allow"
          }
        ],
        staticClass: "nav-item nav-link",
        class: {
          active: _vm.$route.name == "locations.administration.reservations"
        },
        attrs: { role: "presentation" }
      },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: {
                name: "locations.administration.reservations",
                params: {
                  id: _vm.$route.params.id
                }
              }
            }
          },
          [_vm._v("\n        Reservations\n      ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d1b4eae6", { render: render, staticRenderFns: staticRenderFns })
  }
}