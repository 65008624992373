var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { staticClass: "main-page", attrs: { "no-sidebar": true } },
    [
      _c("div", { staticClass: "main-page-board" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._v("\n        Choose from the menu to start\n      ")
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5252bf00", { render: render, staticRenderFns: staticRenderFns })
  }
}