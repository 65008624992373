import {
  employees,
  reservations
} from 'pages/locations/administration'

export default [
  //administration
  { path: '/locations/:id/administration/employees', name: 'locations.administration.employees', component: employees
    , meta:{permits: ['location_user', 'EMPLOYEE_LIST']}},
  { path: '/locations/:id/administration/reservations', name: 'locations.administration.reservations', component: reservations
    , meta:{permits:['location_user', 'reservations_list']}},
]
