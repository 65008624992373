var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-sidebar": true } },
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Affiliates")]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "card mb-4" }, [
            _c("div", { staticClass: "card-block" }, [
              _c("div", { staticClass: "row margin-bottom-3x" }, [
                _c("div", { staticClass: "col-md-3 margin-bottom-3x" }, [
                  _c("div", { staticClass: "input-group margin-bottom-1x" }, [
                    _c("input", {
                      ref: "search",
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "Search for..." },
                      domProps: { value: _vm.searchKey },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            $event.keyCode !== 13
                          ) {
                            return null
                          }
                          return _vm.search(_vm.$refs.search.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-group-append" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.search(_vm.$refs.search.value)
                            }
                          }
                        },
                        [_vm._v("Go!")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group radio" }, [
                    _c("label", { staticClass: "margin-right-2x" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filter,
                            expression: "filter"
                          }
                        ],
                        attrs: { type: "radio", value: "active" },
                        domProps: { checked: _vm._q(_vm.filter, "active") },
                        on: {
                          change: [
                            function($event) {
                              _vm.filter = "active"
                            },
                            function($event) {
                              return _vm.filterChanged()
                            }
                          ]
                        }
                      }),
                      _vm._v("\n              Active\n            ")
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "margin-right-2x" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filter,
                            expression: "filter"
                          }
                        ],
                        attrs: { type: "radio", value: "deleted" },
                        domProps: { checked: _vm._q(_vm.filter, "deleted") },
                        on: {
                          change: [
                            function($event) {
                              _vm.filter = "deleted"
                            },
                            function($event) {
                              return _vm.filterChanged()
                            }
                          ]
                        }
                      }),
                      _vm._v("\n              Deleted\n            ")
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "margin-right-2x" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filter,
                            expression: "filter"
                          }
                        ],
                        attrs: { type: "radio", value: "all" },
                        domProps: { checked: _vm._q(_vm.filter, "all") },
                        on: {
                          change: [
                            function($event) {
                              _vm.filter = "all"
                            },
                            function($event) {
                              return _vm.filterChanged()
                            }
                          ]
                        }
                      }),
                      _vm._v("\n              All\n            ")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-9" }, [
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-default",
                          attrs: { to: { name: "affiliates.create" } }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-plus",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v("\n              New Affiliates\n            ")
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-striped" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Name")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Logo")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Active")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Attribute")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Website")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Edit")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Remove")])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.affiliates.data, function(item, i) {
                          return _c("tr", [
                            _c("td", [_vm._v(_vm._s(item.name))]),
                            _vm._v(" "),
                            _c("td", [
                              _c("img", {
                                staticClass: "affiliate--logo",
                                attrs: { src: item.logo }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.active))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.attribute))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(item.website))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "affiliates.update",
                                        params: { id: item.id }
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-pencil",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _vm.loadings[item.id]
                                ? _c("div", [
                                    _c("i", {
                                      staticClass: "fa fa-spinner fa-spin"
                                    })
                                  ])
                                : _c("div", {}, [
                                    !item.deleted_at
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "text-danger",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.removePage(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-trash",
                                              attrs: { "aria-hidden": "true" }
                                            })
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass: "text-success",
                                            on: {
                                              click: function($event) {
                                                return _vm.undoRemove(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-undo",
                                              attrs: { "aria-hidden": "true" }
                                            })
                                          ]
                                        )
                                  ])
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("pagination", {
                    attrs: { "pages-number": _vm.affiliates.last_page },
                    on: {
                      pageChanged: function(page) {
                        return _vm.loadPage(page)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ]),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } }),
      _vm._v(" "),
      _c("confirm", { ref: "confirmUndoDelete", attrs: { type: "success" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-17e95036", { render: render, staticRenderFns: staticRenderFns })
  }
}