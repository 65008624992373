var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c("div", [
          _c("h3", { staticClass: "mb-2" }, [_vm._v(_vm._s(_vm.port.name))]),
          _vm._v(" "),
          _c("div", { staticClass: "card mb-4" }, [
            _c(
              "div",
              { staticClass: "card-block " },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: ["port_update"],
                        expression: "['port_update']",
                        arg: "allow"
                      }
                    ],
                    staticClass: "text-right"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.processing },
                        attrs: { disabled: _vm.processing },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit()
                          }
                        }
                      },
                      [
                        _vm.processing
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-spinner fa-spin fa-fw"
                              })
                            ])
                          : _vm._e(),
                        _vm._v("\n          Save\n        ")
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "portName" } }, [
                        _vm._v("Port Name")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.name,
                            expression: "form.name",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "portName",
                          placeholder: "Port Name"
                        },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "name",
                                $event.target.value.trim()
                              )
                            },
                            _vm.updateDropCode
                          ],
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.name
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.name[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group " }, [
                      _c("label", [_vm._v("Port Type")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "radio" }, [
                        _c("label", { staticClass: "mr-2" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.type,
                                expression: "form.type"
                              }
                            ],
                            attrs: { type: "radio", value: "airport" },
                            domProps: {
                              checked: _vm._q(_vm.form.type, "airport")
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form, "type", "airport")
                              }
                            }
                          }),
                          _vm._v("\n                Airport\n              ")
                        ]),
                        _vm._v(" "),
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.type,
                                expression: "form.type"
                              }
                            ],
                            attrs: { type: "radio", value: "seaport" },
                            domProps: {
                              checked: _vm._q(_vm.form.type, "seaport")
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form, "type", "seaport")
                              }
                            }
                          }),
                          _vm._v("\n                Seaport\n              ")
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.errors.type
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.type[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "code" } }, [_vm._v("Code")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.code,
                            expression: "form.code",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "code",
                          placeholder: "Airport Code"
                        },
                        domProps: { value: _vm.form.code },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "code",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.code
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.code[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "dropCode" } }, [
                        _vm._v("Drop Code")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.drop_code,
                            expression: "form.drop_code",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "dropCode",
                          placeholder: "Drop Code"
                        },
                        domProps: { value: _vm.form.drop_code },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "drop_code",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.drop_code
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.drop_code[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "slug" } }, [
                        _vm._v("Friendly URL")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.slug,
                            expression: "form.slug",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "slug",
                          placeholder: "SEO Friendly URL"
                        },
                        domProps: { value: _vm.form.slug },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "slug",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.slug
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.slug[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "timezone" } }, [
                        _vm._v("Timezone")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.form.timezone_id,
                              expression: "form.timezone_id",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { placeholder: "Timezone" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "timezone_id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.timezones, function(timezone) {
                          return _c(
                            "option",
                            { domProps: { value: timezone.id } },
                            [_vm._v(_vm._s(timezone.name))]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.errors.timezone_id
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.timezone_id[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "phoneNumber" } }, [
                        _vm._v("Phone")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.phone,
                            expression: "form.phone",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "phoneNumber",
                          placeholder: "phone number"
                        },
                        domProps: { value: _vm.form.phone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "phone",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.phone
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.phone[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "fax" } }, [
                        _vm._v("Fax Number")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.fax,
                            expression: "form.fax",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "fax",
                          placeholder: "Fax Number"
                        },
                        domProps: { value: _vm.form.fax },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "fax",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.fax
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.fax[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "country" } }, [
                        _vm._v("Country")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.form.country_id,
                              expression: "form.country_id",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { placeholder: "Country" },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.form,
                                  "country_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.onChangeCountry
                            ]
                          }
                        },
                        _vm._l(_vm.countries, function(country) {
                          return _c(
                            "option",
                            { domProps: { value: country.id } },
                            [_vm._v(_vm._s(country.name))]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.errors.country_id
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.country_id[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("State")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.form.state_id,
                              expression: "form.state_id",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { placeholder: "State" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "state_id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.states, function(state) {
                          return _c(
                            "option",
                            { domProps: { value: state.id } },
                            [_vm._v(_vm._s(state.name))]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.errors.state_id
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.state_id[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "city" } }, [_vm._v("City")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.city,
                            expression: "form.city",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "city",
                          placeholder: "City Name"
                        },
                        domProps: { value: _vm.form.city },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "city",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.city
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.city[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "city" } }, [
                        _vm._v("Address")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.address,
                            expression: "form.address",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "address",
                          placeholder: "Street Address"
                        },
                        domProps: { value: _vm.form.address },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "address",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.address
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.address[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "locationLatitude" } }, [
                          _vm._v("Geo Coordinates")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("placesAutocomplete", {
                              staticClass: "form-control margin-bottom-3x",
                              attrs: {
                                type: "text",
                                id: "locationPlace",
                                placeholder: "Search for your city or place"
                              },
                              model: {
                                value: _vm.googlePlace,
                                callback: function($$v) {
                                  _vm.googlePlace = $$v
                                },
                                expression: "googlePlace"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("u", [
                          _c("i", [
                            _vm._v(
                              "\n              Search for your place and then click on the map to pin your location\n            "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("google-maps-movable-marker", {
                          staticClass: "admin-location-map",
                          attrs: {
                            center: _vm.center,
                            "default-marker": {
                              lat: parseFloat(_vm.form.latitude),
                              lng: parseFloat(_vm.form.longitude)
                            }
                          },
                          on: { locationChanged: _vm.onLocationChanged }
                        }),
                        _vm._v(" "),
                        _vm.errors.latitude
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.latitude[0]) +
                                  "\n            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.errors.longitude
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.longitude[0]) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "city" } }, [
                          _vm._v("Description")
                        ]),
                        _vm._v(" "),
                        _c("vue-html5-editor", {
                          attrs: {
                            height: 100,
                            content: _vm.form.description || ""
                          },
                          on: {
                            change: function(x) {
                              return (_vm.form.description = x)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.description
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.errors.description[0]) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "city" } }, [
                        _vm._v("Head Title")
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.head_title,
                            expression: "form.head_title",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        domProps: { value: _vm.form.head_title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "head_title",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.head_title
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.head_title[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "city" } }, [
                        _vm._v("Head Description")
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.head_description,
                            expression: "form.head_description",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        domProps: { value: _vm.form.head_description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "head_description",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.head_description
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.head_description[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "city" } }, [
                        _vm._v("Head Keywords")
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.form.head_keywords,
                            expression: "form.head_keywords",
                            modifiers: { trim: true }
                          }
                        ],
                        staticClass: "form-control",
                        domProps: { value: _vm.form.head_keywords },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "head_keywords",
                              $event.target.value.trim()
                            )
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.head_keywords
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.errors.head_keywords[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("label", [_vm._v("Photos")]),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c(
                        "div",
                        { staticClass: "well port-custom-well-box" },
                        [
                          _c(
                            "file-upload",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["PORT_UPDATE"],
                                  expression: "['PORT_UPDATE']",
                                  arg: "allow"
                                }
                              ],
                              ref: "file_upload",
                              attrs: {
                                id: "photosUpload",
                                multiple: true,
                                accept: "image/*"
                              },
                              on: { filesChanged: _vm.imagesToUpload }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  class: { disabled: _vm.files_processing },
                                  attrs: {
                                    id: "uploadphotoBtn",
                                    disabled: _vm.files_processing
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.uploadImages($event)
                                    }
                                  }
                                },
                                [
                                  _vm.files_processing
                                    ? _c("span", [
                                        _vm.files_processing
                                          ? _c("i", {
                                              staticClass:
                                                "fa fa-spinner fa-spin fa-fw"
                                            })
                                          : _vm._e()
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                  Upload\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.errors.images
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.errors.images[0]) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.photos.length
                            ? _c("div", { staticClass: "margin-top-2x" }, [
                                _c(
                                  "ul",
                                  { staticClass: "images-list" },
                                  _vm._l(_vm.photos, function(photo) {
                                    return _c("li", [
                                      _c("div", {}, [
                                        _c("img", {
                                          staticClass: "pointer thumbnail",
                                          class: {
                                            "port-featured-photo":
                                              photo.featured
                                          },
                                          attrs: {
                                            src: photo.image_url,
                                            alt: "",
                                            "data-toggle": "tooltip",
                                            title: photo.featured
                                              ? "This is the featured photo"
                                              : "Make this photo featured?"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.setFeatured(photo)
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            directives: [
                                              {
                                                name: "hasPermits",
                                                rawName: "v-hasPermits:allow",
                                                value: ["PORT_UPDATE"],
                                                expression: "['PORT_UPDATE']",
                                                arg: "allow"
                                              }
                                            ],
                                            staticClass: "remove text-danger",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.removePhoto(photo)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times"
                                            })
                                          ]
                                        )
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: ["port_update"],
                        expression: "['port_update']",
                        arg: "allow"
                      }
                    ],
                    staticClass: "text-right"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        class: { disabled: _vm.processing },
                        attrs: { disabled: _vm.processing },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit()
                          }
                        }
                      },
                      [
                        _vm.processing
                          ? _c("span", [
                              _c("i", {
                                staticClass: "fa fa-spinner fa-spin fa-fw"
                              })
                            ])
                          : _vm._e(),
                        _vm._v("\n          Save\n        ")
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("confirm", { ref: "removePhoto", attrs: { type: "danger" } })
              ],
              1
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-770c5a2e", { render: render, staticRenderFns: staticRenderFns })
  }
}