import { http } from 'services'
// initial state
const state = () => ({
  reservation: null,
  checkin_list: null,
  checkout_list: null,
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['dashboards.validateReservation'] ({ commit, state }, data) {
    commit('CLEAR_ERRORS')

    return (http.get(`reservations/${data.id}/validate?location_id=${data.location_id}`)).then ((res) => {
      commit('VALIDATE_RESERVATION', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.checkInReservation'] ({ commit }, id) {
    commit('CLEAR_ERRORS')

    return (http.post(`reservations/${id}/check-in`)).then ((res) => {
      commit('CHECKIN_RESERVATION', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.checkOutReservation'] ({ commit }, id) {
    commit('CLEAR_ERRORS')

    return (http.post(`reservations/${id}/check-out`)).then ((res) => {
      commit('CHECKOUT_RESERVATION', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.getCheckinList'] ({ commit }, {location_id, params}) {
    commit('CLEAR_ERRORS')
    if (!params) {
      params = {}
    }
    params._all = true;

    return (http.get(`locations/${location_id}/arrivals`, {params})).then ((res) => {
      commit('GET_CHECKIN_LIST', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },

  ['dashboards.getCheckOutList'] ({ commit }, {location_id, params}) {
    commit('CLEAR_ERRORS')
    if (!params) {
      params = {}
    }
    params._all = true;

    return (http.get(`locations/${location_id}/departures`, {params})).then ((res) => {
      commit('GET_CHECKOUT_LIST', res);
    })
    .catch ((res) => {
      commit('DASHBOARDS_ERROR', res);
    })
  },
}

// mutations
const mutations = {

  VALIDATE_RESERVATION (state, res) {
    state.reservation = res.data
  },

  GET_CHECKIN_LIST (state, res) {
    state.checkin_list = res.data
  },

  GET_CHECKOUT_LIST (state, res) {
    state.checkout_list = res.data
  },

  CHECKIN_RESERVATION (state, res) {
    let index = _.findIndex(state.checkin_list, (item) => {
      return item.id == res.data.id
    });

    if (index > -1 ) {
      state.checkin_list[index] = _.assign(state.checkin_list[index], res.data);
      state.checkin_list = _.cloneDeep(state.checkin_list);
    }
  },

  CHECKOUT_RESERVATION (state, res) {
    let index = _.findIndex(state.checkout_list, (item) => {
      return item.id == res.data.id
    });

    if (index > -1) {
      state.checkout_list[index] = _.assign(state.checkout_list[index], res.data);
      state.checkout_list = _.cloneDeep(state.checkout_list);
    }
  },

  //ERRORS
  DASHBOARDS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
