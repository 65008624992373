/*
* @DESC: this component makes an element editable when double click on it.
* @param value: the value that you need to change
* @callback: v-on:valueSaved="doSomething({value, close(), startLoading(), startLoading()})"
*/

const methods = {
  cancel () {
    this.editedValue = this.cachedVal;
    this.active = false;
    this.cachedVal = null;
  },
  close () {
    this.active = false;
    this.cachedVal = null;
  },
  startLoading () {
    this.loading = true;
  },
  stopLoading () {
    this.loading = false;
  },
  save (value) {
    this.$emit('valueSaved', {
      value,
      close: this.close,
      startLoading: this.startLoading,
      stopLoading: this.stopLoading
    });

    this.editedValue = value;
    if (this.autoCloseOnSave) {
      this.active = false;
    }
  },
  enterActiveMode () {
    this.active = true;
    this.cachedVal = _.cloneDeep(this.editedValue);
    this.$nextTick(() => {
      $(this.$refs.input).focus();
    });
  }
}

const watch = {
  value (n, o) {
    if (n == o) return;
    this.editedValue = n;
  }
}

export default {
  name: 'editable-text',
  props: ['value', 'autoCloseOnSave'],
  methods,
  watch,
  data () {
    return {
      active: false,
      cachedVal: null,
      editedValue: this.value,
      loading: false
    }
  },
  mounted () {

  }
}
