import { http } from 'services'
// initial state
const state = () => ({
  all: [],
  active: {},
  permissions: {},
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['roles.getAll'] ({ commit }, params) {
    if (!params._q) params._q = null

    commit('CLEAR_ERRORS')

    return (http.get('roles', {
      params
    })).then ((res) => {
      commit('GET_ALL_ROLES', res);
    })
    .catch ((res) => {
      commit('ROLES_ERROR', res);
    })
  },
  ['roles.newRole'] ({ commit }, data) {
    commit('CLEAR_ERRORS')

    return (http.post('roles', data))
    .then ((res) => {
      // commit('NEW_ROLE', res);
    })
    .catch ((res) => {
      commit('ROLES_ERROR', res);
    })
  },
  ['roles.editRole'] ({ commit }, {id, data}) {
    commit('CLEAR_ERRORS')

    return (http.put('roles/' + id , data))
    .then ((res) => {
      commit('EDIT_ROLE', res);
    })
    .catch ((res) => {
      commit('ROLES_ERROR', res);
    })
  },
  
  ['roles.removeRole'] ({ commit }, id) {
    commit('CLEAR_ERRORS')

    return (http.delete('roles/' + id))
    .then ((res) => {
      commit('REMOVE_ROLE', id);
    })
    .catch ((res) => {
      commit('ROLES_ERROR', res);
    })
  },

  ['roles.getRole'] ({ dispatch, commit, state }, id) {
    commit('CLEAR_ERRORS')
    // if (state.permissions.length) {
    //   return getRole({ dispatch, commit, state }, id)
    // }

    return dispatch('roles.getPermissions').then(() => {

      return getRole({ dispatch, commit, state }, id)
    });
  },

  ['roles.getPermissions'] ({ commit }, params) {
    if (!params) params = {}
    commit('CLEAR_ERRORS')

    return (http.get('permissions?_all=true', {params}))
    .then ((res) => {
      commit('GET_PERMISSIONS', res);
    })
    .catch ((res) => {
      commit('ROLES_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_ROLES (state, res) {

    state.all = res.data;
  },

  GET_ROLE (state, res) {
    state.active = res.data
  },

  REMOVE_ROLE (state, id) {
    state.all.data = _.filter(state.all.data, (item) => item.id != id);
  },

  EDIT_ROLE (state, res) {
    state.active = res.data
  },
  // NEW_ROLE (state, res) {
  //   // state.all.data =
  // },
  GET_PERMISSIONS (state, res) {
    let data = {};
    _.forEach(res.data, (value, key) => {
      let nameKey = value.name.split('_')[0];
      if (!data[nameKey]) {
        data[nameKey] = [];
      }
      data[nameKey].push(value);
    });
    state.permissions = _.cloneDeep(data);
  },

  //ERRORS
  ROLES_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

function getRole ({ dispatch, commit, state }, id) {
  return (http.get('roles/' + id))
  .then ((res) => {
    commit('GET_ROLE', res);
  })
  .catch ((res) => {
    commit('ROLES_ERROR', res);
  })
}

export default {
  state,
  getters,
  actions,
  mutations
}
