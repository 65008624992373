import layout from 'layouts/default'
import toastr from 'toastr'
import {tab, tabs} from 'components/tabs'
import general from './general'
import users from './users'

const components = {
  layout,
  tab,
  tabs,
  general,
  users
}

const methods = {
  
  onChangeTab (title) {
    this.active_tab = title;
  }
}

export default {
  name: 'edit-affiliates',
  components,
  methods,
  data () {
    return {
      loading: false,
      submit_loading: false,
      errors: {},
      formData: {},
      affiliate: null,
      active_tab: null
    }
  },
  created () {
    this.loading = true;
    this.$store.dispatch('affiliates.get', this.$route.params.id).then(() => {
      this.affiliate = _.cloneDeep(this.$store.state.affiliates.active);
      this.loading = false;
    });
  }
}
