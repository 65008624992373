<template>
  <div role="tabpanel" class="tab-pane" :class="{active: active}" :id="id">
    <slot></slot>
  </div>
</template>

<script>
  const computed = {
    active () {
      return this.$parent.active == this.title
    }
  }

  export default {
    props: ['title', 'permits'],
    computed,
    data () {
      return {
        id: this.title.replace(' ', '_') + '_' + (new Date().getTime() + (Math.round(Math.random() * 10000)))
      }
    },
    created () {
      this.$parent.titles.push({
        title: this.title,
        permits: this.permits,
        id: this.id
      });
    },
  }
</script>
