import _ from 'lodash'
import { http, permits } from 'services'

// initial state
const state = () => ({
  all: [],
  active: {},
  uploaded_file: null,
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  //get all ports (params: {_q: search name, page})
  ['pages.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null

    commit('CLEAR_ERRORS')

    return (http.get('p', {
      params
    })).then ((res) => {
      commit('GET_ALL_PAGES', res);
    }).catch ((res) => {
      commit('PAGES_ERROR', res);
    })
  },

  ['pages.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')

    return (Promise.all([
      http.get(`p/${id}`)
    ])).then(([
      page
    ]) => {
      commit('GET_PAGE', page);
    }).catch ((res) => {
      commit('PAGES_ERROR', res);
    });
  },


  //update port
  ['pages.edit'] ({dispatch, commit, state}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`p/${id}`, data)).then ((res) => {
      commit('EDIT_PAGE', {res});
    })
    .catch ((res) => {
      console.log(res)
      commit('PAGES_ERROR', res);
    })
  },

  ['pages.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`p/${id}`)).then ((res) => {
      commit('REMOVE_PAGE', res);
    }).catch ((res) => {
      commit('PAGES_ERROR', res);
    })
  },

  ['pages.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`p/${id}?_restore=true`)).then ((res) => {
      commit('UNDO_REMOVE_PAGE', res);
    }).catch ((res) => {
      commit('PAGES_ERROR', res);
    })
  },

  ['pages.add'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`p`, data)).then ((res) => {
      commit('NEW_PAGE', res);
    }).catch ((res) => {
      commit('PAGES_ERROR', res);
    })
  },

  ['pages.upload'] ({commit}, file) {
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();

    formData.append('image', file);
    
    return (http.post(`p/image`, formData, config)).then ((res) => {
      commit('PAGES_UPLOAD', res);
    }).catch ((res) => {
      commit('PAGES_ERROR', res);
    })
  }
}

// mutations
const mutations = {
  GET_ALL_PAGES (state, res) {
    state.all = res.data;
  },

  REMOVE_PAGE (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_PAGE (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_PAGE (state, res) {
    state.active = _.assign(state.active, res.data);

    state.active = _.cloneDeep(state.active);
  },

  EDIT_PAGE (state, {res, lang}) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  NEW_PAGE (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  PAGES_UPLOAD (state, res) {
    state.uploaded_file = res.data;
  },

  //ERRORS
  PAGES_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
