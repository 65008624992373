var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c("div", [
          _c("h3", { staticClass: "mb-2" }, [
            _vm._v(_vm._s(_vm.location.name))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("general", {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: [
                          "location_show",
                          "LOCATION_BOOKING_CONDITIONS_LIST"
                        ]
                      },
                      expression:
                        "{id: location.id, permits: ['location_show', 'LOCATION_BOOKING_CONDITIONS_LIST']}",
                      arg: "allow"
                    }
                  ]
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c("fees", {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: { id: _vm.location.id, permits: ["FEE_LIST"] },
                      expression: "{id: location.id, permits: ['FEE_LIST']}",
                      arg: "allow"
                    }
                  ]
                }),
                _vm._v(" "),
                _c("extra-fields", {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: {
                        id: _vm.location.id,
                        permits: ["EXTRAFIELDS_LIST"]
                      },
                      expression:
                        "{id: location.id, permits: ['EXTRAFIELDS_LIST']}",
                      arg: "allow"
                    }
                  ]
                })
              ],
              1
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5ad213a2", { render: render, staticRenderFns: staticRenderFns })
  }
}