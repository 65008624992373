import { http, permits } from 'services'

// initial state
const state = () => ({
  all: [],
  active: {},
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  //get all ports (params: {_q: search name, page})
  ['tripProtections.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._get) params._get = null

    commit('CLEAR_ERRORS')

    return (http.get('trip-protections', {
      params
    })).then ((res) => {
      commit('GET_ALL_TRIP_PROTECTIONS', res);
    }).catch ((res) => {
      commit('TRIP_PROTECTIONS_ERROR', res);
    })
  },

  ['tripProtections.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')
    // return;
    return (http.get(`trip-protections/${id}`)).then((res) => {
      commit('GET_TRIP_PROTECTION', res);
    }).catch ((res) => {
      commit('TRIP_PROTECTIONS_ERROR', res);
    });
  },


  //update port
  ['tripProtections.update'] ({dispatch, commit, state}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`trip-protections/${id}`, data)).then ((res) => {
      commit('EDIT_TRIP_PROTECTION', res);
    })
    .catch ((res) => {
      commit('TRIP_PROTECTIONS_ERROR', res);
    })
  },

  ['tripProtections.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`trip-protections/${id}`)).then ((res) => {
      commit('REMOVE_TRIP_PROTECTION', res);
    }).catch ((res) => {
      commit('TRIP_PROTECTIONS_ERROR', res);
    })
  },

  ['tripProtections.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`trip-protections/${id}?_restore=true`)).then ((res) => {
      commit('UNDO_REMOVE_TRIP_PROTECTION', res);
    }).catch ((res) => {
      commit('TRIP_PROTECTIONS_ERROR', res);
    })
  },

  ['tripProtections.create'] ({commit}, data) {
    commit('CLEAR_ERRORS');
    return (http.post(`trip-protections`, data)).then ((res) => {
      commit('NEW_TRIP_PROTECTION', res);
    }).catch ((res) => {
      commit('TRIP_PROTECTIONS_ERROR', res);
    })
  }
}

// mutations
const mutations = {
  GET_ALL_TRIP_PROTECTIONS (state, res) {
    state.all = res.data;
  },

  REMOVE_TRIP_PROTECTION (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_TRIP_PROTECTION (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_TRIP_PROTECTION (state, res) {
    state.active = res.data;
    state.active = _.cloneDeep(state.active);
  },

  EDIT_TRIP_PROTECTION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  NEW_TRIP_PROTECTION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  //ERRORS
  TRIP_PROTECTIONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
