var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("h3", { staticClass: "mb-2" }, [_vm._v("Edit Trip Protection Plan")]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c("div", { staticClass: "card mb-4" }, [
          _c("div", { staticClass: "card-block" }, [
            _c("form", {}, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["tripprotection_update"],
                      expression: "['tripprotection_update']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "text-right"
                },
                [
                  _vm.submit_loading
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: {
                            disabled: _vm.submit_loading,
                            type: "button"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-spinner fa-spin fa-fw"
                          }),
                          _vm._v("\n            SAVING...\n          ")
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onSubmitForm($event)
                            }
                          }
                        },
                        [_vm._v("\n            SAVE\n          ")]
                      )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row margin-top-4x" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "TripProtectionTitle" } }, [
                      _vm._v("Title")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.formData.title,
                          expression: "formData.title",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "TripProtectionTitle",
                        placeholder: "Trip Protection Title"
                      },
                      domProps: { value: _vm.formData.title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formData,
                            "title",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.title
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.title[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "number_of_hours" } }, [
                          _vm._v("Number Of Hours "),
                          _c("small", [_vm._v("after the reservation")])
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.formData.number_of_hours,
                              expression: "formData.number_of_hours",
                              modifiers: { trim: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "number_of_hours",
                            placeholder: "Number Of Hours"
                          },
                          domProps: { value: _vm.formData.number_of_hours },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.formData,
                                "number_of_hours",
                                $event.target.value.trim()
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.number_of_hours
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.errors.number_of_hours[0]) +
                                  "\n                  "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Prices")]),
                    _vm._v(" "),
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Currency Code")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Price")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Percentage")]),
                          _vm._v(" "),
                          _c("th")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.formData.prices, function(item, i) {
                          return _c("tr", [
                            _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formData.prices[i].currency_id,
                                      expression:
                                        "formData.prices[i].currency_id"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.formData.prices[i],
                                        "currency_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { domProps: { value: null } }, [
                                    _vm._v("Select Currency")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.currencies, function(curr) {
                                    return _c(
                                      "option",
                                      { domProps: { value: curr.id } },
                                      [
                                        _vm._v(
                                          _vm._s(curr.name + " - " + curr.code)
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.errors["prices." + i + ".currency_id"]
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.errors[
                                            "prices." + i + ".currency_id"
                                          ][0]
                                        ) +
                                        "\n                      "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData.prices[i].price,
                                    expression: "formData.prices[i].price"
                                  }
                                ],
                                staticClass: "form-control",
                                domProps: {
                                  value: _vm.formData.prices[i].price
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formData.prices[i],
                                      "price",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.errors["prices." + i + ".price"]
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.errors[
                                            "prices." + i + ".price"
                                          ][0]
                                        ) +
                                        "\n                      "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData.prices[i].percentage,
                                    expression: "formData.prices[i].percentage"
                                  }
                                ],
                                staticClass: "form-control",
                                domProps: {
                                  value: _vm.formData.prices[i].percentage
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.formData.prices[i],
                                      "percentage",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.errors["prices." + i + ".percentage"]
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.errors[
                                            "prices." + i + ".percentage"
                                          ][0]
                                        ) +
                                        "\n                      "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              i > 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.removePrice(i)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-trash",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ])
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _vm.errors.currency_id
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.currency_id[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.createPrice($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-plus",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(
                            "\n                  Add another price\n                "
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "short_description" } }, [
                        _vm._v("Short Description")
                      ]),
                      _vm._v(" "),
                      _c("vue-html5-editor", {
                        attrs: {
                          height: 100,
                          content: _vm.formData.short_description
                        },
                        on: { change: _vm.wysiwygShortDescriptionChanged }
                      }),
                      _vm._v(" "),
                      _vm.errors.short_description
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.short_description[0]) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "seo_keywords" } }, [
                        _vm._v("Description")
                      ]),
                      _vm._v(" "),
                      _c("vue-html5-editor", {
                        attrs: { content: _vm.formData.description },
                        on: { change: _vm.wysiwygDescriptionChanged }
                      }),
                      _vm._v(" "),
                      _vm.errors.description
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errors.description[0]) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0149380a", { render: render, staticRenderFns: staticRenderFns })
  }
}