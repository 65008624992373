var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "func-modal",
        {
          ref: "modal",
          attrs: {
            title: (_vm.cancel ? "Cancel" : "Update") + " Reservation",
            "hide-footer": true
          },
          on: { close: _vm.onClose }
        },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _vm.loading
                ? _c("div", { staticClass: "text-center" }, [
                    _c("i", {
                      staticClass: "fa fa-circle-o-notch fa-spin fa-2x fa-fw"
                    })
                  ])
                : _c("div", {}, [
                    !_vm.use_new_card || _vm.cancel
                      ? _c("div", [
                          _vm._.isEmpty(_vm.charge_cards) &&
                          _vm._.isEmpty(_vm.refund_cards) &&
                          _vm.form.refund_type == "without_refund"
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-info f18 margin-bottom-3x"
                                },
                                [
                                  _vm._v(
                                    "The reservation will be updated with no extra charges and nothing will be refunded."
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm._.isEmpty(_vm.charge_cards)
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-info f18 margin-bottom-3x"
                                },
                                [
                                  _vm._v("The customer will be "),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("charged")
                                  ]),
                                  _vm._v(" as the following:")
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm._.isEmpty(_vm.refund_cards)
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-info f18 margin-bottom-3x"
                                },
                                [
                                  _vm._v("The customer will get "),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("refund")
                                  ]),
                                  _vm._v(" as the following:")
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm._.isEmpty(_vm.charge_cards) ||
                          !_vm._.isEmpty(_vm.refund_cards)
                            ? _c(
                                "table",
                                { staticClass: "table table-bordered" },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("TYPE")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("CARD NUMBER")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("AMOUNT")])
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.charge_cards.length
                                        ? _vm.charge_cards
                                        : _vm.refund_cards,
                                      function(item) {
                                        return _c("tr", [
                                          _c("td", [
                                            item.type == "credit_card"
                                              ? _c("span", [
                                                  _vm._v("Credit Card")
                                                ])
                                              : item.type == "customer_credit"
                                              ? _c("span", [
                                                  _vm._v("Customer Credit")
                                                ])
                                              : item.type == "customer_points"
                                              ? _c("span", [_vm._v("Points")])
                                              : _vm._e()
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            item.type == "credit_card"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.card_number)
                                                  )
                                                ])
                                              : item.type == "customer_points"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.number_of_points
                                                    )
                                                  )
                                                ])
                                              : _c("span", [_vm._v("-")])
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  item.amount,
                                                  _vm.currencySymbol(
                                                    _vm.currency_code
                                                  )
                                                )
                                              )
                                            )
                                          ])
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm._.isEmpty(_vm.charge_cards)
                      ? _c("div", [
                          !_vm.use_new_card
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.use_new_card = true
                                    }
                                  }
                                },
                                [_vm._v("Use new card")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.use_new_card
                            ? _c(
                                "div",
                                [
                                  _c("div", {}, [
                                    _vm.use_new_card
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "mb-2 btn btn-default",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                _vm.use_new_card = false
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-angle-left",
                                              attrs: { "aria-hidden": "true" }
                                            }),
                                            _vm._v(" BACK")
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-info f18 margin-bottom-3x"
                                    },
                                    [_vm._v("Please fill card infromation")]
                                  ),
                                  _vm._v(" "),
                                  _c("payment-form", {
                                    key: _vm.currency_code,
                                    attrs: {
                                      activeHistory: _vm.activeHistory,
                                      processing: _vm.processing,
                                      errors: _vm.errors,
                                      newCard: true
                                    },
                                    on: { submit: _vm.submit }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.use_new_card
                      ? _c("div", { staticClass: "text-right" }, [
                          _vm.cancel ||
                          _vm.form.refund_type == "partial_refund" ||
                          _vm.form.refund_type == "full_refund"
                            ? _c(
                                "div",
                                { staticClass: "text-left margin-top-3x" },
                                [
                                  _vm.cancel
                                    ? _c("label", { attrs: { for: "" } }, [
                                        _vm._v("Cancellation Reason:")
                                      ])
                                    : _c("label", { attrs: { for: "" } }, [
                                        _vm._v("Reason:")
                                      ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.reason,
                                        expression: "form.reason"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.form.reason },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "reason",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.errors.reason
                                    ? _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.errors.reason[0]) +
                                              " \n            "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("hr"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-input checkbox text-left" },
                            [
                              _vm.is_reservation_started &&
                              _vm._.isEmpty(_vm.charge_cards)
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: ["super_admin"],
                                          expression: "['super_admin']",
                                          arg: "allow"
                                        }
                                      ],
                                      staticClass:
                                        "form-input checkbox text-left"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-between"
                                        },
                                        [
                                          _c("label", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.form.refund_type,
                                                  expression: "form.refund_type"
                                                }
                                              ],
                                              class: {
                                                disabled: _vm.processing
                                              },
                                              attrs: {
                                                type: "radio",
                                                disabled: _vm.processing,
                                                name: "refund_type",
                                                value: "full_refund",
                                                required: ""
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.form.refund_type,
                                                  "full_refund"
                                                )
                                              },
                                              on: {
                                                change: [
                                                  function($event) {
                                                    return _vm.$set(
                                                      _vm.form,
                                                      "refund_type",
                                                      "full_refund"
                                                    )
                                                  },
                                                  _vm.getChargeOrRefundList
                                                ]
                                              }
                                            }),
                                            _vm._v(
                                              "\n                  Force Full Refund\n                "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          !_vm.cancel && _vm.refund_cards.length
                                            ? _c(
                                                "label",
                                                { staticClass: "w-50" },
                                                [
                                                  _vm._v(
                                                    "Refund Amount (" +
                                                      _vm._s(
                                                        _vm.refund_cards.reduce(
                                                          function(
                                                            sum,
                                                            object
                                                          ) {
                                                            return (
                                                              sum +
                                                              object.amount
                                                            )
                                                          },
                                                          0
                                                        )
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.cancel ||
                                      _vm.form.to_date <
                                        _vm.activeHistory.to_date
                                        ? _c("div", {}, [
                                            _c("label", {}, [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.form.refund_type,
                                                    expression:
                                                      "form.refund_type"
                                                  }
                                                ],
                                                class: {
                                                  disabled: _vm.processing
                                                },
                                                attrs: {
                                                  type: "radio",
                                                  disabled: _vm.processing,
                                                  name: "refund_type",
                                                  value: "partial_refund"
                                                },
                                                domProps: {
                                                  checked: _vm._q(
                                                    _vm.form.refund_type,
                                                    "partial_refund"
                                                  )
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      return _vm.$set(
                                                        _vm.form,
                                                        "refund_type",
                                                        "partial_refund"
                                                      )
                                                    },
                                                    _vm.getChargeOrRefundList
                                                  ]
                                                }
                                              }),
                                              _vm._v(
                                                "\n                  Force Partial Refund\n                "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _vm.form.refund_type ==
                                            "partial_refund"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-content-between"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "w-50 margin-top-2x"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Available Amount (" +
                                                            _vm._s(
                                                              _vm.avaliable_amount.toFixed(
                                                                2
                                                              )
                                                            ) +
                                                            ")"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.trim",
                                                          value:
                                                            _vm.form.amount,
                                                          expression:
                                                            "form.amount",
                                                          modifiers: {
                                                            trim: true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "form-control w-50",
                                                      class: {
                                                        disabled: _vm.processing
                                                      },
                                                      attrs: {
                                                        type: "text",
                                                        disabled:
                                                          _vm.processing,
                                                        placeholder:
                                                          "Amount to be refunded"
                                                      },
                                                      domProps: {
                                                        value: _vm.form.amount
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm.form,
                                                            "amount",
                                                            $event.target.value.trim()
                                                          )
                                                        },
                                                        blur: function($event) {
                                                          return _vm.$forceUpdate()
                                                        }
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.errors.amount
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.errors.amount[0]
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c("label", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.form.refund_type,
                                                expression: "form.refund_type"
                                              }
                                            ],
                                            class: { disabled: _vm.processing },
                                            attrs: {
                                              type: "radio",
                                              disabled: _vm.processing,
                                              name: "refund_type",
                                              value: "without_refund"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.form.refund_type,
                                                "without_refund"
                                              )
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  return _vm.$set(
                                                    _vm.form,
                                                    "refund_type",
                                                    "without_refund"
                                                  )
                                                },
                                                _vm.getChargeOrRefundList
                                              ]
                                            }
                                          }),
                                          _vm._v(
                                            "\n                  Without Refund\n                "
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _vm.errors.refund_type
                                        ? _c(
                                            "div",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.errors.refund_type[0]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.cancel
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermits",
                                          rawName: "v-hasPermits:allow",
                                          value: ["super_admin"],
                                          expression: "['super_admin']",
                                          arg: "allow"
                                        }
                                      ],
                                      staticClass:
                                        "form-input checkbox text-left"
                                    },
                                    [
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.form.refund_trip_protection,
                                              expression:
                                                "form.refund_trip_protection"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.form.refund_trip_protection
                                            )
                                              ? _vm._i(
                                                  _vm.form
                                                    .refund_trip_protection,
                                                  null
                                                ) > -1
                                              : _vm.form.refund_trip_protection
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a =
                                                    _vm.form
                                                      .refund_trip_protection,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.form,
                                                        "refund_trip_protection",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.form,
                                                        "refund_trip_protection",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "refund_trip_protection",
                                                    $$c
                                                  )
                                                }
                                              },
                                              _vm.getChargeOrRefundList
                                            ]
                                          }
                                        }),
                                        _vm._v(
                                          "\n                Refund Trip Protection\n              "
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success",
                              class: [{ disabled: _vm.processing }],
                              attrs: {
                                disabled: _vm.processing,
                                type: "button"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.sendData($event)
                                }
                              }
                            },
                            [
                              _vm.processing
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fa fa-spinner fa-spin fa-fw"
                                    }),
                                    _vm._v(
                                      "\n              Saving...\n            "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n              Confirm\n            "
                                    )
                                  ])
                            ]
                          )
                        ])
                      : _vm._e()
                  ]),
              _vm._v(" "),
              _c("confirm", {
                ref: "warningConfirm",
                attrs: { type: "warning" }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-13394c83", { render: render, staticRenderFns: staticRenderFns })
  }
}