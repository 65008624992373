import objectPath from 'object-path'
import pagination from 'components/pagination'
import { http } from 'services'
import { hasPermits } from 'directives'

const components = {
  pagination
}

const directives = {
  hasPermits
}

let methods = {
  _sort(attribute, sortable) {

    if (!sortable) {
      return;
    }

    
    if (this.sort_by == attribute) {
      this.sort_dir = this.sort_dir == 'asc' ? 'desc' : 'asc';
    } else {
      this.sort_by = attribute
      this.sort_dir = 'asc'
    }

    this.$emit('sortChanged', {sort_by: this.sort_by, sort_dir: this.sort_dir})

    if (this.ajaxUrl) {
      this.buildRows()
      return;
    }

    return _.orderBy(this.rows, [this.sort_by], [this.sort_dir]);
  },

  buildRows() {
    if (this.ajaxUrl) {
      this.getDataFromAjax()
    } else {
      this.rows = this.data;
    }
  },

  getDataFromAjax(page = 1) {

    // Build get params
    var params = ''
    if (this.ajaxUrl.indexOf('?') > -1) {
      params = '&page=' + page
    } else {
      params = '?page=' + page
    }

    if (this.sort_by) {
      params += '&_sort=' + (this.sort_dir == 'desc' ? '-' : '') + this.sort_by;
    }

    if (this.filters) {
      _.each(this.filters, function (value, key) {
        if (value)
          params += '&' + (key + '=' + value);
      });
    }

    this.loading = true;
    this.url = this.ajaxUrl + params;
    http.get(this.url)
      .then((res) => {
        this.loading = false
        this.rows = res.data.data
        this.last_page = res.data.last_page
        this.$router.replace({ query: Object.assign({}, this.$route.query, {
          page: res.data.current_page
        })})
      }).catch((err) => {
        if (err.response.data.errors) {
          this.errors = err.response.data.errors;
          this.$emit('datatableErrors', this.errors);
        }
        this.rows = [];
        this.loading = false
      })
  },
  setCols() {
    for (let i in this.columns) {
      if (!_.isFunction(this.columns[i].click)) {
        this.columns[i].click = () => { };
      }
      if (!_.isFunction(this.columns[i].hover)) {
        this.columns[i].hover = () => { };
      }
      if (!this.columns[i].hasOwnProperty('visible')) {
        this.columns[i].visible = true;
      }
    }
  },

  run () {
    this.buildRows()
    this.setCols();
  }
}

export default {
  name: 'datatable',
  components,
  directives,
  props: {
    ajaxUrl: {
      type: String,
      default: null
    },
    tableClass: {
      type: String,
      default: 'table'
    },
    columns: [Object, Array],
    data: [Object, Array, String, Function],
    filters: {
      type: [Object, Array],
      default: null
    },
    sort: {
      type: Function,
    },
    runOnCreate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sort_by: null,
      sort_dir: null,
      rows: [],
      last_page: 1,
      objectPath,
      loading: false,
      url: this.ajaxUrl,
    }
  },
  methods,
  created() {
    console.log('this.runOnCreate', this.runOnCreate);
    
    if (this.runOnCreate) {
      this.run()
    }
  }
}
