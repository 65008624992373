var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c(
          "div",
          [
            _c("h3", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(_vm.location.name))
            ]),
            _vm._v(" "),
            _c("tabs"),
            _vm._v(" "),
            _c("div", { staticClass: "card mb-4" }, [
              _c("div", { staticClass: "card-block " }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-3 margin-bottom-3x" }, [
                    _c("div", { staticClass: "margin-bottom-1x" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search_key,
                            expression: "search_key"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "search-field",
                          type: "text",
                          placeholder: "Res. # or Email"
                        },
                        domProps: { value: _vm.search_key },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.search_key = $event.target.value
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "radio" }, [
                      _c("span", [_vm._v("Cancelled:")]),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-left-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.cancelled,
                              expression: "filter.cancelled"
                            }
                          ],
                          attrs: { type: "radio" },
                          domProps: {
                            value: null,
                            checked: _vm._q(_vm.filter.cancelled, null)
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.filter, "cancelled", null)
                            }
                          }
                        }),
                        _vm._v(" All\n              ")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-left-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.cancelled,
                              expression: "filter.cancelled"
                            }
                          ],
                          attrs: { type: "radio", value: "true" },
                          domProps: {
                            checked: _vm._q(_vm.filter.cancelled, "true")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.filter, "cancelled", "true")
                            }
                          }
                        }),
                        _vm._v(" Yes\n              ")
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-left-2x" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.cancelled,
                              expression: "filter.cancelled"
                            }
                          ],
                          attrs: { type: "radio", value: "false" },
                          domProps: {
                            checked: _vm._q(_vm.filter.cancelled, "false")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.filter, "cancelled", "false")
                            }
                          }
                        }),
                        _vm._v(" No\n              ")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "margin-top-3x margin-top-3x margin-bottom-3x"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-default btn-orange search-btn",
                            on: {
                              click: function($event) {
                                return _vm.loadPage(1)
                              }
                            }
                          },
                          [_vm._v("Search")]
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.loading
                  ? _c("div", { staticClass: "text-center" }, [
                      _c("i", {
                        staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                      })
                    ])
                  : _c(
                      "div",
                      { staticClass: "table-responsive" },
                      [
                        _c("reservations-list", {
                          attrs: { reservations: _vm.location.reservations },
                          on: { loadPage: _vm.loadPage }
                        })
                      ],
                      1
                    )
              ])
            ])
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-02df59f6", { render: render, staticRenderFns: staticRenderFns })
  }
}