var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mb-4" }, [
    _c("div", { staticClass: "card-block " }, [
      _c("h4", { staticClass: "mb-2" }, [_vm._v("General")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input mb-3" }, [
        _c("label", [_vm._v("Commission Type")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("label", { staticClass: "margin-right-2x" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.commission_type,
                  expression: "form.commission_type"
                }
              ],
              attrs: { type: "radio", value: "percentage" },
              domProps: {
                checked: _vm._q(_vm.form.commission_type, "percentage")
              },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "commission_type", "percentage")
                }
              }
            }),
            _vm._v("\n          Percentage\n        ")
          ]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.commission_type,
                  expression: "form.commission_type"
                }
              ],
              attrs: { type: "radio", value: "flat" },
              domProps: { checked: _vm._q(_vm.form.commission_type, "flat") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "commission_type", "flat")
                }
              }
            }),
            _vm._v("\n          Flat\n        ")
          ])
        ]),
        _vm._v(" "),
        _vm.errors.commission_type
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.errors.commission_type[0]) +
                  "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input mb-3" }, [
        _vm.form.commission_type == "percentage"
          ? _c("label", { attrs: { for: "locationPhone" } }, [
              _vm._v("Location Commission (%)")
            ])
          : _c("label", { attrs: { for: "locationPhone" } }, [
              _vm._v("Channel number of days")
            ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.form.commission,
              expression: "form.commission",
              modifiers: { trim: true }
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "Percentage of location commission, eg: 80"
          },
          domProps: { value: _vm.form.commission },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "commission", $event.target.value.trim())
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.commission
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.errors.commission[0]) + "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input mb-3" }, [
        _c("label", { attrs: { for: "locationPhone" } }, [
          _vm._v("Minimum Booking Days")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.form.min_booking_days,
              expression: "form.min_booking_days",
              modifiers: { trim: true }
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "Minimum booking days of parking"
          },
          domProps: { value: _vm.form.min_booking_days },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "min_booking_days", $event.target.value.trim())
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.min_booking_days
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.errors.min_booking_days[0]) +
                  "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input mb-3" }, [
        _c("label", { attrs: { for: "locationPhone" } }, [
          _vm._v("Number of Hours Prior To Booking ")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.form.hours_before_booking,
              expression: "form.hours_before_booking",
              modifiers: { trim: true }
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "Number of Hours Prior To Booking"
          },
          domProps: { value: _vm.form.hours_before_booking },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.form,
                "hours_before_booking",
                $event.target.value.trim()
              )
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.hours_before_booking
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.errors.hours_before_booking[0]) +
                  "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input mb-3" }, [
        _c("label", [_vm._v("Price Type")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("label", { staticClass: "margin-right-2x" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.price_type,
                  expression: "form.price_type"
                }
              ],
              attrs: { type: "radio", value: "daily" },
              domProps: { checked: _vm._q(_vm.form.price_type, "daily") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "price_type", "daily")
                }
              }
            }),
            _vm._v("\n          Daily\n        ")
          ]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.price_type,
                  expression: "form.price_type"
                }
              ],
              attrs: { type: "radio", value: "hourly" },
              domProps: { checked: _vm._q(_vm.form.price_type, "hourly") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "price_type", "hourly")
                }
              }
            }),
            _vm._v("\n          Hourly\n        ")
          ])
        ]),
        _vm._v(" "),
        _vm.errors.price_type
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.errors.price_type[0]) + "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input mb-3" }, [
        _c("label", [_vm._v("Currency")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.currency_id,
                expression: "form.currency_id"
              }
            ],
            staticClass: "form-control",
            attrs: { placeholder: "Currency" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.form,
                  "currency_id",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { domProps: { value: null } }, [
              _vm._v("Select Currency")
            ]),
            _vm._v(" "),
            _vm._l(_vm.currencies, function(curr) {
              return _c(
                "option",
                { key: curr.id, domProps: { value: curr.id } },
                [_vm._v(_vm._s(curr.name + " - " + curr.code))]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm.errors.currency_id
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.errors.currency_id[0]) + "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input mb-3" }, [
        _c("label", [_vm._v("Tax Type")]),
        _vm._v(" "),
        _c("div", { staticClass: "radio" }, [
          _c("label", { staticClass: "margin-right-2x" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.tax_type,
                  expression: "form.tax_type"
                }
              ],
              attrs: { type: "radio", value: "gross" },
              domProps: { checked: _vm._q(_vm.form.tax_type, "gross") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "tax_type", "gross")
                }
              }
            }),
            _vm._v("\n          Gross (Tax on subtotal)\n        ")
          ]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.tax_type,
                  expression: "form.tax_type"
                }
              ],
              attrs: { type: "radio", value: "net" },
              domProps: { checked: _vm._q(_vm.form.tax_type, "net") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.form, "tax_type", "net")
                }
              }
            }),
            _vm._v("\n          Net (Tax on vendor portion only)\n        ")
          ])
        ]),
        _vm._v(" "),
        _vm.errors.tax_type
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v("\n        " + _vm._s(_vm.errors.tax_type[0]) + "\n      ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input mb-3" }, [
        _c("label", [_vm._v("Tax Value (%)")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.form.tax_percentage,
              expression: "form.tax_percentage",
              modifiers: { trim: true }
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            min: "0",
            placeholder: "The percentage of tax (from 1 to 100)"
          },
          domProps: { value: _vm.form.tax_percentage },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "tax_percentage", $event.target.value.trim())
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.tax_percentage
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.errors.tax_percentage[0]) + "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: ["super_admin"],
              expression: "['super_admin']",
              arg: "allow"
            }
          ],
          staticClass: "form-input checkbox"
        },
        [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.collect_osp_tax,
                  expression: "form.collect_osp_tax"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.form.collect_osp_tax)
                  ? _vm._i(_vm.form.collect_osp_tax, null) > -1
                  : _vm.form.collect_osp_tax
              },
              on: {
                change: function($event) {
                  var $$a = _vm.form.collect_osp_tax,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.form, "collect_osp_tax", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "collect_osp_tax",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "collect_osp_tax", $$c)
                  }
                }
              }
            }),
            _vm._v("\n        Collect OSP Tax\n      ")
          ]),
          _vm._v(" "),
          _vm.errors.collect_osp_tax
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.errors.collect_osp_tax[0]) +
                    "\n      "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-input mb-3" }, [
        _c("label", [_vm._v("Barcode Encoding")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.barcode_encoding,
                expression: "form.barcode_encoding"
              }
            ],
            staticClass: "form-control",
            attrs: { placeholder: "Barcode encoding" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.form,
                  "barcode_encoding",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { domProps: { value: null } }, [
              _vm._v("Select Encoding")
            ]),
            _vm._v(" "),
            _vm._l(_vm.barcode_encodings, function(enc) {
              return _c(
                "option",
                { key: enc.id, domProps: { value: enc.encoding } },
                [_vm._v(_vm._s(enc.encoding))]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm.errors.barcode_encoding
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.errors.barcode_encoding[0]) +
                  "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: {
                id: _vm.location_id,
                permits: ["LOCATION_NETPARK_KEY_MANAGE"]
              },
              expression:
                "{id: location_id, permits: ['LOCATION_NETPARK_KEY_MANAGE']}",
              arg: "allow"
            }
          ],
          staticClass: "form-input mb-3"
        },
        [
          _c("label", [_vm._v("Netpark key")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.form.netpark_key,
                expression: "form.netpark_key",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.form.netpark_key },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "netpark_key", $event.target.value.trim())
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.netpark_key
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.errors.netpark_key[0]) + "\n      "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-input mb-3" }, [
        _c("label", [_vm._v("ReservationsLab ID")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.form.reslab_id,
              expression: "form.reslab_id",
              modifiers: { trim: true }
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.form.reslab_id },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "reslab_id", $event.target.value.trim())
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.reslab_id
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.errors.reslab_id[0]) + "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input checkbox" }, [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.featured,
                expression: "form.featured"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.form.featured)
                ? _vm._i(_vm.form.featured, null) > -1
                : _vm.form.featured
            },
            on: {
              change: function($event) {
                var $$a = _vm.form.featured,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(_vm.form, "featured", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.form,
                        "featured",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.form, "featured", $$c)
                }
              }
            }
          }),
          _vm._v("\n        Featured\n      ")
        ]),
        _vm._v(" "),
        _vm.errors.featured
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v("\n        " + _vm._s(_vm.errors.featured[0]) + "\n      ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input checkbox" }, [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.due_at_location,
                expression: "form.due_at_location"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.form.due_at_location)
                ? _vm._i(_vm.form.due_at_location, null) > -1
                : _vm.form.due_at_location
            },
            on: {
              change: function($event) {
                var $$a = _vm.form.due_at_location,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.form, "due_at_location", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.form,
                        "due_at_location",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.form, "due_at_location", $$c)
                }
              }
            }
          }),
          _vm._v("\n        Due At Location\n      ")
        ]),
        _vm._v(" "),
        _vm.errors.due_at_location
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.errors.due_at_location[0]) +
                  "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input checkbox" }, [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.accepts_coupons,
                expression: "form.accepts_coupons"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.form.accepts_coupons)
                ? _vm._i(_vm.form.accepts_coupons, null) > -1
                : _vm.form.accepts_coupons
            },
            on: {
              change: function($event) {
                var $$a = _vm.form.accepts_coupons,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.form, "accepts_coupons", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.form,
                        "accepts_coupons",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.form, "accepts_coupons", $$c)
                }
              }
            }
          }),
          _vm._v("\n        Accept Coupons\n      ")
        ]),
        _vm._v(" "),
        _vm.errors.accepts_coupons
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.errors.accepts_coupons[0]) +
                  "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-input checkbox" }, [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.printed_receipt,
                expression: "form.printed_receipt"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.form.printed_receipt)
                ? _vm._i(_vm.form.printed_receipt, null) > -1
                : _vm.form.printed_receipt
            },
            on: {
              change: function($event) {
                var $$a = _vm.form.printed_receipt,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.form, "printed_receipt", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.form,
                        "printed_receipt",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.form, "printed_receipt", $$c)
                }
              }
            }
          }),
          _vm._v("\n        Requires Printed Receipt\n      ")
        ]),
        _vm._v(" "),
        _vm.errors.printed_receipt
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.errors.printed_receipt[0]) +
                  "\n      "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: {
                id: _vm.location_id,
                permits: [
                  "LOCATION_UPDATE",
                  "LOCATION_BOOKING_CONDITIONS_UPDATE"
                ]
              },
              expression:
                "{id: location_id, permits: ['LOCATION_UPDATE', 'LOCATION_BOOKING_CONDITIONS_UPDATE']}",
              arg: "allow"
            }
          ],
          staticClass: "text-right"
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-success",
              class: { disabled: _vm.processing },
              attrs: { disabled: _vm.processing },
              on: { click: _vm.submit }
            },
            [
              _vm.processing
                ? _c("span", [
                    _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                    _vm._v("\n          SAVEING...\n        ")
                  ])
                : _c("span", [_vm._v("\n          SAVE\n        ")])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-700a2cc8", { render: render, staticRenderFns: staticRenderFns })
  }
}