import PageNotFound from 'pages/notfound/pageNotFound'
import Main from 'pages/main'
import Reports from 'pages/reports'
import Settings from 'pages/settings'


// import Settings from 'pages/settings'



const routes = [].concat(
  require('./login.js').default,
  require('./locations.js').default,
  require('./ports.js').default,
  require('./reservations.js').default,
  require('./amenities.js').default,
  require('./tripProtections.js').default,
  require('./coupons.js').default,
  require('./referrals.js').default,
  require('./roles.js').default,
  require('./users.js').default,
  require('./customers.js').default,
  require('./locationAdministration.js').default,
  require('./carousels.js').default,
  require('./reviews.js').default,
  require('./adminApiRequests.js').default,
  require('./messages.js').default,
  require('./affiliates.js').default,
  require('./googleAds.js').default,
  require('./appLogs.js').default,
  require('./pages.js').default,
  [
  //User settings
  // { path: '/settings', name: 'settings', component: Settings},
  // //Main and login

  { path: '/', name: 'home', component: Main },
  { path: '/settings', name: 'settings', component: Settings },
  { path: '/reports', name: 'reports', component: Reports },
  { path: '*', name: 'notfound', component: PageNotFound
    , meta: {public: true}}
])

export default routes
