var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: !_vm.update ? "New Amenity" : "Update Amenity" },
      on: { close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(scope) {
            return [
              _c("div", { staticClass: "text-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "text-danger mr-2",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return scope.close()
                      }
                    }
                  },
                  [_vm._v("cancel")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    class: { disabled: _vm.processing },
                    attrs: { disabled: _vm.processing },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.submit()
                      }
                    }
                  },
                  [
                    _vm.processing
                      ? _c("span", [
                          _c("i", {
                            staticClass: "fa fa-spinner fa-spin fa-fw"
                          })
                        ])
                      : _vm._e(),
                    _vm._v("\n        Save\n      ")
                  ]
                )
              ])
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "form-input mb-2" }, [
          _c("label", [_vm._v("Display Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.display_name,
                expression: "form.display_name"
              }
            ],
            staticClass: "form-control",
            attrs: { id: "display_name", type: "text" },
            domProps: { value: _vm.form.display_name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "display_name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.display_name
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.errors.display_name[0]) + "\n      "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-input" },
          [
            _c("label", [_vm._v("Icon")]),
            _vm._v(" "),
            _c("file-upload", {
              on: { filesChanged: _vm.fileSelectedToUpload }
            }),
            _vm._v(" "),
            _vm.errors.icon
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v("\n        " + _vm._s(_vm.errors.icon[0]) + "\n      ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.errors["images.0"]
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errors["images.0"][0]) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-b4ade4b8", { render: render, staticRenderFns: staticRenderFns })
  }
}