import { http } from 'services'
// initial state
const state = () => ({
  active: {},
  all: {},
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {

  ['automatedCoupons.update'] ({ dispatch, commit, state }, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`automated-coupons/${id}`, data))
    .then ((res) => {
        commit('EDIT_AUTOMOTED_COUPON', res);
    })
    .catch ((res) => {
        commit('COUPONS_ERROR', res);
    })
  },

  ['automatedCoupons.get'] ({ commit }, id) {
    commit('CLEAR_ERRORS');

    return (http.get(`automated-coupons/${id}`))
    .then ((res) => {
        commit('GET_AUTOMOTED_COUPON', res);
    })
    .catch ((res) => {
        commit('COUPONS_ERROR', res);
    })
  },

}

// mutations
const mutations = {

  EDIT_AUTOMOTED_COUPON (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  GET_AUTOMOTED_COUPON (state, res) {
    state.active = res.data
  },

  //ERRORS
  COUPONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
