import toastr from 'toastr'
import layout from 'layouts/default'
import fileUpload from 'components/fileUpload'
import placesAutocomplete from 'components/placesAutocomplete'
import googleMapsMovableMarker from 'components/googleMapsMovableMarker'
import confirm from 'components/modals/confirm'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  placesAutocomplete,
  googleMapsMovableMarker,
  fileUpload,
  confirm
}

const computed = {
  port () {
    return this.$store.state.ports.active;
  },
  photos () {
    return this.port.photos
  },
  countries () {
    return [{name: "Select Country", id: null}].concat(this.$store.state.address.countries);
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }
    console.log(states);
    return states;
  },

  timezones () {
    return [{name: "Select Timezone", id: null}].concat(this.$store.state.timezones.all);
  },

  center () {
    if (this.googlePlace) {
      let location = {
        lat: this.googlePlace.geometry.location.lat(),
        lng: this.googlePlace.geometry.location.lng()
      };
      this.form.latitude = this.googlePlace.geometry.location.lat();
      this.form.longitude = this.googlePlace.geometry.location.lng();
      this.googlePlace = null;
      return location;
    } else if (this.form.latitude && this.form.longitude) {
      let location = {
        lat: parseFloat(this.form.latitude),
        lng: parseFloat(this.form.longitude)
      };
      return location;
    }

    return null
  },
}

const methods = {
  onLocationChanged (location) {
    this.form.latitude = location.lat;
    this.form.longitude = location.lng;
  },

  onChangeCountry () {
    this.form.state_id = null;
  },
  updateDropCode() {
    const name = this.form.name;
    const closingParenIndex = name.indexOf(")");
    if (closingParenIndex !== -1) {
      this.form.drop_code = name.substring(0, closingParenIndex + 1);
    } else {
      this.form.drop_code = name;
    }
  },
  submit () {
    
    this.errors = {}
    this.processing = true;
    let data = _.cloneDeep(this.form);
    this.$store.dispatch('ports.edit', {id: this.$route.params.id, data}).then(() => {
      this.processing = false;
      if (this.$store.state.ports.errors) {
        this.errors = _.cloneDeep(this.$store.state.ports.errors.errors);
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    })
  },

  imagesToUpload (files) {
    if (files.length) {

      let maxFileSize = 2 * 1024 * 1024; // 2MB
      if (files[0].size >= maxFileSize) {
        toastr.error("Maximum file size (2MB) has been exceeded!!")
        this.$refs.file_upload.removeFiles()
        return;
      }

      this.images = files;
    } else {
      this.images = [];
    }
  },

  uploadImages () {
    this.errors = {};
    this.files_processing = true;
    this.$store.dispatch('ports.uploadImages', {
      id: this.$route.params.id,
      files: this.images
    }).then(() => {
      this.files_processing = false;

      if (this.$store.state.ports.errors) {
        this.errors = _.cloneDeep(this.$store.state.ports.errors.errors);
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        toastr.success('Data has been saved');
        this.$refs.file_upload.removeFiles();
        $('[data-toggle="tooltip"]').tooltip();
      }
    });
  },

  removePhoto (image) {
    this.$refs.removePhoto.confirm('Are you sure you want to remove this photo?', () => {
      this.$store.dispatch('ports.deleteImage', image.id);
    })
  },

  setFeatured (image) {

    this.errors = {}
    this.processing = true;
    this.$store.dispatch('ports.setFeaturedImage', image.id).then(() => {
      this.processing = false;
      if (this.$store.state.ports.errors) {
        this.errors.images = [this.$store.state.ports.errors.message];
        // toastr.error(this.errors.images[0]);
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  },
}

export default {
  name: "edit-port",
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: true,
      form: {},
      errors: {},
      // timezones: [],
      googlePlace: null,
      processing: false,
      files_processing:false,
      images: [],
    }
  },
  created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('ports.get', this.$route.params.id),
      this.$store.dispatch('ports.getPhotos', this.$route.params.id),
      this.$store.dispatch('address.getCountries'),
      this.$store.dispatch('timezones.getAll'),
    ]).then(() => {
      this.form = Object.assign({}, this.port);
      this.loading = false;
      // this.timezones = _.cloneDeep(this.$store.state.timezones.all);
    });
  }
}
