import toastr from 'toastr'
import layout from 'layouts/default'
import pagination from 'components/pagination'
import confirm from 'components/modals/confirm'
import { hasPermits } from 'directives'


const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  confirm
}

const computed = {
  tripProtections () {
    return _.cloneDeep(this.$store.state.tripProtections.all);
  }
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    let data = {page, _q: this.searchKey};

    this.loading = true;
    data._get = this.filter

    this.$store.dispatch('tripProtections.getAll', data).then(() => {
      this.loading = false;
    });
  },
  search (searchKey) {
    this.searchKey = searchKey;
    this.loadPage(1);
  },
  removePage (item) {
    this.$refs.confirmDelete.confirm(
    'are you sure that you want to delete this Trip Protection?',
    () => {
      this.$set(this.loadings, item.id, true);

      this.$store.dispatch('tripProtections.remove', item.id).then(() => {
        this.loadings[item.id] = false;
        let errors;
        if (errors = this.$store.state.tripProtections.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },

  undoRemove (item) {
    this.$refs.confirmUndoDelete.confirm(
    'Are you sure that you want to undelete this Trip Protections?',
    () => {
      this.$set(this.loadings, item.id, true);

      this.$store.dispatch('tripProtections.undoRemove', item.id).then(() => {
        this.loadings[item.id] = false;
        let errors;
        if (errors = this.$store.state.tripProtections.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },

  filterChanged () {
    this.loadPage(1);
  }
}

const data = {
  loading: false,
  searchKey: null,
  loadings: {},
  filter: 'active'
}

export default {
  name: 'all-trip-protections',
  components,
  computed,
  methods,
  directives,
  data: () => data,
  created () {
    this.loadPage(this.$route.query.page || 1);
  }
}
