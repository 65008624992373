import { http } from 'services'
// initial state
const state = () => ({
  all: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {

  ['deferredRevenuesEmails.getAll'] ({ commit }) {
    commit('CLEAR_ERRORS')

    return (http.get('deferred-revenues-emails')).then ((res) => {
      commit('GET_ALL_DEFERRED_REVENUES_EMAILS', res);
    })
    .catch ((res) => {
      commit('DEFERRED_REVENUES_EMAILS_ERROR', res);
    })
  },

  ['deferredRevenuesEmails.new'] ({ commit }, data) {
    commit('CLEAR_ERRORS')

    return (http.post('deferred-revenues-emails', data))
    .then ((res) => {
      commit('GET_ALL_DEFERRED_REVENUES_EMAILS', res);
    })
    .catch ((res) => {
      commit('DEFERRED_REVENUES_EMAILS_ERROR', res);
    })
  },

  ['deferredRevenuesEmails.remove'] ({ commit }, id) {
    commit('CLEAR_ERRORS')

    return (http.delete('deferred-revenues-emails/' + id))
    .then ((res) => {
      commit('REMOVE_DEFERRED_REVENUES_EMAIL', id);
    })
    .catch ((res) => {
      commit('DEFERRED_REVENUES_EMAILS_ERROR', res);
    })
  },

  ['deferredRevenuesEmails.edit'] ({ commit }, id) {
    commit('CLEAR_ERRORS')

    return (http.put('deferred-revenues-emails/' + id))
    .then ((res) => {
      commit('EDIT_DEFERRED_REVENUES_EMAILS', res);
    })
    .catch ((res) => {
      commit('DEFERRED_REVENUES_EMAILS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_DEFERRED_REVENUES_EMAILS (state, res) {
    state.all = res.data.data;
  },

  REMOVE_DEFERRED_REVENUES_EMAIL (state, id) {
    state.all = _.filter(state.all, (item) => item.id != id);
    state.all = _.cloneDeep(state.all);
  },

  EDIT_DEFERRED_REVENUES_EMAILS (state, res) {
    state.all = _.assign(state.all, res.data);
    state.all = _.orderBy(_.cloneDeep(state.all), ['id'], ['asc']);
  },

  //ERRORS
  DEFERRED_REVENUES_EMAILS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
