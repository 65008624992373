import Vue from 'vue'
import _ from 'lodash'
import { http, permits } from 'services'

// initial state
const state = () => ({
  all: [],
  active: {
    locations: [],
    photos: []
  },
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  ['googleAds.sync'] ({dispatch, commit}, params = {}) {
    commit('CLEAR_ERRORS');

    return (http.post(`google-ads/sync-ads`)).then (() => {
    }).catch ((res) => {
      commit('GOOGLE_ADS_ERROR', res);
    })
  },
  ['googleAds.getAllCampaigns'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._get) params._get = null

    params._sort= 'name';

    commit('CLEAR_ERRORS')

    return (http.get('google-ads/campaigns', {
      params
    })).then ((res) => {
      commit('GET_ALL_CAMPAIGNS', res);
    }).catch ((res) => {
      commit('GOOGLE_ADS_ERROR', res);
    })
  },

  //get single Campaign by id
  ['googleAds.getCampaign'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')

    // return;
    return (http.get(`google-ads/campaigns/${id}`)).then(campaign => {
      commit('GET_CAMPAIGN', campaign);
    }).catch ((res) => {
      commit('GOOGLE_ADS_ERROR', res);
    });
  },

  //update Campaign
  ['googleAds.editCampaign'] ({dispatch, commit, state}, {id, data, params}) {
    commit('CLEAR_ERRORS');

    return (http.put(`google-ads/campaigns/${id}`, data, {params})).then ((res) => {
      commit('EDIT_CAMPAIGN', res);
    })
    .catch ((res) => {
      commit('GOOGLE_ADS_ERROR', res);
    })
  },

  ['googleAds.removeCampaign'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`google-ads/campaigns/${id}`)).then ((res) => {
      commit('REMOVE_CAMPAIGN', res);
    }).catch ((res) => {
      commit('GOOGLE_ADS_ERROR', res);
    })
  },

  ['googleAds.undoRemoveCampaign'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`google-ads/campaigns/${id}?_restore=true`)).then ((res) => {
      commit('UNDO_REMOVE_CAMPAIGN', res);
    }).catch ((res) => {
      commit('GOOGLE_ADS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_CAMPAIGNS (state, res) {
    state.all = res.data;
  },

  REMOVE_CAMPAIGN (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_CAMPAIGN (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_CAMPAIGN (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  EDIT_CAMPAIGN (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },
  
  //ERRORS
  GOOGLE_ADS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
