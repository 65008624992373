import { http } from 'services'
// initial state
const state = () => ({
    errors: null,
    active: {},
    all: {},
})

// getters
const getters = {
}

// actions
const actions = {
    ['messages.getAll']({ commit }, params) {
        if (!params._q) params._q = null

        commit('CLEAR_ERRORS')

        return (http.get('messages', {
            params
        })).then((res) => {
            commit('GET_ALL_MESSAGES', res);
        })
        .catch((res) => {
            commit('MESSAGES_ERROR', res);
        })
    },

    ['messages.get'] ({ commit }, id) {
        commit('CLEAR_ERRORS')
        return (http.get('messages/' + id)).then((res) => {
            commit('GET_MESSAGE', res);
        })
        .catch((res) => {
            commit('MESSAGES_ERROR', res);
        })
    },
}

// mutations
const mutations = {
    GET_ALL_MESSAGES (state, res) {
        state.all = res.data;
    },

    GET_MESSAGE (state, res) {
        state.active = res.data;
    },

    //ERRORS
    MESSAGES_ERROR(state, err) {
        try {
            state.errors = err.response.data;
        } catch (e) {
            state.errors = err;
        }
    },

    CLEAR_ERRORS(state) {
        state.errors = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}