import layout from 'layouts/default'
import amenities from './amenities'
import photos from './photos'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  amenities,
  photos
}

const computed = {
  photos () {
    return this.$store.state.locations.active.photos;
  }
}

export default {
  name: "amenities-and-photos",
  components,
  directives,
  computed,
  data () {
    return {
      loading: false,
      location: null,
      amenities: [],
    }
  },

  created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.getPhotos', this.$route.params.id),
      this.$store.dispatch('amenities.getAll')
    ]).then(() => {
      this.location = _.cloneDeep(this.$store.state.locations.active);
      // this.photos = this.location.photos;
      this.amenities = this.$store.state.amenities.all;
      this.loading = false;
    });
  }
}
