<template>
    <textarea ref="editor" :value="value" ></textarea>
</template>
<script>
import tinymce from 'tinymce'
import 'tinymce/themes/silver'
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';

export default {
    props: ['value'],
    mounted () {
        console.log(this.$refs.editor);
        
        tinymce.init({
            target: this.$refs.editor,
            height: 500,
            menubar: false,
            object_resizing: true,
            image_advtab: true,
            theme: 'silver',
            plugins: 'paste searchreplace autolink directionality code visualblocks visualchars fullscreen image link media table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools help charmap',
            toolbar: 'undo redo | ltr rtl | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap | insertfile image media link anchor code',
            init_instance_callback: (editor) => {
                editor.on('keyup', (e) => {
                    this.$emit('input', e.target.innerHTML);
                });

                editor.on('change', (e) => {
                    this.$emit('input', editor.getContent());
                });

                editor.on('undo', (e) => {
                    this.$emit('input', editor.getContent());
                });

                editor.on('redo', (e) => {
                    this.$emit('input', editor.getContent());
                });
            },

            images_upload_handler: (blobInfo, success, failure) => {
                this.$store.dispatch('pages.upload', blobInfo.blob()).then(() => {
                    if (this.$store.state.pages.errors) {
                        failure(this.$store.state.pages.errors.errors.image[0]);
                        return
                    }

                    success(this.$store.state.pages.uploaded_file);
                });
            }
        });        
    }
}
</script>