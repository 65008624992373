var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mb-4" }, [
    _c("div", { staticClass: "card-block " }, [
      _c("h4", { staticClass: "mb-2" }, [_vm._v("Amenities")]),
      _vm._v(" "),
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.amenities, function(amenity) {
            return _c("tr", [
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.location_amenities,
                      expression: "location_amenities"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: amenity.id,
                    checked: Array.isArray(_vm.location_amenities)
                      ? _vm._i(_vm.location_amenities, amenity.id) > -1
                      : _vm.location_amenities
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.location_amenities,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = amenity.id,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.location_amenities = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.location_amenities = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.location_amenities = $$c
                      }
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("td", [
                _c("img", { attrs: { src: amenity.icon_url, width: "30" } })
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(amenity.display_name))])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: {
                id: _vm.location.id,
                permits: ["LOCATION_AMENITIES_UPDATE"]
              },
              expression:
                "{id: location.id, permits: ['LOCATION_AMENITIES_UPDATE']}",
              arg: "allow"
            }
          ],
          staticClass: "text-right"
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-success",
              class: { disabled: _vm.processing },
              attrs: { disabled: _vm.processing },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.submit()
                }
              }
            },
            [
              _vm.processing
                ? _c("span", [
                    _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
                  ])
                : _vm._e(),
              _vm._v("\n        Save\n      ")
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Select")]),
        _vm._v(" "),
        _c("th", [_vm._v("Icon")]),
        _vm._v(" "),
        _c("th", [_vm._v("Name")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ce0443fe", { render: render, staticRenderFns: staticRenderFns })
  }
}