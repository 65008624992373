import _ from 'lodash'
import { http, permits } from 'services'

// initial state
const state = () => ({
  all: [],
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  ['appLogs.getAll'] ({ commit, state }, params) {
    commit('CLEAR_ERRORS')
    return (http.get('app-logs', {
      params
    })).then ((res) => {
      commit('GET_ALL_LOGS', res);
    }).catch ((res) => {
      commit('LOGS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_LOGS (state, res) {
    state.all = res.data;
  },
  
  //ERRORS
  LOGS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
