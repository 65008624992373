export default {
  template: require('./notfound.html'),
  name: 'PageNotFound',
  data () {
    console.log('route',this.$route);
    return {
      page: this.$route
    }
  }
}
