import toastr from 'toastr'
import moment from 'moment'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  confirm
}

const computed = {
  types () {
    return this.$store.state.locations.active.types;
  }
}

const methods = {
  loadRates () {
    for (let i in this.types) {
      this.$set(this.loadings, this.types[i].id, true);
      this.$store.dispatch('locations.getTypeRates', {
        type_id: this.types[i].id,
        params: {
          modified: "all",
        }
      }).then(res => {

        console.log('ssss', this.types[i].id);
        this.$set(this.loadings, this.types[i].id, false);
      });
    }
  },

  remove (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.$set(this.remove_loadings, item.id, true);
      this.$store.dispatch(`locations.removeRate`, item.id).then(() => {
        this.$set(this.remove_loadings, item.id, false);
        let errors = this.$store.state.locations.errors;
        if (errors) {
          toastr.error(errors.message);
        }
      })
    });
  },

  eligibleDays (item) {
    let weekDays = [
      'Monday', 'Tuesday', 'Wednesday', 'Thursday',
      'Friday', 'Saturday', 'Sunday'
    ];

    let days = [];
    for (let i in weekDays) {
      if (item[weekDays[i].toLowerCase()]) {
        days.push(weekDays[i]);
      }
    }

    return days.join(', ');
  }
}

export default {
  name: 'rates-list-view',
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      loadings: {},
      remove_loadings: {},
      moment,
      location_id: this.$route.params.id
    }
  },
  created () {
    console.log('types', this.types);
    this.loadRates();
  }
}
