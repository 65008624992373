import moment from 'moment'
import env from 'constants/env'
import datatable from 'components/datatable'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.show_table = true;
        this.$nextTick(() => {
            this.$refs.table.run();
        })
    }
}

let computed = {
    user () {
        return this.$store.state.user.data;
    }
}

export default {
    name: "tp-reviews",
    methods,
    components,
    computed,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/trust-pilot-reviews',
        columns: []
    }),
    mounted () {
        this.columns = [
            {label: "Customer Name", attribute: 'customer_name'},
            {label: "Rating", attribute: 'rating',sortable: true, sort_key: "rating"},
            {label: "Title", attribute: 'title'},
            {label: "text", attribute: 'text'},
            {label: "Date", attribute: (data) => (
                moment(data.date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')
            ),sortable: true, sort_key: "date"},
        ]
    }
}