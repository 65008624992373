import moment from 'moment'
import datepicker from 'components/datepicker'

const components = {
  datepicker
}

const computed = {
  types () {
    return this.$store.state.locations.active.types;
  }
}

const methods = {
  loadRates () {
    this.loading = true;

    let promises = [];
    console.log('types', this.types);
    for (let i in this.types) {
      promises.push(this.$store.dispatch('locations.getTypeRates', {
        type_id: this.types[i].id,
        params: {
          modified: "all",
          _sort: '-id',
          from_date: moment(this.from_date, [this.calendar_format]).format('YYYY-MM-DD'),
          to_date: moment(this.to_date, [this.calendar_format]).format('YYYY-MM-DD')
        }
      }))
    }

    Promise.all(promises).then(() => {
      this.loading = false;
    });
  },

  getPriceAtDate(type, date) {
    let day_name = date.format('dddd').toLowerCase();
    let rates = type.rates;
    for (let i in rates) {
      let start_date = moment(rates[i].from_date, ['YYYY-MM-DD']);
      let end_date = moment(rates[i].to_date, ['YYYY-MM-DD']);
      if (date.isBetween(start_date, end_date, null, "[]") && rates[i][day_name]) {
        return rates[i].price;
      }
    }

    return 0;
  },

  goBackToToday () {
    this.from_date = moment().format(this.calendar_format);
  },

  nextMonth (e) {
    e.preventDefault();
    this.from_date = moment(this.from_date, [this.calendar_format]).add(this.number_of_days, 'days').format(this.calendar_format);
  },

  prevMonth (e) {
    e.preventDefault();
    let prev = moment(this.from_date, [this.calendar_format]).subtract(this.number_of_days, 'days');
    if (prev.isBefore(moment(), 'days')) {
      return;
    }
    this.from_date = prev.format(this.calendar_format);
  }
}

const watch = {
  from_date () {
    let from_date_unformated = moment(this.from_date, [this.calendar_format]);
    let to_date_unformated = moment(this.from_date, [this.calendar_format]).add(this.number_of_days, 'days');
    this.to_date = to_date_unformated.format(this.calendar_format);
    this.loadRates();
  },
}

export default {
  name: 'rates-calendar-view',
  components,
  computed,
  methods,
  watch,
  data () {
    let calendar_format = 'MM/DD/YYYY';
    let number_of_days = 30;
    let from_date_unformated = moment();
    let from_date = moment().format(calendar_format);
    let to_date_unformated = moment(from_date, [calendar_format]).add(number_of_days, 'days');
    let to_date = to_date_unformated.format(calendar_format);

    return {
      loading: false,
      calendar_format,
      number_of_days,
      from_date,
      to_date,
      moment
    }
  },
  created () {
    this.loadRates();
  }
}
