import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'
import { dateFormat } from 'filters'

let components = {
    layout
}

let directives = {
    hasPermits
}

let filters = {
    dateFormat
}

let computed = {
    review () {
        return this.$store.state.reviews.active;
    }
}

let methods = {
    publish () {
        this.processing = true;
        this.$store.dispatch('reviews.publish', {
            id: this.review.id, 
            data: {publish: !this.review.published}
        }).then(() => {    
            this.processing = false;
            if (this.$store.state.reviews.errors) {
                this.errors = _.cloneDeep(this.$store.state.reviews.errors.errors);
                if (this.errors.hasOwnProperty('message')) {
                    toastr.error(this.errors.message);
                }
            }
        })
    }
}

export default {
    name: "show-review",
    components,
    directives,
    computed,
    methods,
    filters,
    data () {
        return {
            loading: true,
            processing: false
        }
    },

    created () {
        this.$store.dispatch('reviews.get', this.$route.params.id).then(() => {
            this.loading = false
        });
    }
}