import toastr from 'toastr'
import moment from 'moment'
import { hasPermits } from 'directives'
import { dateFormat } from 'filters'
import { confirm } from 'components/modals'
import pagination from 'components/pagination'
import viewReservation from 'layouts/partials/viewReservation'
import addPoints from './addPoints'

let directives = {
    hasPermits
}

const components = {
    pagination, viewReservation, 
    addPoints, confirm
}

let filters = {
    dateFormat
}

const methods = {
    loadReservations (page = this.page) {
        this.loading = true;
        this.$store.dispatch('customers.getCustomerReservations', {
            id: this.$route.params.id, page
        }).then(() => {
            this.page = page;
            this.reservations = this.$store.state.customers.active.reservations;
            this.loading = false;
        });
    },

    loadPoints () {
        this.loading = true;
        this.$store.dispatch('customers.getCustomerPoints', {
            id: this.$route.params.id, params: {_all: true}
        }).then(() => {
            this.loading = false;
        });
    },

    changeView (view) {
        this.view = view;
        if (view == 'reservations') {
            this.loadReservations();
        } else if (view == 'points') {
            this.loadPoints();
        }
    },

    showReservationDetails (item_id) {
        this.$set(this.view_loadings, item_id, true);
        this.$store.dispatch('reservations.get', item_id).then(() => {
          let errors;
          if (errors = this.$store.state.reservations.errors) {
            if (errors.message) {
              toastr.error(errors.message);
            } else {
              toastr.error(errors.error);
            }
          } else {
            this.$refs.view.open();
          }
    
          this.view_loadings[item_id] = false;
        });
    
        return false;
    },

    reservationEnded (to_date) {
        console.log('to_date', to_date);
        let yesterday = moment().subtract(1, 'days');
        console.log('yesterday', yesterday.format('YYYY-MM-DD HH:mm:ss'));
        console.log("moment(to_date, ['YYYY-MM-DD HH:mm:ss']).isSameOrAfter(yesterday)", moment(to_date, ['YYYY-MM-DD HH:mm:ss']).isSameOrAfter(yesterday));
        

        return moment(to_date, ['YYYY-MM-DD HH:mm:ss']).isSameOrBefore(yesterday);
    },

    openAddPointsModal () {
        this.$refs.addPointsModal.open();
    },

    revokePoints (record) {
        this.$refs.confirmRevoke.confirm('Are you sure?', () => {
            this.$set(this.revoke_loadings, record.id, true);
            this.$store.dispatch('customers.revokePoints', {
                customer_id: this.$route.params.id,
                points_id: record.id
            }).then(() => {
                if (this.$store.state.customers.errors) {
                    toastr.error(this.$store.state.customers.errors.message)
                } else {
                    toastr.success('Data has been deleted');
                }

                this.$set(this.revoke_loadings, record.id, false);
            });
        });
    }
}

let computed = {
    customer () {
        return _.cloneDeep(this.$store.state.customers.active);
    },

    points () {
        return this.$store.state.customers.active.points || [];
    }
}

let watch = {
    activeTab (n) {
        console.log('this.page', this.page);
        
        if (n && n.title == "Rewards") {
            this.$router.replace({ query: Object.assign({}, this.$route.query, { page: this.page }) })
        }
    }
} 

export default {
    name: 'customer-points',
    props: ['activeTab'],
    components,
    watch,
    methods,
    directives,
    filters,
    computed,
    data () {
        return {
            loading: false,
            revoke_loadings: {},
            view_loadings: {},
            reservations: {},
            page: 1,
            view: 'reservations', // reservations, points
        }
    },
    created () {
        this.changeView('reservations');
    }
}