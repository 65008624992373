import moment from 'moment'
import env from 'constants/env'
import datatable from 'components/datatable'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.show_table = true;
        this.$nextTick(() => {
            this.$refs.table.run();
        })
    }
}

let computed = {
    user () {
        return this.$store.state.user.data;
    }
}

export default {
    name: "customers-report",
    methods,
    components,
    computed,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/customers',
        columns: []
    }),
    mounted () {
        this.columns = [
            {label: "Customer Name", attribute: (data) => (
                data.first_name + " " + data.last_name
            ),sortable: true, sort_key: "first_name"},
            {label: "Customer Email", attribute: 'email'},
            {label: "Last Reservation Number", attribute: 'reservation_number',sortable: true, sort_key: "last_reservation_number"},
            {label: "Last Reservation Date", attribute: (data) => (moment(data.res_created_at, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY')),sortable: true, sort_key: "last_reservation_date"},
            {label: "Last Reservation Port", attribute: 'name',sortable: true, sort_key: "last_reservation_port"},
            {label: "Used Points In The Past", attribute: (data) => (
                data.spent_points > 0 ? 'Yes' : 'No'
            )},
            {label: "Rewards Program Tier", attribute: 'current_tier.name'},
        ]
    }
}