var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", { attrs: { "no-sidebar": true } }, [
    _c("h3", { staticClass: "mb-2" }, [_vm._v("Review Preview")]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
        ])
      : _c("div", { staticClass: "card mb-4" }, [
          _c("div", { staticClass: "card-block" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-sm-8" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", { staticClass: "w-25" }, [
                        _vm._v("Customer Name")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm.review.customer
                          ? _c("span", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(_vm.review.customer.first_name) +
                                  "\n                                        " +
                                  _vm._s(_vm.review.customer.last_name) +
                                  "\n                                    "
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.review.reservation.history[0]
                                      .customer_name
                                  ) +
                                  "\n                                    "
                              )
                            ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("Customer Email")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm.review.customer
                          ? _c("span", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(_vm.review.customer.email) +
                                  "\n                                    "
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.review.reservation.history[0]
                                      .contact_email
                                  ) +
                                  "\n                                    "
                              )
                            ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("Location Name")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.review.location.name))])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("Date Added")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.review.created_at,
                              "MM/DD/YYYY hh:mm a"
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("Status")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          class: [
                            { "text-success": _vm.review.published },
                            { "text-warning": !_vm.review.published }
                          ]
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.review.published ? "Published" : "Pending"
                              ) +
                              "\n                                "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("rating")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("div", { staticClass: "bold" }, [
                            _vm._v(
                              "Overall Rating: " +
                                _vm._s(_vm.review.overall_rating.toFixed(1))
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.review.rating_criteria, function(item) {
                            return _c("div", { key: item.id }, [
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(item.name) +
                                  ": " +
                                  _vm._s(item.pivot.rating.toFixed(1)) +
                                  "\n                                    "
                              )
                            ])
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("Review Title")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.review.title))])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v("Review Comment")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.review.comment))])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-content-end align-items-center"
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: { permits: ["review_publish"] },
                            expression: "{permits: ['review_publish']}",
                            arg: "allow"
                          }
                        ]
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            class: [
                              { disabled: _vm.processing },
                              { "btn-success": !_vm.review.published },
                              { "btn-danger": _vm.review.published }
                            ],
                            attrs: { disabled: _vm.processing },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.publish($event)
                              }
                            }
                          },
                          [
                            _vm.processing
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "fa fa-spinner fa-spin fa-fw"
                                  }),
                                  _vm._v(
                                    "\n                                    SAVING...\n                                "
                                  )
                                ])
                              : _vm.review.published
                              ? _c("span", [_vm._v("Unpublish")])
                              : _c("span", [_vm._v("publish")])
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.review.prev_id || _vm.review.next_id
                      ? _c(
                          "div",
                          { staticClass: "margin-left-4x" },
                          [
                            _vm.review.prev_id
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "reviews.edit",
                                        params: { id: _vm.review.prev_id }
                                      }
                                    }
                                  },
                                  [_vm._v("Prev")]
                                )
                              : _vm._e(),
                            _vm._v(" |\n                            "),
                            _vm.review.next_id
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "reviews.edit",
                                        params: { id: _vm.review.next_id }
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-46726005", { render: render, staticRenderFns: staticRenderFns })
  }
}