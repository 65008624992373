import toastr from 'toastr'
import layout from 'layouts/default'
import createUpdateAmenity from '../createUpdate'
import confirm from 'components/modals/confirm'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  createUpdateAmenity,
  confirm
}

const computed = {
  amenities () {
    return this.$store.state.amenities.all
  }
}

const methods = {
  onAmenityModalClosed () {
    this.shouldShowModal = false;
  },

  onAmenityModalOpen (update = false) {
    if (!update) this.selectedAmenity = null;

    console.log('this.selectedAmenity', this.selectedAmenity);

    this.updateMode = update;
    this.shouldShowModal = true;
    this.$nextTick(() => {
      this.$refs.amenityModal.open();
    })
  },

  setSelectedAmenity (amenity) {
    this.selectedAmenity = amenity;
    this.onAmenityModalOpen(true);
  },

  getAmenities () {
    this.loading = true;
    this.$store.dispatch('amenities.getAll').then(() => this.loading = false);
  },

  remove (item) {
    this.$refs.confirmDelete.confirm(
    'are you sure that you want to delete this amenity? you can\'t undo this action',
    () => {
      this.$set(this.loadings, item.id, true);

      this.$store.dispatch('amenities.remove', item.id).then(() => {
        this.loadings[item.id] = false;
        console.log('this.$store.state.amenities.errors', this.$store.state.amenities.errors);
        let errors;
        if (errors = this.$store.state.amenities.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },
}

export default {
  name: 'list-amenities',
  computed,
  components,
  methods,
  directives,
  data () {
    return {
      loading: false,
      loadings: {},
      shouldShowModal: false,
      updateMode: false,
      selectedAmenity: null
    }
  },
  created () {
    this.getAmenities();
  }
}
