/*
* @INFO: Autocomplete component
* @PROP label: a dot notation string or a function to specify the label of the objects (the text to be shown to the user).
* @PROP inputClass: the css classes to be assigned to the input field.
* @PROP url: the url to be requested for remote datasets. If the url needs a parameter, append it to the url like _q=.
* @PROP source: the local source of items. If you have a local list, use this (if this is set, the url param  will be ignored)
* @CALLBACK select: an event that is triggerred when an item is selected. params ({item, input})
*/

import { http } from 'services'

const methods = {
  startAutocomplete (e) {
    this.$emit('select', {item: null, input: this.$refs.input})

    console.log('e.target.value', [e.target.value]);
    
    
    if (this.source) {
      this.items = _.cloneDeep(this.source).filter((item) =>
        item.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
      this.toggleList();
    } else {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loading = true;
        http.get(this.url + e.target.value).then((res) => {
          console.log('typeof res.data', typeof res.data);
          if (res.data.data) {
            this.items = res.data.data;
          } else {
            this.items = res.data;
          }
          this.loading = false;
          this.toggleList();
        }).catch((e) => {
          this.loading = false;
          console.log('error in autocomplete', e);
        });
      }, 300);
    }
    this.$refs.input.focus();
  },
  toggleList () {
    if(!this.$refs.input.value.length) {
      this.active = false;
    } else {
      this.active = true;
    }
    setTimeout(() => {
      this.$refs.input.focus();
    }, 200);
  },
  select (item) {
    this.$refs.input.value = this.formatedItem(item)
    this.$emit('select', {item, input: this.$refs.input})
    this.active = false;
  },
  formatedItem (item) {
    if(this.label) {
      if (typeof this.label == 'function') {
        return this.label(item);
      }

      return this.label.split('.').reduce(
        (o,i) => o[i], item
      )
    }

    return item
  }
}

const computed = {

}

export default {
  name: 'autocomplete',
  props: ['label', 'inputClass', 'url', 'source', 'placeholder', 'text'],
  computed,
  methods,
  data () {
    return {
      active: false,
      items: [],
      loading: false,
      timeout: null
    }
  },
  mounted () {
    if (this.text) {
      this.$refs.input.value = this.text;
    }
  }
}
