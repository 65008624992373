import toastr from 'toastr'
import fileUpload from 'components/fileUpload'
import layout from 'layouts/default'
import hasPermits from 'directives/hasPermits'

const filters = {

}

const directives = {
  hasPermits
}
const watch = {

}

const components = {
  fileUpload,
  layout
}

const methods = {
  submit () {
    let errors = false;
    this.errors = {};

    //validating totals
    let data = _.cloneDeep(this.form)
    this.errors = {};
    this.loading = true;
    this.submit_loading = true;

    this.$store.dispatch('carousels.new', {
      data: data,
      image: this.image
    }).then(() => {
      this.loading = false;
      this.submit_loading = false;
      console.log('this.$store.state.carousels.errors',this.$store.state.carousels.errors)
      if (this.$store.state.carousels.errors) {
        this.errors = _.cloneDeep(this.$store.state.carousels.errors.errors);
        toastr.error(this.$store.state.carousels.errors.message);
      } else {
        toastr.success('Data has been saved');
        // this.$refs.file_upload.removeFiles();
        this.$router.push({name: 'carousels.list'})
      }
    });
  },

  imageToUpload (files) {
    if (files.length) {
      this.image = files;
    } else {
      this.image = [];
    }
  },

  wysiwygContentChanged: function(val) {
    this.form.text = val
  }
}

export default {
  name: 'new-carousel',
  methods,
  components,
  watch,
  directives,
  filters,
  data() {
    return {
      errors: {},
      form: {
        title: null,
        text: '',
        link: null,
      },
      image: [],
      loading: false,
      submit_loading: false,
    }
  }
}
