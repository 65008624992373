var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { "no-sidebar": true } },
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Amenities")]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "card mb-4" }, [
            _c("div", { staticClass: "card-block" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["amenity_create"],
                      expression: "['amenity_create']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "text-right mb-3"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onAmenityModalOpen(false)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-plus",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v("\n          New Amenity\n        ")
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Display Name")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Icon")]),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["amenity_update"],
                              expression: "['amenity_update']",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v("Edit")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["amenity_delete"],
                              expression: "['amenity_delete']",
                              arg: "allow"
                            }
                          ]
                        },
                        [_vm._v("Remove")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.amenities, function(amenity) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(amenity.display_name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(amenity.name))]),
                        _vm._v(" "),
                        _c("td", [
                          _c("img", {
                            attrs: { src: amenity.icon_url, width: "90" }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["amenity_update"],
                                expression: "['amenity_update']",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.setSelectedAmenity(amenity)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-pencil",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["amenity_delete"],
                                expression: "['amenity_delete']",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _vm.loadings[amenity.id]
                              ? _c("div", [
                                  _c("i", {
                                    staticClass: "fa fa-spinner fa-spin"
                                  })
                                ])
                              : _c("div", {}, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-danger",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.remove(amenity)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-trash",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                ])
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          ]),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } }),
      _vm._v(" "),
      _vm.shouldShowModal
        ? _c("create-update-amenity", {
            ref: "amenityModal",
            attrs: {
              formData: _vm.selectedAmenity,
              update: _vm.updateMode,
              "on-close": _vm.onAmenityModalClosed
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2123361d", { render: render, staticRenderFns: staticRenderFns })
  }
}