import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout
}

const computed = {
  roles () {
    return this.$store.state.roles.all;
  },
  countries () {
    let countries = [{name: "Select Country", id: null}]
      .concat(this.$store.state.address.countries)

    return countries
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }

    return states;
  }
}

const methods = {
  submit () {
    this.errors = {}
    if (this.form.password) {
      if (this.form.password !== this.form.password_confirmation) {
        this.errors.password = ['Password and password confirmation do not match']
        return;
      }
    }

    let data = _.clone(this.form);

    try {
      if (data.role_id == null) {
        delete data.role_id;
      }
    } catch(e) {}

    this.processing = true;
    this.$store.dispatch('users.editUser', {id: this.$route.params.id, data})
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.users.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  }
}

export default {
  name: 'edit-user',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: true,
      processing: false,
      errors: {},
      form: {
        country_id: null,
        state_id: null,
        role_id: null
      }
    }
  },
  created () {
    this.$store.dispatch('users.getUser', this.$route.params.id).then(() => {
      this.loading = false;
      this.form = _.cloneDeep(this.$store.state.users.active);
      console.log('form', this.form);
    });
    this.$store.dispatch('address.getCountries');
  }
}
