import toastr from 'toastr'
import moment from 'moment'
import { funcModal } from 'components/modals'
import datepicker from 'components/datepicker'

const components = {
  funcModal,
  datepicker
}

const computed = {
  types () {
    return _.cloneDeep(this.$store.state.locations.active.types);
  },
}

const methods = {
  open () {
    this.$refs.modal.open();
  },

  submit () {
    this.errors = {};

    if (!this.location_type_id) {
      this.errors.location_type_id = ['The location type field is required'];
      return;
    }

    this.processing = true;

    let data = _.clone(this.form);

    if (data.from_date) {
      data.from_date = moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    if (data.to_date) {
      data.to_date = moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
    }

    this.$store.dispatch('locations.newRate', {
      type_id: this.location_type_id,
      data
    }).then(() => {
      this.processing = false;

      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        this.$refs.modal.close();
        toastr.success('Data has been saved');
      }
    });
  },

  otherDaysSelected (day) {
    const days = [
      'saturday', 'sunday', 'monday', 
      'tuesday', 'wednesday', 'thursday', 'friday'
    ];

    for (const i in days) {
      if (this.form[days[i]] && day != days[i]) {
        return true
      }
    }

    return false
  }
}

export default {
  name: 'new-rate-modal',
  props: ['onClose'],
  computed,
  components,
  methods,
  data () {
    return {
      errors: {},
      processing: false,
      location_type_id: null,
      form: {
        from_date: null,
        to_date: null,
        price: null,
        saturday: 1,
        sunday: 1,
        monday: 1,
        tuesday: 1,
        wednesday: 1,
        thursday: 1,
        friday: 1,
      }
    }
  },
  created () {

  }
}
