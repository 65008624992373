import Vue from 'vue'
import _ from 'lodash'
import { http, permits } from 'services'

// initial state
const state = () => ({
  all: [],
  active: {
    locations: [],
    photos: []
  },
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  //get all ports (params: {_q: search name, page})
  ['ports.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._get) params._get = null

    params._sort= 'name';

    commit('CLEAR_ERRORS')

    return (http.get('airports', {
      params
    })).then ((res) => {
      commit('GET_ALL_PORTS', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  //get single port by id
  ['ports.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')


    // return;
    return (http.get(`airports/${id}`)).then(port => {
      commit('GET_PORT', port);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    });
  },

  //get locations of a port
  ['ports.getLocations'] ({commit, state}, {airport_id, params}) {
    commit('CLEAR_ERRORS')
    console.log('params', params);
    return (http.get(`airports/${airport_id}/locations?_all=true`, {params})).then(res => {
      commit('GET_PORT_LOCATIONS', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    });
  },

  //update port
  ['ports.edit'] ({dispatch, commit, state}, {id, data, params}) {
    commit('CLEAR_ERRORS');

    return (http.put(`airports/${id}`, data, {params})).then ((res) => {
      commit('EDIT_PORT', res);
    })
    .catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`airports/${id}`)).then ((res) => {
      commit('REMOVE_PORT', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`airports/${id}?_restore=true`)).then ((res) => {
      commit('UNDO_REMOVE_PORT', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.add'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`airports`, data)).then ((res) => {
      commit('NEW_PORT', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.getPhotos'] ({commit, state}, airport_id) {
    commit('CLEAR_ERRORS')
    return (http.get(`airports/${airport_id}/photos?_all=true`)).then(res => {
      commit('GET_PORT_PHOTOS', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    });
  },

  ['ports.uploadImages'] ({commit}, {id, files}) {
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("images[]", files[i]);
      }
    }

    return (http.post(`airports/${id}/photos`, formData, config)).then((res) => {
      console.log('tesstt');
      commit('UPLOAD_IMAGES', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.setFeaturedImage'] ({commit}, id) {
    commit('CLEAR_ERRORS')
    return (http.post(`airport-photos/${id}`)).then((res) => {
      commit('SET_IMAGE_AS_FEATURED', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.deleteImage'] ({dispatch, commit, state}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`airport-photos/${id}`)).then ((res) => {
      commit('REMOVE_IMAGE_FROM_PORT', res)
    })
    .catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_PORTS (state, res) {
    state.all = res.data;
  },

  REMOVE_PORT (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_PORT (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_PORT (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  GET_PORT_LOCATIONS (state, res) {
    state.active.locations = res.data;
    state.active = _.cloneDeep(state.active);
  },

  EDIT_PORT (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  NEW_PORT (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  UPLOAD_IMAGES (state, res) {
    state.active.photos = _.concat(state.active.photos, res.data);
    state.active = _.cloneDeep(state.active);
  },

  SET_IMAGE_AS_FEATURED (state, res) {
    _.forEach(state.active.photos, (photo, i) => {
      state.active.photos[i].featured = 1;
      if (photo.id != res.data.id) {
        state.active.photos[i].featured = 0;
      }
    });

    state.active = _.cloneDeep(state.active);
  },

  REMOVE_IMAGE_FROM_PORT (state, res) {
    state.active.photos = state.active.photos.filter((item) => item.id != res.data.id);
    state.active = _.cloneDeep(state.active);
  },

  GET_PORT_PHOTOS (state, res) {
    state.active.photos = res.data;
    state.active = _.cloneDeep(state.active);
  },

  //ERRORS
  PORTS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
