import env from 'constants/env'
import layout from 'layouts/default'
import {tabs, tab} from 'components/tabs'
import { hasPermits } from 'directives'
import toastr from 'toastr'

const components = {
  layout,
  tabs,
  tab,
}

const directives = {
  hasPermits
}

const computed = {
  currencies () {
    return [].concat(this.$store.state.currencies.all);
  },
}

const methods = {
  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('tripProtections.update', {
      id: this.$route.params.id,
      data: this.formData
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.tripProtections.errors) {
        this.errors = _.cloneDeep(this.$store.state.tripProtections.errors.errors);
        toastr.error(this.$store.state.tripProtections.errors.message);
      } else {
        toastr.success('Data has been updated successfully!');
      }
    })
  },
  gettripProtection () {
    this.loading = true;
    this.$store.dispatch('tripProtections.get', this.$route.params.id).then(() => {
      this.formData = _.cloneDeep(this.$store.state.tripProtections.active);
      this.loading = false;
    });
  },
  wysiwygDescriptionChanged: function(val) {
    this.formData.description = val
  },

  wysiwygShortDescriptionChanged: function(val) {
    this.formData.short_description = val
  },

  createPrice() {
    this.formData.prices.push({
      currency_code: null, price: null
    });
  },

  removePrice(index) {
    this.formData.prices.splice(index, 1)
  }
}

export default {
  name: 'edit-trip-protection',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      errors: {},
      formData: {},
    }
  },
  created () {
    this.gettripProtection();
    this.$store.dispatch('currencies.getAll');
  }
}
