import toastr from 'toastr'
import { funcModal } from 'components/modals'
import { Captcha } from 'services'
import validateReservation from './validateReservation'

const components = {
  funcModal,
  validateReservation
}

const methods = {
  async submit (email, password) {
    this.errors = {};
    this.loading = true;
    let token = await Captcha.execute('user_login');
    this.$store.dispatch('user.login', {email, password, token}).then(() => {
      this.loading = false;
      let errors = this.$store.state.user.errors;

      if (!_.isEmpty(errors)){
        if (!_.isEmpty(errors.error_code) && (errors.error_code == "invalid_credentials" || errors.error_code == "account_inactive" || errors.error_code == "captcha_validation_failed")) {
          this.errors = _.cloneDeep(errors.errors);
          toastr.error(errors.message);
        }
      } else {
        this.$router.push({ name: 'home'});
      }
    });
  },

  openSendEmail () {
    this.$refs.forgotPass.open();
  },

  openValidateReservationModal () {
    this.$refs.validateReservation.open();
  },

  submitSendEmail (modal) {
    this.errors = {};
    this.processing = true;
    this.$store.dispatch('user.forgotPassword', this.forgotPass.email).then(() => {
      this.processing = false;
      let errors;
      if (errors = this.$store.state.user.errors) {
        this.errors = errors.errors;
      } else {
        this.forgotPass.email = null;
        toastr.success("Please check your email");
        modal.close();
      }
    });
  }
}

export default {
  name: "login-layout",
  methods,
  components,
  data () {
    return {
      errors: {},
      forgotPass: {
        email: null
      },
      loading: false,
      processing: false
    }
  },

  mounted () {
    Captcha.init();
  },
  created () {
  }
}
