import { http, permits } from 'services'

// initial state
const state = () => ({
  all: [],
  active: {
    users: [],
  },
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  ['affiliates.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._get) params._get = null

    commit('CLEAR_ERRORS')

    return (http.get('affiliates', {
      params
    })).then ((res) => {
      commit('GET_ALL_AFFILIATES', res);
    }).catch ((res) => {
      commit('AFFILIATES_ERROR', res);
    })
  },

  ['affiliates.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')
    
    return (http.get(`affiliates/${id}`)).then((res) => {
      commit('GET_AFFILIATE', res);
    }).catch ((res) => {
      commit('AFFILIATES_ERROR', res);
    });
  },


  ['affiliates.update'] ({dispatch, commit, state}, {id, data, logo, favicon}) {
    commit('CLEAR_ERRORS');
    let config = {
        headers: {
            'Content-Type': ''
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
    }
    let formData = new FormData();
    let active = data.active ? 1 : 0;

    if (data.daily_email != null) {
      formData.append('daily_email', data.daily_email);
    }
    
    if (data.name != null) {
      formData.append('name',data.name);
    }

    if (data.commission != null) {
      formData.append('commission',data.commission);
    }
    
    formData.append('active',active);
    if (data.attribute != null) {
        formData.append('attribute',data.attribute);
    }
    if (data.website != null) {
        formData.append('website',data.website);
    }

    if (data.host != null) {
      formData.append('host',data.host);
    }

    if (data.email_sender != null) {
      formData.append('email_sender',data.email_sender);
    }

    if (data.term_and_condition_link != null) {
      formData.append('term_and_condition_link',data.term_and_condition_link);
    }

    if (logo.length) {
        formData.append("logo", logo[0]);
    }

    if (favicon.length) {
      formData.append("favicon", favicon[0]);
    }

    if (data.extra_parameters != null) {
      formData.append("extra_parameters", JSON.stringify(data.extra_parameters));
    }

    formData.append("_method", "PUT");

    return (http.post(`affiliates/${id}`, formData, config)).then ((res) => {
      commit('EDIT_AFFILIATE', res);
    })
    .catch ((res) => {
      commit('AFFILIATES_ERROR', res);
    })
  },

  ['affiliates.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`affiliates/${id}`)).then ((res) => {
      commit('REMOVE_AFFILIATE', res);
    }).catch ((res) => {
      commit('AFFILIATES_ERROR', res);
    })
  },

  ['affiliates.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`affiliates/${id}?_restore=true`)).then ((res) => {
      commit('UNDO_REMOVE_AFFILIATE', res);
    }).catch ((res) => {
      commit('AFFILIATES_ERROR', res);
    })
  },

  ['affiliates.create'] ({commit}, {data, logo, favicon}) {

    let config = {
        headers: {
            'Content-Type': ''
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
    }
    let formData = new FormData();
    let active = data.active ? 1 : 0;

    if (data.name != null) {
      formData.append('name',data.name);
    }
    formData.append('active',active);
    if (data.attribute != null) {
        formData.append('attribute',data.attribute);
    }
    if (data.website != null) {
        formData.append('website',data.website);
    }

    if (logo.length) {
        formData.append("logo", logo[0]);
    }

    if (favicon.length) {
      formData.append("favicon", favicon[0]);
    }
  
    commit('CLEAR_ERRORS');
    return (http.post(`affiliates`, formData, config)).then ((res) => {
      commit('NEW_AFFILIATE', res);
    }).catch ((res) => {
      commit('AFFILIATES_ERROR', res);
    })
  },

  ['affiliates.getUsers'] ({ commit, state }, id) {
    commit('CLEAR_ERRORS')

    return (http.get(`affiliates/${id}/users`)).then ((res) => {
      commit('GET_AFFILIATE_USERS', res);
    }).catch ((res) => {
      commit('AFFILIATES_ERROR', res);
    })
  },

  ['affiliates.addUser'] ({ commit, dispatch }, {id, data}) {
    commit('CLEAR_ERRORS')
    return new Promise((resolve, reject) => {
      http.post(`affiliates/${id}/users`, data)
      .then (() => {
        dispatch('affiliates.getUsers', id)
        .then(resolve)
        .catch(reject);
      })
      .catch (reject)
    })
    .catch((res) => {
      commit('AFFILIATES_ERROR', res);
    })
  },

  ['affiliates.updateUser'] ({ commit, dispatch }, {id, affiliate_id, data}) {
    commit('CLEAR_ERRORS')
    return new Promise((resolve, reject) => {
      http.put(`affiliate-users/${id}`, data)
      .then (() => {
        dispatch('affiliates.getUsers', affiliate_id)
        .then(resolve)
        .catch(reject);
      })
      .catch (reject)
    })
    .catch((res) => {
      commit('AFFILIATES_ERROR', res);
    })
  },

  ['affiliates.removeUser'] ({ commit }, {id}) {
    commit('CLEAR_ERRORS')

    return (http.delete(`affiliate-users/${id}`))
    .then ((res) => {
      commit('REMOVE_AFFILIATE_USER', res);
    })
    .catch ((res) => {
      commit('AFFILIATES_ERROR', res);
    })
  }
}

// mutations
const mutations = {
  GET_ALL_AFFILIATES (state, res) {
    state.all = res.data;
  },

  GET_AFFILIATE_USERS (state, res) {
    state.active.users = res.data;
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_AFFILIATE (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_AFFILIATE (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_AFFILIATE (state, res) {
    state.active = _.assign(state.active, res.data);
  },

  EDIT_AFFILIATE (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  NEW_AFFILIATE (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_AFFILIATE_USER (state, res) {
    state.active.users = state.active.users.filter(
      (user) => user.id != res.data.id
    );
  },

  //ERRORS
  AFFILIATES_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
