import { hasPermits } from 'directives'
import { dateFormat } from 'filters'
import { confirm } from 'components/modals'
import newCredit from './newCredit'

let components = {
    confirm,
    newCredit
}

let filters = {
    dateFormat
}

let directives = {
    hasPermits
}

let computed = {
    credits () {
        return this.$store.state.customers.active.credits;
    }
}

const methods = {
    loadPage() {
        this.loading = true;
        this.$store.dispatch('customers.getCredits', this.$route.params.id).then(() => {
            this.loading = false;
        });
    },

    removeCredit(item) {
        this.$refs.deleteConfirm.confirm('Are you sure?', () => {
            this.$set(this.credit_loadings, item.id, true);

            this.$store.dispatch('customers.removeCredit', {
                customer_id: this.$route.params.id,
                credit_id: item.id
            }).then(() => {
                this.$set(this.credit_loadings, item.id, false);

                if (this.$store.state.customers.errors) {
                    this.errors = _.cloneDeep(this.$store.state.customers.errors.errors);
                    if (this.errors.hasOwnProperty('message')) {
                        toastr.error(this.errors.message);
                    }
                }
            })
        });
    },

    openNewCreditModal () {
        this.$refs.newCredit.open();
    }
}

export default {
    name: 'customer-credits',
    methods,
    directives,
    filters,
    components,
    computed,
    data() {
        return {
            loading: false,
            credit_loadings: {}
        }
    },
    created() {
        this.loadPage();
    }
}