import moment from 'moment'
import { hasPermits } from 'directives'
import layout from 'layouts/default'
import pagination from 'components/pagination'
import datepicker from 'components/datepicker'

const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  datepicker,
}
if (!isServer) {
  components.JsonViewer = require('vue-json-viewer').default;
}
const methods = {
  heightOver( column, row) {
    setTimeout(() => {
      let content = document.querySelector(`.logs #column-${column}-${row} .jv-container`);
      if (content) {
        if (content.offsetHeight < content.scrollHeight) {
          $(`#ellipsis-btn-${column}-${row}`).css('display', 'flex');
        } else {
          $(`#ellipsis-btn-${column}-${row}`).css('display', 'none');
          $(`#column-${column}-${row} .jv-container`).css({'max-height': 'unset', 'overflow-y': 'auto'});
        }
      }
    }, 100);
    return true    
  },

  showHiddenItems (row) {
    $(`#row-${row} .jv-container`).css('max-height', 'unset');
    $(`.ellipsis-btn-${row}`).css('display', 'none');
  },

  loadPage (page) {
    this.$router.push({
      query: Object.assign({}, this.$route.query, { page }),
    });
    
    let data = _.cloneDeep(this.filter);
    data['page'] = page;
    data.from_date = data.from_date ? moment(data.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null
    data.to_date = data.to_date ? moment(data.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD') : null

    this.loading = true
    this.$store.dispatch('appLogs.getAll', data).then(() => {
      this.loading = false
    })
  },

}

const computed = {
  logs() {
    return this.$store.state.appLogs.all
  }
}

export default {
  name: 'system-logs',
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      loading: false,
      filter: {
        type: null,
        area: null,
        model_type: null,
        model_id: null,
        from_date: moment().format('MM/DD/YYYY'),
        to_date: moment().format('MM/DD/YYYY'),
      }
    }
  },
  created () {
    
  },
  mounted () {
    this.loadPage(1)
  }
}
